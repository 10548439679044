@charset "UTF-8";
@media print {
  *,
  *::before,
  *::after {
    text-shadow: none !important;
    box-shadow: none !important;
  }
  a:not(.btn) {
    text-decoration: underline;
  }
  abbr[title]::after {
    content: " (" attr(title) ")";
  }
  pre {
    white-space: pre-wrap !important;
  }
  pre,
  blockquote {
    border: 1px solid #adb5bd;
    page-break-inside: avoid;
  }
  thead {
    display: table-header-group;
  }
  tr,
  img {
    page-break-inside: avoid;
  }
  p,
  h2,
  h3 {
    orphans: 3;
    widows: 3;
  }
  h2,
  h3 {
    page-break-after: avoid;
  }
  @page {
    size: a3;
  }
  body {
    min-width: 992px !important;
  }
  .container {
    min-width: 992px !important;
  }
  .navbar {
    display: none;
  }
  .badge {
    border: 1px solid #000000;
  }
  .table {
    border-collapse: collapse !important;
  }
  .table td,
  .table th {
    background-color: #FFFFFF !important;
  }
  .table-bordered th,
  .table-bordered td {
    border: 1px solid #dee2e6 !important;
  }
  .table-dark {
    color: inherit;
  }
  .table-dark th,
  .table-dark td,
  .table-dark thead th,
  .table-dark tbody + tbody {
    border-color: #eceeef;
  }
  .table .thead-dark th {
    color: inherit;
    border-color: #eceeef;
  }
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

html {
  font-family: sans-serif;
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

article, aside, figcaption, figure, footer, header, hgroup, main, nav, section {
  display: block;
}

body {
  margin: 0;
  font-family: "Open Sans", sans-serif, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.538;
  color: #545454;
  text-align: left;
  background-color: #f4f4f4;
}

[tabindex="-1"]:focus {
  outline: 0 !important;
}

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible;
}

h1, h2, h3, h4, h5, h6 {
  margin-top: 0;
  margin-bottom: 0.7692rem;
}

p {
  margin-top: 0;
  margin-bottom: 1rem;
}

abbr[title],
abbr[data-original-title] {
  text-decoration: underline;
  text-decoration: underline dotted;
  cursor: help;
  border-bottom: 0;
  text-decoration-skip-ink: none;
}

address {
  margin-bottom: 1rem;
  font-style: normal;
  line-height: inherit;
}

ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 1rem;
}

ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom: 0;
}

dt {
  font-weight: 700;
}

dd {
  margin-bottom: .5rem;
  margin-left: 0;
}

blockquote {
  margin: 0 0 1rem;
}

b,
strong {
  font-weight: bolder;
}

small {
  font-size: 80%;
}

sub,
sup {
  position: relative;
  font-size: 75%;
  line-height: 0;
  vertical-align: baseline;
}

sub {
  bottom: -.25em;
}

sup {
  top: -.5em;
}

a {
  color: #4ad69e;
  text-decoration: none;
  background-color: transparent;
}

a:hover {
  color: #27ac77;
  text-decoration: underline;
}

a:not([href]):not([tabindex]) {
  color: inherit;
  text-decoration: none;
}

a:not([href]):not([tabindex]):hover, a:not([href]):not([tabindex]):focus {
  color: inherit;
  text-decoration: none;
}

a:not([href]):not([tabindex]):focus {
  outline: 0;
}

pre,
code,
kbd,
samp {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  font-size: 1em;
}

pre {
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto;
}

figure {
  margin: 0 0 1rem;
}

img {
  vertical-align: middle;
  border-style: none;
}

svg {
  overflow: hidden;
  vertical-align: middle;
}

table {
  border-collapse: collapse;
}

caption {
  padding-top: 0.9rem;
  padding-bottom: 0.9rem;
  color: #878787;
  text-align: left;
  caption-side: bottom;
}

th {
  text-align: inherit;
}

label {
  display: inline-block;
  margin-bottom: 0.5rem;
}

button {
  border-radius: 0;
}

button:focus {
  outline: 1px dotted;
  outline: 5px auto -webkit-focus-ring-color;
}

input,
button,
select,
optgroup,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}

button,
input {
  overflow: visible;
}

button,
select {
  text-transform: none;
}

select {
  word-wrap: normal;
}

button,
[type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button;
}

button:not(:disabled),
[type="button"]:not(:disabled),
[type="reset"]:not(:disabled),
[type="submit"]:not(:disabled) {
  cursor: pointer;
}

button::-moz-focus-inner,
[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner {
  padding: 0;
  border-style: none;
}

input[type="radio"],
input[type="checkbox"] {
  box-sizing: border-box;
  padding: 0;
}

input[type="date"],
input[type="time"],
input[type="datetime-local"],
input[type="month"] {
  -webkit-appearance: listbox;
}

textarea {
  overflow: auto;
  resize: vertical;
}

fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0;
}

legend {
  display: block;
  width: 100%;
  max-width: 100%;
  padding: 0;
  margin-bottom: .5rem;
  font-size: 1.5rem;
  line-height: inherit;
  color: inherit;
  white-space: normal;
}

progress {
  vertical-align: baseline;
}

[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
  height: auto;
}

[type="search"] {
  outline-offset: -2px;
  -webkit-appearance: none;
}

[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button;
}

output {
  display: inline-block;
}

summary {
  display: list-item;
  cursor: pointer;
}

template {
  display: none;
}

[hidden] {
  display: none !important;
}

h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6 {
  margin-bottom: 0.7692rem;
  font-weight: 500;
  line-height: 1.1;
}

h1, .h1 {
  font-size: 2.5rem;
}

h2, .h2 {
  font-size: 2rem;
}

h3, .h3 {
  font-size: 1.75rem;
}

h4, .h4 {
  font-size: 1.5rem;
}

h5, .h5 {
  font-size: 1.25rem;
}

h6, .h6 {
  font-size: 1rem;
}

.lead {
  font-size: 1.615rem;
  font-weight: 300;
}

.display-1 {
  font-size: 6rem;
  font-weight: 300;
  line-height: 1.1;
}

.display-2 {
  font-size: 5.5rem;
  font-weight: 300;
  line-height: 1.1;
}

.display-3 {
  font-size: 4.5rem;
  font-weight: 300;
  line-height: 1.1;
}

.display-4 {
  font-size: 3.5rem;
  font-weight: 300;
  line-height: 1.1;
}

hr {
  margin-top: 1rem;
  margin-bottom: 1rem;
  border: 0;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
}

small,
.small {
  font-size: 80%;
  font-weight: 400;
}

mark,
.mark {
  padding: 0.2em;
  background-color: #fcf8e3;
}

.list-unstyled {
  padding-left: 0;
  list-style: none;
}

.list-inline {
  padding-left: 0;
  list-style: none;
}

.list-inline-item {
  display: inline-block;
}

.list-inline-item:not(:last-child) {
  margin-right: 0.5rem;
}

.initialism {
  font-size: 90%;
  text-transform: uppercase;
}

.blockquote {
  margin-bottom: 1rem;
  font-size: 1.308rem;
}

.blockquote-footer {
  display: block;
  font-size: 80%;
  color: #878787;
}

.blockquote-footer::before {
  content: "\2014\00A0";
}

.img-fluid {
  max-width: 100%;
  height: auto;
}

.img-thumbnail {
  padding: 0.25rem;
  background-color: #f4f4f4;
  border: 1px solid #dee2e6;
  max-width: 100%;
  height: auto;
}

.figure {
  display: inline-block;
}

.figure-img {
  margin-bottom: 0.5rem;
  line-height: 1;
}

.figure-caption {
  font-size: 90%;
  color: #878787;
}

code {
  font-size: 87.5%;
  color: #e83e8c;
  word-break: break-word;
}

a > code {
  color: inherit;
}

kbd {
  padding: 0.2rem 0.4rem;
  font-size: 87.5%;
  color: #FFFFFF;
  background-color: #212529;
}

kbd kbd {
  padding: 0;
  font-size: 100%;
  font-weight: 700;
}

pre {
  display: block;
  font-size: 87.5%;
  color: #212529;
}

pre code {
  font-size: inherit;
  color: inherit;
  word-break: normal;
}

.pre-scrollable {
  max-height: 340px;
  overflow-y: scroll;
}

.container {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}

@media (min-width: 576px) {
  .container {
    max-width: 540px;
  }
}

@media (min-width: 768px) {
  .container {
    max-width: 720px;
  }
}

@media (min-width: 992px) {
  .container {
    max-width: 960px;
  }
}

@media (min-width: 1200px) {
  .container {
    max-width: 1140px;
  }
}

.container-fluid {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}

.row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px;
}

.no-gutters {
  margin-right: 0;
  margin-left: 0;
}

.no-gutters > .col,
.no-gutters > [class*="col-"] {
  padding-right: 0;
  padding-left: 0;
}

.col-1, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-10, .col-11, .col-12, .col,
.col-auto, .col-sm-1, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm,
.col-sm-auto, .col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12, .col-md,
.col-md-auto, .col-lg-1, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg,
.col-lg-auto, .col-xl-1, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-10, .col-xl-11, .col-xl-12, .col-xl,
.col-xl-auto {
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
}

.col {
  flex-basis: 0;
  flex-grow: 1;
  max-width: 100%;
}

.col-auto {
  flex: 0 0 auto;
  width: auto;
  max-width: 100%;
}

.col-1 {
  flex: 0 0 8.333333%;
  max-width: 8.333333%;
}

.col-2 {
  flex: 0 0 16.666667%;
  max-width: 16.666667%;
}

.col-3 {
  flex: 0 0 25%;
  max-width: 25%;
}

.col-4 {
  flex: 0 0 33.333333%;
  max-width: 33.333333%;
}

.col-5 {
  flex: 0 0 41.666667%;
  max-width: 41.666667%;
}

.col-6 {
  flex: 0 0 50%;
  max-width: 50%;
}

.col-7 {
  flex: 0 0 58.333333%;
  max-width: 58.333333%;
}

.col-8 {
  flex: 0 0 66.666667%;
  max-width: 66.666667%;
}

.col-9 {
  flex: 0 0 75%;
  max-width: 75%;
}

.col-10 {
  flex: 0 0 83.333333%;
  max-width: 83.333333%;
}

.col-11 {
  flex: 0 0 91.666667%;
  max-width: 91.666667%;
}

.col-12 {
  flex: 0 0 100%;
  max-width: 100%;
}

.order-first {
  order: -1;
}

.order-last {
  order: 13;
}

.order-0 {
  order: 0;
}

.order-1 {
  order: 1;
}

.order-2 {
  order: 2;
}

.order-3 {
  order: 3;
}

.order-4 {
  order: 4;
}

.order-5 {
  order: 5;
}

.order-6 {
  order: 6;
}

.order-7 {
  order: 7;
}

.order-8 {
  order: 8;
}

.order-9 {
  order: 9;
}

.order-10 {
  order: 10;
}

.order-11 {
  order: 11;
}

.order-12 {
  order: 12;
}

.offset-1 {
  margin-left: 8.333333%;
}

.offset-2 {
  margin-left: 16.666667%;
}

.offset-3 {
  margin-left: 25%;
}

.offset-4 {
  margin-left: 33.333333%;
}

.offset-5 {
  margin-left: 41.666667%;
}

.offset-6 {
  margin-left: 50%;
}

.offset-7 {
  margin-left: 58.333333%;
}

.offset-8 {
  margin-left: 66.666667%;
}

.offset-9 {
  margin-left: 75%;
}

.offset-10 {
  margin-left: 83.333333%;
}

.offset-11 {
  margin-left: 91.666667%;
}

@media (min-width: 576px) {
  .col-sm {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }
  .col-sm-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }
  .col-sm-1 {
    flex: 0 0 8.333333%;
    max-width: 8.333333%;
  }
  .col-sm-2 {
    flex: 0 0 16.666667%;
    max-width: 16.666667%;
  }
  .col-sm-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-sm-4 {
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
  }
  .col-sm-5 {
    flex: 0 0 41.666667%;
    max-width: 41.666667%;
  }
  .col-sm-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-sm-7 {
    flex: 0 0 58.333333%;
    max-width: 58.333333%;
  }
  .col-sm-8 {
    flex: 0 0 66.666667%;
    max-width: 66.666667%;
  }
  .col-sm-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-sm-10 {
    flex: 0 0 83.333333%;
    max-width: 83.333333%;
  }
  .col-sm-11 {
    flex: 0 0 91.666667%;
    max-width: 91.666667%;
  }
  .col-sm-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .order-sm-first {
    order: -1;
  }
  .order-sm-last {
    order: 13;
  }
  .order-sm-0 {
    order: 0;
  }
  .order-sm-1 {
    order: 1;
  }
  .order-sm-2 {
    order: 2;
  }
  .order-sm-3 {
    order: 3;
  }
  .order-sm-4 {
    order: 4;
  }
  .order-sm-5 {
    order: 5;
  }
  .order-sm-6 {
    order: 6;
  }
  .order-sm-7 {
    order: 7;
  }
  .order-sm-8 {
    order: 8;
  }
  .order-sm-9 {
    order: 9;
  }
  .order-sm-10 {
    order: 10;
  }
  .order-sm-11 {
    order: 11;
  }
  .order-sm-12 {
    order: 12;
  }
  .offset-sm-0 {
    margin-left: 0;
  }
  .offset-sm-1 {
    margin-left: 8.333333%;
  }
  .offset-sm-2 {
    margin-left: 16.666667%;
  }
  .offset-sm-3 {
    margin-left: 25%;
  }
  .offset-sm-4 {
    margin-left: 33.333333%;
  }
  .offset-sm-5 {
    margin-left: 41.666667%;
  }
  .offset-sm-6 {
    margin-left: 50%;
  }
  .offset-sm-7 {
    margin-left: 58.333333%;
  }
  .offset-sm-8 {
    margin-left: 66.666667%;
  }
  .offset-sm-9 {
    margin-left: 75%;
  }
  .offset-sm-10 {
    margin-left: 83.333333%;
  }
  .offset-sm-11 {
    margin-left: 91.666667%;
  }
}

@media (min-width: 768px) {
  .col-md {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }
  .col-md-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }
  .col-md-1 {
    flex: 0 0 8.333333%;
    max-width: 8.333333%;
  }
  .col-md-2 {
    flex: 0 0 16.666667%;
    max-width: 16.666667%;
  }
  .col-md-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-md-4 {
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
  }
  .col-md-5 {
    flex: 0 0 41.666667%;
    max-width: 41.666667%;
  }
  .col-md-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-md-7 {
    flex: 0 0 58.333333%;
    max-width: 58.333333%;
  }
  .col-md-8 {
    flex: 0 0 66.666667%;
    max-width: 66.666667%;
  }
  .col-md-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-md-10 {
    flex: 0 0 83.333333%;
    max-width: 83.333333%;
  }
  .col-md-11 {
    flex: 0 0 91.666667%;
    max-width: 91.666667%;
  }
  .col-md-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .order-md-first {
    order: -1;
  }
  .order-md-last {
    order: 13;
  }
  .order-md-0 {
    order: 0;
  }
  .order-md-1 {
    order: 1;
  }
  .order-md-2 {
    order: 2;
  }
  .order-md-3 {
    order: 3;
  }
  .order-md-4 {
    order: 4;
  }
  .order-md-5 {
    order: 5;
  }
  .order-md-6 {
    order: 6;
  }
  .order-md-7 {
    order: 7;
  }
  .order-md-8 {
    order: 8;
  }
  .order-md-9 {
    order: 9;
  }
  .order-md-10 {
    order: 10;
  }
  .order-md-11 {
    order: 11;
  }
  .order-md-12 {
    order: 12;
  }
  .offset-md-0 {
    margin-left: 0;
  }
  .offset-md-1 {
    margin-left: 8.333333%;
  }
  .offset-md-2 {
    margin-left: 16.666667%;
  }
  .offset-md-3 {
    margin-left: 25%;
  }
  .offset-md-4 {
    margin-left: 33.333333%;
  }
  .offset-md-5 {
    margin-left: 41.666667%;
  }
  .offset-md-6 {
    margin-left: 50%;
  }
  .offset-md-7 {
    margin-left: 58.333333%;
  }
  .offset-md-8 {
    margin-left: 66.666667%;
  }
  .offset-md-9 {
    margin-left: 75%;
  }
  .offset-md-10 {
    margin-left: 83.333333%;
  }
  .offset-md-11 {
    margin-left: 91.666667%;
  }
}

@media (min-width: 992px) {
  .col-lg {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }
  .col-lg-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }
  .col-lg-1 {
    flex: 0 0 8.333333%;
    max-width: 8.333333%;
  }
  .col-lg-2 {
    flex: 0 0 16.666667%;
    max-width: 16.666667%;
  }
  .col-lg-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-lg-4 {
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
  }
  .col-lg-5 {
    flex: 0 0 41.666667%;
    max-width: 41.666667%;
  }
  .col-lg-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-lg-7 {
    flex: 0 0 58.333333%;
    max-width: 58.333333%;
  }
  .col-lg-8 {
    flex: 0 0 66.666667%;
    max-width: 66.666667%;
  }
  .col-lg-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-lg-10 {
    flex: 0 0 83.333333%;
    max-width: 83.333333%;
  }
  .col-lg-11 {
    flex: 0 0 91.666667%;
    max-width: 91.666667%;
  }
  .col-lg-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .order-lg-first {
    order: -1;
  }
  .order-lg-last {
    order: 13;
  }
  .order-lg-0 {
    order: 0;
  }
  .order-lg-1 {
    order: 1;
  }
  .order-lg-2 {
    order: 2;
  }
  .order-lg-3 {
    order: 3;
  }
  .order-lg-4 {
    order: 4;
  }
  .order-lg-5 {
    order: 5;
  }
  .order-lg-6 {
    order: 6;
  }
  .order-lg-7 {
    order: 7;
  }
  .order-lg-8 {
    order: 8;
  }
  .order-lg-9 {
    order: 9;
  }
  .order-lg-10 {
    order: 10;
  }
  .order-lg-11 {
    order: 11;
  }
  .order-lg-12 {
    order: 12;
  }
  .offset-lg-0 {
    margin-left: 0;
  }
  .offset-lg-1 {
    margin-left: 8.333333%;
  }
  .offset-lg-2 {
    margin-left: 16.666667%;
  }
  .offset-lg-3 {
    margin-left: 25%;
  }
  .offset-lg-4 {
    margin-left: 33.333333%;
  }
  .offset-lg-5 {
    margin-left: 41.666667%;
  }
  .offset-lg-6 {
    margin-left: 50%;
  }
  .offset-lg-7 {
    margin-left: 58.333333%;
  }
  .offset-lg-8 {
    margin-left: 66.666667%;
  }
  .offset-lg-9 {
    margin-left: 75%;
  }
  .offset-lg-10 {
    margin-left: 83.333333%;
  }
  .offset-lg-11 {
    margin-left: 91.666667%;
  }
}

@media (min-width: 1200px) {
  .col-xl {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }
  .col-xl-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }
  .col-xl-1 {
    flex: 0 0 8.333333%;
    max-width: 8.333333%;
  }
  .col-xl-2 {
    flex: 0 0 16.666667%;
    max-width: 16.666667%;
  }
  .col-xl-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-xl-4 {
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
  }
  .col-xl-5 {
    flex: 0 0 41.666667%;
    max-width: 41.666667%;
  }
  .col-xl-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-xl-7 {
    flex: 0 0 58.333333%;
    max-width: 58.333333%;
  }
  .col-xl-8 {
    flex: 0 0 66.666667%;
    max-width: 66.666667%;
  }
  .col-xl-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-xl-10 {
    flex: 0 0 83.333333%;
    max-width: 83.333333%;
  }
  .col-xl-11 {
    flex: 0 0 91.666667%;
    max-width: 91.666667%;
  }
  .col-xl-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .order-xl-first {
    order: -1;
  }
  .order-xl-last {
    order: 13;
  }
  .order-xl-0 {
    order: 0;
  }
  .order-xl-1 {
    order: 1;
  }
  .order-xl-2 {
    order: 2;
  }
  .order-xl-3 {
    order: 3;
  }
  .order-xl-4 {
    order: 4;
  }
  .order-xl-5 {
    order: 5;
  }
  .order-xl-6 {
    order: 6;
  }
  .order-xl-7 {
    order: 7;
  }
  .order-xl-8 {
    order: 8;
  }
  .order-xl-9 {
    order: 9;
  }
  .order-xl-10 {
    order: 10;
  }
  .order-xl-11 {
    order: 11;
  }
  .order-xl-12 {
    order: 12;
  }
  .offset-xl-0 {
    margin-left: 0;
  }
  .offset-xl-1 {
    margin-left: 8.333333%;
  }
  .offset-xl-2 {
    margin-left: 16.666667%;
  }
  .offset-xl-3 {
    margin-left: 25%;
  }
  .offset-xl-4 {
    margin-left: 33.333333%;
  }
  .offset-xl-5 {
    margin-left: 41.666667%;
  }
  .offset-xl-6 {
    margin-left: 50%;
  }
  .offset-xl-7 {
    margin-left: 58.333333%;
  }
  .offset-xl-8 {
    margin-left: 66.666667%;
  }
  .offset-xl-9 {
    margin-left: 75%;
  }
  .offset-xl-10 {
    margin-left: 83.333333%;
  }
  .offset-xl-11 {
    margin-left: 91.666667%;
  }
}

.table {
  width: 100%;
  margin-bottom: 1rem;
  color: #545454;
}

.table th,
.table td {
  padding: 0.9rem;
  vertical-align: top;
  border-top: 1px solid #eceeef;
}

.table thead th {
  vertical-align: bottom;
  border-bottom: 2px solid #eceeef;
}

.table tbody + tbody {
  border-top: 2px solid #eceeef;
}

.table-sm th,
.table-sm td {
  padding: 0.6rem;
}

.table-bordered {
  border: 1px solid #eceeef;
}

.table-bordered th,
.table-bordered td {
  border: 1px solid #eceeef;
}

.table-bordered thead th,
.table-bordered thead td {
  border-bottom-width: 2px;
}

.table-borderless th,
.table-borderless td,
.table-borderless thead th,
.table-borderless tbody + tbody {
  border: 0;
}

.table-striped tbody tr:nth-of-type(odd) {
  background-color: whitesmoke;
}

.table-hover tbody tr:hover {
  color: #545454;
  background-color: rgba(0, 0, 0, 0.075);
}

.table-primary,
.table-primary > th,
.table-primary > td {
  background-color: #c4eedd;
}

.table-primary th,
.table-primary td,
.table-primary thead th,
.table-primary tbody + tbody {
  border-color: #91dfc0;
}

.table-hover .table-primary:hover {
  background-color: #b0e8d2;
}

.table-hover .table-primary:hover > td,
.table-hover .table-primary:hover > th {
  background-color: #b0e8d2;
}

.table-secondary,
.table-secondary > th,
.table-secondary > td {
  background-color: #dddddd;
}

.table-secondary th,
.table-secondary td,
.table-secondary thead th,
.table-secondary tbody + tbody {
  border-color: #c1c1c1;
}

.table-hover .table-secondary:hover {
  background-color: #d0d0d0;
}

.table-hover .table-secondary:hover > td,
.table-hover .table-secondary:hover > th {
  background-color: #d0d0d0;
}

.table-success,
.table-success > th,
.table-success > td {
  background-color: #c4eedd;
}

.table-success th,
.table-success td,
.table-success thead th,
.table-success tbody + tbody {
  border-color: #91dfc0;
}

.table-hover .table-success:hover {
  background-color: #b0e8d2;
}

.table-hover .table-success:hover > td,
.table-hover .table-success:hover > th {
  background-color: #b0e8d2;
}

.table-info,
.table-info > th,
.table-info > td {
  background-color: #d0effb;
}

.table-info th,
.table-info td,
.table-info thead th,
.table-info tbody + tbody {
  border-color: #a8e2f7;
}

.table-hover .table-info:hover {
  background-color: #b9e7f9;
}

.table-hover .table-info:hover > td,
.table-hover .table-info:hover > th {
  background-color: #b9e7f9;
}

.table-warning,
.table-warning > th,
.table-warning > td {
  background-color: #fff5ca;
}

.table-warning th,
.table-warning td,
.table-warning thead th,
.table-warning tbody + tbody {
  border-color: #ffed9d;
}

.table-hover .table-warning:hover {
  background-color: #fff0b1;
}

.table-hover .table-warning:hover > td,
.table-hover .table-warning:hover > th {
  background-color: #fff0b1;
}

.table-danger,
.table-danger > th,
.table-danger > td {
  background-color: #fcd0cb;
}

.table-danger th,
.table-danger td,
.table-danger thead th,
.table-danger tbody + tbody {
  border-color: #f9a89f;
}

.table-hover .table-danger:hover {
  background-color: #fbbab3;
}

.table-hover .table-danger:hover > td,
.table-hover .table-danger:hover > th {
  background-color: #fbbab3;
}

.table-light,
.table-light > th,
.table-light > td {
  background-color: white;
}

.table-light th,
.table-light td,
.table-light thead th,
.table-light tbody + tbody {
  border-color: white;
}

.table-hover .table-light:hover {
  background-color: #f2f2f2;
}

.table-hover .table-light:hover > td,
.table-hover .table-light:hover > th {
  background-color: #f2f2f2;
}

.table-dark,
.table-dark > th,
.table-dark > td {
  background-color: #c6c6c6;
}

.table-dark th,
.table-dark td,
.table-dark thead th,
.table-dark tbody + tbody {
  border-color: #949494;
}

.table-hover .table-dark:hover {
  background-color: #b9b9b9;
}

.table-hover .table-dark:hover > td,
.table-hover .table-dark:hover > th {
  background-color: #b9b9b9;
}

.table-grey,
.table-grey > th,
.table-grey > td {
  background-color: #f8f8f8;
}

.table-grey th,
.table-grey td,
.table-grey thead th,
.table-grey tbody + tbody {
  border-color: #f1f1f1;
}

.table-hover .table-grey:hover {
  background-color: #ebebeb;
}

.table-hover .table-grey:hover > td,
.table-hover .table-grey:hover > th {
  background-color: #ebebeb;
}

.table-noir,
.table-noir > th,
.table-noir > td {
  background-color: #cfcfcf;
}

.table-noir th,
.table-noir td,
.table-noir thead th,
.table-noir tbody + tbody {
  border-color: #a6a6a6;
}

.table-hover .table-noir:hover {
  background-color: #c2c2c2;
}

.table-hover .table-noir:hover > td,
.table-hover .table-noir:hover > th {
  background-color: #c2c2c2;
}

.table-active,
.table-active > th,
.table-active > td {
  background-color: rgba(0, 0, 0, 0.075);
}

.table-hover .table-active:hover {
  background-color: rgba(0, 0, 0, 0.075);
}

.table-hover .table-active:hover > td,
.table-hover .table-active:hover > th {
  background-color: rgba(0, 0, 0, 0.075);
}

.table .thead-dark th {
  color: #FFFFFF;
  background-color: #323232;
  border-color: #454545;
}

.table .thead-light th {
  color: #636c72;
  background-color: #eceeef;
  border-color: #eceeef;
}

.table-dark {
  color: #FFFFFF;
  background-color: #323232;
}

.table-dark th,
.table-dark td,
.table-dark thead th {
  border-color: #454545;
}

.table-dark.table-bordered {
  border: 0;
}

.table-dark.table-striped tbody tr:nth-of-type(odd) {
  background-color: rgba(255, 255, 255, 0.05);
}

.table-dark.table-hover tbody tr:hover {
  color: #FFFFFF;
  background-color: rgba(255, 255, 255, 0.075);
}

@media (max-width: 575.98px) {
  .table-responsive-sm {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
  .table-responsive-sm > .table-bordered {
    border: 0;
  }
}

@media (max-width: 767.98px) {
  .table-responsive-md {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
  .table-responsive-md > .table-bordered {
    border: 0;
  }
}

@media (max-width: 991.98px) {
  .table-responsive-lg {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
  .table-responsive-lg > .table-bordered {
    border: 0;
  }
}

@media (max-width: 1199.98px) {
  .table-responsive-xl {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
  .table-responsive-xl > .table-bordered {
    border: 0;
  }
}

.table-responsive {
  display: block;
  width: 100%;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
}

.table-responsive > .table-bordered {
  border: 0;
}

.form-control {
  display: block;
  width: 100%;
  height: 3.23077rem;
  padding: 0.923077rem 1.307rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1;
  color: #878787;
  background-color: #FFFFFF;
  background-clip: padding-box;
  border: 2px solid #ebebeb;
  border-radius: 0;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .form-control {
    transition: none;
  }
}

.form-control::-ms-expand {
  background-color: transparent;
  border: 0;
}

.form-control:focus {
  color: #878787;
  background-color: #FFFFFF;
  border-color: #89e4bf;
  outline: 0;
  box-shadow: 0 0 0 0 transparent;
}

.form-control::placeholder {
  color: #bababa;
  opacity: 1;
}

.form-control:disabled, .form-control[readonly] {
  background-color: #fafafa;
  opacity: 1;
}

select.form-control:focus::-ms-value {
  color: #878787;
  background-color: #FFFFFF;
}

.form-control-file,
.form-control-range {
  display: block;
  width: 100%;
}

.col-form-label {
  padding-top: calc(0.923077rem + 2px);
  padding-bottom: calc(0.923077rem + 2px);
  margin-bottom: 0;
  font-size: inherit;
  line-height: 1;
}

.col-form-label-lg {
  padding-top: calc(1.15385rem + 2px);
  padding-bottom: calc(1.15385rem + 2px);
  font-size: 1.154rem;
  line-height: 1;
}

.col-form-label-sm {
  padding-top: calc(0.615385rem + 2px);
  padding-bottom: calc(0.615385rem + 2px);
  font-size: 0.8462rem;
  line-height: 1;
}

.form-control-plaintext {
  display: block;
  width: 100%;
  padding-top: 0.923077rem;
  padding-bottom: 0.923077rem;
  margin-bottom: 0;
  line-height: 1;
  color: #545454;
  background-color: transparent;
  border: solid transparent;
  border-width: 2px 0;
}

.form-control-plaintext.form-control-sm, .form-control-plaintext.form-control-lg {
  padding-right: 0;
  padding-left: 0;
}

.form-control-sm {
  height: 2.53846rem;
  padding: 0.615385rem 1.307rem;
  font-size: 0.8462rem;
  line-height: 1;
}

.form-control-lg {
  height: 3.84615rem;
  padding: 1.15385rem 1.307rem;
  font-size: 1.154rem;
  line-height: 1;
}

select.form-control[size], select.form-control[multiple] {
  height: auto;
}

textarea.form-control {
  height: auto;
}

.form-group {
  margin-bottom: 1.5rem;
}

.form-text {
  display: block;
  margin-top: 0.25rem;
}

.form-row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -5px;
  margin-left: -5px;
}

.form-row > .col,
.form-row > [class*="col-"] {
  padding-right: 5px;
  padding-left: 5px;
}

.form-check {
  position: relative;
  display: block;
  padding-left: 1.25rem;
}

.form-check-input {
  position: absolute;
  margin-top: 0.3rem;
  margin-left: -1.25rem;
}

.form-check-input:disabled ~ .form-check-label {
  color: #878787;
}

.form-check-label {
  margin-bottom: 0;
}

.form-check-inline {
  display: inline-flex;
  align-items: center;
  padding-left: 0;
  margin-right: 0.75rem;
}

.form-check-inline .form-check-input {
  position: static;
  margin-top: 0;
  margin-right: 0.3125rem;
  margin-left: 0;
}

.valid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #2cc185;
}

.valid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: .1rem;
  font-size: 0.8462rem;
  line-height: 1.538;
  color: #FFFFFF;
  background-color: rgba(44, 193, 133, 0.9);
}

.was-validated .form-control:valid, .form-control.is-valid {
  border-color: #2cc185;
  padding-right: calc(1em + 1.846154rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%232cc185' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: center right calc(0.25em + 0.461539rem);
  background-size: calc(0.5em + 0.923077rem) calc(0.5em + 0.923077rem);
}

.was-validated .form-control:valid:focus, .form-control.is-valid:focus {
  border-color: #2cc185;
  box-shadow: 0 0 0 0 rgba(44, 193, 133, 0.25);
}

.was-validated .form-control:valid ~ .valid-feedback,
.was-validated .form-control:valid ~ .valid-tooltip, .form-control.is-valid ~ .valid-feedback,
.form-control.is-valid ~ .valid-tooltip {
  display: block;
}

.was-validated textarea.form-control:valid, textarea.form-control.is-valid {
  padding-right: calc(1em + 1.846154rem);
  background-position: top calc(0.25em + 0.461539rem) right calc(0.25em + 0.461539rem);
}

.was-validated .custom-select:valid, .custom-select.is-valid {
  border-color: #2cc185;
  padding-right: calc((1em + 1.846154rem) * 3 / 4 + 2.307rem);
  background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 5'%3e%3cpath fill='%23323232' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") no-repeat right 1.307rem center/8px 10px, url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%232cc185' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e") #FFFFFF no-repeat center right 2.307rem/calc(0.5em + 0.923077rem) calc(0.5em + 0.923077rem);
}

.was-validated .custom-select:valid:focus, .custom-select.is-valid:focus {
  border-color: #2cc185;
  box-shadow: 0 0 0 0 rgba(44, 193, 133, 0.25);
}

.was-validated .custom-select:valid ~ .valid-feedback,
.was-validated .custom-select:valid ~ .valid-tooltip, .custom-select.is-valid ~ .valid-feedback,
.custom-select.is-valid ~ .valid-tooltip {
  display: block;
}

.was-validated .form-control-file:valid ~ .valid-feedback,
.was-validated .form-control-file:valid ~ .valid-tooltip, .form-control-file.is-valid ~ .valid-feedback,
.form-control-file.is-valid ~ .valid-tooltip {
  display: block;
}

.was-validated .form-check-input:valid ~ .form-check-label, .form-check-input.is-valid ~ .form-check-label {
  color: #2cc185;
}

.was-validated .form-check-input:valid ~ .valid-feedback,
.was-validated .form-check-input:valid ~ .valid-tooltip, .form-check-input.is-valid ~ .valid-feedback,
.form-check-input.is-valid ~ .valid-tooltip {
  display: block;
}

.was-validated .custom-control-input:valid ~ .custom-control-label, .custom-control-input.is-valid ~ .custom-control-label {
  color: #2cc185;
}

.was-validated .custom-control-input:valid ~ .custom-control-label::before, .custom-control-input.is-valid ~ .custom-control-label::before {
  border-color: #2cc185;
}

.was-validated .custom-control-input:valid ~ .valid-feedback,
.was-validated .custom-control-input:valid ~ .valid-tooltip, .custom-control-input.is-valid ~ .valid-feedback,
.custom-control-input.is-valid ~ .valid-tooltip {
  display: block;
}

.was-validated .custom-control-input:valid:checked ~ .custom-control-label::before, .custom-control-input.is-valid:checked ~ .custom-control-label::before {
  border-color: #4ad69e;
  background-color: #4ad69e;
}

.was-validated .custom-control-input:valid:focus ~ .custom-control-label::before, .custom-control-input.is-valid:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 0 rgba(44, 193, 133, 0.25);
}

.was-validated .custom-control-input:valid:focus:not(:checked) ~ .custom-control-label::before, .custom-control-input.is-valid:focus:not(:checked) ~ .custom-control-label::before {
  border-color: #2cc185;
}

.was-validated .custom-file-input:valid ~ .custom-file-label, .custom-file-input.is-valid ~ .custom-file-label {
  border-color: #2cc185;
}

.was-validated .custom-file-input:valid ~ .valid-feedback,
.was-validated .custom-file-input:valid ~ .valid-tooltip, .custom-file-input.is-valid ~ .valid-feedback,
.custom-file-input.is-valid ~ .valid-tooltip {
  display: block;
}

.was-validated .custom-file-input:valid:focus ~ .custom-file-label, .custom-file-input.is-valid:focus ~ .custom-file-label {
  border-color: #2cc185;
  box-shadow: 0 0 0 0 rgba(44, 193, 133, 0.25);
}

.invalid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #F45846;
}

.invalid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: .1rem;
  font-size: 0.8462rem;
  line-height: 1.538;
  color: #FFFFFF;
  background-color: rgba(244, 88, 70, 0.9);
}

.was-validated .form-control:invalid, .form-control.is-invalid {
  border-color: #F45846;
  padding-right: calc(1em + 1.846154rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23F45846' viewBox='-2 -2 7 7'%3e%3cpath stroke='%23F45846' d='M0 0l3 3m0-3L0 3'/%3e%3ccircle r='.5'/%3e%3ccircle cx='3' r='.5'/%3e%3ccircle cy='3' r='.5'/%3e%3ccircle cx='3' cy='3' r='.5'/%3e%3c/svg%3E");
  background-repeat: no-repeat;
  background-position: center right calc(0.25em + 0.461539rem);
  background-size: calc(0.5em + 0.923077rem) calc(0.5em + 0.923077rem);
}

.was-validated .form-control:invalid:focus, .form-control.is-invalid:focus {
  border-color: #F45846;
  box-shadow: 0 0 0 0 rgba(244, 88, 70, 0.25);
}

.was-validated .form-control:invalid ~ .invalid-feedback,
.was-validated .form-control:invalid ~ .invalid-tooltip, .form-control.is-invalid ~ .invalid-feedback,
.form-control.is-invalid ~ .invalid-tooltip {
  display: block;
}

.was-validated textarea.form-control:invalid, textarea.form-control.is-invalid {
  padding-right: calc(1em + 1.846154rem);
  background-position: top calc(0.25em + 0.461539rem) right calc(0.25em + 0.461539rem);
}

.was-validated .custom-select:invalid, .custom-select.is-invalid {
  border-color: #F45846;
  padding-right: calc((1em + 1.846154rem) * 3 / 4 + 2.307rem);
  background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 5'%3e%3cpath fill='%23323232' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") no-repeat right 1.307rem center/8px 10px, url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23F45846' viewBox='-2 -2 7 7'%3e%3cpath stroke='%23F45846' d='M0 0l3 3m0-3L0 3'/%3e%3ccircle r='.5'/%3e%3ccircle cx='3' r='.5'/%3e%3ccircle cy='3' r='.5'/%3e%3ccircle cx='3' cy='3' r='.5'/%3e%3c/svg%3E") #FFFFFF no-repeat center right 2.307rem/calc(0.5em + 0.923077rem) calc(0.5em + 0.923077rem);
}

.was-validated .custom-select:invalid:focus, .custom-select.is-invalid:focus {
  border-color: #F45846;
  box-shadow: 0 0 0 0 rgba(244, 88, 70, 0.25);
}

.was-validated .custom-select:invalid ~ .invalid-feedback,
.was-validated .custom-select:invalid ~ .invalid-tooltip, .custom-select.is-invalid ~ .invalid-feedback,
.custom-select.is-invalid ~ .invalid-tooltip {
  display: block;
}

.was-validated .form-control-file:invalid ~ .invalid-feedback,
.was-validated .form-control-file:invalid ~ .invalid-tooltip, .form-control-file.is-invalid ~ .invalid-feedback,
.form-control-file.is-invalid ~ .invalid-tooltip {
  display: block;
}

.was-validated .form-check-input:invalid ~ .form-check-label, .form-check-input.is-invalid ~ .form-check-label {
  color: #F45846;
}

.was-validated .form-check-input:invalid ~ .invalid-feedback,
.was-validated .form-check-input:invalid ~ .invalid-tooltip, .form-check-input.is-invalid ~ .invalid-feedback,
.form-check-input.is-invalid ~ .invalid-tooltip {
  display: block;
}

.was-validated .custom-control-input:invalid ~ .custom-control-label, .custom-control-input.is-invalid ~ .custom-control-label {
  color: #F45846;
}

.was-validated .custom-control-input:invalid ~ .custom-control-label::before, .custom-control-input.is-invalid ~ .custom-control-label::before {
  border-color: #F45846;
}

.was-validated .custom-control-input:invalid ~ .invalid-feedback,
.was-validated .custom-control-input:invalid ~ .invalid-tooltip, .custom-control-input.is-invalid ~ .invalid-feedback,
.custom-control-input.is-invalid ~ .invalid-tooltip {
  display: block;
}

.was-validated .custom-control-input:invalid:checked ~ .custom-control-label::before, .custom-control-input.is-invalid:checked ~ .custom-control-label::before {
  border-color: #f78376;
  background-color: #f78376;
}

.was-validated .custom-control-input:invalid:focus ~ .custom-control-label::before, .custom-control-input.is-invalid:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 0 rgba(244, 88, 70, 0.25);
}

.was-validated .custom-control-input:invalid:focus:not(:checked) ~ .custom-control-label::before, .custom-control-input.is-invalid:focus:not(:checked) ~ .custom-control-label::before {
  border-color: #F45846;
}

.was-validated .custom-file-input:invalid ~ .custom-file-label, .custom-file-input.is-invalid ~ .custom-file-label {
  border-color: #F45846;
}

.was-validated .custom-file-input:invalid ~ .invalid-feedback,
.was-validated .custom-file-input:invalid ~ .invalid-tooltip, .custom-file-input.is-invalid ~ .invalid-feedback,
.custom-file-input.is-invalid ~ .invalid-tooltip {
  display: block;
}

.was-validated .custom-file-input:invalid:focus ~ .custom-file-label, .custom-file-input.is-invalid:focus ~ .custom-file-label {
  border-color: #F45846;
  box-shadow: 0 0 0 0 rgba(244, 88, 70, 0.25);
}

.form-inline {
  display: flex;
  flex-flow: row wrap;
  align-items: center;
}

.form-inline .form-check {
  width: 100%;
}

@media (min-width: 576px) {
  .form-inline label {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 0;
  }
  .form-inline .form-group {
    display: flex;
    flex: 0 0 auto;
    flex-flow: row wrap;
    align-items: center;
    margin-bottom: 0;
  }
  .form-inline .form-control {
    display: inline-block;
    width: auto;
    vertical-align: middle;
  }
  .form-inline .form-control-plaintext {
    display: inline-block;
  }
  .form-inline .input-group,
  .form-inline .custom-select {
    width: auto;
  }
  .form-inline .form-check {
    display: flex;
    align-items: center;
    justify-content: center;
    width: auto;
    padding-left: 0;
  }
  .form-inline .form-check-input {
    position: relative;
    flex-shrink: 0;
    margin-top: 0;
    margin-right: 0.25rem;
    margin-left: 0;
  }
  .form-inline .custom-control {
    align-items: center;
    justify-content: center;
  }
  .form-inline .custom-control-label {
    margin-bottom: 0;
  }
}

.btn {
  display: inline-block;
  font-weight: 400;
  color: #545454;
  text-align: center;
  vertical-align: middle;
  user-select: none;
  background-color: transparent;
  border: 1px solid transparent;
  padding: 0.81rem 0.7692rem;
  font-size: 1rem;
  line-height: 1;
  border-radius: 0;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .btn {
    transition: none;
  }
}

.btn:hover {
  color: #545454;
  text-decoration: none;
}

.btn:focus, .btn.focus {
  outline: 0;
  box-shadow: none;
}

.btn.disabled, .btn:disabled {
  opacity: 0.65;
}

a.btn.disabled,
fieldset:disabled a.btn {
  pointer-events: none;
}

.btn-primary {
  color: #FFFFFF;
  background-color: #2cc185;
  border-color: #2cc185;
}

.btn-primary:hover {
  color: #FFFFFF;
  background-color: #25a270;
  border-color: #239768;
}

.btn-primary:focus, .btn-primary.focus {
  box-shadow: 0 0 0 0 rgba(76, 202, 151, 0.5);
}

.btn-primary.disabled, .btn-primary:disabled {
  color: #FFFFFF;
  background-color: #2cc185;
  border-color: #2cc185;
}

.btn-primary:not(:disabled):not(.disabled):active, .btn-primary:not(:disabled):not(.disabled).active,
.show > .btn-primary.dropdown-toggle {
  color: #FFFFFF;
  background-color: #239768;
  border-color: #208d61;
}

.btn-primary:not(:disabled):not(.disabled):active:focus, .btn-primary:not(:disabled):not(.disabled).active:focus,
.show > .btn-primary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0 rgba(76, 202, 151, 0.5);
}

.btn-secondary, .note-btn-group .btn-default {
  color: #FFFFFF;
  background-color: #878787;
  border-color: #878787;
}

.btn-secondary:hover, .note-btn-group .btn-default:hover {
  color: #FFFFFF;
  background-color: #747474;
  border-color: #6e6e6e;
}

.btn-secondary:focus, .note-btn-group .btn-default:focus, .btn-secondary.focus, .note-btn-group .focus.btn-default {
  box-shadow: 0 0 0 0 rgba(153, 153, 153, 0.5);
}

.btn-secondary.disabled, .note-btn-group .disabled.btn-default, .btn-secondary:disabled, .note-btn-group .btn-default:disabled {
  color: #FFFFFF;
  background-color: #878787;
  border-color: #878787;
}

.btn-secondary:not(:disabled):not(.disabled):active, .note-btn-group .btn-default:not(:disabled):not(.disabled):active, .btn-secondary:not(:disabled):not(.disabled).active, .note-btn-group .btn-default:not(:disabled):not(.disabled).active,
.show > .btn-secondary.dropdown-toggle,
.note-btn-group .show > .dropdown-toggle.btn-default {
  color: #FFFFFF;
  background-color: #6e6e6e;
  border-color: #676767;
}

.btn-secondary:not(:disabled):not(.disabled):active:focus, .note-btn-group .btn-default:not(:disabled):not(.disabled):active:focus, .btn-secondary:not(:disabled):not(.disabled).active:focus, .note-btn-group .btn-default:not(:disabled):not(.disabled).active:focus,
.show > .btn-secondary.dropdown-toggle:focus,
.note-btn-group .show > .dropdown-toggle.btn-default:focus {
  box-shadow: 0 0 0 0 rgba(153, 153, 153, 0.5);
}

.btn-success {
  color: #FFFFFF;
  background-color: #2cc185;
  border-color: #2cc185;
}

.btn-success:hover {
  color: #FFFFFF;
  background-color: #25a270;
  border-color: #239768;
}

.btn-success:focus, .btn-success.focus {
  box-shadow: 0 0 0 0 rgba(76, 202, 151, 0.5);
}

.btn-success.disabled, .btn-success:disabled {
  color: #FFFFFF;
  background-color: #2cc185;
  border-color: #2cc185;
}

.btn-success:not(:disabled):not(.disabled):active, .btn-success:not(:disabled):not(.disabled).active,
.show > .btn-success.dropdown-toggle {
  color: #FFFFFF;
  background-color: #239768;
  border-color: #208d61;
}

.btn-success:not(:disabled):not(.disabled):active:focus, .btn-success:not(:disabled):not(.disabled).active:focus,
.show > .btn-success.dropdown-toggle:focus {
  box-shadow: 0 0 0 0 rgba(76, 202, 151, 0.5);
}

.btn-info {
  color: #212529;
  background-color: #58C7EF;
  border-color: #58C7EF;
}

.btn-info:hover {
  color: #212529;
  background-color: #35bbec;
  border-color: #29b7eb;
}

.btn-info:focus, .btn-info.focus {
  box-shadow: 0 0 0 0 rgba(80, 175, 209, 0.5);
}

.btn-info.disabled, .btn-info:disabled {
  color: #212529;
  background-color: #58C7EF;
  border-color: #58C7EF;
}

.btn-info:not(:disabled):not(.disabled):active, .btn-info:not(:disabled):not(.disabled).active,
.show > .btn-info.dropdown-toggle {
  color: #FFFFFF;
  background-color: #29b7eb;
  border-color: #1eb3e9;
}

.btn-info:not(:disabled):not(.disabled):active:focus, .btn-info:not(:disabled):not(.disabled).active:focus,
.show > .btn-info.dropdown-toggle:focus {
  box-shadow: 0 0 0 0 rgba(80, 175, 209, 0.5);
}

.btn-warning {
  color: #212529;
  background-color: #FFDC42;
  border-color: #FFDC42;
}

.btn-warning:hover {
  color: #212529;
  background-color: #ffd51c;
  border-color: #ffd30f;
}

.btn-warning:focus, .btn-warning.focus {
  box-shadow: 0 0 0 0 rgba(222, 193, 62, 0.5);
}

.btn-warning.disabled, .btn-warning:disabled {
  color: #212529;
  background-color: #FFDC42;
  border-color: #FFDC42;
}

.btn-warning:not(:disabled):not(.disabled):active, .btn-warning:not(:disabled):not(.disabled).active,
.show > .btn-warning.dropdown-toggle {
  color: #212529;
  background-color: #ffd30f;
  border-color: #ffd002;
}

.btn-warning:not(:disabled):not(.disabled):active:focus, .btn-warning:not(:disabled):not(.disabled).active:focus,
.show > .btn-warning.dropdown-toggle:focus {
  box-shadow: 0 0 0 0 rgba(222, 193, 62, 0.5);
}

.btn-danger {
  color: #FFFFFF;
  background-color: #F45846;
  border-color: #F45846;
}

.btn-danger:hover {
  color: #FFFFFF;
  background-color: #f23722;
  border-color: #f12d16;
}

.btn-danger:focus, .btn-danger.focus {
  box-shadow: 0 0 0 0 rgba(246, 113, 98, 0.5);
}

.btn-danger.disabled, .btn-danger:disabled {
  color: #FFFFFF;
  background-color: #F45846;
  border-color: #F45846;
}

.btn-danger:not(:disabled):not(.disabled):active, .btn-danger:not(:disabled):not(.disabled).active,
.show > .btn-danger.dropdown-toggle {
  color: #FFFFFF;
  background-color: #f12d16;
  border-color: #ec250e;
}

.btn-danger:not(:disabled):not(.disabled):active:focus, .btn-danger:not(:disabled):not(.disabled).active:focus,
.show > .btn-danger.dropdown-toggle:focus {
  box-shadow: 0 0 0 0 rgba(246, 113, 98, 0.5);
}

.btn-light {
  color: #212529;
  background-color: #FFFFFF;
  border-color: #FFFFFF;
}

.btn-light:hover {
  color: #212529;
  background-color: #ececec;
  border-color: #e6e6e6;
}

.btn-light:focus, .btn-light.focus {
  box-shadow: 0 0 0 0 rgba(222, 222, 223, 0.5);
}

.btn-light.disabled, .btn-light:disabled {
  color: #212529;
  background-color: #FFFFFF;
  border-color: #FFFFFF;
}

.btn-light:not(:disabled):not(.disabled):active, .btn-light:not(:disabled):not(.disabled).active,
.show > .btn-light.dropdown-toggle {
  color: #212529;
  background-color: #e6e6e6;
  border-color: #dfdfdf;
}

.btn-light:not(:disabled):not(.disabled):active:focus, .btn-light:not(:disabled):not(.disabled).active:focus,
.show > .btn-light.dropdown-toggle:focus {
  box-shadow: 0 0 0 0 rgba(222, 222, 223, 0.5);
}

.btn-dark {
  color: #FFFFFF;
  background-color: #323232;
  border-color: #323232;
}

.btn-dark:hover {
  color: #FFFFFF;
  background-color: #1f1f1f;
  border-color: #191919;
}

.btn-dark:focus, .btn-dark.focus {
  box-shadow: 0 0 0 0 rgba(81, 81, 81, 0.5);
}

.btn-dark.disabled, .btn-dark:disabled {
  color: #FFFFFF;
  background-color: #323232;
  border-color: #323232;
}

.btn-dark:not(:disabled):not(.disabled):active, .btn-dark:not(:disabled):not(.disabled).active,
.show > .btn-dark.dropdown-toggle {
  color: #FFFFFF;
  background-color: #191919;
  border-color: #121212;
}

.btn-dark:not(:disabled):not(.disabled):active:focus, .btn-dark:not(:disabled):not(.disabled).active:focus,
.show > .btn-dark.dropdown-toggle:focus {
  box-shadow: 0 0 0 0 rgba(81, 81, 81, 0.5);
}

.btn-grey {
  color: #212529;
  background-color: #e5e5e5;
  border-color: #e5e5e5;
}

.btn-grey:hover {
  color: #212529;
  background-color: #d2d2d2;
  border-color: #cccccc;
}

.btn-grey:focus, .btn-grey.focus {
  box-shadow: 0 0 0 0 rgba(200, 200, 201, 0.5);
}

.btn-grey.disabled, .btn-grey:disabled {
  color: #212529;
  background-color: #e5e5e5;
  border-color: #e5e5e5;
}

.btn-grey:not(:disabled):not(.disabled):active, .btn-grey:not(:disabled):not(.disabled).active,
.show > .btn-grey.dropdown-toggle {
  color: #212529;
  background-color: #cccccc;
  border-color: #c5c5c5;
}

.btn-grey:not(:disabled):not(.disabled):active:focus, .btn-grey:not(:disabled):not(.disabled).active:focus,
.show > .btn-grey.dropdown-toggle:focus {
  box-shadow: 0 0 0 0 rgba(200, 200, 201, 0.5);
}

.btn-noir {
  color: #FFFFFF;
  background-color: #545454;
  border-color: #545454;
}

.btn-noir:hover {
  color: #FFFFFF;
  background-color: #414141;
  border-color: #3b3b3b;
}

.btn-noir:focus, .btn-noir.focus {
  box-shadow: 0 0 0 0 rgba(110, 110, 110, 0.5);
}

.btn-noir.disabled, .btn-noir:disabled {
  color: #FFFFFF;
  background-color: #545454;
  border-color: #545454;
}

.btn-noir:not(:disabled):not(.disabled):active, .btn-noir:not(:disabled):not(.disabled).active,
.show > .btn-noir.dropdown-toggle {
  color: #FFFFFF;
  background-color: #3b3b3b;
  border-color: #343434;
}

.btn-noir:not(:disabled):not(.disabled):active:focus, .btn-noir:not(:disabled):not(.disabled).active:focus,
.show > .btn-noir.dropdown-toggle:focus {
  box-shadow: 0 0 0 0 rgba(110, 110, 110, 0.5);
}

.btn-outline-primary {
  color: #2cc185;
  border-color: #2cc185;
}

.btn-outline-primary:hover {
  color: #FFFFFF;
  background-color: #2cc185;
  border-color: #2cc185;
}

.btn-outline-primary:focus, .btn-outline-primary.focus {
  box-shadow: 0 0 0 0 rgba(44, 193, 133, 0.5);
}

.btn-outline-primary.disabled, .btn-outline-primary:disabled {
  color: #2cc185;
  background-color: transparent;
}

.btn-outline-primary:not(:disabled):not(.disabled):active, .btn-outline-primary:not(:disabled):not(.disabled).active,
.show > .btn-outline-primary.dropdown-toggle {
  color: #FFFFFF;
  background-color: #2cc185;
  border-color: #2cc185;
}

.btn-outline-primary:not(:disabled):not(.disabled):active:focus, .btn-outline-primary:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-primary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0 rgba(44, 193, 133, 0.5);
}

.btn-outline-secondary {
  color: #878787;
  border-color: #878787;
}

.btn-outline-secondary:hover {
  color: #FFFFFF;
  background-color: #878787;
  border-color: #878787;
}

.btn-outline-secondary:focus, .btn-outline-secondary.focus {
  box-shadow: 0 0 0 0 rgba(135, 135, 135, 0.5);
}

.btn-outline-secondary.disabled, .btn-outline-secondary:disabled {
  color: #878787;
  background-color: transparent;
}

.btn-outline-secondary:not(:disabled):not(.disabled):active, .btn-outline-secondary:not(:disabled):not(.disabled).active,
.show > .btn-outline-secondary.dropdown-toggle {
  color: #FFFFFF;
  background-color: #878787;
  border-color: #878787;
}

.btn-outline-secondary:not(:disabled):not(.disabled):active:focus, .btn-outline-secondary:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-secondary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0 rgba(135, 135, 135, 0.5);
}

.btn-outline-success {
  color: #2cc185;
  border-color: #2cc185;
}

.btn-outline-success:hover {
  color: #FFFFFF;
  background-color: #2cc185;
  border-color: #2cc185;
}

.btn-outline-success:focus, .btn-outline-success.focus {
  box-shadow: 0 0 0 0 rgba(44, 193, 133, 0.5);
}

.btn-outline-success.disabled, .btn-outline-success:disabled {
  color: #2cc185;
  background-color: transparent;
}

.btn-outline-success:not(:disabled):not(.disabled):active, .btn-outline-success:not(:disabled):not(.disabled).active,
.show > .btn-outline-success.dropdown-toggle {
  color: #FFFFFF;
  background-color: #2cc185;
  border-color: #2cc185;
}

.btn-outline-success:not(:disabled):not(.disabled):active:focus, .btn-outline-success:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-success.dropdown-toggle:focus {
  box-shadow: 0 0 0 0 rgba(44, 193, 133, 0.5);
}

.btn-outline-info {
  color: #58C7EF;
  border-color: #58C7EF;
}

.btn-outline-info:hover {
  color: #212529;
  background-color: #58C7EF;
  border-color: #58C7EF;
}

.btn-outline-info:focus, .btn-outline-info.focus {
  box-shadow: 0 0 0 0 rgba(88, 199, 239, 0.5);
}

.btn-outline-info.disabled, .btn-outline-info:disabled {
  color: #58C7EF;
  background-color: transparent;
}

.btn-outline-info:not(:disabled):not(.disabled):active, .btn-outline-info:not(:disabled):not(.disabled).active,
.show > .btn-outline-info.dropdown-toggle {
  color: #212529;
  background-color: #58C7EF;
  border-color: #58C7EF;
}

.btn-outline-info:not(:disabled):not(.disabled):active:focus, .btn-outline-info:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-info.dropdown-toggle:focus {
  box-shadow: 0 0 0 0 rgba(88, 199, 239, 0.5);
}

.btn-outline-warning {
  color: #FFDC42;
  border-color: #FFDC42;
}

.btn-outline-warning:hover {
  color: #212529;
  background-color: #FFDC42;
  border-color: #FFDC42;
}

.btn-outline-warning:focus, .btn-outline-warning.focus {
  box-shadow: 0 0 0 0 rgba(255, 220, 66, 0.5);
}

.btn-outline-warning.disabled, .btn-outline-warning:disabled {
  color: #FFDC42;
  background-color: transparent;
}

.btn-outline-warning:not(:disabled):not(.disabled):active, .btn-outline-warning:not(:disabled):not(.disabled).active,
.show > .btn-outline-warning.dropdown-toggle {
  color: #212529;
  background-color: #FFDC42;
  border-color: #FFDC42;
}

.btn-outline-warning:not(:disabled):not(.disabled):active:focus, .btn-outline-warning:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-warning.dropdown-toggle:focus {
  box-shadow: 0 0 0 0 rgba(255, 220, 66, 0.5);
}

.btn-outline-danger {
  color: #F45846;
  border-color: #F45846;
}

.btn-outline-danger:hover {
  color: #FFFFFF;
  background-color: #F45846;
  border-color: #F45846;
}

.btn-outline-danger:focus, .btn-outline-danger.focus {
  box-shadow: 0 0 0 0 rgba(244, 88, 70, 0.5);
}

.btn-outline-danger.disabled, .btn-outline-danger:disabled {
  color: #F45846;
  background-color: transparent;
}

.btn-outline-danger:not(:disabled):not(.disabled):active, .btn-outline-danger:not(:disabled):not(.disabled).active,
.show > .btn-outline-danger.dropdown-toggle {
  color: #FFFFFF;
  background-color: #F45846;
  border-color: #F45846;
}

.btn-outline-danger:not(:disabled):not(.disabled):active:focus, .btn-outline-danger:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-danger.dropdown-toggle:focus {
  box-shadow: 0 0 0 0 rgba(244, 88, 70, 0.5);
}

.btn-outline-light {
  color: #FFFFFF;
  border-color: #FFFFFF;
}

.btn-outline-light:hover {
  color: #212529;
  background-color: #FFFFFF;
  border-color: #FFFFFF;
}

.btn-outline-light:focus, .btn-outline-light.focus {
  box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.5);
}

.btn-outline-light.disabled, .btn-outline-light:disabled {
  color: #FFFFFF;
  background-color: transparent;
}

.btn-outline-light:not(:disabled):not(.disabled):active, .btn-outline-light:not(:disabled):not(.disabled).active,
.show > .btn-outline-light.dropdown-toggle {
  color: #212529;
  background-color: #FFFFFF;
  border-color: #FFFFFF;
}

.btn-outline-light:not(:disabled):not(.disabled):active:focus, .btn-outline-light:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-light.dropdown-toggle:focus {
  box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.5);
}

.btn-outline-dark {
  color: #323232;
  border-color: #323232;
}

.btn-outline-dark:hover {
  color: #FFFFFF;
  background-color: #323232;
  border-color: #323232;
}

.btn-outline-dark:focus, .btn-outline-dark.focus {
  box-shadow: 0 0 0 0 rgba(50, 50, 50, 0.5);
}

.btn-outline-dark.disabled, .btn-outline-dark:disabled {
  color: #323232;
  background-color: transparent;
}

.btn-outline-dark:not(:disabled):not(.disabled):active, .btn-outline-dark:not(:disabled):not(.disabled).active,
.show > .btn-outline-dark.dropdown-toggle {
  color: #FFFFFF;
  background-color: #323232;
  border-color: #323232;
}

.btn-outline-dark:not(:disabled):not(.disabled):active:focus, .btn-outline-dark:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-dark.dropdown-toggle:focus {
  box-shadow: 0 0 0 0 rgba(50, 50, 50, 0.5);
}

.btn-outline-grey {
  color: #e5e5e5;
  border-color: #e5e5e5;
}

.btn-outline-grey:hover {
  color: #212529;
  background-color: #e5e5e5;
  border-color: #e5e5e5;
}

.btn-outline-grey:focus, .btn-outline-grey.focus {
  box-shadow: 0 0 0 0 rgba(229, 229, 229, 0.5);
}

.btn-outline-grey.disabled, .btn-outline-grey:disabled {
  color: #e5e5e5;
  background-color: transparent;
}

.btn-outline-grey:not(:disabled):not(.disabled):active, .btn-outline-grey:not(:disabled):not(.disabled).active,
.show > .btn-outline-grey.dropdown-toggle {
  color: #212529;
  background-color: #e5e5e5;
  border-color: #e5e5e5;
}

.btn-outline-grey:not(:disabled):not(.disabled):active:focus, .btn-outline-grey:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-grey.dropdown-toggle:focus {
  box-shadow: 0 0 0 0 rgba(229, 229, 229, 0.5);
}

.btn-outline-noir {
  color: #545454;
  border-color: #545454;
}

.btn-outline-noir:hover {
  color: #FFFFFF;
  background-color: #545454;
  border-color: #545454;
}

.btn-outline-noir:focus, .btn-outline-noir.focus {
  box-shadow: 0 0 0 0 rgba(84, 84, 84, 0.5);
}

.btn-outline-noir.disabled, .btn-outline-noir:disabled {
  color: #545454;
  background-color: transparent;
}

.btn-outline-noir:not(:disabled):not(.disabled):active, .btn-outline-noir:not(:disabled):not(.disabled).active,
.show > .btn-outline-noir.dropdown-toggle {
  color: #FFFFFF;
  background-color: #545454;
  border-color: #545454;
}

.btn-outline-noir:not(:disabled):not(.disabled):active:focus, .btn-outline-noir:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-noir.dropdown-toggle:focus {
  box-shadow: 0 0 0 0 rgba(84, 84, 84, 0.5);
}

.btn-link {
  font-weight: 400;
  color: #4ad69e;
  text-decoration: none;
}

.btn-link:hover {
  color: #27ac77;
  text-decoration: underline;
}

.btn-link:focus, .btn-link.focus {
  text-decoration: underline;
  box-shadow: none;
}

.btn-link:disabled, .btn-link.disabled {
  color: #878787;
  pointer-events: none;
}

.btn-lg, .btn-group-lg > .btn {
  padding: 0.9231rem 0.7692rem;
  font-size: 1.154rem;
  line-height: 1;
  border-radius: 0;
}

.btn-sm, .btn-group-sm > .btn {
  padding: 0.6923rem 0.7692rem;
  font-size: 0.8462rem;
  line-height: 1;
  border-radius: 0;
}

.btn-block {
  display: block;
  width: 100%;
}

.btn-block + .btn-block {
  margin-top: 0.5rem;
}

input[type="submit"].btn-block,
input[type="reset"].btn-block,
input[type="button"].btn-block {
  width: 100%;
}

.fade {
  transition: opacity 0.15s linear;
}

@media (prefers-reduced-motion: reduce) {
  .fade {
    transition: none;
  }
}

.fade:not(.show) {
  opacity: 0;
}

.collapse:not(.show) {
  display: none;
}

.collapsing {
  position: relative;
  height: 0;
  overflow: hidden;
  transition: height 0.35s ease;
}

@media (prefers-reduced-motion: reduce) {
  .collapsing {
    transition: none;
  }
}

.dropup,
.dropright,
.dropdown,
.dropleft {
  position: relative;
}

.dropdown-toggle {
  white-space: nowrap;
}

.dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid;
  border-right: 0.3em solid transparent;
  border-bottom: 0;
  border-left: 0.3em solid transparent;
}

.dropdown-toggle:empty::after {
  margin-left: 0;
}

.dropdown-menu {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1000;
  display: none;
  float: left;
  min-width: 10rem;
  padding: 0.8462rem 0;
  margin: 0.125rem 0 0;
  font-size: 1rem;
  color: #545454;
  text-align: left;
  list-style: none;
  background-color: #FFFFFF;
  background-clip: padding-box;
  border: 0 solid rgba(0, 0, 0, 0.15);
}

.dropdown-menu-left {
  right: auto;
  left: 0;
}

.dropdown-menu-right {
  right: 0;
  left: auto;
}

@media (min-width: 576px) {
  .dropdown-menu-sm-left {
    right: auto;
    left: 0;
  }
  .dropdown-menu-sm-right {
    right: 0;
    left: auto;
  }
}

@media (min-width: 768px) {
  .dropdown-menu-md-left {
    right: auto;
    left: 0;
  }
  .dropdown-menu-md-right {
    right: 0;
    left: auto;
  }
}

@media (min-width: 992px) {
  .dropdown-menu-lg-left {
    right: auto;
    left: 0;
  }
  .dropdown-menu-lg-right {
    right: 0;
    left: auto;
  }
}

@media (min-width: 1200px) {
  .dropdown-menu-xl-left {
    right: auto;
    left: 0;
  }
  .dropdown-menu-xl-right {
    right: 0;
    left: auto;
  }
}

.dropup .dropdown-menu {
  top: auto;
  bottom: 100%;
  margin-top: 0;
  margin-bottom: 0.125rem;
}

.dropup .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0;
  border-right: 0.3em solid transparent;
  border-bottom: 0.3em solid;
  border-left: 0.3em solid transparent;
}

.dropup .dropdown-toggle:empty::after {
  margin-left: 0;
}

.dropright .dropdown-menu {
  top: 0;
  right: auto;
  left: 100%;
  margin-top: 0;
  margin-left: 0.125rem;
}

.dropright .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0;
  border-bottom: 0.3em solid transparent;
  border-left: 0.3em solid;
}

.dropright .dropdown-toggle:empty::after {
  margin-left: 0;
}

.dropright .dropdown-toggle::after {
  vertical-align: 0;
}

.dropleft .dropdown-menu {
  top: 0;
  right: 100%;
  left: auto;
  margin-top: 0;
  margin-right: 0.125rem;
}

.dropleft .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
}

.dropleft .dropdown-toggle::after {
  display: none;
}

.dropleft .dropdown-toggle::before {
  display: inline-block;
  margin-right: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0.3em solid;
  border-bottom: 0.3em solid transparent;
}

.dropleft .dropdown-toggle:empty::after {
  margin-left: 0;
}

.dropleft .dropdown-toggle::before {
  vertical-align: 0;
}

.dropdown-menu[x-placement^="top"], .dropdown-menu[x-placement^="right"], .dropdown-menu[x-placement^="bottom"], .dropdown-menu[x-placement^="left"] {
  right: auto;
  bottom: auto;
}

.dropdown-divider {
  height: 0;
  margin: 0.5rem 0;
  overflow: hidden;
  border-top: 1px solid #eceeef;
}

.dropdown-item {
  display: block;
  width: 100%;
  padding: 0.7rem 1.5rem;
  clear: both;
  font-weight: 400;
  color: #212529;
  text-align: inherit;
  white-space: nowrap;
  background-color: transparent;
  border: 0;
}

.dropdown-item:hover, .dropdown-item:focus {
  color: #16181b;
  text-decoration: none;
  background-color: #f8f9fa;
}

.dropdown-item.active, .dropdown-item:active {
  color: #2cc185;
  text-decoration: none;
  background-color: transparent;
}

.dropdown-item.disabled, .dropdown-item:disabled {
  color: #878787;
  pointer-events: none;
  background-color: transparent;
}

.dropdown-menu.show {
  display: block;
}

.dropdown-header {
  display: block;
  padding: 0.8462rem 1.5rem;
  margin-bottom: 0;
  font-size: 0.8462rem;
  color: #878787;
  white-space: nowrap;
}

.dropdown-item-text {
  display: block;
  padding: 0.7rem 1.5rem;
  color: #212529;
}

.btn-group,
.btn-group-vertical {
  position: relative;
  display: inline-flex;
  vertical-align: middle;
}

.btn-group > .btn,
.btn-group-vertical > .btn {
  position: relative;
  flex: 1 1 auto;
}

.btn-group > .btn:hover,
.btn-group-vertical > .btn:hover {
  z-index: 1;
}

.btn-group > .btn:focus, .btn-group > .btn:active, .btn-group > .btn.active,
.btn-group-vertical > .btn:focus,
.btn-group-vertical > .btn:active,
.btn-group-vertical > .btn.active {
  z-index: 1;
}

.btn-toolbar {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
}

.btn-toolbar .input-group {
  width: auto;
}

.btn-group > .btn:not(:first-child),
.btn-group > .btn-group:not(:first-child) {
  margin-left: -1px;
}

.dropdown-toggle-split {
  padding-right: 0.5769rem;
  padding-left: 0.5769rem;
}

.dropdown-toggle-split::after,
.dropup .dropdown-toggle-split::after,
.dropright .dropdown-toggle-split::after {
  margin-left: 0;
}

.dropleft .dropdown-toggle-split::before {
  margin-right: 0;
}

.btn-sm + .dropdown-toggle-split, .btn-group-sm > .btn + .dropdown-toggle-split {
  padding-right: 0.5769rem;
  padding-left: 0.5769rem;
}

.btn-lg + .dropdown-toggle-split, .btn-group-lg > .btn + .dropdown-toggle-split {
  padding-right: 0.5769rem;
  padding-left: 0.5769rem;
}

.btn-group-vertical {
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}

.btn-group-vertical > .btn,
.btn-group-vertical > .btn-group {
  width: 100%;
}

.btn-group-vertical > .btn:not(:first-child),
.btn-group-vertical > .btn-group:not(:first-child) {
  margin-top: -1px;
}

.btn-group-toggle > .btn,
.btn-group-toggle > .btn-group > .btn {
  margin-bottom: 0;
}

.btn-group-toggle > .btn input[type="radio"],
.btn-group-toggle > .btn input[type="checkbox"],
.btn-group-toggle > .btn-group > .btn input[type="radio"],
.btn-group-toggle > .btn-group > .btn input[type="checkbox"] {
  position: absolute;
  clip: rect(0, 0, 0, 0);
  pointer-events: none;
}

.input-group {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  width: 100%;
}

.input-group > .form-control,
.input-group > .form-control-plaintext,
.input-group > .custom-select,
.input-group > .custom-file {
  position: relative;
  flex: 1 1 auto;
  width: 1%;
  margin-bottom: 0;
}

.input-group > .form-control + .form-control,
.input-group > .form-control + .custom-select,
.input-group > .form-control + .custom-file,
.input-group > .form-control-plaintext + .form-control,
.input-group > .form-control-plaintext + .custom-select,
.input-group > .form-control-plaintext + .custom-file,
.input-group > .custom-select + .form-control,
.input-group > .custom-select + .custom-select,
.input-group > .custom-select + .custom-file,
.input-group > .custom-file + .form-control,
.input-group > .custom-file + .custom-select,
.input-group > .custom-file + .custom-file {
  margin-left: -2px;
}

.input-group > .form-control:focus,
.input-group > .custom-select:focus,
.input-group > .custom-file .custom-file-input:focus ~ .custom-file-label {
  z-index: 3;
}

.input-group > .custom-file .custom-file-input:focus {
  z-index: 4;
}

.input-group > .custom-file {
  display: flex;
  align-items: center;
}

.input-group-prepend,
.input-group-append {
  display: flex;
}

.input-group-prepend .btn,
.input-group-append .btn {
  position: relative;
  z-index: 2;
}

.input-group-prepend .btn:focus,
.input-group-append .btn:focus {
  z-index: 3;
}

.input-group-prepend .btn + .btn,
.input-group-prepend .btn + .input-group-text,
.input-group-prepend .input-group-text + .input-group-text,
.input-group-prepend .input-group-text + .btn,
.input-group-append .btn + .btn,
.input-group-append .btn + .input-group-text,
.input-group-append .input-group-text + .input-group-text,
.input-group-append .input-group-text + .btn {
  margin-left: -2px;
}

.input-group-prepend {
  margin-right: -2px;
}

.input-group-append {
  margin-left: -2px;
}

.input-group-text {
  display: flex;
  align-items: center;
  padding: 0.923077rem 1.307rem;
  margin-bottom: 0;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1;
  color: #878787;
  text-align: center;
  white-space: nowrap;
  background-color: #ebebeb;
  border: 2px solid #ebebeb;
}

.input-group-text input[type="radio"],
.input-group-text input[type="checkbox"] {
  margin-top: 0;
}

.input-group-lg > .form-control:not(textarea),
.input-group-lg > .custom-select {
  height: 3.84615rem;
}

.input-group-lg > .form-control,
.input-group-lg > .custom-select,
.input-group-lg > .input-group-prepend > .input-group-text,
.input-group-lg > .input-group-append > .input-group-text,
.input-group-lg > .input-group-prepend > .btn,
.input-group-lg > .input-group-append > .btn {
  padding: 1.15385rem 1.307rem;
  font-size: 1.154rem;
  line-height: 1;
}

.input-group-sm > .form-control:not(textarea),
.input-group-sm > .custom-select {
  height: 2.53846rem;
}

.input-group-sm > .form-control,
.input-group-sm > .custom-select,
.input-group-sm > .input-group-prepend > .input-group-text,
.input-group-sm > .input-group-append > .input-group-text,
.input-group-sm > .input-group-prepend > .btn,
.input-group-sm > .input-group-append > .btn {
  padding: 0.615385rem 1.307rem;
  font-size: 0.8462rem;
  line-height: 1;
}

.input-group-lg > .custom-select,
.input-group-sm > .custom-select {
  padding-right: 2.307rem;
}

.custom-control {
  position: relative;
  display: block;
  min-height: 1.538rem;
  padding-left: 2.19231rem;
}

.custom-control-inline {
  display: inline-flex;
  margin-right: 1rem;
}

.custom-control-input {
  position: absolute;
  z-index: -1;
  opacity: 0;
}

.custom-control-input:checked ~ .custom-control-label::before {
  color: #2cc185;
  border-color: #FFFFFF;
  background-color: #FFFFFF;
}

.custom-control-input:focus ~ .custom-control-label::before {
  box-shadow: none;
}

.custom-control-input:focus:not(:checked) ~ .custom-control-label::before {
  border-color: #89e4bf;
}

.custom-control-input:not(:disabled):active ~ .custom-control-label::before {
  color: #FFFFFF;
  background-color: #b2edd6;
  border-color: #b2edd6;
}

.custom-control-input:disabled ~ .custom-control-label {
  color: #878787;
}

.custom-control-input:disabled ~ .custom-control-label::before {
  background-color: #fafafa;
}

.custom-control-label {
  position: relative;
  margin-bottom: 0;
  vertical-align: top;
}

.custom-control-label::before {
  position: absolute;
  top: -0.077155rem;
  left: -2.19231rem;
  display: block;
  width: 1.69231rem;
  height: 1.69231rem;
  pointer-events: none;
  content: "";
  background-color: #FFFFFF;
  border: #adb5bd solid 2px;
}

.custom-control-label::after {
  position: absolute;
  top: -0.077155rem;
  left: -2.19231rem;
  display: block;
  width: 1.69231rem;
  height: 1.69231rem;
  content: "";
  background: no-repeat 50% / 50% 50%;
}

.custom-checkbox .custom-control-input:checked ~ .custom-control-label::after {
  background-image: none;
}

.custom-checkbox .custom-control-input:indeterminate ~ .custom-control-label::before {
  border-color: #2cc185;
  background-color: #2cc185;
}

.custom-checkbox .custom-control-input:indeterminate ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 4'%3e%3cpath stroke='%232cc185' d='M0 2h4'/%3e%3c/svg%3e");
}

.custom-checkbox .custom-control-input:disabled:checked ~ .custom-control-label::before {
  background-color: rgba(44, 193, 133, 0.5);
}

.custom-checkbox .custom-control-input:disabled:indeterminate ~ .custom-control-label::before {
  background-color: rgba(44, 193, 133, 0.5);
}

.custom-radio .custom-control-label::before {
  border-radius: 50%;
}

.custom-radio .custom-control-input:checked ~ .custom-control-label::after {
  background-image: none;
}

.custom-radio .custom-control-input:disabled:checked ~ .custom-control-label::before {
  background-color: rgba(44, 193, 133, 0.5);
}

.custom-switch {
  padding-left: 3.461543rem;
}

.custom-switch .custom-control-label::before {
  left: -3.461543rem;
  width: 2.961543rem;
  pointer-events: all;
  border-radius: 0.846155rem;
}

.custom-switch .custom-control-label::after {
  top: calc(-0.077155rem + 4px);
  left: calc(-3.461543rem + 4px);
  width: calc(1.69231rem - 8px);
  height: calc(1.69231rem - 8px);
  background-color: #adb5bd;
  border-radius: 0.846155rem;
  transition: transform 0.15s ease-in-out, none;
}

@media (prefers-reduced-motion: reduce) {
  .custom-switch .custom-control-label::after {
    transition: none;
  }
}

.custom-switch .custom-control-input:checked ~ .custom-control-label::after {
  background-color: #FFFFFF;
  transform: translateX(1.269233rem);
}

.custom-switch .custom-control-input:disabled:checked ~ .custom-control-label::before {
  background-color: rgba(44, 193, 133, 0.5);
}

.custom-select {
  display: inline-block;
  width: 100%;
  height: 3.23077rem;
  padding: 0.923077rem 2.307rem 0.923077rem 1.307rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1;
  color: #878787;
  vertical-align: middle;
  background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 5'%3e%3cpath fill='%23323232' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") no-repeat right 1.307rem center/8px 10px;
  background-color: #FFFFFF;
  border: 2px solid #ebebeb;
  border-radius: 0;
  appearance: none;
}

.custom-select:focus {
  border-color: #89e4bf;
  outline: 0;
  box-shadow: 0 0 0 0 transparent;
}

.custom-select:focus::-ms-value {
  color: #878787;
  background-color: #FFFFFF;
}

.custom-select[multiple], .custom-select[size]:not([size="1"]) {
  height: auto;
  padding-right: 1.307rem;
  background-image: none;
}

.custom-select:disabled {
  color: #878787;
  background-color: #eceeef;
}

.custom-select::-ms-expand {
  display: none;
}

.custom-select-sm {
  height: 2.53846rem;
  padding-top: 0.615385rem;
  padding-bottom: 0.615385rem;
  padding-left: 1.307rem;
  font-size: 0.8462rem;
}

.custom-select-lg {
  height: 3.84615rem;
  padding-top: 1.15385rem;
  padding-bottom: 1.15385rem;
  padding-left: 1.307rem;
  font-size: 1.154rem;
}

.custom-file {
  position: relative;
  display: inline-block;
  width: 100%;
  height: 3.23077rem;
  margin-bottom: 0;
}

.custom-file-input {
  position: relative;
  z-index: 2;
  width: 100%;
  height: 3.23077rem;
  margin: 0;
  opacity: 0;
}

.custom-file-input:focus ~ .custom-file-label {
  border-color: #89e4bf;
  box-shadow: 0 0 0 0 transparent;
}

.custom-file-input:disabled ~ .custom-file-label {
  background-color: #fafafa;
}

.custom-file-input:lang(en) ~ .custom-file-label::after {
  content: "Browse";
}

.custom-file-input ~ .custom-file-label[data-browse]::after {
  content: attr(data-browse);
}

.custom-file-label {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1;
  height: 3.23077rem;
  padding: 0.923077rem 1.307rem;
  font-weight: 400;
  line-height: 1;
  color: #878787;
  background-color: #FFFFFF;
  border: 2px solid #ebebeb;
}

.custom-file-label::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 3;
  display: block;
  height: calc(1em + 1.846154rem);
  padding: 0.923077rem 1.307rem;
  line-height: 1;
  color: #878787;
  content: "Browse";
  background-color: #ebebeb;
  border-left: inherit;
}

.custom-range {
  width: 100%;
  height: calc(1rem + 0);
  padding: 0;
  background-color: transparent;
  appearance: none;
}

.custom-range:focus {
  outline: none;
}

.custom-range:focus::-webkit-slider-thumb {
  box-shadow: 0 0 0 1px #f4f4f4, 0 0 0 0 transparent;
}

.custom-range:focus::-moz-range-thumb {
  box-shadow: 0 0 0 1px #f4f4f4, 0 0 0 0 transparent;
}

.custom-range:focus::-ms-thumb {
  box-shadow: 0 0 0 1px #f4f4f4, 0 0 0 0 transparent;
}

.custom-range::-moz-focus-outer {
  border: 0;
}

.custom-range::-webkit-slider-thumb {
  width: 1rem;
  height: 1rem;
  margin-top: -0.25rem;
  background-color: #2cc185;
  border: 0;
  transition: none;
  appearance: none;
}

@media (prefers-reduced-motion: reduce) {
  .custom-range::-webkit-slider-thumb {
    transition: none;
  }
}

.custom-range::-webkit-slider-thumb:active {
  background-color: #b2edd6;
}

.custom-range::-webkit-slider-runnable-track {
  width: 100%;
  height: 0.5rem;
  color: transparent;
  cursor: pointer;
  background-color: #dee2e6;
  border-color: transparent;
}

.custom-range::-moz-range-thumb {
  width: 1rem;
  height: 1rem;
  background-color: #2cc185;
  border: 0;
  transition: none;
  appearance: none;
}

@media (prefers-reduced-motion: reduce) {
  .custom-range::-moz-range-thumb {
    transition: none;
  }
}

.custom-range::-moz-range-thumb:active {
  background-color: #b2edd6;
}

.custom-range::-moz-range-track {
  width: 100%;
  height: 0.5rem;
  color: transparent;
  cursor: pointer;
  background-color: #dee2e6;
  border-color: transparent;
}

.custom-range::-ms-thumb {
  width: 1rem;
  height: 1rem;
  margin-top: 0;
  margin-right: 0;
  margin-left: 0;
  background-color: #2cc185;
  border: 0;
  transition: none;
  appearance: none;
}

@media (prefers-reduced-motion: reduce) {
  .custom-range::-ms-thumb {
    transition: none;
  }
}

.custom-range::-ms-thumb:active {
  background-color: #b2edd6;
}

.custom-range::-ms-track {
  width: 100%;
  height: 0.5rem;
  color: transparent;
  cursor: pointer;
  background-color: transparent;
  border-color: transparent;
  border-width: 0.5rem;
}

.custom-range::-ms-fill-lower {
  background-color: #dee2e6;
}

.custom-range::-ms-fill-upper {
  margin-right: 15px;
  background-color: #dee2e6;
}

.custom-range:disabled::-webkit-slider-thumb {
  background-color: #adb5bd;
}

.custom-range:disabled::-webkit-slider-runnable-track {
  cursor: default;
}

.custom-range:disabled::-moz-range-thumb {
  background-color: #adb5bd;
}

.custom-range:disabled::-moz-range-track {
  cursor: default;
}

.custom-range:disabled::-ms-thumb {
  background-color: #adb5bd;
}

.custom-control-label::before,
.custom-file-label,
.custom-select {
  transition: none;
}

@media (prefers-reduced-motion: reduce) {
  .custom-control-label::before,
  .custom-file-label,
  .custom-select {
    transition: none;
  }
}

.nav {
  display: flex;
  flex-wrap: wrap;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}

.nav-link {
  display: block;
  padding: 0.885rem 1.5rem;
}

.nav-link:hover, .nav-link:focus {
  text-decoration: none;
}

.nav-link.disabled {
  color: #878787;
  pointer-events: none;
  cursor: default;
}

.nav-tabs {
  border-bottom: 0 solid transparent;
}

.nav-tabs .nav-item {
  margin-bottom: 0;
}

.nav-tabs .nav-link {
  border: 0 solid transparent;
}

.nav-tabs .nav-link:hover, .nav-tabs .nav-link:focus {
  border-color: #eceeef #eceeef transparent;
}

.nav-tabs .nav-link.disabled {
  color: #878787;
  background-color: transparent;
  border-color: transparent;
}

.nav-tabs .nav-link.active,
.nav-tabs .nav-item.show .nav-link {
  color: #636c72;
  background-color: #FFFFFF;
  border-color: #dee2e6 #dee2e6 #FFFFFF;
}

.nav-tabs .dropdown-menu {
  margin-top: 0;
}

.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  color: #FFFFFF;
  background-color: #2cc185;
}

.nav-fill .nav-item {
  flex: 1 1 auto;
  text-align: center;
}

.nav-justified .nav-item {
  flex-basis: 0;
  flex-grow: 1;
  text-align: center;
}

.tab-content > .tab-pane {
  display: none;
}

.tab-content > .active {
  display: block;
}

.navbar {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  padding: 0.5rem 1rem;
}

.navbar > .container,
.navbar > .container-fluid {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
}

.navbar-brand {
  display: inline-block;
  padding-top: 0.766574rem;
  padding-bottom: 0.766574rem;
  margin-right: 1rem;
  font-size: 1.154rem;
  line-height: inherit;
  white-space: nowrap;
}

.navbar-brand:hover, .navbar-brand:focus {
  text-decoration: none;
}

.navbar-nav {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}

.navbar-nav .nav-link {
  padding-right: 0;
  padding-left: 0;
}

.navbar-nav .dropdown-menu {
  position: static;
  float: none;
}

.navbar-text {
  display: inline-block;
  padding-top: 0.885rem;
  padding-bottom: 0.885rem;
}

.navbar-collapse {
  flex-basis: 100%;
  flex-grow: 1;
  align-items: center;
}

.navbar-toggler {
  padding: 0.25rem 0.75rem;
  font-size: 1.154rem;
  line-height: 1;
  background-color: transparent;
  border: 1px solid transparent;
}

.navbar-toggler:hover, .navbar-toggler:focus {
  text-decoration: none;
}

.navbar-toggler-icon {
  display: inline-block;
  width: 1.5em;
  height: 1.5em;
  vertical-align: middle;
  content: "";
  background: no-repeat center center;
  background-size: 100% 100%;
}

@media (max-width: 575.98px) {
  .navbar-expand-sm > .container,
  .navbar-expand-sm > .container-fluid {
    padding-right: 0;
    padding-left: 0;
  }
}

@media (min-width: 576px) {
  .navbar-expand-sm {
    flex-flow: row nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-sm .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-sm .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-sm .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .navbar-expand-sm > .container,
  .navbar-expand-sm > .container-fluid {
    flex-wrap: nowrap;
  }
  .navbar-expand-sm .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-sm .navbar-toggler {
    display: none;
  }
}

@media (max-width: 767.98px) {
  .navbar-expand-md > .container,
  .navbar-expand-md > .container-fluid {
    padding-right: 0;
    padding-left: 0;
  }
}

@media (min-width: 768px) {
  .navbar-expand-md {
    flex-flow: row nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-md .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-md .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-md .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .navbar-expand-md > .container,
  .navbar-expand-md > .container-fluid {
    flex-wrap: nowrap;
  }
  .navbar-expand-md .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-md .navbar-toggler {
    display: none;
  }
}

@media (max-width: 991.98px) {
  .navbar-expand-lg > .container,
  .navbar-expand-lg > .container-fluid {
    padding-right: 0;
    padding-left: 0;
  }
}

@media (min-width: 992px) {
  .navbar-expand-lg {
    flex-flow: row nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-lg .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-lg .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-lg .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .navbar-expand-lg > .container,
  .navbar-expand-lg > .container-fluid {
    flex-wrap: nowrap;
  }
  .navbar-expand-lg .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-lg .navbar-toggler {
    display: none;
  }
}

@media (max-width: 1199.98px) {
  .navbar-expand-xl > .container,
  .navbar-expand-xl > .container-fluid {
    padding-right: 0;
    padding-left: 0;
  }
}

@media (min-width: 1200px) {
  .navbar-expand-xl {
    flex-flow: row nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-xl .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-xl .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-xl .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .navbar-expand-xl > .container,
  .navbar-expand-xl > .container-fluid {
    flex-wrap: nowrap;
  }
  .navbar-expand-xl .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-xl .navbar-toggler {
    display: none;
  }
}

.navbar-expand {
  flex-flow: row nowrap;
  justify-content: flex-start;
}

.navbar-expand > .container,
.navbar-expand > .container-fluid {
  padding-right: 0;
  padding-left: 0;
}

.navbar-expand .navbar-nav {
  flex-direction: row;
}

.navbar-expand .navbar-nav .dropdown-menu {
  position: absolute;
}

.navbar-expand .navbar-nav .nav-link {
  padding-right: 0.5rem;
  padding-left: 0.5rem;
}

.navbar-expand > .container,
.navbar-expand > .container-fluid {
  flex-wrap: nowrap;
}

.navbar-expand .navbar-collapse {
  display: flex !important;
  flex-basis: auto;
}

.navbar-expand .navbar-toggler {
  display: none;
}

.navbar-light .navbar-brand {
  color: rgba(0, 0, 0, 0.9);
}

.navbar-light .navbar-brand:hover, .navbar-light .navbar-brand:focus {
  color: rgba(0, 0, 0, 0.9);
}

.navbar-light .navbar-nav .nav-link {
  color: rgba(0, 0, 0, 0.5);
}

.navbar-light .navbar-nav .nav-link:hover, .navbar-light .navbar-nav .nav-link:focus {
  color: rgba(0, 0, 0, 0.7);
}

.navbar-light .navbar-nav .nav-link.disabled {
  color: rgba(0, 0, 0, 0.3);
}

.navbar-light .navbar-nav .show > .nav-link,
.navbar-light .navbar-nav .active > .nav-link,
.navbar-light .navbar-nav .nav-link.show,
.navbar-light .navbar-nav .nav-link.active {
  color: rgba(0, 0, 0, 0.9);
}

.navbar-light .navbar-toggler {
  color: rgba(0, 0, 0, 0.5);
  border-color: rgba(0, 0, 0, 0.1);
}

.navbar-light .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3e%3cpath stroke='rgba(0, 0, 0, 0.5)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
}

.navbar-light .navbar-text {
  color: rgba(0, 0, 0, 0.5);
}

.navbar-light .navbar-text a {
  color: rgba(0, 0, 0, 0.9);
}

.navbar-light .navbar-text a:hover, .navbar-light .navbar-text a:focus {
  color: rgba(0, 0, 0, 0.9);
}

.navbar-dark .navbar-brand {
  color: rgba(255, 255, 255, 0.75);
}

.navbar-dark .navbar-brand:hover, .navbar-dark .navbar-brand:focus {
  color: rgba(255, 255, 255, 0.75);
}

.navbar-dark .navbar-nav .nav-link {
  color: rgba(255, 255, 255, 0.5);
}

.navbar-dark .navbar-nav .nav-link:hover, .navbar-dark .navbar-nav .nav-link:focus {
  color: rgba(255, 255, 255, 0.75);
}

.navbar-dark .navbar-nav .nav-link.disabled {
  color: rgba(255, 255, 255, 0.25);
}

.navbar-dark .navbar-nav .show > .nav-link,
.navbar-dark .navbar-nav .active > .nav-link,
.navbar-dark .navbar-nav .nav-link.show,
.navbar-dark .navbar-nav .nav-link.active {
  color: rgba(255, 255, 255, 0.75);
}

.navbar-dark .navbar-toggler {
  color: rgba(255, 255, 255, 0.5);
  border-color: rgba(255, 255, 255, 0.1);
}

.navbar-dark .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3e%3cpath stroke='rgba(255, 255, 255, 0.5)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
}

.navbar-dark .navbar-text {
  color: rgba(255, 255, 255, 0.5);
}

.navbar-dark .navbar-text a {
  color: rgba(255, 255, 255, 0.75);
}

.navbar-dark .navbar-text a:hover, .navbar-dark .navbar-text a:focus {
  color: rgba(255, 255, 255, 0.75);
}

.card {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #FFFFFF;
  background-clip: border-box;
  border: 0 solid rgba(0, 0, 0, 0.125);
}

.card > hr {
  margin-right: 0;
  margin-left: 0;
}

.card-body {
  flex: 1 1 auto;
  padding: 1.25rem;
}

.card-title {
  margin-bottom: 0.75rem;
}

.card-subtitle {
  margin-top: -0.375rem;
  margin-bottom: 0;
}

.card-text:last-child {
  margin-bottom: 0;
}

.card-link:hover {
  text-decoration: none;
}

.card-link + .card-link {
  margin-left: 1.25rem;
}

.card-header {
  padding: 0.75rem 1.25rem;
  margin-bottom: 0;
  background-color: transparent;
  border-bottom: 0 solid rgba(0, 0, 0, 0.125);
}

.card-header + .list-group .list-group-item:first-child {
  border-top: 0;
}

.card-footer {
  padding: 0.75rem 1.25rem;
  background-color: transparent;
  border-top: 0 solid rgba(0, 0, 0, 0.125);
}

.card-header-tabs {
  margin-right: -0.625rem;
  margin-bottom: -0.75rem;
  margin-left: -0.625rem;
  border-bottom: 0;
}

.card-header-pills {
  margin-right: -0.625rem;
  margin-left: -0.625rem;
}

.card-img-overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding: 1.25rem;
}

.card-img {
  width: 100%;
}

.card-img-top {
  width: 100%;
}

.card-img-bottom {
  width: 100%;
}

.card-deck {
  display: flex;
  flex-direction: column;
}

.card-deck .card {
  margin-bottom: 15px;
}

@media (min-width: 576px) {
  .card-deck {
    flex-flow: row wrap;
    margin-right: -15px;
    margin-left: -15px;
  }
  .card-deck .card {
    display: flex;
    flex: 1 0 0%;
    flex-direction: column;
    margin-right: 15px;
    margin-bottom: 0;
    margin-left: 15px;
  }
}

.card-group {
  display: flex;
  flex-direction: column;
}

.card-group > .card {
  margin-bottom: 15px;
}

@media (min-width: 576px) {
  .card-group {
    flex-flow: row wrap;
  }
  .card-group > .card {
    flex: 1 0 0%;
    margin-bottom: 0;
  }
  .card-group > .card + .card {
    margin-left: 0;
    border-left: 0;
  }
}

.card-columns .card {
  margin-bottom: 0.75rem;
}

@media (min-width: 576px) {
  .card-columns {
    column-count: 3;
    column-gap: 1.25rem;
    orphans: 1;
    widows: 1;
  }
  .card-columns .card {
    display: inline-block;
    width: 100%;
  }
}

.accordion > .card {
  overflow: hidden;
}

.accordion > .card:not(:first-of-type):not(:last-of-type) {
  border-bottom: 0;
}

.accordion > .card:first-of-type {
  border-bottom: 0;
}

.accordion > .card .card-header {
  margin-bottom: 0;
}

.breadcrumb {
  display: flex;
  flex-wrap: wrap;
  padding: 0.75rem 1rem;
  margin-bottom: 1rem;
  list-style: none;
  background-color: #FFFFFF;
}

.breadcrumb-item + .breadcrumb-item {
  padding-left: 0.5rem;
}

.breadcrumb-item + .breadcrumb-item::before {
  display: inline-block;
  padding-right: 0.5rem;
  color: #878787;
  content: "";
}

.breadcrumb-item + .breadcrumb-item:hover::before {
  text-decoration: underline;
}

.breadcrumb-item + .breadcrumb-item:hover::before {
  text-decoration: none;
}

.breadcrumb-item.active {
  color: #878787;
}

.pagination {
  display: flex;
  padding-left: 0;
  list-style: none;
}

.page-link {
  position: relative;
  display: block;
  padding: 0.5rem 0.75rem;
  margin-left: -1px;
  line-height: 1.25;
  color: #4ad69e;
  background-color: #FFFFFF;
  border: 1px solid #dee2e6;
}

.page-link:hover {
  z-index: 2;
  color: #27ac77;
  text-decoration: none;
  background-color: #eceeef;
  border-color: #dee2e6;
}

.page-link:focus {
  z-index: 2;
  outline: 0;
  box-shadow: 0 0 0 0 transparent;
}

.page-item:first-child .page-link {
  margin-left: 0;
}

.page-item.active .page-link {
  z-index: 1;
  color: #2cc185;
  background-color: #FFFFFF;
  border-color: #2cc185;
}

.page-item.disabled .page-link {
  color: #878787;
  pointer-events: none;
  cursor: auto;
  background-color: #FFFFFF;
  border-color: #dee2e6;
}

.pagination-lg .page-link {
  padding: 0.75rem 1.5rem;
  font-size: 1.154rem;
  line-height: 1.333333;
}

.pagination-sm .page-link {
  padding: 0.25rem 0.5rem;
  font-size: 0.8462rem;
  line-height: 1.5;
}

.badge {
  display: inline-block;
  padding: 0.385rem 0.6154rem;
  font-size: 0.9231rem;
  font-weight: 400;
  line-height: 1;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .badge {
    transition: none;
  }
}

a.badge:hover, a.badge:focus {
  text-decoration: none;
}

.badge:empty {
  display: none;
}

.btn .badge {
  position: relative;
  top: -1px;
}

.badge-pill {
  padding-right: 0.6em;
  padding-left: 0.6em;
}

.badge-primary {
  color: #FFFFFF;
  background-color: #2cc185;
}

a.badge-primary:hover, a.badge-primary:focus {
  color: #FFFFFF;
  background-color: #239768;
}

a.badge-primary:focus, a.badge-primary.focus {
  outline: 0;
  box-shadow: 0 0 0 0 rgba(44, 193, 133, 0.5);
}

.badge-secondary {
  color: #FFFFFF;
  background-color: #878787;
}

a.badge-secondary:hover, a.badge-secondary:focus {
  color: #FFFFFF;
  background-color: #6e6e6e;
}

a.badge-secondary:focus, a.badge-secondary.focus {
  outline: 0;
  box-shadow: 0 0 0 0 rgba(135, 135, 135, 0.5);
}

.badge-success {
  color: #FFFFFF;
  background-color: #2cc185;
}

a.badge-success:hover, a.badge-success:focus {
  color: #FFFFFF;
  background-color: #239768;
}

a.badge-success:focus, a.badge-success.focus {
  outline: 0;
  box-shadow: 0 0 0 0 rgba(44, 193, 133, 0.5);
}

.badge-info {
  color: #212529;
  background-color: #58C7EF;
}

a.badge-info:hover, a.badge-info:focus {
  color: #212529;
  background-color: #29b7eb;
}

a.badge-info:focus, a.badge-info.focus {
  outline: 0;
  box-shadow: 0 0 0 0 rgba(88, 199, 239, 0.5);
}

.badge-warning {
  color: #212529;
  background-color: #FFDC42;
}

a.badge-warning:hover, a.badge-warning:focus {
  color: #212529;
  background-color: #ffd30f;
}

a.badge-warning:focus, a.badge-warning.focus {
  outline: 0;
  box-shadow: 0 0 0 0 rgba(255, 220, 66, 0.5);
}

.badge-danger {
  color: #FFFFFF;
  background-color: #F45846;
}

a.badge-danger:hover, a.badge-danger:focus {
  color: #FFFFFF;
  background-color: #f12d16;
}

a.badge-danger:focus, a.badge-danger.focus {
  outline: 0;
  box-shadow: 0 0 0 0 rgba(244, 88, 70, 0.5);
}

.badge-light {
  color: #212529;
  background-color: #FFFFFF;
}

a.badge-light:hover, a.badge-light:focus {
  color: #212529;
  background-color: #e6e6e6;
}

a.badge-light:focus, a.badge-light.focus {
  outline: 0;
  box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.5);
}

.badge-dark {
  color: #FFFFFF;
  background-color: #323232;
}

a.badge-dark:hover, a.badge-dark:focus {
  color: #FFFFFF;
  background-color: #191919;
}

a.badge-dark:focus, a.badge-dark.focus {
  outline: 0;
  box-shadow: 0 0 0 0 rgba(50, 50, 50, 0.5);
}

.badge-grey {
  color: #212529;
  background-color: #e5e5e5;
}

a.badge-grey:hover, a.badge-grey:focus {
  color: #212529;
  background-color: #cccccc;
}

a.badge-grey:focus, a.badge-grey.focus {
  outline: 0;
  box-shadow: 0 0 0 0 rgba(229, 229, 229, 0.5);
}

.badge-noir {
  color: #FFFFFF;
  background-color: #545454;
}

a.badge-noir:hover, a.badge-noir:focus {
  color: #FFFFFF;
  background-color: #3b3b3b;
}

a.badge-noir:focus, a.badge-noir.focus {
  outline: 0;
  box-shadow: 0 0 0 0 rgba(84, 84, 84, 0.5);
}

.jumbotron {
  padding: 2rem 1rem;
  margin-bottom: 2rem;
  background-color: #eceeef;
}

@media (min-width: 576px) {
  .jumbotron {
    padding: 4rem 2rem;
  }
}

.jumbotron-fluid {
  padding-right: 0;
  padding-left: 0;
}

.alert {
  position: relative;
  padding: 1.308rem 1.538rem;
  margin-bottom: 1rem;
  border: 1px solid transparent;
}

.alert-heading {
  color: inherit;
}

.alert-link {
  font-weight: 700;
}

.alert-dismissible {
  padding-right: 4.576rem;
}

.alert-dismissible .close {
  position: absolute;
  top: 0;
  right: 0;
  padding: 1.308rem 1.538rem;
  color: inherit;
}

.alert-primary {
  color: #176445;
  background-color: #d5f3e7;
  border-color: #c4eedd;
}

.alert-primary hr {
  border-top-color: #b0e8d2;
}

.alert-primary .alert-link {
  color: #0d3b28;
}

.alert-secondary {
  color: #464646;
  background-color: #e7e7e7;
  border-color: #dddddd;
}

.alert-secondary hr {
  border-top-color: #d0d0d0;
}

.alert-secondary .alert-link {
  color: #2d2d2d;
}

.alert-success {
  color: #176445;
  background-color: #d5f3e7;
  border-color: #c4eedd;
}

.alert-success hr {
  border-top-color: #b0e8d2;
}

.alert-success .alert-link {
  color: #0d3b28;
}

.alert-info {
  color: #2e677c;
  background-color: #def4fc;
  border-color: #d0effb;
}

.alert-info hr {
  border-top-color: #b9e7f9;
}

.alert-info .alert-link {
  color: #204857;
}

.alert-warning {
  color: #857222;
  background-color: #fff8d9;
  border-color: #fff5ca;
}

.alert-warning hr {
  border-top-color: #fff0b1;
}

.alert-warning .alert-link {
  color: #5c4f18;
}

.alert-danger {
  color: #7f2e24;
  background-color: #fddeda;
  border-color: #fcd0cb;
}

.alert-danger hr {
  border-top-color: #fbbab3;
}

.alert-danger .alert-link {
  color: #572019;
}

.alert-light {
  color: #858585;
  background-color: white;
  border-color: white;
}

.alert-light hr {
  border-top-color: #f2f2f2;
}

.alert-light .alert-link {
  color: #6c6c6c;
}

.alert-dark {
  color: #1a1a1a;
  background-color: #d6d6d6;
  border-color: #c6c6c6;
}

.alert-dark hr {
  border-top-color: #b9b9b9;
}

.alert-dark .alert-link {
  color: #010101;
}

.alert-grey {
  color: #777777;
  background-color: #fafafa;
  border-color: #f8f8f8;
}

.alert-grey hr {
  border-top-color: #ebebeb;
}

.alert-grey .alert-link {
  color: #5e5e5e;
}

.alert-noir {
  color: #2c2c2c;
  background-color: #dddddd;
  border-color: #cfcfcf;
}

.alert-noir hr {
  border-top-color: #c2c2c2;
}

.alert-noir .alert-link {
  color: #131313;
}

@keyframes progress-bar-stripes {
  from {
    background-position: 1.692rem 0;
  }
  to {
    background-position: 0 0;
  }
}

.progress {
  display: flex;
  height: 1.692rem;
  overflow: hidden;
  font-size: 1rem;
  background-color: #eceeef;
}

.progress-bar {
  display: flex;
  flex-direction: column;
  justify-content: center;
  color: #FFFFFF;
  text-align: center;
  white-space: nowrap;
  background-color: #2cc185;
  transition: width 0.6s ease;
}

@media (prefers-reduced-motion: reduce) {
  .progress-bar {
    transition: none;
  }
}

.progress-bar-striped {
  background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
  background-size: 1.692rem 1.692rem;
}

.progress-bar-animated {
  animation: progress-bar-stripes 1s linear infinite;
}

@media (prefers-reduced-motion: reduce) {
  .progress-bar-animated {
    animation: none;
  }
}

.media {
  display: flex;
  align-items: flex-start;
}

.media-body {
  flex: 1;
}

.list-group {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
}

.list-group-item-action {
  width: 100%;
  color: #636c72;
  text-align: inherit;
}

.list-group-item-action:hover, .list-group-item-action:focus {
  z-index: 1;
  color: #636c72;
  text-decoration: none;
  background-color: #f8f9fa;
}

.list-group-item-action:active {
  color: #545454;
  background-color: #eceeef;
}

.list-group-item {
  position: relative;
  display: block;
  padding: 0.924rem 1.48rem;
  margin-bottom: -1px;
  background-color: #FFFFFF;
  border: 1px solid rgba(0, 0, 0, 0.125);
}

.list-group-item:last-child {
  margin-bottom: 0;
}

.list-group-item.disabled, .list-group-item:disabled {
  color: #878787;
  pointer-events: none;
  background-color: #FFFFFF;
}

.list-group-item.active {
  z-index: 2;
  color: #FFFFFF;
  background-color: #2cc185;
  border-color: #2cc185;
}

.list-group-horizontal {
  flex-direction: row;
}

.list-group-horizontal .list-group-item {
  margin-right: -1px;
  margin-bottom: 0;
}

.list-group-horizontal .list-group-item:last-child {
  margin-right: 0;
}

@media (min-width: 576px) {
  .list-group-horizontal-sm {
    flex-direction: row;
  }
  .list-group-horizontal-sm .list-group-item {
    margin-right: -1px;
    margin-bottom: 0;
  }
  .list-group-horizontal-sm .list-group-item:last-child {
    margin-right: 0;
  }
}

@media (min-width: 768px) {
  .list-group-horizontal-md {
    flex-direction: row;
  }
  .list-group-horizontal-md .list-group-item {
    margin-right: -1px;
    margin-bottom: 0;
  }
  .list-group-horizontal-md .list-group-item:last-child {
    margin-right: 0;
  }
}

@media (min-width: 992px) {
  .list-group-horizontal-lg {
    flex-direction: row;
  }
  .list-group-horizontal-lg .list-group-item {
    margin-right: -1px;
    margin-bottom: 0;
  }
  .list-group-horizontal-lg .list-group-item:last-child {
    margin-right: 0;
  }
}

@media (min-width: 1200px) {
  .list-group-horizontal-xl {
    flex-direction: row;
  }
  .list-group-horizontal-xl .list-group-item {
    margin-right: -1px;
    margin-bottom: 0;
  }
  .list-group-horizontal-xl .list-group-item:last-child {
    margin-right: 0;
  }
}

.list-group-flush .list-group-item {
  border-right: 0;
  border-left: 0;
}

.list-group-flush .list-group-item:last-child {
  margin-bottom: -1px;
}

.list-group-flush:first-child .list-group-item:first-child {
  border-top: 0;
}

.list-group-flush:last-child .list-group-item:last-child {
  margin-bottom: 0;
  border-bottom: 0;
}

.list-group-item-primary {
  color: #176445;
  background-color: #c4eedd;
}

.list-group-item-primary.list-group-item-action:hover, .list-group-item-primary.list-group-item-action:focus {
  color: #176445;
  background-color: #b0e8d2;
}

.list-group-item-primary.list-group-item-action.active {
  color: #FFFFFF;
  background-color: #176445;
  border-color: #176445;
}

.list-group-item-secondary {
  color: #464646;
  background-color: #dddddd;
}

.list-group-item-secondary.list-group-item-action:hover, .list-group-item-secondary.list-group-item-action:focus {
  color: #464646;
  background-color: #d0d0d0;
}

.list-group-item-secondary.list-group-item-action.active {
  color: #FFFFFF;
  background-color: #464646;
  border-color: #464646;
}

.list-group-item-success {
  color: #176445;
  background-color: #c4eedd;
}

.list-group-item-success.list-group-item-action:hover, .list-group-item-success.list-group-item-action:focus {
  color: #176445;
  background-color: #b0e8d2;
}

.list-group-item-success.list-group-item-action.active {
  color: #FFFFFF;
  background-color: #176445;
  border-color: #176445;
}

.list-group-item-info {
  color: #2e677c;
  background-color: #d0effb;
}

.list-group-item-info.list-group-item-action:hover, .list-group-item-info.list-group-item-action:focus {
  color: #2e677c;
  background-color: #b9e7f9;
}

.list-group-item-info.list-group-item-action.active {
  color: #FFFFFF;
  background-color: #2e677c;
  border-color: #2e677c;
}

.list-group-item-warning {
  color: #857222;
  background-color: #fff5ca;
}

.list-group-item-warning.list-group-item-action:hover, .list-group-item-warning.list-group-item-action:focus {
  color: #857222;
  background-color: #fff0b1;
}

.list-group-item-warning.list-group-item-action.active {
  color: #FFFFFF;
  background-color: #857222;
  border-color: #857222;
}

.list-group-item-danger {
  color: #7f2e24;
  background-color: #fcd0cb;
}

.list-group-item-danger.list-group-item-action:hover, .list-group-item-danger.list-group-item-action:focus {
  color: #7f2e24;
  background-color: #fbbab3;
}

.list-group-item-danger.list-group-item-action.active {
  color: #FFFFFF;
  background-color: #7f2e24;
  border-color: #7f2e24;
}

.list-group-item-light {
  color: #858585;
  background-color: white;
}

.list-group-item-light.list-group-item-action:hover, .list-group-item-light.list-group-item-action:focus {
  color: #858585;
  background-color: #f2f2f2;
}

.list-group-item-light.list-group-item-action.active {
  color: #FFFFFF;
  background-color: #858585;
  border-color: #858585;
}

.list-group-item-dark {
  color: #1a1a1a;
  background-color: #c6c6c6;
}

.list-group-item-dark.list-group-item-action:hover, .list-group-item-dark.list-group-item-action:focus {
  color: #1a1a1a;
  background-color: #b9b9b9;
}

.list-group-item-dark.list-group-item-action.active {
  color: #FFFFFF;
  background-color: #1a1a1a;
  border-color: #1a1a1a;
}

.list-group-item-grey {
  color: #777777;
  background-color: #f8f8f8;
}

.list-group-item-grey.list-group-item-action:hover, .list-group-item-grey.list-group-item-action:focus {
  color: #777777;
  background-color: #ebebeb;
}

.list-group-item-grey.list-group-item-action.active {
  color: #FFFFFF;
  background-color: #777777;
  border-color: #777777;
}

.list-group-item-noir {
  color: #2c2c2c;
  background-color: #cfcfcf;
}

.list-group-item-noir.list-group-item-action:hover, .list-group-item-noir.list-group-item-action:focus {
  color: #2c2c2c;
  background-color: #c2c2c2;
}

.list-group-item-noir.list-group-item-action.active {
  color: #FFFFFF;
  background-color: #2c2c2c;
  border-color: #2c2c2c;
}

.close {
  float: right;
  font-size: 1.5rem;
  font-weight: 700;
  line-height: 1;
  color: #000000;
  text-shadow: 0 1px 0 #FFFFFF;
  opacity: .5;
}

.close:hover {
  color: #000000;
  text-decoration: none;
}

.close:not(:disabled):not(.disabled):hover, .close:not(:disabled):not(.disabled):focus {
  opacity: .75;
}

button.close {
  padding: 0;
  background-color: transparent;
  border: 0;
  appearance: none;
}

a.close.disabled {
  pointer-events: none;
}

.modal-open {
  overflow: hidden;
}

.modal-open .modal {
  overflow-x: hidden;
  overflow-y: auto;
}

.modal {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1050;
  display: none;
  width: 100%;
  height: 100%;
  overflow: hidden;
  outline: 0;
}

.modal-dialog {
  position: relative;
  width: auto;
  margin: 0.5rem;
  pointer-events: none;
}

.modal.fade .modal-dialog {
  transition: transform 0.3s ease-out;
  transform: translate(0, -50px);
}

@media (prefers-reduced-motion: reduce) {
  .modal.fade .modal-dialog {
    transition: none;
  }
}

.modal.show .modal-dialog {
  transform: none;
}

.modal-dialog-scrollable {
  display: flex;
  max-height: calc(100% - 1rem);
}

.modal-dialog-scrollable .modal-content {
  max-height: calc(100vh - 1rem);
  overflow: hidden;
}

.modal-dialog-scrollable .modal-header,
.modal-dialog-scrollable .modal-footer {
  flex-shrink: 0;
}

.modal-dialog-scrollable .modal-body {
  overflow-y: auto;
}

.modal-dialog-centered {
  display: flex;
  align-items: center;
  min-height: calc(100% - 1rem);
}

.modal-dialog-centered::before {
  display: block;
  height: calc(100vh - 1rem);
  content: "";
}

.modal-dialog-centered.modal-dialog-scrollable {
  flex-direction: column;
  justify-content: center;
  height: 100%;
}

.modal-dialog-centered.modal-dialog-scrollable .modal-content {
  max-height: none;
}

.modal-dialog-centered.modal-dialog-scrollable::before {
  content: none;
}

.modal-content {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  pointer-events: auto;
  background-color: #FFFFFF;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  outline: 0;
}

.modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1040;
  width: 100vw;
  height: 100vh;
  background-color: #000000;
}

.modal-backdrop.fade {
  opacity: 0;
}

.modal-backdrop.show {
  opacity: 0.5;
}

.modal-header {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding: 1rem 1rem;
  border-bottom: 1px solid #dee2e6;
}

.modal-header .close {
  padding: 1rem 1rem;
  margin: -1rem -1rem -1rem auto;
}

.modal-title {
  margin-bottom: 0;
  line-height: 1.538;
}

.modal-body {
  position: relative;
  flex: 1 1 auto;
  padding: 1rem;
}

.modal-footer {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 1rem;
  border-top: 1px solid #dee2e6;
}

.modal-footer > :not(:first-child) {
  margin-left: .25rem;
}

.modal-footer > :not(:last-child) {
  margin-right: .25rem;
}

.modal-scrollbar-measure {
  position: absolute;
  top: -9999px;
  width: 50px;
  height: 50px;
  overflow: scroll;
}

@media (min-width: 576px) {
  .modal-dialog {
    max-width: 545px;
    margin: 1.75rem auto;
  }
  .modal-dialog-scrollable {
    max-height: calc(100% - 3.5rem);
  }
  .modal-dialog-scrollable .modal-content {
    max-height: calc(100vh - 3.5rem);
  }
  .modal-dialog-centered {
    min-height: calc(100% - 3.5rem);
  }
  .modal-dialog-centered::before {
    height: calc(100vh - 3.5rem);
  }
  .modal-sm {
    max-width: 300px;
  }
}

@media (min-width: 992px) {
  .modal-lg,
  .modal-xl {
    max-width: 750px;
  }
}

@media (min-width: 1200px) {
  .modal-xl {
    max-width: 1140px;
  }
}

.tooltip {
  position: absolute;
  z-index: 1070;
  display: block;
  margin: 0;
  font-family: "Open Sans", sans-serif, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
  font-style: normal;
  font-weight: 400;
  line-height: 1.538;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
  font-size: 0.8462rem;
  word-wrap: break-word;
  opacity: 0;
}

.tooltip.show {
  opacity: 0.9;
}

.tooltip .arrow {
  position: absolute;
  display: block;
  width: 0.8rem;
  height: 0.4rem;
}

.tooltip .arrow::before {
  position: absolute;
  content: "";
  border-color: transparent;
  border-style: solid;
}

.bs-tooltip-top, .bs-tooltip-auto[x-placement^="top"] {
  padding: 0.4rem 0;
}

.bs-tooltip-top .arrow, .bs-tooltip-auto[x-placement^="top"] .arrow {
  bottom: 0;
}

.bs-tooltip-top .arrow::before, .bs-tooltip-auto[x-placement^="top"] .arrow::before {
  top: 0;
  border-width: 0.4rem 0.4rem 0;
  border-top-color: #000000;
}

.bs-tooltip-right, .bs-tooltip-auto[x-placement^="right"] {
  padding: 0 0.4rem;
}

.bs-tooltip-right .arrow, .bs-tooltip-auto[x-placement^="right"] .arrow {
  left: 0;
  width: 0.4rem;
  height: 0.8rem;
}

.bs-tooltip-right .arrow::before, .bs-tooltip-auto[x-placement^="right"] .arrow::before {
  right: 0;
  border-width: 0.4rem 0.4rem 0.4rem 0;
  border-right-color: #000000;
}

.bs-tooltip-bottom, .bs-tooltip-auto[x-placement^="bottom"] {
  padding: 0.4rem 0;
}

.bs-tooltip-bottom .arrow, .bs-tooltip-auto[x-placement^="bottom"] .arrow {
  top: 0;
}

.bs-tooltip-bottom .arrow::before, .bs-tooltip-auto[x-placement^="bottom"] .arrow::before {
  bottom: 0;
  border-width: 0 0.4rem 0.4rem;
  border-bottom-color: #000000;
}

.bs-tooltip-left, .bs-tooltip-auto[x-placement^="left"] {
  padding: 0 0.4rem;
}

.bs-tooltip-left .arrow, .bs-tooltip-auto[x-placement^="left"] .arrow {
  right: 0;
  width: 0.4rem;
  height: 0.8rem;
}

.bs-tooltip-left .arrow::before, .bs-tooltip-auto[x-placement^="left"] .arrow::before {
  left: 0;
  border-width: 0.4rem 0 0.4rem 0.4rem;
  border-left-color: #000000;
}

.tooltip-inner {
  max-width: 200px;
  padding: 0.25rem 0.5rem;
  color: #FFFFFF;
  text-align: center;
  background-color: #000000;
}

.popover {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1060;
  display: block;
  max-width: 276px;
  font-family: "Open Sans", sans-serif, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
  font-style: normal;
  font-weight: 400;
  line-height: 1.538;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
  font-size: 0.8462rem;
  word-wrap: break-word;
  background-color: #FFFFFF;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
}

.popover .arrow {
  position: absolute;
  display: block;
  width: 1rem;
  height: 0.5rem;
  margin: 0 0.3rem;
}

.popover .arrow::before, .popover .arrow::after {
  position: absolute;
  display: block;
  content: "";
  border-color: transparent;
  border-style: solid;
}

.bs-popover-top, .bs-popover-auto[x-placement^="top"] {
  margin-bottom: 0.5rem;
}

.bs-popover-top > .arrow, .bs-popover-auto[x-placement^="top"] > .arrow {
  bottom: calc((0.5rem + 1px) * -1);
}

.bs-popover-top > .arrow::before, .bs-popover-auto[x-placement^="top"] > .arrow::before {
  bottom: 0;
  border-width: 0.5rem 0.5rem 0;
  border-top-color: rgba(0, 0, 0, 0.25);
}

.bs-popover-top > .arrow::after, .bs-popover-auto[x-placement^="top"] > .arrow::after {
  bottom: 1px;
  border-width: 0.5rem 0.5rem 0;
  border-top-color: #FFFFFF;
}

.bs-popover-right, .bs-popover-auto[x-placement^="right"] {
  margin-left: 0.5rem;
}

.bs-popover-right > .arrow, .bs-popover-auto[x-placement^="right"] > .arrow {
  left: calc((0.5rem + 1px) * -1);
  width: 0.5rem;
  height: 1rem;
  margin: 0.3rem 0;
}

.bs-popover-right > .arrow::before, .bs-popover-auto[x-placement^="right"] > .arrow::before {
  left: 0;
  border-width: 0.5rem 0.5rem 0.5rem 0;
  border-right-color: rgba(0, 0, 0, 0.25);
}

.bs-popover-right > .arrow::after, .bs-popover-auto[x-placement^="right"] > .arrow::after {
  left: 1px;
  border-width: 0.5rem 0.5rem 0.5rem 0;
  border-right-color: #FFFFFF;
}

.bs-popover-bottom, .bs-popover-auto[x-placement^="bottom"] {
  margin-top: 0.5rem;
}

.bs-popover-bottom > .arrow, .bs-popover-auto[x-placement^="bottom"] > .arrow {
  top: calc((0.5rem + 1px) * -1);
}

.bs-popover-bottom > .arrow::before, .bs-popover-auto[x-placement^="bottom"] > .arrow::before {
  top: 0;
  border-width: 0 0.5rem 0.5rem 0.5rem;
  border-bottom-color: rgba(0, 0, 0, 0.25);
}

.bs-popover-bottom > .arrow::after, .bs-popover-auto[x-placement^="bottom"] > .arrow::after {
  top: 1px;
  border-width: 0 0.5rem 0.5rem 0.5rem;
  border-bottom-color: #FFFFFF;
}

.bs-popover-bottom .popover-header::before, .bs-popover-auto[x-placement^="bottom"] .popover-header::before {
  position: absolute;
  top: 0;
  left: 50%;
  display: block;
  width: 1rem;
  margin-left: -0.5rem;
  content: "";
  border-bottom: 1px solid #f7f7f7;
}

.bs-popover-left, .bs-popover-auto[x-placement^="left"] {
  margin-right: 0.5rem;
}

.bs-popover-left > .arrow, .bs-popover-auto[x-placement^="left"] > .arrow {
  right: calc((0.5rem + 1px) * -1);
  width: 0.5rem;
  height: 1rem;
  margin: 0.3rem 0;
}

.bs-popover-left > .arrow::before, .bs-popover-auto[x-placement^="left"] > .arrow::before {
  right: 0;
  border-width: 0.5rem 0 0.5rem 0.5rem;
  border-left-color: rgba(0, 0, 0, 0.25);
}

.bs-popover-left > .arrow::after, .bs-popover-auto[x-placement^="left"] > .arrow::after {
  right: 1px;
  border-width: 0.5rem 0 0.5rem 0.5rem;
  border-left-color: #FFFFFF;
}

.popover-header {
  padding: 0.5rem 0.75rem;
  margin-bottom: 0;
  font-size: 1rem;
  background-color: #f7f7f7;
  border-bottom: 1px solid #ebebeb;
}

.popover-header:empty {
  display: none;
}

.popover-body {
  padding: 0.5rem 0.75rem;
  color: #545454;
}

.carousel {
  position: relative;
}

.carousel.pointer-event {
  touch-action: pan-y;
}

.carousel-inner {
  position: relative;
  width: 100%;
  overflow: hidden;
}

.carousel-inner::after {
  display: block;
  clear: both;
  content: "";
}

.carousel-item {
  position: relative;
  display: none;
  float: left;
  width: 100%;
  margin-right: -100%;
  backface-visibility: hidden;
  transition: transform 0.6s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .carousel-item {
    transition: none;
  }
}

.carousel-item.active,
.carousel-item-next,
.carousel-item-prev {
  display: block;
}

.carousel-item-next:not(.carousel-item-left),
.active.carousel-item-right {
  transform: translateX(100%);
}

.carousel-item-prev:not(.carousel-item-right),
.active.carousel-item-left {
  transform: translateX(-100%);
}

.carousel-fade .carousel-item {
  opacity: 0;
  transition-property: opacity;
  transform: none;
}

.carousel-fade .carousel-item.active,
.carousel-fade .carousel-item-next.carousel-item-left,
.carousel-fade .carousel-item-prev.carousel-item-right {
  z-index: 1;
  opacity: 1;
}

.carousel-fade .active.carousel-item-left,
.carousel-fade .active.carousel-item-right {
  z-index: 0;
  opacity: 0;
  transition: 0s 0.6s opacity;
}

@media (prefers-reduced-motion: reduce) {
  .carousel-fade .active.carousel-item-left,
  .carousel-fade .active.carousel-item-right {
    transition: none;
  }
}

.carousel-control-prev,
.carousel-control-next {
  position: absolute;
  top: 0;
  bottom: 0;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 15%;
  color: #FFFFFF;
  text-align: center;
  opacity: 0.5;
  transition: opacity 0.15s ease;
}

@media (prefers-reduced-motion: reduce) {
  .carousel-control-prev,
  .carousel-control-next {
    transition: none;
  }
}

.carousel-control-prev:hover, .carousel-control-prev:focus,
.carousel-control-next:hover,
.carousel-control-next:focus {
  color: #FFFFFF;
  text-decoration: none;
  outline: 0;
  opacity: 0.9;
}

.carousel-control-prev {
  left: 0;
}

.carousel-control-next {
  right: 0;
}

.carousel-control-prev-icon,
.carousel-control-next-icon {
  display: inline-block;
  width: 20px;
  height: 20px;
  background: no-repeat 50% / 100% 100%;
}

.carousel-control-prev-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23FFFFFF' viewBox='0 0 8 8'%3e%3cpath d='M5.25 0l-4 4 4 4 1.5-1.5-2.5-2.5 2.5-2.5-1.5-1.5z'/%3e%3c/svg%3e");
}

.carousel-control-next-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23FFFFFF' viewBox='0 0 8 8'%3e%3cpath d='M2.75 0l-1.5 1.5 2.5 2.5-2.5 2.5 1.5 1.5 4-4-4-4z'/%3e%3c/svg%3e");
}

.carousel-indicators {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 15;
  display: flex;
  justify-content: center;
  padding-left: 0;
  margin-right: 15%;
  margin-left: 15%;
  list-style: none;
}

.carousel-indicators li {
  box-sizing: content-box;
  flex: 0 1 auto;
  width: 30px;
  height: 3px;
  margin-right: 3px;
  margin-left: 3px;
  text-indent: -999px;
  cursor: pointer;
  background-color: #FFFFFF;
  background-clip: padding-box;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  opacity: .5;
  transition: opacity 0.6s ease;
}

@media (prefers-reduced-motion: reduce) {
  .carousel-indicators li {
    transition: none;
  }
}

.carousel-indicators .active {
  opacity: 1;
}

.carousel-caption {
  position: absolute;
  right: 15%;
  bottom: 20px;
  left: 15%;
  z-index: 10;
  padding-top: 20px;
  padding-bottom: 20px;
  color: #FFFFFF;
  text-align: center;
}

.align-baseline {
  vertical-align: baseline !important;
}

.align-top {
  vertical-align: top !important;
}

.align-middle {
  vertical-align: middle !important;
}

.align-bottom {
  vertical-align: bottom !important;
}

.align-text-bottom {
  vertical-align: text-bottom !important;
}

.align-text-top {
  vertical-align: text-top !important;
}

.bg-primary {
  background-color: #2cc185 !important;
}

a.bg-primary:hover, a.bg-primary:focus,
button.bg-primary:hover,
button.bg-primary:focus {
  background-color: #239768 !important;
}

.bg-secondary {
  background-color: #878787 !important;
}

a.bg-secondary:hover, a.bg-secondary:focus,
button.bg-secondary:hover,
button.bg-secondary:focus {
  background-color: #6e6e6e !important;
}

.bg-success {
  background-color: #2cc185 !important;
}

a.bg-success:hover, a.bg-success:focus,
button.bg-success:hover,
button.bg-success:focus {
  background-color: #239768 !important;
}

.bg-info {
  background-color: #58C7EF !important;
}

a.bg-info:hover, a.bg-info:focus,
button.bg-info:hover,
button.bg-info:focus {
  background-color: #29b7eb !important;
}

.bg-warning {
  background-color: #FFDC42 !important;
}

a.bg-warning:hover, a.bg-warning:focus,
button.bg-warning:hover,
button.bg-warning:focus {
  background-color: #ffd30f !important;
}

.bg-danger {
  background-color: #F45846 !important;
}

a.bg-danger:hover, a.bg-danger:focus,
button.bg-danger:hover,
button.bg-danger:focus {
  background-color: #f12d16 !important;
}

.bg-light {
  background-color: #FFFFFF !important;
}

a.bg-light:hover, a.bg-light:focus,
button.bg-light:hover,
button.bg-light:focus {
  background-color: #e6e6e6 !important;
}

.bg-dark {
  background-color: #323232 !important;
}

a.bg-dark:hover, a.bg-dark:focus,
button.bg-dark:hover,
button.bg-dark:focus {
  background-color: #191919 !important;
}

.bg-grey {
  background-color: #e5e5e5 !important;
}

a.bg-grey:hover, a.bg-grey:focus,
button.bg-grey:hover,
button.bg-grey:focus {
  background-color: #cccccc !important;
}

.bg-noir {
  background-color: #545454 !important;
}

a.bg-noir:hover, a.bg-noir:focus,
button.bg-noir:hover,
button.bg-noir:focus {
  background-color: #3b3b3b !important;
}

.bg-white {
  background-color: #FFFFFF !important;
}

.bg-transparent {
  background-color: transparent !important;
}

.border {
  border: 1px solid #dee2e6 !important;
}

.border-top {
  border-top: 1px solid #dee2e6 !important;
}

.border-right {
  border-right: 1px solid #dee2e6 !important;
}

.border-bottom {
  border-bottom: 1px solid #dee2e6 !important;
}

.border-left {
  border-left: 1px solid #dee2e6 !important;
}

.border-0 {
  border: 0 !important;
}

.border-top-0 {
  border-top: 0 !important;
}

.border-right-0 {
  border-right: 0 !important;
}

.border-bottom-0 {
  border-bottom: 0 !important;
}

.border-left-0 {
  border-left: 0 !important;
}

.border-primary {
  border-color: #2cc185 !important;
}

.border-secondary {
  border-color: #878787 !important;
}

.border-success {
  border-color: #2cc185 !important;
}

.border-info {
  border-color: #58C7EF !important;
}

.border-warning {
  border-color: #FFDC42 !important;
}

.border-danger {
  border-color: #F45846 !important;
}

.border-light {
  border-color: #FFFFFF !important;
}

.border-dark {
  border-color: #323232 !important;
}

.border-grey {
  border-color: #e5e5e5 !important;
}

.border-noir {
  border-color: #545454 !important;
}

.border-white {
  border-color: #FFFFFF !important;
}

.rounded-sm {
  border-radius: 0.2rem !important;
}

.rounded {
  border-radius: 0.25rem !important;
}

.rounded-top {
  border-top-left-radius: 0.25rem !important;
  border-top-right-radius: 0.25rem !important;
}

.rounded-right {
  border-top-right-radius: 0.25rem !important;
  border-bottom-right-radius: 0.25rem !important;
}

.rounded-bottom {
  border-bottom-right-radius: 0.25rem !important;
  border-bottom-left-radius: 0.25rem !important;
}

.rounded-left {
  border-top-left-radius: 0.25rem !important;
  border-bottom-left-radius: 0.25rem !important;
}

.rounded-lg {
  border-radius: 0.3rem !important;
}

.rounded-circle {
  border-radius: 50% !important;
}

.rounded-pill {
  border-radius: 50rem !important;
}

.rounded-0 {
  border-radius: 0 !important;
}

.clearfix::after {
  display: block;
  clear: both;
  content: "";
}

.d-none {
  display: none !important;
}

.d-inline {
  display: inline !important;
}

.d-inline-block {
  display: inline-block !important;
}

.d-block {
  display: block !important;
}

.d-table {
  display: table !important;
}

.d-table-row {
  display: table-row !important;
}

.d-table-cell {
  display: table-cell !important;
}

.d-flex {
  display: flex !important;
}

.d-inline-flex {
  display: inline-flex !important;
}

@media (min-width: 576px) {
  .d-sm-none {
    display: none !important;
  }
  .d-sm-inline {
    display: inline !important;
  }
  .d-sm-inline-block {
    display: inline-block !important;
  }
  .d-sm-block {
    display: block !important;
  }
  .d-sm-table {
    display: table !important;
  }
  .d-sm-table-row {
    display: table-row !important;
  }
  .d-sm-table-cell {
    display: table-cell !important;
  }
  .d-sm-flex {
    display: flex !important;
  }
  .d-sm-inline-flex {
    display: inline-flex !important;
  }
}

@media (min-width: 768px) {
  .d-md-none {
    display: none !important;
  }
  .d-md-inline {
    display: inline !important;
  }
  .d-md-inline-block {
    display: inline-block !important;
  }
  .d-md-block {
    display: block !important;
  }
  .d-md-table {
    display: table !important;
  }
  .d-md-table-row {
    display: table-row !important;
  }
  .d-md-table-cell {
    display: table-cell !important;
  }
  .d-md-flex {
    display: flex !important;
  }
  .d-md-inline-flex {
    display: inline-flex !important;
  }
}

@media (min-width: 992px) {
  .d-lg-none {
    display: none !important;
  }
  .d-lg-inline {
    display: inline !important;
  }
  .d-lg-inline-block {
    display: inline-block !important;
  }
  .d-lg-block {
    display: block !important;
  }
  .d-lg-table {
    display: table !important;
  }
  .d-lg-table-row {
    display: table-row !important;
  }
  .d-lg-table-cell {
    display: table-cell !important;
  }
  .d-lg-flex {
    display: flex !important;
  }
  .d-lg-inline-flex {
    display: inline-flex !important;
  }
}

@media (min-width: 1200px) {
  .d-xl-none {
    display: none !important;
  }
  .d-xl-inline {
    display: inline !important;
  }
  .d-xl-inline-block {
    display: inline-block !important;
  }
  .d-xl-block {
    display: block !important;
  }
  .d-xl-table {
    display: table !important;
  }
  .d-xl-table-row {
    display: table-row !important;
  }
  .d-xl-table-cell {
    display: table-cell !important;
  }
  .d-xl-flex {
    display: flex !important;
  }
  .d-xl-inline-flex {
    display: inline-flex !important;
  }
}

@media print {
  .d-print-none {
    display: none !important;
  }
  .d-print-inline {
    display: inline !important;
  }
  .d-print-inline-block {
    display: inline-block !important;
  }
  .d-print-block {
    display: block !important;
  }
  .d-print-table {
    display: table !important;
  }
  .d-print-table-row {
    display: table-row !important;
  }
  .d-print-table-cell {
    display: table-cell !important;
  }
  .d-print-flex {
    display: flex !important;
  }
  .d-print-inline-flex {
    display: inline-flex !important;
  }
}

.embed-responsive {
  position: relative;
  display: block;
  width: 100%;
  padding: 0;
  overflow: hidden;
}

.embed-responsive::before {
  display: block;
  content: "";
}

.embed-responsive .embed-responsive-item,
.embed-responsive iframe,
.embed-responsive embed,
.embed-responsive object,
.embed-responsive video {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 0;
}

.embed-responsive-21by9::before {
  padding-top: 42.857143%;
}

.embed-responsive-16by9::before {
  padding-top: 56.25%;
}

.embed-responsive-4by3::before {
  padding-top: 75%;
}

.embed-responsive-1by1::before {
  padding-top: 100%;
}

.flex-row {
  flex-direction: row !important;
}

.flex-column {
  flex-direction: column !important;
}

.flex-row-reverse {
  flex-direction: row-reverse !important;
}

.flex-column-reverse {
  flex-direction: column-reverse !important;
}

.flex-wrap {
  flex-wrap: wrap !important;
}

.flex-nowrap {
  flex-wrap: nowrap !important;
}

.flex-wrap-reverse {
  flex-wrap: wrap-reverse !important;
}

.flex-fill {
  flex: 1 1 auto !important;
}

.flex-grow-0 {
  flex-grow: 0 !important;
}

.flex-grow-1 {
  flex-grow: 1 !important;
}

.flex-shrink-0 {
  flex-shrink: 0 !important;
}

.flex-shrink-1 {
  flex-shrink: 1 !important;
}

.justify-content-start {
  justify-content: flex-start !important;
}

.justify-content-end {
  justify-content: flex-end !important;
}

.justify-content-center {
  justify-content: center !important;
}

.justify-content-between {
  justify-content: space-between !important;
}

.justify-content-around {
  justify-content: space-around !important;
}

.align-items-start {
  align-items: flex-start !important;
}

.align-items-end {
  align-items: flex-end !important;
}

.align-items-center {
  align-items: center !important;
}

.align-items-baseline {
  align-items: baseline !important;
}

.align-items-stretch {
  align-items: stretch !important;
}

.align-content-start {
  align-content: flex-start !important;
}

.align-content-end {
  align-content: flex-end !important;
}

.align-content-center {
  align-content: center !important;
}

.align-content-between {
  align-content: space-between !important;
}

.align-content-around {
  align-content: space-around !important;
}

.align-content-stretch {
  align-content: stretch !important;
}

.align-self-auto {
  align-self: auto !important;
}

.align-self-start {
  align-self: flex-start !important;
}

.align-self-end {
  align-self: flex-end !important;
}

.align-self-center {
  align-self: center !important;
}

.align-self-baseline {
  align-self: baseline !important;
}

.align-self-stretch {
  align-self: stretch !important;
}

@media (min-width: 576px) {
  .flex-sm-row {
    flex-direction: row !important;
  }
  .flex-sm-column {
    flex-direction: column !important;
  }
  .flex-sm-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-sm-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-sm-wrap {
    flex-wrap: wrap !important;
  }
  .flex-sm-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-sm-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .flex-sm-fill {
    flex: 1 1 auto !important;
  }
  .flex-sm-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-sm-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-sm-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-sm-shrink-1 {
    flex-shrink: 1 !important;
  }
  .justify-content-sm-start {
    justify-content: flex-start !important;
  }
  .justify-content-sm-end {
    justify-content: flex-end !important;
  }
  .justify-content-sm-center {
    justify-content: center !important;
  }
  .justify-content-sm-between {
    justify-content: space-between !important;
  }
  .justify-content-sm-around {
    justify-content: space-around !important;
  }
  .align-items-sm-start {
    align-items: flex-start !important;
  }
  .align-items-sm-end {
    align-items: flex-end !important;
  }
  .align-items-sm-center {
    align-items: center !important;
  }
  .align-items-sm-baseline {
    align-items: baseline !important;
  }
  .align-items-sm-stretch {
    align-items: stretch !important;
  }
  .align-content-sm-start {
    align-content: flex-start !important;
  }
  .align-content-sm-end {
    align-content: flex-end !important;
  }
  .align-content-sm-center {
    align-content: center !important;
  }
  .align-content-sm-between {
    align-content: space-between !important;
  }
  .align-content-sm-around {
    align-content: space-around !important;
  }
  .align-content-sm-stretch {
    align-content: stretch !important;
  }
  .align-self-sm-auto {
    align-self: auto !important;
  }
  .align-self-sm-start {
    align-self: flex-start !important;
  }
  .align-self-sm-end {
    align-self: flex-end !important;
  }
  .align-self-sm-center {
    align-self: center !important;
  }
  .align-self-sm-baseline {
    align-self: baseline !important;
  }
  .align-self-sm-stretch {
    align-self: stretch !important;
  }
}

@media (min-width: 768px) {
  .flex-md-row {
    flex-direction: row !important;
  }
  .flex-md-column {
    flex-direction: column !important;
  }
  .flex-md-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-md-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-md-wrap {
    flex-wrap: wrap !important;
  }
  .flex-md-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-md-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .flex-md-fill {
    flex: 1 1 auto !important;
  }
  .flex-md-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-md-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-md-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-md-shrink-1 {
    flex-shrink: 1 !important;
  }
  .justify-content-md-start {
    justify-content: flex-start !important;
  }
  .justify-content-md-end {
    justify-content: flex-end !important;
  }
  .justify-content-md-center {
    justify-content: center !important;
  }
  .justify-content-md-between {
    justify-content: space-between !important;
  }
  .justify-content-md-around {
    justify-content: space-around !important;
  }
  .align-items-md-start {
    align-items: flex-start !important;
  }
  .align-items-md-end {
    align-items: flex-end !important;
  }
  .align-items-md-center {
    align-items: center !important;
  }
  .align-items-md-baseline {
    align-items: baseline !important;
  }
  .align-items-md-stretch {
    align-items: stretch !important;
  }
  .align-content-md-start {
    align-content: flex-start !important;
  }
  .align-content-md-end {
    align-content: flex-end !important;
  }
  .align-content-md-center {
    align-content: center !important;
  }
  .align-content-md-between {
    align-content: space-between !important;
  }
  .align-content-md-around {
    align-content: space-around !important;
  }
  .align-content-md-stretch {
    align-content: stretch !important;
  }
  .align-self-md-auto {
    align-self: auto !important;
  }
  .align-self-md-start {
    align-self: flex-start !important;
  }
  .align-self-md-end {
    align-self: flex-end !important;
  }
  .align-self-md-center {
    align-self: center !important;
  }
  .align-self-md-baseline {
    align-self: baseline !important;
  }
  .align-self-md-stretch {
    align-self: stretch !important;
  }
}

@media (min-width: 992px) {
  .flex-lg-row {
    flex-direction: row !important;
  }
  .flex-lg-column {
    flex-direction: column !important;
  }
  .flex-lg-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-lg-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-lg-wrap {
    flex-wrap: wrap !important;
  }
  .flex-lg-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-lg-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .flex-lg-fill {
    flex: 1 1 auto !important;
  }
  .flex-lg-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-lg-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-lg-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-lg-shrink-1 {
    flex-shrink: 1 !important;
  }
  .justify-content-lg-start {
    justify-content: flex-start !important;
  }
  .justify-content-lg-end {
    justify-content: flex-end !important;
  }
  .justify-content-lg-center {
    justify-content: center !important;
  }
  .justify-content-lg-between {
    justify-content: space-between !important;
  }
  .justify-content-lg-around {
    justify-content: space-around !important;
  }
  .align-items-lg-start {
    align-items: flex-start !important;
  }
  .align-items-lg-end {
    align-items: flex-end !important;
  }
  .align-items-lg-center {
    align-items: center !important;
  }
  .align-items-lg-baseline {
    align-items: baseline !important;
  }
  .align-items-lg-stretch {
    align-items: stretch !important;
  }
  .align-content-lg-start {
    align-content: flex-start !important;
  }
  .align-content-lg-end {
    align-content: flex-end !important;
  }
  .align-content-lg-center {
    align-content: center !important;
  }
  .align-content-lg-between {
    align-content: space-between !important;
  }
  .align-content-lg-around {
    align-content: space-around !important;
  }
  .align-content-lg-stretch {
    align-content: stretch !important;
  }
  .align-self-lg-auto {
    align-self: auto !important;
  }
  .align-self-lg-start {
    align-self: flex-start !important;
  }
  .align-self-lg-end {
    align-self: flex-end !important;
  }
  .align-self-lg-center {
    align-self: center !important;
  }
  .align-self-lg-baseline {
    align-self: baseline !important;
  }
  .align-self-lg-stretch {
    align-self: stretch !important;
  }
}

@media (min-width: 1200px) {
  .flex-xl-row {
    flex-direction: row !important;
  }
  .flex-xl-column {
    flex-direction: column !important;
  }
  .flex-xl-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-xl-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-xl-wrap {
    flex-wrap: wrap !important;
  }
  .flex-xl-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-xl-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .flex-xl-fill {
    flex: 1 1 auto !important;
  }
  .flex-xl-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-xl-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-xl-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-xl-shrink-1 {
    flex-shrink: 1 !important;
  }
  .justify-content-xl-start {
    justify-content: flex-start !important;
  }
  .justify-content-xl-end {
    justify-content: flex-end !important;
  }
  .justify-content-xl-center {
    justify-content: center !important;
  }
  .justify-content-xl-between {
    justify-content: space-between !important;
  }
  .justify-content-xl-around {
    justify-content: space-around !important;
  }
  .align-items-xl-start {
    align-items: flex-start !important;
  }
  .align-items-xl-end {
    align-items: flex-end !important;
  }
  .align-items-xl-center {
    align-items: center !important;
  }
  .align-items-xl-baseline {
    align-items: baseline !important;
  }
  .align-items-xl-stretch {
    align-items: stretch !important;
  }
  .align-content-xl-start {
    align-content: flex-start !important;
  }
  .align-content-xl-end {
    align-content: flex-end !important;
  }
  .align-content-xl-center {
    align-content: center !important;
  }
  .align-content-xl-between {
    align-content: space-between !important;
  }
  .align-content-xl-around {
    align-content: space-around !important;
  }
  .align-content-xl-stretch {
    align-content: stretch !important;
  }
  .align-self-xl-auto {
    align-self: auto !important;
  }
  .align-self-xl-start {
    align-self: flex-start !important;
  }
  .align-self-xl-end {
    align-self: flex-end !important;
  }
  .align-self-xl-center {
    align-self: center !important;
  }
  .align-self-xl-baseline {
    align-self: baseline !important;
  }
  .align-self-xl-stretch {
    align-self: stretch !important;
  }
}

.float-left {
  float: left !important;
}

.float-right {
  float: right !important;
}

.float-none {
  float: none !important;
}

@media (min-width: 576px) {
  .float-sm-left {
    float: left !important;
  }
  .float-sm-right {
    float: right !important;
  }
  .float-sm-none {
    float: none !important;
  }
}

@media (min-width: 768px) {
  .float-md-left {
    float: left !important;
  }
  .float-md-right {
    float: right !important;
  }
  .float-md-none {
    float: none !important;
  }
}

@media (min-width: 992px) {
  .float-lg-left {
    float: left !important;
  }
  .float-lg-right {
    float: right !important;
  }
  .float-lg-none {
    float: none !important;
  }
}

@media (min-width: 1200px) {
  .float-xl-left {
    float: left !important;
  }
  .float-xl-right {
    float: right !important;
  }
  .float-xl-none {
    float: none !important;
  }
}

.overflow-auto {
  overflow: auto !important;
}

.overflow-hidden {
  overflow: hidden !important;
}

.position-static {
  position: static !important;
}

.position-relative {
  position: relative !important;
}

.position-absolute {
  position: absolute !important;
}

.position-fixed {
  position: fixed !important;
}

.position-sticky {
  position: sticky !important;
}

.fixed-top {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030;
}

.fixed-bottom {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1030;
}

@supports (position: sticky) {
  .sticky-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }
}

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0;
}

.sr-only-focusable:active, .sr-only-focusable:focus {
  position: static;
  width: auto;
  height: auto;
  overflow: visible;
  clip: auto;
  white-space: normal;
}

.shadow-sm {
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important;
}

.shadow {
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
}

.shadow-lg {
  box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175) !important;
}

.shadow-none {
  box-shadow: none !important;
}

.w-25 {
  width: 25% !important;
}

.w-50 {
  width: 50% !important;
}

.w-75 {
  width: 75% !important;
}

.w-100 {
  width: 100% !important;
}

.w-auto {
  width: auto !important;
}

.h-25 {
  height: 25% !important;
}

.h-50 {
  height: 50% !important;
}

.h-75 {
  height: 75% !important;
}

.h-100 {
  height: 100% !important;
}

.h-auto {
  height: auto !important;
}

.mw-100 {
  max-width: 100% !important;
}

.mh-100 {
  max-height: 100% !important;
}

.min-vw-100 {
  min-width: 100vw !important;
}

.min-vh-100 {
  min-height: 100vh !important;
}

.vw-100 {
  width: 100vw !important;
}

.vh-100 {
  height: 100vh !important;
}

.stretched-link::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  pointer-events: auto;
  content: "";
  background-color: rgba(0, 0, 0, 0);
}

.m-0 {
  margin: 0 !important;
}

.mt-0,
.my-0 {
  margin-top: 0 !important;
}

.mr-0,
.mx-0 {
  margin-right: 0 !important;
}

.mb-0,
.my-0 {
  margin-bottom: 0 !important;
}

.ml-0,
.mx-0 {
  margin-left: 0 !important;
}

.m-1 {
  margin: 0.25rem !important;
}

.mt-1,
.my-1 {
  margin-top: 0.25rem !important;
}

.mr-1,
.mx-1 {
  margin-right: 0.25rem !important;
}

.mb-1,
.my-1 {
  margin-bottom: 0.25rem !important;
}

.ml-1,
.mx-1 {
  margin-left: 0.25rem !important;
}

.m-2 {
  margin: 0.5rem !important;
}

.mt-2,
.my-2 {
  margin-top: 0.5rem !important;
}

.mr-2,
.mx-2 {
  margin-right: 0.5rem !important;
}

.mb-2,
.my-2 {
  margin-bottom: 0.5rem !important;
}

.ml-2,
.mx-2 {
  margin-left: 0.5rem !important;
}

.m-3 {
  margin: 1rem !important;
}

.mt-3,
.my-3 {
  margin-top: 1rem !important;
}

.mr-3,
.mx-3 {
  margin-right: 1rem !important;
}

.mb-3,
.my-3 {
  margin-bottom: 1rem !important;
}

.ml-3,
.mx-3 {
  margin-left: 1rem !important;
}

.m-4 {
  margin: 1.5rem !important;
}

.mt-4,
.my-4 {
  margin-top: 1.5rem !important;
}

.mr-4,
.mx-4 {
  margin-right: 1.5rem !important;
}

.mb-4,
.my-4 {
  margin-bottom: 1.5rem !important;
}

.ml-4,
.mx-4 {
  margin-left: 1.5rem !important;
}

.m-5 {
  margin: 3rem !important;
}

.mt-5,
.my-5 {
  margin-top: 3rem !important;
}

.mr-5,
.mx-5 {
  margin-right: 3rem !important;
}

.mb-5,
.my-5 {
  margin-bottom: 3rem !important;
}

.ml-5,
.mx-5 {
  margin-left: 3rem !important;
}

.m-6 {
  margin: 4.5rem !important;
}

.mt-6,
.my-6 {
  margin-top: 4.5rem !important;
}

.mr-6,
.mx-6 {
  margin-right: 4.5rem !important;
}

.mb-6,
.my-6 {
  margin-bottom: 4.5rem !important;
}

.ml-6,
.mx-6 {
  margin-left: 4.5rem !important;
}

.p-0 {
  padding: 0 !important;
}

.pt-0,
.py-0 {
  padding-top: 0 !important;
}

.pr-0,
.px-0 {
  padding-right: 0 !important;
}

.pb-0,
.py-0 {
  padding-bottom: 0 !important;
}

.pl-0,
.px-0 {
  padding-left: 0 !important;
}

.p-1 {
  padding: 0.25rem !important;
}

.pt-1,
.py-1 {
  padding-top: 0.25rem !important;
}

.pr-1,
.px-1 {
  padding-right: 0.25rem !important;
}

.pb-1,
.py-1 {
  padding-bottom: 0.25rem !important;
}

.pl-1,
.px-1 {
  padding-left: 0.25rem !important;
}

.p-2 {
  padding: 0.5rem !important;
}

.pt-2,
.py-2 {
  padding-top: 0.5rem !important;
}

.pr-2,
.px-2 {
  padding-right: 0.5rem !important;
}

.pb-2,
.py-2 {
  padding-bottom: 0.5rem !important;
}

.pl-2,
.px-2 {
  padding-left: 0.5rem !important;
}

.p-3 {
  padding: 1rem !important;
}

.pt-3,
.py-3 {
  padding-top: 1rem !important;
}

.pr-3,
.px-3 {
  padding-right: 1rem !important;
}

.pb-3,
.py-3 {
  padding-bottom: 1rem !important;
}

.pl-3,
.px-3 {
  padding-left: 1rem !important;
}

.p-4 {
  padding: 1.5rem !important;
}

.pt-4,
.py-4 {
  padding-top: 1.5rem !important;
}

.pr-4,
.px-4 {
  padding-right: 1.5rem !important;
}

.pb-4,
.py-4 {
  padding-bottom: 1.5rem !important;
}

.pl-4,
.px-4 {
  padding-left: 1.5rem !important;
}

.p-5 {
  padding: 3rem !important;
}

.pt-5,
.py-5 {
  padding-top: 3rem !important;
}

.pr-5,
.px-5 {
  padding-right: 3rem !important;
}

.pb-5,
.py-5 {
  padding-bottom: 3rem !important;
}

.pl-5,
.px-5 {
  padding-left: 3rem !important;
}

.p-6 {
  padding: 4.5rem !important;
}

.pt-6,
.py-6 {
  padding-top: 4.5rem !important;
}

.pr-6,
.px-6 {
  padding-right: 4.5rem !important;
}

.pb-6,
.py-6 {
  padding-bottom: 4.5rem !important;
}

.pl-6,
.px-6 {
  padding-left: 4.5rem !important;
}

.m-n1 {
  margin: -0.25rem !important;
}

.mt-n1,
.my-n1 {
  margin-top: -0.25rem !important;
}

.mr-n1,
.mx-n1 {
  margin-right: -0.25rem !important;
}

.mb-n1,
.my-n1 {
  margin-bottom: -0.25rem !important;
}

.ml-n1,
.mx-n1 {
  margin-left: -0.25rem !important;
}

.m-n2 {
  margin: -0.5rem !important;
}

.mt-n2,
.my-n2 {
  margin-top: -0.5rem !important;
}

.mr-n2,
.mx-n2 {
  margin-right: -0.5rem !important;
}

.mb-n2,
.my-n2 {
  margin-bottom: -0.5rem !important;
}

.ml-n2,
.mx-n2 {
  margin-left: -0.5rem !important;
}

.m-n3 {
  margin: -1rem !important;
}

.mt-n3,
.my-n3 {
  margin-top: -1rem !important;
}

.mr-n3,
.mx-n3 {
  margin-right: -1rem !important;
}

.mb-n3,
.my-n3 {
  margin-bottom: -1rem !important;
}

.ml-n3,
.mx-n3 {
  margin-left: -1rem !important;
}

.m-n4 {
  margin: -1.5rem !important;
}

.mt-n4,
.my-n4 {
  margin-top: -1.5rem !important;
}

.mr-n4,
.mx-n4 {
  margin-right: -1.5rem !important;
}

.mb-n4,
.my-n4 {
  margin-bottom: -1.5rem !important;
}

.ml-n4,
.mx-n4 {
  margin-left: -1.5rem !important;
}

.m-n5 {
  margin: -3rem !important;
}

.mt-n5,
.my-n5 {
  margin-top: -3rem !important;
}

.mr-n5,
.mx-n5 {
  margin-right: -3rem !important;
}

.mb-n5,
.my-n5 {
  margin-bottom: -3rem !important;
}

.ml-n5,
.mx-n5 {
  margin-left: -3rem !important;
}

.m-n6 {
  margin: -4.5rem !important;
}

.mt-n6,
.my-n6 {
  margin-top: -4.5rem !important;
}

.mr-n6,
.mx-n6 {
  margin-right: -4.5rem !important;
}

.mb-n6,
.my-n6 {
  margin-bottom: -4.5rem !important;
}

.ml-n6,
.mx-n6 {
  margin-left: -4.5rem !important;
}

.m-auto {
  margin: auto !important;
}

.mt-auto,
.my-auto {
  margin-top: auto !important;
}

.mr-auto,
.mx-auto {
  margin-right: auto !important;
}

.mb-auto,
.my-auto {
  margin-bottom: auto !important;
}

.ml-auto,
.mx-auto {
  margin-left: auto !important;
}

@media (min-width: 576px) {
  .m-sm-0 {
    margin: 0 !important;
  }
  .mt-sm-0,
  .my-sm-0 {
    margin-top: 0 !important;
  }
  .mr-sm-0,
  .mx-sm-0 {
    margin-right: 0 !important;
  }
  .mb-sm-0,
  .my-sm-0 {
    margin-bottom: 0 !important;
  }
  .ml-sm-0,
  .mx-sm-0 {
    margin-left: 0 !important;
  }
  .m-sm-1 {
    margin: 0.25rem !important;
  }
  .mt-sm-1,
  .my-sm-1 {
    margin-top: 0.25rem !important;
  }
  .mr-sm-1,
  .mx-sm-1 {
    margin-right: 0.25rem !important;
  }
  .mb-sm-1,
  .my-sm-1 {
    margin-bottom: 0.25rem !important;
  }
  .ml-sm-1,
  .mx-sm-1 {
    margin-left: 0.25rem !important;
  }
  .m-sm-2 {
    margin: 0.5rem !important;
  }
  .mt-sm-2,
  .my-sm-2 {
    margin-top: 0.5rem !important;
  }
  .mr-sm-2,
  .mx-sm-2 {
    margin-right: 0.5rem !important;
  }
  .mb-sm-2,
  .my-sm-2 {
    margin-bottom: 0.5rem !important;
  }
  .ml-sm-2,
  .mx-sm-2 {
    margin-left: 0.5rem !important;
  }
  .m-sm-3 {
    margin: 1rem !important;
  }
  .mt-sm-3,
  .my-sm-3 {
    margin-top: 1rem !important;
  }
  .mr-sm-3,
  .mx-sm-3 {
    margin-right: 1rem !important;
  }
  .mb-sm-3,
  .my-sm-3 {
    margin-bottom: 1rem !important;
  }
  .ml-sm-3,
  .mx-sm-3 {
    margin-left: 1rem !important;
  }
  .m-sm-4 {
    margin: 1.5rem !important;
  }
  .mt-sm-4,
  .my-sm-4 {
    margin-top: 1.5rem !important;
  }
  .mr-sm-4,
  .mx-sm-4 {
    margin-right: 1.5rem !important;
  }
  .mb-sm-4,
  .my-sm-4 {
    margin-bottom: 1.5rem !important;
  }
  .ml-sm-4,
  .mx-sm-4 {
    margin-left: 1.5rem !important;
  }
  .m-sm-5 {
    margin: 3rem !important;
  }
  .mt-sm-5,
  .my-sm-5 {
    margin-top: 3rem !important;
  }
  .mr-sm-5,
  .mx-sm-5 {
    margin-right: 3rem !important;
  }
  .mb-sm-5,
  .my-sm-5 {
    margin-bottom: 3rem !important;
  }
  .ml-sm-5,
  .mx-sm-5 {
    margin-left: 3rem !important;
  }
  .m-sm-6 {
    margin: 4.5rem !important;
  }
  .mt-sm-6,
  .my-sm-6 {
    margin-top: 4.5rem !important;
  }
  .mr-sm-6,
  .mx-sm-6 {
    margin-right: 4.5rem !important;
  }
  .mb-sm-6,
  .my-sm-6 {
    margin-bottom: 4.5rem !important;
  }
  .ml-sm-6,
  .mx-sm-6 {
    margin-left: 4.5rem !important;
  }
  .p-sm-0 {
    padding: 0 !important;
  }
  .pt-sm-0,
  .py-sm-0 {
    padding-top: 0 !important;
  }
  .pr-sm-0,
  .px-sm-0 {
    padding-right: 0 !important;
  }
  .pb-sm-0,
  .py-sm-0 {
    padding-bottom: 0 !important;
  }
  .pl-sm-0,
  .px-sm-0 {
    padding-left: 0 !important;
  }
  .p-sm-1 {
    padding: 0.25rem !important;
  }
  .pt-sm-1,
  .py-sm-1 {
    padding-top: 0.25rem !important;
  }
  .pr-sm-1,
  .px-sm-1 {
    padding-right: 0.25rem !important;
  }
  .pb-sm-1,
  .py-sm-1 {
    padding-bottom: 0.25rem !important;
  }
  .pl-sm-1,
  .px-sm-1 {
    padding-left: 0.25rem !important;
  }
  .p-sm-2 {
    padding: 0.5rem !important;
  }
  .pt-sm-2,
  .py-sm-2 {
    padding-top: 0.5rem !important;
  }
  .pr-sm-2,
  .px-sm-2 {
    padding-right: 0.5rem !important;
  }
  .pb-sm-2,
  .py-sm-2 {
    padding-bottom: 0.5rem !important;
  }
  .pl-sm-2,
  .px-sm-2 {
    padding-left: 0.5rem !important;
  }
  .p-sm-3 {
    padding: 1rem !important;
  }
  .pt-sm-3,
  .py-sm-3 {
    padding-top: 1rem !important;
  }
  .pr-sm-3,
  .px-sm-3 {
    padding-right: 1rem !important;
  }
  .pb-sm-3,
  .py-sm-3 {
    padding-bottom: 1rem !important;
  }
  .pl-sm-3,
  .px-sm-3 {
    padding-left: 1rem !important;
  }
  .p-sm-4 {
    padding: 1.5rem !important;
  }
  .pt-sm-4,
  .py-sm-4 {
    padding-top: 1.5rem !important;
  }
  .pr-sm-4,
  .px-sm-4 {
    padding-right: 1.5rem !important;
  }
  .pb-sm-4,
  .py-sm-4 {
    padding-bottom: 1.5rem !important;
  }
  .pl-sm-4,
  .px-sm-4 {
    padding-left: 1.5rem !important;
  }
  .p-sm-5 {
    padding: 3rem !important;
  }
  .pt-sm-5,
  .py-sm-5 {
    padding-top: 3rem !important;
  }
  .pr-sm-5,
  .px-sm-5 {
    padding-right: 3rem !important;
  }
  .pb-sm-5,
  .py-sm-5 {
    padding-bottom: 3rem !important;
  }
  .pl-sm-5,
  .px-sm-5 {
    padding-left: 3rem !important;
  }
  .p-sm-6 {
    padding: 4.5rem !important;
  }
  .pt-sm-6,
  .py-sm-6 {
    padding-top: 4.5rem !important;
  }
  .pr-sm-6,
  .px-sm-6 {
    padding-right: 4.5rem !important;
  }
  .pb-sm-6,
  .py-sm-6 {
    padding-bottom: 4.5rem !important;
  }
  .pl-sm-6,
  .px-sm-6 {
    padding-left: 4.5rem !important;
  }
  .m-sm-n1 {
    margin: -0.25rem !important;
  }
  .mt-sm-n1,
  .my-sm-n1 {
    margin-top: -0.25rem !important;
  }
  .mr-sm-n1,
  .mx-sm-n1 {
    margin-right: -0.25rem !important;
  }
  .mb-sm-n1,
  .my-sm-n1 {
    margin-bottom: -0.25rem !important;
  }
  .ml-sm-n1,
  .mx-sm-n1 {
    margin-left: -0.25rem !important;
  }
  .m-sm-n2 {
    margin: -0.5rem !important;
  }
  .mt-sm-n2,
  .my-sm-n2 {
    margin-top: -0.5rem !important;
  }
  .mr-sm-n2,
  .mx-sm-n2 {
    margin-right: -0.5rem !important;
  }
  .mb-sm-n2,
  .my-sm-n2 {
    margin-bottom: -0.5rem !important;
  }
  .ml-sm-n2,
  .mx-sm-n2 {
    margin-left: -0.5rem !important;
  }
  .m-sm-n3 {
    margin: -1rem !important;
  }
  .mt-sm-n3,
  .my-sm-n3 {
    margin-top: -1rem !important;
  }
  .mr-sm-n3,
  .mx-sm-n3 {
    margin-right: -1rem !important;
  }
  .mb-sm-n3,
  .my-sm-n3 {
    margin-bottom: -1rem !important;
  }
  .ml-sm-n3,
  .mx-sm-n3 {
    margin-left: -1rem !important;
  }
  .m-sm-n4 {
    margin: -1.5rem !important;
  }
  .mt-sm-n4,
  .my-sm-n4 {
    margin-top: -1.5rem !important;
  }
  .mr-sm-n4,
  .mx-sm-n4 {
    margin-right: -1.5rem !important;
  }
  .mb-sm-n4,
  .my-sm-n4 {
    margin-bottom: -1.5rem !important;
  }
  .ml-sm-n4,
  .mx-sm-n4 {
    margin-left: -1.5rem !important;
  }
  .m-sm-n5 {
    margin: -3rem !important;
  }
  .mt-sm-n5,
  .my-sm-n5 {
    margin-top: -3rem !important;
  }
  .mr-sm-n5,
  .mx-sm-n5 {
    margin-right: -3rem !important;
  }
  .mb-sm-n5,
  .my-sm-n5 {
    margin-bottom: -3rem !important;
  }
  .ml-sm-n5,
  .mx-sm-n5 {
    margin-left: -3rem !important;
  }
  .m-sm-n6 {
    margin: -4.5rem !important;
  }
  .mt-sm-n6,
  .my-sm-n6 {
    margin-top: -4.5rem !important;
  }
  .mr-sm-n6,
  .mx-sm-n6 {
    margin-right: -4.5rem !important;
  }
  .mb-sm-n6,
  .my-sm-n6 {
    margin-bottom: -4.5rem !important;
  }
  .ml-sm-n6,
  .mx-sm-n6 {
    margin-left: -4.5rem !important;
  }
  .m-sm-auto {
    margin: auto !important;
  }
  .mt-sm-auto,
  .my-sm-auto {
    margin-top: auto !important;
  }
  .mr-sm-auto,
  .mx-sm-auto {
    margin-right: auto !important;
  }
  .mb-sm-auto,
  .my-sm-auto {
    margin-bottom: auto !important;
  }
  .ml-sm-auto,
  .mx-sm-auto {
    margin-left: auto !important;
  }
}

@media (min-width: 768px) {
  .m-md-0 {
    margin: 0 !important;
  }
  .mt-md-0,
  .my-md-0 {
    margin-top: 0 !important;
  }
  .mr-md-0,
  .mx-md-0 {
    margin-right: 0 !important;
  }
  .mb-md-0,
  .my-md-0 {
    margin-bottom: 0 !important;
  }
  .ml-md-0,
  .mx-md-0 {
    margin-left: 0 !important;
  }
  .m-md-1 {
    margin: 0.25rem !important;
  }
  .mt-md-1,
  .my-md-1 {
    margin-top: 0.25rem !important;
  }
  .mr-md-1,
  .mx-md-1 {
    margin-right: 0.25rem !important;
  }
  .mb-md-1,
  .my-md-1 {
    margin-bottom: 0.25rem !important;
  }
  .ml-md-1,
  .mx-md-1 {
    margin-left: 0.25rem !important;
  }
  .m-md-2 {
    margin: 0.5rem !important;
  }
  .mt-md-2,
  .my-md-2 {
    margin-top: 0.5rem !important;
  }
  .mr-md-2,
  .mx-md-2 {
    margin-right: 0.5rem !important;
  }
  .mb-md-2,
  .my-md-2 {
    margin-bottom: 0.5rem !important;
  }
  .ml-md-2,
  .mx-md-2 {
    margin-left: 0.5rem !important;
  }
  .m-md-3 {
    margin: 1rem !important;
  }
  .mt-md-3,
  .my-md-3 {
    margin-top: 1rem !important;
  }
  .mr-md-3,
  .mx-md-3 {
    margin-right: 1rem !important;
  }
  .mb-md-3,
  .my-md-3 {
    margin-bottom: 1rem !important;
  }
  .ml-md-3,
  .mx-md-3 {
    margin-left: 1rem !important;
  }
  .m-md-4 {
    margin: 1.5rem !important;
  }
  .mt-md-4,
  .my-md-4 {
    margin-top: 1.5rem !important;
  }
  .mr-md-4,
  .mx-md-4 {
    margin-right: 1.5rem !important;
  }
  .mb-md-4,
  .my-md-4 {
    margin-bottom: 1.5rem !important;
  }
  .ml-md-4,
  .mx-md-4 {
    margin-left: 1.5rem !important;
  }
  .m-md-5 {
    margin: 3rem !important;
  }
  .mt-md-5,
  .my-md-5 {
    margin-top: 3rem !important;
  }
  .mr-md-5,
  .mx-md-5 {
    margin-right: 3rem !important;
  }
  .mb-md-5,
  .my-md-5 {
    margin-bottom: 3rem !important;
  }
  .ml-md-5,
  .mx-md-5 {
    margin-left: 3rem !important;
  }
  .m-md-6 {
    margin: 4.5rem !important;
  }
  .mt-md-6,
  .my-md-6 {
    margin-top: 4.5rem !important;
  }
  .mr-md-6,
  .mx-md-6 {
    margin-right: 4.5rem !important;
  }
  .mb-md-6,
  .my-md-6 {
    margin-bottom: 4.5rem !important;
  }
  .ml-md-6,
  .mx-md-6 {
    margin-left: 4.5rem !important;
  }
  .p-md-0 {
    padding: 0 !important;
  }
  .pt-md-0,
  .py-md-0 {
    padding-top: 0 !important;
  }
  .pr-md-0,
  .px-md-0 {
    padding-right: 0 !important;
  }
  .pb-md-0,
  .py-md-0 {
    padding-bottom: 0 !important;
  }
  .pl-md-0,
  .px-md-0 {
    padding-left: 0 !important;
  }
  .p-md-1 {
    padding: 0.25rem !important;
  }
  .pt-md-1,
  .py-md-1 {
    padding-top: 0.25rem !important;
  }
  .pr-md-1,
  .px-md-1 {
    padding-right: 0.25rem !important;
  }
  .pb-md-1,
  .py-md-1 {
    padding-bottom: 0.25rem !important;
  }
  .pl-md-1,
  .px-md-1 {
    padding-left: 0.25rem !important;
  }
  .p-md-2 {
    padding: 0.5rem !important;
  }
  .pt-md-2,
  .py-md-2 {
    padding-top: 0.5rem !important;
  }
  .pr-md-2,
  .px-md-2 {
    padding-right: 0.5rem !important;
  }
  .pb-md-2,
  .py-md-2 {
    padding-bottom: 0.5rem !important;
  }
  .pl-md-2,
  .px-md-2 {
    padding-left: 0.5rem !important;
  }
  .p-md-3 {
    padding: 1rem !important;
  }
  .pt-md-3,
  .py-md-3 {
    padding-top: 1rem !important;
  }
  .pr-md-3,
  .px-md-3 {
    padding-right: 1rem !important;
  }
  .pb-md-3,
  .py-md-3 {
    padding-bottom: 1rem !important;
  }
  .pl-md-3,
  .px-md-3 {
    padding-left: 1rem !important;
  }
  .p-md-4 {
    padding: 1.5rem !important;
  }
  .pt-md-4,
  .py-md-4 {
    padding-top: 1.5rem !important;
  }
  .pr-md-4,
  .px-md-4 {
    padding-right: 1.5rem !important;
  }
  .pb-md-4,
  .py-md-4 {
    padding-bottom: 1.5rem !important;
  }
  .pl-md-4,
  .px-md-4 {
    padding-left: 1.5rem !important;
  }
  .p-md-5 {
    padding: 3rem !important;
  }
  .pt-md-5,
  .py-md-5 {
    padding-top: 3rem !important;
  }
  .pr-md-5,
  .px-md-5 {
    padding-right: 3rem !important;
  }
  .pb-md-5,
  .py-md-5 {
    padding-bottom: 3rem !important;
  }
  .pl-md-5,
  .px-md-5 {
    padding-left: 3rem !important;
  }
  .p-md-6 {
    padding: 4.5rem !important;
  }
  .pt-md-6,
  .py-md-6 {
    padding-top: 4.5rem !important;
  }
  .pr-md-6,
  .px-md-6 {
    padding-right: 4.5rem !important;
  }
  .pb-md-6,
  .py-md-6 {
    padding-bottom: 4.5rem !important;
  }
  .pl-md-6,
  .px-md-6 {
    padding-left: 4.5rem !important;
  }
  .m-md-n1 {
    margin: -0.25rem !important;
  }
  .mt-md-n1,
  .my-md-n1 {
    margin-top: -0.25rem !important;
  }
  .mr-md-n1,
  .mx-md-n1 {
    margin-right: -0.25rem !important;
  }
  .mb-md-n1,
  .my-md-n1 {
    margin-bottom: -0.25rem !important;
  }
  .ml-md-n1,
  .mx-md-n1 {
    margin-left: -0.25rem !important;
  }
  .m-md-n2 {
    margin: -0.5rem !important;
  }
  .mt-md-n2,
  .my-md-n2 {
    margin-top: -0.5rem !important;
  }
  .mr-md-n2,
  .mx-md-n2 {
    margin-right: -0.5rem !important;
  }
  .mb-md-n2,
  .my-md-n2 {
    margin-bottom: -0.5rem !important;
  }
  .ml-md-n2,
  .mx-md-n2 {
    margin-left: -0.5rem !important;
  }
  .m-md-n3 {
    margin: -1rem !important;
  }
  .mt-md-n3,
  .my-md-n3 {
    margin-top: -1rem !important;
  }
  .mr-md-n3,
  .mx-md-n3 {
    margin-right: -1rem !important;
  }
  .mb-md-n3,
  .my-md-n3 {
    margin-bottom: -1rem !important;
  }
  .ml-md-n3,
  .mx-md-n3 {
    margin-left: -1rem !important;
  }
  .m-md-n4 {
    margin: -1.5rem !important;
  }
  .mt-md-n4,
  .my-md-n4 {
    margin-top: -1.5rem !important;
  }
  .mr-md-n4,
  .mx-md-n4 {
    margin-right: -1.5rem !important;
  }
  .mb-md-n4,
  .my-md-n4 {
    margin-bottom: -1.5rem !important;
  }
  .ml-md-n4,
  .mx-md-n4 {
    margin-left: -1.5rem !important;
  }
  .m-md-n5 {
    margin: -3rem !important;
  }
  .mt-md-n5,
  .my-md-n5 {
    margin-top: -3rem !important;
  }
  .mr-md-n5,
  .mx-md-n5 {
    margin-right: -3rem !important;
  }
  .mb-md-n5,
  .my-md-n5 {
    margin-bottom: -3rem !important;
  }
  .ml-md-n5,
  .mx-md-n5 {
    margin-left: -3rem !important;
  }
  .m-md-n6 {
    margin: -4.5rem !important;
  }
  .mt-md-n6,
  .my-md-n6 {
    margin-top: -4.5rem !important;
  }
  .mr-md-n6,
  .mx-md-n6 {
    margin-right: -4.5rem !important;
  }
  .mb-md-n6,
  .my-md-n6 {
    margin-bottom: -4.5rem !important;
  }
  .ml-md-n6,
  .mx-md-n6 {
    margin-left: -4.5rem !important;
  }
  .m-md-auto {
    margin: auto !important;
  }
  .mt-md-auto,
  .my-md-auto {
    margin-top: auto !important;
  }
  .mr-md-auto,
  .mx-md-auto {
    margin-right: auto !important;
  }
  .mb-md-auto,
  .my-md-auto {
    margin-bottom: auto !important;
  }
  .ml-md-auto,
  .mx-md-auto {
    margin-left: auto !important;
  }
}

@media (min-width: 992px) {
  .m-lg-0 {
    margin: 0 !important;
  }
  .mt-lg-0,
  .my-lg-0 {
    margin-top: 0 !important;
  }
  .mr-lg-0,
  .mx-lg-0 {
    margin-right: 0 !important;
  }
  .mb-lg-0,
  .my-lg-0 {
    margin-bottom: 0 !important;
  }
  .ml-lg-0,
  .mx-lg-0 {
    margin-left: 0 !important;
  }
  .m-lg-1 {
    margin: 0.25rem !important;
  }
  .mt-lg-1,
  .my-lg-1 {
    margin-top: 0.25rem !important;
  }
  .mr-lg-1,
  .mx-lg-1 {
    margin-right: 0.25rem !important;
  }
  .mb-lg-1,
  .my-lg-1 {
    margin-bottom: 0.25rem !important;
  }
  .ml-lg-1,
  .mx-lg-1 {
    margin-left: 0.25rem !important;
  }
  .m-lg-2 {
    margin: 0.5rem !important;
  }
  .mt-lg-2,
  .my-lg-2 {
    margin-top: 0.5rem !important;
  }
  .mr-lg-2,
  .mx-lg-2 {
    margin-right: 0.5rem !important;
  }
  .mb-lg-2,
  .my-lg-2 {
    margin-bottom: 0.5rem !important;
  }
  .ml-lg-2,
  .mx-lg-2 {
    margin-left: 0.5rem !important;
  }
  .m-lg-3 {
    margin: 1rem !important;
  }
  .mt-lg-3,
  .my-lg-3 {
    margin-top: 1rem !important;
  }
  .mr-lg-3,
  .mx-lg-3 {
    margin-right: 1rem !important;
  }
  .mb-lg-3,
  .my-lg-3 {
    margin-bottom: 1rem !important;
  }
  .ml-lg-3,
  .mx-lg-3 {
    margin-left: 1rem !important;
  }
  .m-lg-4 {
    margin: 1.5rem !important;
  }
  .mt-lg-4,
  .my-lg-4 {
    margin-top: 1.5rem !important;
  }
  .mr-lg-4,
  .mx-lg-4 {
    margin-right: 1.5rem !important;
  }
  .mb-lg-4,
  .my-lg-4 {
    margin-bottom: 1.5rem !important;
  }
  .ml-lg-4,
  .mx-lg-4 {
    margin-left: 1.5rem !important;
  }
  .m-lg-5 {
    margin: 3rem !important;
  }
  .mt-lg-5,
  .my-lg-5 {
    margin-top: 3rem !important;
  }
  .mr-lg-5,
  .mx-lg-5 {
    margin-right: 3rem !important;
  }
  .mb-lg-5,
  .my-lg-5 {
    margin-bottom: 3rem !important;
  }
  .ml-lg-5,
  .mx-lg-5 {
    margin-left: 3rem !important;
  }
  .m-lg-6 {
    margin: 4.5rem !important;
  }
  .mt-lg-6,
  .my-lg-6 {
    margin-top: 4.5rem !important;
  }
  .mr-lg-6,
  .mx-lg-6 {
    margin-right: 4.5rem !important;
  }
  .mb-lg-6,
  .my-lg-6 {
    margin-bottom: 4.5rem !important;
  }
  .ml-lg-6,
  .mx-lg-6 {
    margin-left: 4.5rem !important;
  }
  .p-lg-0 {
    padding: 0 !important;
  }
  .pt-lg-0,
  .py-lg-0 {
    padding-top: 0 !important;
  }
  .pr-lg-0,
  .px-lg-0 {
    padding-right: 0 !important;
  }
  .pb-lg-0,
  .py-lg-0 {
    padding-bottom: 0 !important;
  }
  .pl-lg-0,
  .px-lg-0 {
    padding-left: 0 !important;
  }
  .p-lg-1 {
    padding: 0.25rem !important;
  }
  .pt-lg-1,
  .py-lg-1 {
    padding-top: 0.25rem !important;
  }
  .pr-lg-1,
  .px-lg-1 {
    padding-right: 0.25rem !important;
  }
  .pb-lg-1,
  .py-lg-1 {
    padding-bottom: 0.25rem !important;
  }
  .pl-lg-1,
  .px-lg-1 {
    padding-left: 0.25rem !important;
  }
  .p-lg-2 {
    padding: 0.5rem !important;
  }
  .pt-lg-2,
  .py-lg-2 {
    padding-top: 0.5rem !important;
  }
  .pr-lg-2,
  .px-lg-2 {
    padding-right: 0.5rem !important;
  }
  .pb-lg-2,
  .py-lg-2 {
    padding-bottom: 0.5rem !important;
  }
  .pl-lg-2,
  .px-lg-2 {
    padding-left: 0.5rem !important;
  }
  .p-lg-3 {
    padding: 1rem !important;
  }
  .pt-lg-3,
  .py-lg-3 {
    padding-top: 1rem !important;
  }
  .pr-lg-3,
  .px-lg-3 {
    padding-right: 1rem !important;
  }
  .pb-lg-3,
  .py-lg-3 {
    padding-bottom: 1rem !important;
  }
  .pl-lg-3,
  .px-lg-3 {
    padding-left: 1rem !important;
  }
  .p-lg-4 {
    padding: 1.5rem !important;
  }
  .pt-lg-4,
  .py-lg-4 {
    padding-top: 1.5rem !important;
  }
  .pr-lg-4,
  .px-lg-4 {
    padding-right: 1.5rem !important;
  }
  .pb-lg-4,
  .py-lg-4 {
    padding-bottom: 1.5rem !important;
  }
  .pl-lg-4,
  .px-lg-4 {
    padding-left: 1.5rem !important;
  }
  .p-lg-5 {
    padding: 3rem !important;
  }
  .pt-lg-5,
  .py-lg-5 {
    padding-top: 3rem !important;
  }
  .pr-lg-5,
  .px-lg-5 {
    padding-right: 3rem !important;
  }
  .pb-lg-5,
  .py-lg-5 {
    padding-bottom: 3rem !important;
  }
  .pl-lg-5,
  .px-lg-5 {
    padding-left: 3rem !important;
  }
  .p-lg-6 {
    padding: 4.5rem !important;
  }
  .pt-lg-6,
  .py-lg-6 {
    padding-top: 4.5rem !important;
  }
  .pr-lg-6,
  .px-lg-6 {
    padding-right: 4.5rem !important;
  }
  .pb-lg-6,
  .py-lg-6 {
    padding-bottom: 4.5rem !important;
  }
  .pl-lg-6,
  .px-lg-6 {
    padding-left: 4.5rem !important;
  }
  .m-lg-n1 {
    margin: -0.25rem !important;
  }
  .mt-lg-n1,
  .my-lg-n1 {
    margin-top: -0.25rem !important;
  }
  .mr-lg-n1,
  .mx-lg-n1 {
    margin-right: -0.25rem !important;
  }
  .mb-lg-n1,
  .my-lg-n1 {
    margin-bottom: -0.25rem !important;
  }
  .ml-lg-n1,
  .mx-lg-n1 {
    margin-left: -0.25rem !important;
  }
  .m-lg-n2 {
    margin: -0.5rem !important;
  }
  .mt-lg-n2,
  .my-lg-n2 {
    margin-top: -0.5rem !important;
  }
  .mr-lg-n2,
  .mx-lg-n2 {
    margin-right: -0.5rem !important;
  }
  .mb-lg-n2,
  .my-lg-n2 {
    margin-bottom: -0.5rem !important;
  }
  .ml-lg-n2,
  .mx-lg-n2 {
    margin-left: -0.5rem !important;
  }
  .m-lg-n3 {
    margin: -1rem !important;
  }
  .mt-lg-n3,
  .my-lg-n3 {
    margin-top: -1rem !important;
  }
  .mr-lg-n3,
  .mx-lg-n3 {
    margin-right: -1rem !important;
  }
  .mb-lg-n3,
  .my-lg-n3 {
    margin-bottom: -1rem !important;
  }
  .ml-lg-n3,
  .mx-lg-n3 {
    margin-left: -1rem !important;
  }
  .m-lg-n4 {
    margin: -1.5rem !important;
  }
  .mt-lg-n4,
  .my-lg-n4 {
    margin-top: -1.5rem !important;
  }
  .mr-lg-n4,
  .mx-lg-n4 {
    margin-right: -1.5rem !important;
  }
  .mb-lg-n4,
  .my-lg-n4 {
    margin-bottom: -1.5rem !important;
  }
  .ml-lg-n4,
  .mx-lg-n4 {
    margin-left: -1.5rem !important;
  }
  .m-lg-n5 {
    margin: -3rem !important;
  }
  .mt-lg-n5,
  .my-lg-n5 {
    margin-top: -3rem !important;
  }
  .mr-lg-n5,
  .mx-lg-n5 {
    margin-right: -3rem !important;
  }
  .mb-lg-n5,
  .my-lg-n5 {
    margin-bottom: -3rem !important;
  }
  .ml-lg-n5,
  .mx-lg-n5 {
    margin-left: -3rem !important;
  }
  .m-lg-n6 {
    margin: -4.5rem !important;
  }
  .mt-lg-n6,
  .my-lg-n6 {
    margin-top: -4.5rem !important;
  }
  .mr-lg-n6,
  .mx-lg-n6 {
    margin-right: -4.5rem !important;
  }
  .mb-lg-n6,
  .my-lg-n6 {
    margin-bottom: -4.5rem !important;
  }
  .ml-lg-n6,
  .mx-lg-n6 {
    margin-left: -4.5rem !important;
  }
  .m-lg-auto {
    margin: auto !important;
  }
  .mt-lg-auto,
  .my-lg-auto {
    margin-top: auto !important;
  }
  .mr-lg-auto,
  .mx-lg-auto {
    margin-right: auto !important;
  }
  .mb-lg-auto,
  .my-lg-auto {
    margin-bottom: auto !important;
  }
  .ml-lg-auto,
  .mx-lg-auto {
    margin-left: auto !important;
  }
}

@media (min-width: 1200px) {
  .m-xl-0 {
    margin: 0 !important;
  }
  .mt-xl-0,
  .my-xl-0 {
    margin-top: 0 !important;
  }
  .mr-xl-0,
  .mx-xl-0 {
    margin-right: 0 !important;
  }
  .mb-xl-0,
  .my-xl-0 {
    margin-bottom: 0 !important;
  }
  .ml-xl-0,
  .mx-xl-0 {
    margin-left: 0 !important;
  }
  .m-xl-1 {
    margin: 0.25rem !important;
  }
  .mt-xl-1,
  .my-xl-1 {
    margin-top: 0.25rem !important;
  }
  .mr-xl-1,
  .mx-xl-1 {
    margin-right: 0.25rem !important;
  }
  .mb-xl-1,
  .my-xl-1 {
    margin-bottom: 0.25rem !important;
  }
  .ml-xl-1,
  .mx-xl-1 {
    margin-left: 0.25rem !important;
  }
  .m-xl-2 {
    margin: 0.5rem !important;
  }
  .mt-xl-2,
  .my-xl-2 {
    margin-top: 0.5rem !important;
  }
  .mr-xl-2,
  .mx-xl-2 {
    margin-right: 0.5rem !important;
  }
  .mb-xl-2,
  .my-xl-2 {
    margin-bottom: 0.5rem !important;
  }
  .ml-xl-2,
  .mx-xl-2 {
    margin-left: 0.5rem !important;
  }
  .m-xl-3 {
    margin: 1rem !important;
  }
  .mt-xl-3,
  .my-xl-3 {
    margin-top: 1rem !important;
  }
  .mr-xl-3,
  .mx-xl-3 {
    margin-right: 1rem !important;
  }
  .mb-xl-3,
  .my-xl-3 {
    margin-bottom: 1rem !important;
  }
  .ml-xl-3,
  .mx-xl-3 {
    margin-left: 1rem !important;
  }
  .m-xl-4 {
    margin: 1.5rem !important;
  }
  .mt-xl-4,
  .my-xl-4 {
    margin-top: 1.5rem !important;
  }
  .mr-xl-4,
  .mx-xl-4 {
    margin-right: 1.5rem !important;
  }
  .mb-xl-4,
  .my-xl-4 {
    margin-bottom: 1.5rem !important;
  }
  .ml-xl-4,
  .mx-xl-4 {
    margin-left: 1.5rem !important;
  }
  .m-xl-5 {
    margin: 3rem !important;
  }
  .mt-xl-5,
  .my-xl-5 {
    margin-top: 3rem !important;
  }
  .mr-xl-5,
  .mx-xl-5 {
    margin-right: 3rem !important;
  }
  .mb-xl-5,
  .my-xl-5 {
    margin-bottom: 3rem !important;
  }
  .ml-xl-5,
  .mx-xl-5 {
    margin-left: 3rem !important;
  }
  .m-xl-6 {
    margin: 4.5rem !important;
  }
  .mt-xl-6,
  .my-xl-6 {
    margin-top: 4.5rem !important;
  }
  .mr-xl-6,
  .mx-xl-6 {
    margin-right: 4.5rem !important;
  }
  .mb-xl-6,
  .my-xl-6 {
    margin-bottom: 4.5rem !important;
  }
  .ml-xl-6,
  .mx-xl-6 {
    margin-left: 4.5rem !important;
  }
  .p-xl-0 {
    padding: 0 !important;
  }
  .pt-xl-0,
  .py-xl-0 {
    padding-top: 0 !important;
  }
  .pr-xl-0,
  .px-xl-0 {
    padding-right: 0 !important;
  }
  .pb-xl-0,
  .py-xl-0 {
    padding-bottom: 0 !important;
  }
  .pl-xl-0,
  .px-xl-0 {
    padding-left: 0 !important;
  }
  .p-xl-1 {
    padding: 0.25rem !important;
  }
  .pt-xl-1,
  .py-xl-1 {
    padding-top: 0.25rem !important;
  }
  .pr-xl-1,
  .px-xl-1 {
    padding-right: 0.25rem !important;
  }
  .pb-xl-1,
  .py-xl-1 {
    padding-bottom: 0.25rem !important;
  }
  .pl-xl-1,
  .px-xl-1 {
    padding-left: 0.25rem !important;
  }
  .p-xl-2 {
    padding: 0.5rem !important;
  }
  .pt-xl-2,
  .py-xl-2 {
    padding-top: 0.5rem !important;
  }
  .pr-xl-2,
  .px-xl-2 {
    padding-right: 0.5rem !important;
  }
  .pb-xl-2,
  .py-xl-2 {
    padding-bottom: 0.5rem !important;
  }
  .pl-xl-2,
  .px-xl-2 {
    padding-left: 0.5rem !important;
  }
  .p-xl-3 {
    padding: 1rem !important;
  }
  .pt-xl-3,
  .py-xl-3 {
    padding-top: 1rem !important;
  }
  .pr-xl-3,
  .px-xl-3 {
    padding-right: 1rem !important;
  }
  .pb-xl-3,
  .py-xl-3 {
    padding-bottom: 1rem !important;
  }
  .pl-xl-3,
  .px-xl-3 {
    padding-left: 1rem !important;
  }
  .p-xl-4 {
    padding: 1.5rem !important;
  }
  .pt-xl-4,
  .py-xl-4 {
    padding-top: 1.5rem !important;
  }
  .pr-xl-4,
  .px-xl-4 {
    padding-right: 1.5rem !important;
  }
  .pb-xl-4,
  .py-xl-4 {
    padding-bottom: 1.5rem !important;
  }
  .pl-xl-4,
  .px-xl-4 {
    padding-left: 1.5rem !important;
  }
  .p-xl-5 {
    padding: 3rem !important;
  }
  .pt-xl-5,
  .py-xl-5 {
    padding-top: 3rem !important;
  }
  .pr-xl-5,
  .px-xl-5 {
    padding-right: 3rem !important;
  }
  .pb-xl-5,
  .py-xl-5 {
    padding-bottom: 3rem !important;
  }
  .pl-xl-5,
  .px-xl-5 {
    padding-left: 3rem !important;
  }
  .p-xl-6 {
    padding: 4.5rem !important;
  }
  .pt-xl-6,
  .py-xl-6 {
    padding-top: 4.5rem !important;
  }
  .pr-xl-6,
  .px-xl-6 {
    padding-right: 4.5rem !important;
  }
  .pb-xl-6,
  .py-xl-6 {
    padding-bottom: 4.5rem !important;
  }
  .pl-xl-6,
  .px-xl-6 {
    padding-left: 4.5rem !important;
  }
  .m-xl-n1 {
    margin: -0.25rem !important;
  }
  .mt-xl-n1,
  .my-xl-n1 {
    margin-top: -0.25rem !important;
  }
  .mr-xl-n1,
  .mx-xl-n1 {
    margin-right: -0.25rem !important;
  }
  .mb-xl-n1,
  .my-xl-n1 {
    margin-bottom: -0.25rem !important;
  }
  .ml-xl-n1,
  .mx-xl-n1 {
    margin-left: -0.25rem !important;
  }
  .m-xl-n2 {
    margin: -0.5rem !important;
  }
  .mt-xl-n2,
  .my-xl-n2 {
    margin-top: -0.5rem !important;
  }
  .mr-xl-n2,
  .mx-xl-n2 {
    margin-right: -0.5rem !important;
  }
  .mb-xl-n2,
  .my-xl-n2 {
    margin-bottom: -0.5rem !important;
  }
  .ml-xl-n2,
  .mx-xl-n2 {
    margin-left: -0.5rem !important;
  }
  .m-xl-n3 {
    margin: -1rem !important;
  }
  .mt-xl-n3,
  .my-xl-n3 {
    margin-top: -1rem !important;
  }
  .mr-xl-n3,
  .mx-xl-n3 {
    margin-right: -1rem !important;
  }
  .mb-xl-n3,
  .my-xl-n3 {
    margin-bottom: -1rem !important;
  }
  .ml-xl-n3,
  .mx-xl-n3 {
    margin-left: -1rem !important;
  }
  .m-xl-n4 {
    margin: -1.5rem !important;
  }
  .mt-xl-n4,
  .my-xl-n4 {
    margin-top: -1.5rem !important;
  }
  .mr-xl-n4,
  .mx-xl-n4 {
    margin-right: -1.5rem !important;
  }
  .mb-xl-n4,
  .my-xl-n4 {
    margin-bottom: -1.5rem !important;
  }
  .ml-xl-n4,
  .mx-xl-n4 {
    margin-left: -1.5rem !important;
  }
  .m-xl-n5 {
    margin: -3rem !important;
  }
  .mt-xl-n5,
  .my-xl-n5 {
    margin-top: -3rem !important;
  }
  .mr-xl-n5,
  .mx-xl-n5 {
    margin-right: -3rem !important;
  }
  .mb-xl-n5,
  .my-xl-n5 {
    margin-bottom: -3rem !important;
  }
  .ml-xl-n5,
  .mx-xl-n5 {
    margin-left: -3rem !important;
  }
  .m-xl-n6 {
    margin: -4.5rem !important;
  }
  .mt-xl-n6,
  .my-xl-n6 {
    margin-top: -4.5rem !important;
  }
  .mr-xl-n6,
  .mx-xl-n6 {
    margin-right: -4.5rem !important;
  }
  .mb-xl-n6,
  .my-xl-n6 {
    margin-bottom: -4.5rem !important;
  }
  .ml-xl-n6,
  .mx-xl-n6 {
    margin-left: -4.5rem !important;
  }
  .m-xl-auto {
    margin: auto !important;
  }
  .mt-xl-auto,
  .my-xl-auto {
    margin-top: auto !important;
  }
  .mr-xl-auto,
  .mx-xl-auto {
    margin-right: auto !important;
  }
  .mb-xl-auto,
  .my-xl-auto {
    margin-bottom: auto !important;
  }
  .ml-xl-auto,
  .mx-xl-auto {
    margin-left: auto !important;
  }
}

.text-monospace {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace !important;
}

.text-justify {
  text-align: justify !important;
}

.text-wrap {
  white-space: normal !important;
}

.text-nowrap {
  white-space: nowrap !important;
}

.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.text-left {
  text-align: left !important;
}

.text-right {
  text-align: right !important;
}

.text-center {
  text-align: center !important;
}

@media (min-width: 576px) {
  .text-sm-left {
    text-align: left !important;
  }
  .text-sm-right {
    text-align: right !important;
  }
  .text-sm-center {
    text-align: center !important;
  }
}

@media (min-width: 768px) {
  .text-md-left {
    text-align: left !important;
  }
  .text-md-right {
    text-align: right !important;
  }
  .text-md-center {
    text-align: center !important;
  }
}

@media (min-width: 992px) {
  .text-lg-left {
    text-align: left !important;
  }
  .text-lg-right {
    text-align: right !important;
  }
  .text-lg-center {
    text-align: center !important;
  }
}

@media (min-width: 1200px) {
  .text-xl-left {
    text-align: left !important;
  }
  .text-xl-right {
    text-align: right !important;
  }
  .text-xl-center {
    text-align: center !important;
  }
}

.text-lowercase {
  text-transform: lowercase !important;
}

.text-uppercase {
  text-transform: uppercase !important;
}

.text-capitalize {
  text-transform: capitalize !important;
}

.font-weight-light {
  font-weight: 300 !important;
}

.font-weight-lighter {
  font-weight: lighter !important;
}

.font-weight-normal {
  font-weight: 400 !important;
}

.font-weight-bold {
  font-weight: 700 !important;
}

.font-weight-bolder {
  font-weight: bolder !important;
}

.font-italic {
  font-style: italic !important;
}

.text-white {
  color: #FFFFFF !important;
}

.text-primary {
  color: #2cc185 !important;
}

a.text-primary:hover, a.text-primary:focus {
  color: #1e835a !important;
}

.text-secondary {
  color: #878787 !important;
}

a.text-secondary:hover, a.text-secondary:focus {
  color: #616161 !important;
}

.text-success {
  color: #2cc185 !important;
}

a.text-success:hover, a.text-success:focus {
  color: #1e835a !important;
}

.text-info {
  color: #58C7EF !important;
}

a.text-info:hover, a.text-info:focus {
  color: #16aee5 !important;
}

.text-warning {
  color: #FFDC42 !important;
}

a.text-warning:hover, a.text-warning:focus {
  color: #f5c700 !important;
}

.text-danger {
  color: #F45846 !important;
}

a.text-danger:hover, a.text-danger:focus {
  color: #e0230d !important;
}

.text-light {
  color: #FFFFFF !important;
}

a.text-light:hover, a.text-light:focus {
  color: #d9d9d9 !important;
}

.text-dark {
  color: #323232 !important;
}

a.text-dark:hover, a.text-dark:focus {
  color: #0c0c0c !important;
}

.text-grey {
  color: #e5e5e5 !important;
}

a.text-grey:hover, a.text-grey:focus {
  color: #bfbfbf !important;
}

.text-noir {
  color: #545454 !important;
}

a.text-noir:hover, a.text-noir:focus {
  color: #2e2e2e !important;
}

.text-body {
  color: #545454 !important;
}

.text-muted {
  color: #878787 !important;
}

.text-black-50 {
  color: rgba(0, 0, 0, 0.5) !important;
}

.text-white-50 {
  color: rgba(255, 255, 255, 0.5) !important;
}

.text-hide {
  font: 0/0 a;
  color: transparent;
  text-shadow: none;
  background-color: transparent;
  border: 0;
}

.text-decoration-none {
  text-decoration: none !important;
}

.text-break {
  word-break: break-word !important;
  overflow-wrap: break-word !important;
}

.text-reset {
  color: inherit !important;
}

.visible {
  visibility: visible !important;
}

.invisible {
  visibility: hidden !important;
}

/* General styles for the modal */
/* 
Styles for the html/body for special modal where we want 3d effects
Note that we need a container wrapping all content on the page for the 
perspective effects (not including the modals and the overlay).
*/
.modal-open {
  overflow: hidden;
}

.modal-perspective,
.modal-perspective body {
  height: 100%;
  overflow: hidden;
}

.modal-perspective body {
  background: #222;
  -webkit-perspective: 600px;
  -moz-perspective: 600px;
  perspective: 600px;
}

.modal-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1050;
  visibility: hidden;
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  backface-visibility: hidden;
}

.modal-show {
  visibility: visible;
  overflow-x: hidden;
  overflow-y: auto;
  z-index: 1051;
}

.modal-overlay {
  position: fixed;
  width: 100%;
  height: 100%;
  visibility: hidden;
  top: 0;
  left: 0;
  z-index: 1050;
  opacity: 0;
  background: rgba(0, 0, 0, 0.5);
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  transition: all 0.3s;
}

.modal-show ~ .modal-overlay {
  opacity: 1;
  visibility: visible;
}

/* Content styles */
.modal-content {
  position: relative;
  border-radius: 3px;
  margin: 30px auto;
  max-width: 630px;
  min-width: 320px;
}

.modal-scrollbar-measure {
  position: absolute;
  top: -9999px;
  width: 50px;
  height: 50px;
  overflow: scroll;
}

/* Effect 1: Fade in and scale up */
.modal-effect-1 .modal-content {
  -webkit-transform: scale(0.7);
  -moz-transform: scale(0.7);
  -ms-transform: scale(0.7);
  transform: scale(0.7);
  opacity: 0;
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  transition: all 0.3s;
}

.modal-show.modal-effect-1 .modal-content {
  -webkit-transform: scale(1);
  -moz-transform: scale(1);
  -ms-transform: scale(1);
  transform: scale(1);
  opacity: 1;
}

/* Effect 2: Slide from the right */
.modal-effect-2 .modal-content {
  -webkit-transform: translateX(20%);
  -moz-transform: translateX(20%);
  -ms-transform: translateX(20%);
  transform: translateX(20%);
  opacity: 0;
  -webkit-transition: all 0.3s cubic-bezier(0.25, 0.5, 0.5, 0.9);
  -moz-transition: all 0.3s cubic-bezier(0.25, 0.5, 0.5, 0.9);
  transition: all 0.3s cubic-bezier(0.25, 0.5, 0.5, 0.9);
}

.modal-show.modal-effect-2 .modal-content {
  -webkit-transform: translateX(0);
  -moz-transform: translateX(0);
  -ms-transform: translateX(0);
  transform: translateX(0);
  opacity: 1;
}

/* Effect 3: Slide from the bottom */
.modal-effect-3 .modal-content {
  -webkit-transform: translateY(20%);
  -moz-transform: translateY(20%);
  -ms-transform: translateY(20%);
  transform: translateY(20%);
  opacity: 0;
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  transition: all 0.3s;
}

.modal-show.modal-effect-3 .modal-content {
  -webkit-transform: translateY(0);
  -moz-transform: translateY(0);
  -ms-transform: translateY(0);
  transform: translateY(0);
  opacity: 1;
}

/* Effect 4: Newspaper */
.modal-effect-4 .modal-content {
  -webkit-transform: scale(0) rotate(720deg);
  -moz-transform: scale(0) rotate(720deg);
  -ms-transform: scale(0) rotate(720deg);
  transform: scale(0) rotate(720deg);
  opacity: 0;
}

.modal-show.modal-effect-4 ~ .modal-overlay,
.modal-effect-4 .modal-content {
  -webkit-transition: all 0.5s;
  -moz-transition: all 0.5s;
  transition: all 0.5s;
}

.modal-show.modal-effect-4 .modal-content {
  -webkit-transform: scale(1) rotate(0deg);
  -moz-transform: scale(1) rotate(0deg);
  -ms-transform: scale(1) rotate(0deg);
  transform: scale(1) rotate(0deg);
  opacity: 1;
}

/* Effect 5: fall */
.modal-effect-5.modal-container {
  -webkit-perspective: 1300px;
  -moz-perspective: 1300px;
  perspective: 1300px;
}

.modal-effect-5 .modal-content {
  -webkit-transform-style: preserve-3d;
  -moz-transform-style: preserve-3d;
  transform-style: preserve-3d;
  -webkit-transform: translateZ(600px) rotateX(20deg);
  -moz-transform: translateZ(600px) rotateX(20deg);
  -ms-transform: translateZ(600px) rotateX(20deg);
  transform: translateZ(600px) rotateX(20deg);
  opacity: 0;
}

.modal-show.modal-effect-5 .modal-content {
  -webkit-transition: all 0.3s ease-in;
  -moz-transition: all 0.3s ease-in;
  transition: all 0.3s ease-in;
  -webkit-transform: translateZ(0px) rotateX(0deg);
  -moz-transform: translateZ(0px) rotateX(0deg);
  -ms-transform: translateZ(0px) rotateX(0deg);
  transform: translateZ(0px) rotateX(0deg);
  opacity: 1;
}

/* Effect 6: side fall */
.modal-effect-6.modal-container {
  -webkit-perspective: 1300px;
  -moz-perspective: 1300px;
  perspective: 1300px;
}

.modal-effect-6 .modal-content {
  -webkit-transform-style: preserve-3d;
  -moz-transform-style: preserve-3d;
  transform-style: preserve-3d;
  -webkit-transform: translate(30%) translateZ(600px) rotate(10deg);
  -moz-transform: translate(30%) translateZ(600px) rotate(10deg);
  -ms-transform: translate(30%) translateZ(600px) rotate(10deg);
  transform: translate(30%) translateZ(600px) rotate(10deg);
  opacity: 0;
}

.modal-show.modal-effect-6 .modal-content {
  -webkit-transition: all 0.3s ease-in;
  -moz-transition: all 0.3s ease-in;
  transition: all 0.3s ease-in;
  -webkit-transform: translate(0%) translateZ(0) rotate(0deg);
  -moz-transform: translate(0%) translateZ(0) rotate(0deg);
  -ms-transform: translate(0%) translateZ(0) rotate(0deg);
  transform: translate(0%) translateZ(0) rotate(0deg);
  opacity: 1;
}

/* Effect 7:  slide and stick to top */
.modal-effect-7 {
  top: 0;
}

.modal-effect-7 .modal-content {
  margin-top: 0;
  -webkit-transform: translateY(-200%);
  -moz-transform: translateY(-200%);
  -ms-transform: translateY(-200%);
  transform: translateY(-200%);
  -webkit-transition: all .3s;
  -moz-transition: all .3s;
  transition: all .3s;
  opacity: 0;
}

.modal-show.modal-effect-7 .modal-content {
  -webkit-transform: translateY(0%);
  -moz-transform: translateY(0%);
  -ms-transform: translateY(0%);
  transform: translateY(0%);
  border-radius: 0 0 3px 3px;
  opacity: 1;
}

/* Effect 8: 3D flip horizontal */
.modal-effect-8.modal-container {
  -webkit-perspective: 1300px;
  -moz-perspective: 1300px;
  perspective: 1300px;
}

.modal-effect-8 .modal-content {
  -webkit-transform-style: preserve-3d;
  -moz-transform-style: preserve-3d;
  transform-style: preserve-3d;
  -webkit-transform: rotateY(-70deg);
  -moz-transform: rotateY(-70deg);
  -ms-transform: rotateY(-70deg);
  transform: rotateY(-70deg);
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  transition: all 0.3s;
  opacity: 0;
}

.modal-show.modal-effect-8 .modal-content {
  -webkit-transform: rotateY(0deg);
  -moz-transform: rotateY(0deg);
  -ms-transform: rotateY(0deg);
  transform: rotateY(0deg);
  opacity: 1;
}

/* Effect 9: 3D flip vertical */
.modal-effect-9.modal-container {
  -webkit-perspective: 1300px;
  -moz-perspective: 1300px;
  perspective: 1300px;
}

.modal-effect-9 .modal-content {
  -webkit-transform-style: preserve-3d;
  -moz-transform-style: preserve-3d;
  transform-style: preserve-3d;
  -webkit-transform: rotateX(-70deg);
  -moz-transform: rotateX(-70deg);
  -ms-transform: rotateX(-70deg);
  transform: rotateX(-70deg);
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  transition: all 0.3s;
  opacity: 0;
}

.modal-show.modal-effect-9 .modal-content {
  -webkit-transform: rotateX(0deg);
  -moz-transform: rotateX(0deg);
  -ms-transform: rotateX(0deg);
  transform: rotateX(0deg);
  opacity: 1;
}

/* Effect 10: 3D sign */
.modal-effect-10.modal-container {
  -webkit-perspective: 1300px;
  -moz-perspective: 1300px;
  perspective: 1300px;
}

.modal-effect-10 .modal-content {
  -webkit-transform-style: preserve-3d;
  -moz-transform-style: preserve-3d;
  transform-style: preserve-3d;
  -webkit-transform: rotateX(-60deg);
  -moz-transform: rotateX(-60deg);
  -ms-transform: rotateX(-60deg);
  transform: rotateX(-60deg);
  -webkit-transform-origin: 50% 0;
  -moz-transform-origin: 50% 0;
  transform-origin: 50% 0;
  opacity: 0;
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  transition: all 0.3s;
}

.modal-show.modal-effect-10 .modal-content {
  -webkit-transform: rotateX(0deg);
  -moz-transform: rotateX(0deg);
  -ms-transform: rotateX(0deg);
  transform: rotateX(0deg);
  opacity: 1;
}

/* Effect 11: Super scaled */
.modal-effect-11 .modal-content {
  -webkit-transform: scale(2);
  -moz-transform: scale(2);
  -ms-transform: scale(2);
  transform: scale(2);
  opacity: 0;
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  transition: all 0.3s;
}

.modal-show.modal-effect-11 .modal-content {
  -webkit-transform: scale(1);
  -moz-transform: scale(1);
  -ms-transform: scale(1);
  transform: scale(1);
  opacity: 1;
}

/* Effect 12:  Just me */
.modal-effect-12 .modal-content {
  -webkit-transform: scale(0.8);
  -moz-transform: scale(0.8);
  -ms-transform: scale(0.8);
  transform: scale(0.8);
  opacity: 0;
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  transition: all 0.3s;
}

.modal-show.modal-effect-12 ~ .modal-overlay {
  background: #000;
}

.modal-show.modal-effect-12 .modal-content {
  -webkit-transform: scale(1);
  -moz-transform: scale(1);
  -ms-transform: scale(1);
  transform: scale(1);
  opacity: 1;
}

/* Effect 13: 3D slit */
.modal-effect-13.modal-container {
  -webkit-perspective: 1300px;
  -moz-perspective: 1300px;
  perspective: 1300px;
}

.modal-effect-13 .modal-content {
  -webkit-transform-style: preserve-3d;
  -moz-transform-style: preserve-3d;
  transform-style: preserve-3d;
  -webkit-transform: translateZ(-3000px) rotateY(90deg);
  -moz-transform: translateZ(-3000px) rotateY(90deg);
  -ms-transform: translateZ(-3000px) rotateY(90deg);
  transform: translateZ(-3000px) rotateY(90deg);
  opacity: 0;
}

.modal-show.modal-effect-13 .modal-content {
  -webkit-animation: slit .7s forwards ease-out;
  -moz-animation: slit .7s forwards ease-out;
  animation: slit .7s forwards ease-out;
}

@-webkit-keyframes slit {
  50% {
    -webkit-transform: translateZ(-250px) rotateY(89deg);
    opacity: .5;
    -webkit-animation-timing-function: ease-out;
  }
  100% {
    -webkit-transform: translateZ(0) rotateY(0deg);
    opacity: 1;
  }
}

@-moz-keyframes slit {
  50% {
    -moz-transform: translateZ(-250px) rotateY(89deg);
    opacity: .5;
    -moz-animation-timing-function: ease-out;
  }
  100% {
    -moz-transform: translateZ(0) rotateY(0deg);
    opacity: 1;
  }
}

@keyframes slit {
  50% {
    transform: translateZ(-250px) rotateY(89deg);
    opacity: 1;
    animation-timing-function: ease-in;
  }
  100% {
    transform: translateZ(0) rotateY(0deg);
    opacity: 1;
  }
}

/* Effect 14:  3D Rotate from bottom */
.modal-effect-14.modal-container {
  -webkit-perspective: 1300px;
  -moz-perspective: 1300px;
  perspective: 1300px;
}

.modal-effect-14 .modal-content {
  -webkit-transform-style: preserve-3d;
  -moz-transform-style: preserve-3d;
  transform-style: preserve-3d;
  -webkit-transform: translateY(100%) rotateX(90deg);
  -moz-transform: translateY(100%) rotateX(90deg);
  -ms-transform: translateY(100%) rotateX(90deg);
  transform: translateY(100%) rotateX(90deg);
  -webkit-transform-origin: 0 100%;
  -moz-transform-origin: 0 100%;
  transform-origin: 0 100%;
  opacity: 0;
  -webkit-transition: all 0.3s ease-out;
  -moz-transition: all 0.3s ease-out;
  transition: all 0.3s ease-out;
}

.modal-show.modal-effect-14 .modal-content {
  -webkit-transform: translateY(0%) rotateX(0deg);
  -moz-transform: translateY(0%) rotateX(0deg);
  -ms-transform: translateY(0%) rotateX(0deg);
  transform: translateY(0%) rotateX(0deg);
  opacity: 1;
}

/* Effect 15:  3D Rotate in from left */
.modal-effect-15.modal-container {
  -webkit-perspective: 1300px;
  -moz-perspective: 1300px;
  perspective: 1300px;
}

.modal-effect-15 .modal-content {
  -webkit-transform-style: preserve-3d;
  -moz-transform-style: preserve-3d;
  transform-style: preserve-3d;
  -webkit-transform: translateZ(100px) translateX(-30%) rotateY(90deg);
  -moz-transform: translateZ(100px) translateX(-30%) rotateY(90deg);
  -ms-transform: translateZ(100px) translateX(-30%) rotateY(90deg);
  transform: translateZ(100px) translateX(-30%) rotateY(90deg);
  -webkit-transform-origin: 0 100%;
  -moz-transform-origin: 0 100%;
  transform-origin: 0 100%;
  opacity: 0;
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  transition: all 0.3s;
}

.modal-show.modal-effect-15 .modal-content {
  -webkit-transform: translateZ(0px) translateX(0%) rotateY(0deg);
  -moz-transform: translateZ(0px) translateX(0%) rotateY(0deg);
  -ms-transform: translateZ(0px) translateX(0%) rotateY(0deg);
  transform: translateZ(0px) translateX(0%) rotateY(0deg);
  opacity: 1;
}

/* Effect 16:  Blur */
.modal-show.modal-effect-16 ~ .modal-overlay {
  background: rgba(180, 46, 32, 0.5);
}

.modal-show.modal-effect-16 ~ .container {
  -webkit-filter: blur(3px);
  -moz-filter: blur(3px);
  filter: blur(3px);
}

.modal-effect-16 .modal-content {
  -webkit-transform: translateY(-5%);
  -moz-transform: translateY(-5%);
  -ms-transform: translateY(-5%);
  transform: translateY(-5%);
  opacity: 0;
}

.modal-show.modal-effect-16 ~ .container,
.modal-effect-16 .modal-content {
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  transition: all 0.3s;
}

.modal-show.modal-effect-16 .modal-content {
  -webkit-transform: translateY(0);
  -moz-transform: translateY(0);
  -ms-transform: translateY(0);
  transform: translateY(0);
  opacity: 1;
}

/* Effect 17:  Slide in from bottom with perspective on container */
.modal-show.modal-effect-17 ~ .container {
  height: 100%;
  overflow: hidden;
  -webkit-transition: -webkit-transform 0.3s;
  -moz-transition: -moz-transform 0.3s;
  transition: transform 0.3s;
}

.modal-show.modal-effect-17 ~ .container,
.modal-show.modal-effect-17 ~ .modal-overlay {
  -webkit-transform: rotateX(-2deg);
  -moz-transform: rotateX(-2deg);
  -ms-transform: rotateX(-2deg);
  transform: rotateX(-2deg);
  -webkit-transform-origin: 50% 0%;
  -moz-transform-origin: 50% 0%;
  transform-origin: 50% 0%;
  -webkit-transform-style: preserve-3d;
  -moz-transform-style: preserve-3d;
  transform-style: preserve-3d;
}

.modal-effect-17 .modal-content {
  opacity: 0;
  -webkit-transform: translateY(200%);
  -moz-transform: translateY(200%);
  -ms-transform: translateY(200%);
  transform: translateY(200%);
}

.modal-show.modal-effect-17 .modal-content {
  -webkit-transform: translateY(0);
  -moz-transform: translateY(0);
  -ms-transform: translateY(0);
  transform: translateY(0);
  opacity: 1;
  -webkit-transition: all 0.3s 0.2s;
  -moz-transition: all 0.3s 0.2s;
  transition: all 0.3s 0.2s;
}

/* Effect 18:  Slide from right with perspective on container */
.modal-show.modal-effect-18 ~ .container {
  height: 100%;
  overflow: hidden;
}

.modal-show.modal-effect-18 ~ .modal-overlay {
  background: rgba(143, 27, 15, 0.8);
  -webkit-transition: all 0.5s;
  -moz-transition: all 0.5s;
  transition: all 0.5s;
}

.modal-show.modal-effect-18 ~ .container,
.modal-show.modal-effect-18 ~ .modal-overlay {
  -webkit-transform-style: preserve-3d;
  -webkit-transform-origin: 0% 50%;
  -webkit-animation: rotateRightSideFirst 0.5s forwards ease-in;
  -moz-transform-style: preserve-3d;
  -moz-transform-origin: 0% 50%;
  -moz-animation: rotateRightSideFirst 0.5s forwards ease-in;
  transform-style: preserve-3d;
  transform-origin: 0% 50%;
  animation: rotateRightSideFirst 0.5s forwards ease-in;
}

@-webkit-keyframes rotateRightSideFirst {
  50% {
    -webkit-transform: translateZ(-50px) rotateY(5deg);
    -webkit-animation-timing-function: ease-out;
  }
  100% {
    -webkit-transform: translateZ(-200px);
  }
}

@-moz-keyframes rotateRightSideFirst {
  50% {
    -moz-transform: translateZ(-50px) rotateY(5deg);
    -moz-animation-timing-function: ease-out;
  }
  100% {
    -moz-transform: translateZ(-200px);
  }
}

@keyframes rotateRightSideFirst {
  50% {
    transform: translateZ(-50px) rotateY(5deg);
    animation-timing-function: ease-out;
  }
  100% {
    transform: translateZ(-200px);
  }
}

.modal-effect-18 .modal-content {
  -webkit-transform: translateX(200%);
  -moz-transform: translateX(200%);
  -ms-transform: translateX(200%);
  transform: translateX(200%);
  opacity: 0;
}

.modal-show.modal-effect-18 .modal-content {
  -webkit-transform: translateX(0);
  -moz-transform: translateX(0);
  -ms-transform: translateX(0);
  transform: translateX(0);
  opacity: 1;
  -webkit-transition: all 0.5s 0.1s;
  -moz-transition: all 0.5s 0.1s;
  transition: all 0.5s 0.1s;
}

/* Open Sans Webfont Kit
 * -------------------------- */
/* Light 300
 * -------------------------- */
@font-face {
  font-family: 'Open Sans';
  src: url("../lib/open-sans/light/opensans-light-webfont.eot");
  src: url("../lib/open-sans/light/opensans-light-webfont.eot?#iefix") format("embedded-opentype"), url("../lib/open-sans/light/opensans-light-webfont.woff") format("woff"), url("../lib/open-sans/light/opensans-light-webfont.ttf") format("truetype"), url("../lib/open-sans/light/opensans-light-webfont.svg#OpenSansLight") format("svg");
  font-weight: 300;
  font-style: normal;
}

/* Light 300 Italic
 * -------------------------- */
@font-face {
  font-family: 'Open Sans';
  src: url("../lib/open-sans/light-italic/opensans-light-italic-webfont.eot");
  src: url("../lib/open-sans/light-italic/opensans-light-italic-webfont.eot?#iefix") format("embedded-opentype"), url("../lib/open-sans/light-italic/opensans-light-italic-webfont.woff") format("woff"), url("../lib/open-sans/light-italic/opensans-light-italic-webfont.ttf") format("truetype"), url("../lib/open-sans/light-italic/opensans-light-italic-webfont.svg#OpenSansLightItalic") format("svg");
  font-weight: 300;
  font-style: italic;
}

/* Regular 400
 * -------------------------- */
@font-face {
  font-family: 'Open Sans';
  src: url("../lib/open-sans/regular/opensans-regular-webfont.eot");
  src: url("../lib/open-sans/regular/opensans-regular-webfont.eot?#iefix") format("embedded-opentype"), url("../lib/open-sans/regular/opensans-regular-webfont.woff") format("woff"), url("../lib/open-sans/regular/opensans-regular-webfont.ttf") format("truetype"), url("../lib/open-sans/regular/opensans-regular-webfont.svg#OpenSansRegular") format("svg");
  font-weight: normal;
  font-style: normal;
}

/* Regular 400 Italic
 * -------------------------- */
@font-face {
  font-family: 'Open Sans';
  src: url("../lib/open-sans/italic/opensans-italic-webfont.eot");
  src: url("../lib/open-sans/italic/opensans-italic-webfont.eot?#iefix") format("embedded-opentype"), url("../lib/open-sans/italic/opensans-italic-webfont.woff") format("woff"), url("../lib/open-sans/italic/opensans-italic-webfont.ttf") format("truetype"), url("../lib/open-sans/italic/opensans-italic-webfont.svg#OpenSansItalic") format("svg");
  font-weight: normal;
  font-style: italic;
}

/* Semibold 600
 * -------------------------- */
@font-face {
  font-family: 'Open Sans';
  src: url("../lib/open-sans/semibold/opensans-semibold-webfont.eot");
  src: url("../lib/open-sans/semibold/opensans-semibold-webfont.eot?#iefix") format("embedded-opentype"), url("../lib/open-sans/semibold/opensans-semibold-webfont.woff") format("woff"), url("../lib/open-sans/semibold/opensans-semibold-webfont.ttf") format("truetype"), url("../lib/open-sans/semibold/opensans-semibold-webfont.svg#OpenSansSemibold") format("svg");
  font-weight: 600;
  font-style: normal;
}

/* Semi-Bold 600 Italic
 * -------------------------- */
@font-face {
  font-family: 'Open Sans';
  src: url("../lib/open-sans/semibold-italic/opensans-semibold-italic-webfont.eot");
  src: url("../lib/open-sans/semibold-italic/opensans-semibold-italic-webfont.eot?#iefix") format("embedded-opentype"), url("../lib/open-sans/semibold-italic/opensans-semibold-italic-webfont.woff") format("woff"), url("../lib/open-sans/semibold-italic/opensans-semibold-italic-webfont.ttf") format("truetype"), url("../lib/open-sans/semibold-italic/opensans-semibold-italic-webfont.svg#OpenSansSemiboldItalic") format("svg");
  font-weight: 600;
  font-style: italic;
}

/* Bold 700
 * -------------------------- */
@font-face {
  font-family: 'Open Sans';
  src: url("../lib/open-sans/bold/opensans-bold-webfont.eot");
  src: url("../lib/open-sans/bold/opensans-bold-webfont.eot?#iefix") format("embedded-opentype"), url("../lib/open-sans/bold/opensans-bold-webfont.woff") format("woff"), url("../lib/open-sans/bold/opensans-bold-webfont.ttf") format("truetype"), url("../lib/open-sans/bold/opensans-bold-webfont.svg#OpenSansBold") format("svg");
  font-weight: bold;
  font-style: normal;
}

/* Bold 700 Italic
 * -------------------------- */
@font-face {
  font-family: 'Open Sans';
  src: url("../lib/open-sans/bold-italic/opensans-bold-italic-webfont.eot");
  src: url("../lib/open-sans/bold-italic/opensans-bold-italic-webfont.eot?#iefix") format("embedded-opentype"), url("../lib/open-sans/bold-italic/opensans-bold-italic-webfont.woff") format("woff"), url("../lib/open-sans/bold-italic/opensans-bold-italic-webfont.ttf") format("truetype"), url("../lib/open-sans/bold-italic/opensans-bold-italic-webfont.svg#OpenSansItalic") format("svg");
  font-weight: bold;
  font-style: italic;
}

/* Extrabold 800
 * -------------------------- */
@font-face {
  font-family: 'Open Sans';
  src: url("../lib/open-sans/extrabold/opensans-extrabold-webfont.eot");
  src: url("../lib/open-sans/extrabold/opensans-extrabold-webfont.eot?#iefix") format("embedded-opentype"), url("../lib/open-sans/extrabold/opensans-extrabold-webfont.woff") format("woff"), url("../lib/open-sans/extrabold/opensans-extrabold-webfont.ttf") format("truetype"), url("../lib/open-sans/extrabold/opensans-extrabold-webfont.svg#OpenSansExtrabold") format("svg");
  font-weight: 800;
  font-style: normal;
}

/* Extra-Bold 800 Italic
 * -------------------------- */
@font-face {
  font-family: 'Open Sans';
  src: url("../lib/open-sans/extrabold-italic/opensans-extrabold-italic-webfont.eot");
  src: url("../lib/open-sans/extrabold-italic/opensans-extrabold-italic-webfont.eot?#iefix") format("embedded-opentype"), url("../lib/open-sans/extrabold-italic/opensans-extrabold-italic-webfont.woff") format("woff"), url("../lib/open-sans/extrabold-italic/opensans-extrabold-italic-webfont.ttf") format("truetype"), url("../lib/open-sans/extrabold-italic/opensans-extrabold-italic-webfont.svg#OpenSansExtraboldItalic") format("svg");
  font-weight: 800;
  font-style: italic;
}

@font-face {
  font-family: "Raleway";
  src: url("../lib/raleway/thin/raleway-thin.eot");
  src: url("../lib/raleway/thin/raleway-thin.eot?#iefix") format("embedded-opentype"), url("../lib/raleway/thin/raleway-thin.woff") format("woff"), url("../lib/raleway/thin/raleway-thin.ttf") format("truetype"), url("../lib/raleway/thin/raleway-thin.svg#ralewaythin") format("svg");
  font-weight: 100;
  font-style: normal;
}

@font-face {
  font-family: "Raleway";
  src: url("../lib/raleway/extralight/raleway-extralight.eot");
  src: url("../lib/raleway/extralight/raleway-extralight.eot?#iefix") format("embedded-opentype"), url("../lib/raleway/extralight/raleway-extralight.woff") format("woff"), url("../lib/raleway/extralight/raleway-extralight.ttf") format("truetype"), url("../lib/raleway/extralight/raleway-extralight.svg#ralewayextralight") format("svg");
  font-weight: 200;
  font-style: normal;
}

@font-face {
  font-family: "Raleway";
  src: url("../lib/raleway/light/raleway-light.eot");
  src: url("../lib/raleway/light/raleway-light.eot?#iefix") format("embedded-opentype"), url("../lib/raleway/light/raleway-light.woff") format("woff"), url("../lib/raleway/light/raleway-light.ttf") format("truetype"), url("../lib/raleway/light/raleway-light.svg#ralewaylight") format("svg");
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: "Raleway";
  src: url("../lib/raleway/regular/raleway-regular.eot");
  src: url("../lib/raleway/regular/raleway-regular.eot?#iefix") format("embedded-opentype"), url("../lib/raleway/regular/raleway-regular.woff") format("woff"), url("../lib/raleway/regular/raleway-regular.ttf") format("truetype"), url("../lib/raleway/regular/raleway-regular.svg#ralewayregular") format("svg");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: "Raleway";
  src: url("../lib/raleway/medium/raleway-medium.eot");
  src: url("../lib/raleway/medium/raleway-medium.eot?#iefix") format("embedded-opentype"), url("../lib/raleway/medium/raleway-medium.woff") format("woff"), url("../lib/raleway/medium/raleway-medium.ttf") format("truetype"), url("../lib/raleway/medium/raleway-medium.svg#ralewaymedium") format("svg");
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: "Raleway";
  src: url("../lib/raleway/semibold/raleway-semibold.eot");
  src: url("../lib/raleway/semibold/raleway-semibold.eot?#iefix") format("embedded-opentype"), url("../lib/raleway/semibold/raleway-semibold.woff") format("woff"), url("../lib/raleway/semibold/raleway-semibold.ttf") format("truetype"), url("../lib/raleway/semibold/raleway-semibold.svg#ralewaysemibold") format("svg");
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: "Raleway";
  src: url("../lib/raleway/bold/raleway-bold.eot");
  src: url("../lib/raleway/bold/raleway-bold.eot?#iefix") format("embedded-opentype"), url("../lib/raleway/bold/raleway-bold.woff") format("woff"), url("../lib/raleway/bold/raleway-bold.ttf") format("truetype"), url("../lib/raleway/bold/raleway-bold.svg#ralewaybold") format("svg");
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: "Raleway";
  src: url("../lib/raleway/extrabold/raleway-extrabold.eot");
  src: url("../lib/raleway/extrabold/raleway-extrabold.eot?#iefix") format("embedded-opentype"), url("../lib/raleway/extrabold/raleway-extrabold.woff") format("woff"), url("../lib/raleway/extrabold/raleway-extrabold.ttf") format("truetype"), url("../lib/raleway/extrabold/raleway-extrabold.svg#ralewayextrabold") format("svg");
  font-weight: 800;
  font-style: normal;
}

@font-face {
  font-family: "Raleway";
  src: url("../lib/raleway/heavy/raleway-heavy.eot");
  src: url("../lib/raleway/heavy/raleway-heavy.eot?#iefix") format("embedded-opentype"), url("../lib/raleway/heavy/raleway-heavy.woff") format("woff"), url("../lib/raleway/heavy/raleway-heavy.ttf") format("truetype"), url("../lib/raleway/heavy/raleway-heavy.svg#ralewayheavy") format("svg");
  font-weight: 900;
  font-style: normal;
}

html {
  font-size: 13px;
  height: 100%;
}

body {
  height: 100%;
}

a {
  outline: none;
}

a:hover, a:focus {
  text-decoration: none;
  outline: none;
}

p {
  font-size: 1.077rem;
}

.main-content {
  padding: 45px 0;
}

@media (max-width: 767.98px) {
  .main-content {
    padding-top: 25px;
    padding-left: 20px;
    padding-right: 20px;
  }
}

.mai-mega-dropdown {
  position: static;
}

.mai-mega-menu.dropdown-menu {
  padding: 15px 0 25px;
  margin-top: 0;
  width: 100%;
  border-width: 0;
  box-shadow: 0 3px 0.3077rem rgba(0, 0, 0, 0.1);
  border-top-right-radius: 0;
  border-top-left-radius: 0;
  z-index: 999;
}

@media (max-width: 767.98px) {
  .mai-nav-tabs-sub .mai-mega-menu.dropdown-menu {
    padding: 0;
    background-color: #424242;
  }
}

.mai-mega-menu-row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -20px;
  margin-left: -20px;
}

@media (max-width: 767.98px) {
  .mai-mega-menu-row {
    flex-direction: column;
    margin: 0;
    padding: 0;
  }
}

.mai-mega-menu-column {
  position: relative;
  padding-top: 7px;
  padding-bottom: 7px;
  width: 100%;
  min-height: 1px;
  padding-right: 20px;
  padding-left: 20px;
  flex: 0 0 25%;
  max-width: 25%;
  border-right: 1px solid #f2f2f2;
}

@media (max-width: 767.98px) {
  .mai-mega-menu-column {
    max-width: 100%;
    border-right-width: 0;
    padding: 0;
  }
}

.mai-mega-menu-column:first-child {
  padding-left: 40px;
}

@media (max-width: 767.98px) {
  .mai-mega-menu-column:first-child {
    padding: 0;
  }
}

.mai-mega-menu-column:last-child {
  border-right-width: 0;
}

.mai-mega-menu-section {
  margin: 0 0 15px;
}

@media (max-width: 767.98px) {
  .mai-mega-menu-section {
    margin: 0;
  }
  .mai-mega-menu-section.parent > .nav-link {
    color: #a1a1a1;
  }
  .mai-mega-menu-section.parent > .nav-link:after {
    position: absolute;
    content: "";
    font-family: "Stroke 7";
    font-size: 1.9rem;
    vertical-align: middle;
    top: 0;
    right: 15px;
  }
  .mai-mega-menu-section.open > .nav-link {
    color: #4ad69e;
  }
  .mai-mega-menu-section.open > .nav-link:after {
    content: "";
  }
}

.mai-mega-menu-section > .nav-link {
  color: #2cc185;
  font-size: 1.231rem;
  font-weight: 400;
}

.navbar .navbar-nav .mai-mega-menu-section > .nav-link {
  padding: 10px 0 6px;
}

@media (max-width: 767.98px) {
  .navbar .navbar-nav .mai-mega-menu-section > .nav-link {
    padding: 0 17px 0 56px;
  }
}

@media (max-width: 767.98px) {
  .mai-mega-menu-section > .nav-link {
    position: relative;
    font-size: 1rem;
    line-height: 50.4px;
    color: #a1a1a1;
  }
}

.mai-mega-menu-section > .nav-link .icon {
  font-size: 1.385rem;
  line-height: 1.1;
  vertical-align: middle;
  margin-right: 6px;
}

@media (max-width: 767.98px) {
  .mai-mega-menu-sub-items {
    display: none;
    padding: 0.6rem 0;
  }
  .mai-mega-menu-section.open .mai-mega-menu-sub-items {
    display: block;
  }
}

.mai-mega-menu-sub-items .dropdown-item {
  font-size: 1.077rem;
  color: #616161;
}

.navbar .navbar-nav .mai-mega-menu-sub-items .dropdown-item {
  padding: 8px 23px;
}

.navbar .navbar-nav .mai-mega-menu-sub-items .dropdown-item:hover, .navbar .navbar-nav .mai-mega-menu-sub-items .dropdown-item.active {
  background-color: transparent;
  color: #2cc185;
}

.navbar .navbar-nav .mai-mega-menu-sub-items .dropdown-item:focus {
  background-color: transparent;
}

@media (max-width: 767.98px) {
  .mai-nav-tabs-sub .dropdown-menu .mai-mega-menu-sub-items .dropdown-item {
    padding: 0.7rem 1.5rem 0.7rem 78px;
    font-size: 1rem;
  }
}

.page-head {
  padding: 20px 0 30px;
}

@media (max-width: 767.98px) {
  .page-head {
    flex-direction: column;
    align-items: center;
    text-align: center;
  }
}

.page-head-heading {
  justify-content: center;
  display: flex;
  flex-direction: column;
}

.page-head-heading h1 {
  font-size: 3.07692rem;
  line-height: 1.3;
}

@media (max-width: 767.98px) {
  .page-head-heading h1 {
    font-size: 2.5rem;
  }
}

.page-head-desc {
  border-left: 2px solid #d9d9d9;
  text-align: center;
}

@media (max-width: 767.98px) {
  .page-head-desc {
    border-left-width: 0;
  }
}

.page-head-desc h3 {
  font-size: 1.69231rem;
  color: #919191;
  line-height: 1.4;
}

@media (max-width: 767.98px) {
  .page-head-desc h3 {
    font-size: 1.4rem;
  }
}

.mai-sub-header {
  border: 0;
  border-radius: 0;
  margin-bottom: 0;
  background-color: #2cc185;
  padding: 0 0 70px;
}

@media (max-width: 767.98px) {
  .mai-sub-header {
    padding-bottom: 0;
    background-color: #3f3f3f;
  }
}

.mai-sub-header:before {
  position: absolute;
  content: '';
  display: block;
  background-color: #FFFFFF;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 70px;
  z-index: 1;
}

@media (max-width: 767.98px) {
  .mai-sub-header:before {
    display: none;
  }
}

.mai-sub-header.mai-sub-header--no-child {
  padding-bottom: 6px;
}

.mai-sub-header.mai-sub-header--no-child:before {
  height: 6px;
}

.mai-sub-header > .container {
  padding: 0;
  padding-top: 42px;
  position: relative;
}

@media (max-width: 767.98px) {
  .mai-sub-header > .container {
    padding: 0;
  }
}

.mai-sub-header .navbar {
  padding: 0;
  width: 100%;
}

.mai-sub-header .navbar-toggler {
  padding: 14px 0;
  height: auto;
  width: 100%;
  background-color: #2ab77e;
}

.mai-sub-header .navbar-toggler:focus {
  outline: none;
}

.mai-sub-header .navbar-toggler.collapsed {
  background-color: #353535;
}

.mai-sub-header .navbar-toggler.collapsed .icon-bar span {
  background-color: #d8d8d8;
}

.mai-sub-header .navbar-toggler .icon-bar {
  display: inline-block;
  background-color: transparent;
  height: auto;
  width: auto;
  vertical-align: middle;
}

.mai-sub-header .navbar-toggler .icon-bar span {
  display: block;
  width: 24px;
  height: 1px;
  margin-top: 6px;
  background-color: #FFFFFF;
}

.mai-sub-header .navbar-toggler .icon-bar span:first-child {
  margin-top: 0;
}

.mai-sub-header .navbar-nav > .nav-item + .nav-item {
  margin-left: 0;
}

.mai-sub-header .navbar-nav > .nav-item > .nav-link {
  font-family: "Raleway", sans-serif;
  font-size: 1.308rem;
  font-weight: 500;
  line-height: 54px;
  min-width: 120px;
  padding: 0 17px;
  text-align: center;
  border-radius: 3px 3px 0 0;
  color: #FFFFFF;
  transition: color .12s ease-in-out;
}

@media (min-width: 768px) and (max-width: 991.98px) {
  .mai-sub-header .navbar-nav > .nav-item > .nav-link {
    font-size: 1.2rem;
    min-width: 90px;
    padding: 0 13px;
  }
}

@media (max-width: 767.98px) {
  .mai-sub-header .navbar-nav > .nav-item > .nav-link {
    color: #bebebe;
    text-align: left;
    background-color: #3a3a3a;
    font-size: 1.077rem;
  }
}

.mai-sub-header .navbar-nav > .nav-item > .nav-link > .icon {
  font-size: 2.154rem;
  margin-right: 5px;
}

@media (min-width: 768px) and (max-width: 991.98px) {
  .mai-sub-header .navbar-nav > .nav-item > .nav-link > .icon {
    font-size: 1.5rem;
  }
}

@media (max-width: 767.98px) {
  .mai-sub-header .navbar-nav > .nav-item > .nav-link > .icon {
    display: inline-block;
    font-size: 1.615rem;
    margin-right: 10px;
    min-width: 21px;
    text-align: center;
  }
}

.mai-sub-header .navbar-nav > .nav-item > .nav-link > span {
  vertical-align: middle;
}

.mai-sub-header .navbar-nav > .nav-item > .nav-link:hover {
  background-color: transparent;
  color: #337f61;
}

@media (max-width: 767.98px) {
  .mai-sub-header .navbar-nav > .nav-item > .nav-link:hover {
    background-color: #3a3a3a;
    color: #bebebe;
  }
}

.mai-sub-header .navbar-nav > .nav-item > .nav-link.dropdown-toggle:after {
  display: none;
}

@media (max-width: 767.98px) {
  .mai-sub-header .navbar-nav > .nav-item.parent > .nav-link:after {
    position: absolute;
    content: "";
    font-family: "Stroke 7";
    font-size: 1.9rem;
    vertical-align: middle;
    right: 15px;
  }
  .mai-sub-header .navbar-nav > .nav-item.parent.open > .nav-link:after {
    content: "";
  }
}

.mai-sub-header .navbar-nav > .nav-item.open > a, .mai-sub-header .navbar-nav > .nav-item.open:hover > a {
  color: #2cc185;
  background-color: #FFFFFF;
}

@media (max-width: 767.98px) {
  .mai-sub-header .navbar-nav > .nav-item.open > a, .mai-sub-header .navbar-nav > .nav-item.open:hover > a {
    background-color: #3a3a3a;
  }
}

.mai-sub-header .search {
  position: absolute;
  top: 48px;
  right: 0;
  margin-top: -21px;
  width: 235px;
  border-bottom: 1px solid #2ec88a;
}

@media (max-width: 1199.98px) {
  .mai-sub-header .search {
    display: none;
  }
}

.mai-sub-header .search > input {
  border: 1px solid transparent;
  background-color: #2ab77e;
  color: #FFFFFF;
  width: 100%;
  font-family: "Raleway", sans-serif;
  font-size: 1.308rem;
  font-weight: 500;
  padding: 8px 10px;
  outline: none;
  transition: border-color 0.3s ease;
}

.mai-sub-header .search > input:focus {
  border-color: #26a874;
}

.mai-sub-header .search > span {
  position: absolute;
  color: #FFFFFF;
  right: 8px;
  top: 50%;
  font-size: 1.846rem;
  margin-top: -12px;
}

.mai-sub-header ::-webkit-input-placeholder {
  /* WebKit browsers */
  color: rgba(255, 255, 255, 0.4);
}

.mai-sub-header :-moz-placeholder {
  /* Mozilla Firefox 4 to 18 */
  color: rgba(255, 255, 255, 0.4);
  opacity: 1;
}

.mai-sub-header :-ms-input-placeholder {
  /* Internet Explorer 10+ */
  color: rgba(255, 255, 255, 0.4);
}

.mai-nav-tabs-sub {
  position: absolute;
  display: none;
  left: 0;
  top: 100%;
  z-index: 999;
  width: 100%;
  background-color: #FFFFFF;
}

@media (max-width: 767.98px) {
  .mai-nav-tabs-sub {
    position: static;
    top: 0;
    background-color: #3f3f3f;
    flex-direction: column;
    padding: 5px 0;
  }
}

.nav-item.open .mai-nav-tabs-sub {
  display: flex;
}

.mai-nav-tabs-sub > .nav-item.mega-menu {
  position: static;
}

.mai-nav-tabs-sub > .nav-item > .nav-link {
  padding: 0 26px;
  line-height: 70px;
}

@media (min-width: 768px) and (max-width: 991.98px) {
  .mai-nav-tabs-sub > .nav-item > .nav-link {
    padding: 0 14px;
  }
}

@media (max-width: 767.98px) {
  .mai-nav-tabs-sub > .nav-item > .nav-link {
    padding-left: 34px;
    line-height: 50.4px;
    color: #a2a2a2;
    font-size: 1rem;
  }
}

.mai-nav-tabs-sub > .nav-item > .nav-link .icon {
  margin-right: .4rem;
}

@media (min-width: 768px) and (max-width: 991.98px) {
  .mai-nav-tabs-sub > .nav-item > .nav-link .icon {
    display: none;
  }
}

.mai-nav-tabs-sub > .nav-item > .nav-link.active {
  color: #2cc185;
}

.mai-nav-tabs-sub > .nav-item.parent > .nav-link {
  padding-right: 34px;
}

.mai-nav-tabs-sub > .nav-item.parent > .nav-link:after {
  position: absolute;
  content: "";
  font-family: "Stroke 7";
  font-size: 1.9rem;
  vertical-align: middle;
}

@media (max-width: 767.98px) {
  .mai-nav-tabs-sub > .nav-item.parent > .nav-link:after {
    right: 15px;
  }
}

.mai-nav-tabs-sub > .nav-item.parent.open > .nav-link {
  color: #4ad69e;
}

@media (max-width: 767.98px) {
  .mai-nav-tabs-sub > .nav-item.parent.open > .nav-link:after {
    content: "";
  }
}

@media (max-width: 767.98px) {
  .mai-nav-tabs-sub .dropdown.open > .dropdown-menu {
    display: block;
  }
}

.mai-nav-tabs-sub .dropdown-menu {
  min-width: 200px;
  box-shadow: 0 3px 0.3077rem rgba(0, 0, 0, 0.1);
  margin-top: 0;
  padding-top: 0.6rem;
  border: 0;
}

.mai-nav-tabs-sub .dropdown-menu .dropdown-item {
  color: #616161;
}

.mai-nav-tabs-sub .dropdown-menu .dropdown-item.active {
  color: #2cc185;
}

@media (max-width: 767.98px) {
  .mai-nav-tabs-sub .dropdown-menu {
    background-color: #424242;
    box-shadow: none;
    padding-bottom: 0.6rem;
  }
  .mai-nav-tabs-sub .dropdown-menu .dropdown-item {
    padding-left: 56px;
    color: #a2a2a2;
  }
  .mai-nav-tabs-sub .dropdown-menu .dropdown-item:hover {
    background-color: inherit;
  }
}

.mai-splash-screen .main-content {
  max-width: 100%;
  width: 100%;
}

.splash-container {
  padding-top: 55px;
  width: 800px;
  margin: 0 auto;
}

@media (max-width: 991.98px) {
  .splash-container {
    width: 700px;
  }
}

@media (max-width: 767.98px) {
  .splash-container {
    padding: 20px;
    width: 400px;
    display: flex;
  }
}

@media (max-width: 575.98px) {
  .splash-container {
    padding: 0 20px;
    width: 100%;
    background-color: #363636;
  }
}

.splash-container .user-message {
  display: flex;
  flex-direction: column;
  background-color: #2cc185;
  color: #FFFFFF;
  padding: 155px 30px 40px;
}

@media (max-width: 767.98px) {
  .splash-container .user-message {
    display: none;
  }
}

.splash-container .splash-message {
  flex: 1 1 auto;
  font-size: 2rem;
  line-height: 1.3;
  font-weight: 300;
}

.splash-container .alternative-message {
  color: #2e7357;
}

.splash-container .alternative-message a {
  color: #FFFFFF;
  opacity: .8;
}

.splash-container .alternative-message a:hover {
  opacity: 1;
}

.splash-container .form-message {
  display: flex;
  flex-direction: column;
  padding: 60px 30px 40px;
  background-color: #363636;
  color: #d4d4d4;
}

@media (max-width: 575.98px) {
  .splash-container .form-message {
    padding: 60px 0 40px;
  }
}

.splash-container .form-message .out-links a {
  text-align: center;
  display: block;
  margin-top: 3rem;
  color: #ababab;
}

.splash-container .form-message .out-links a:hover {
  color: #FFFFFF;
}

.splash-container .logo-img {
  margin: 0 auto;
  display: block;
}

.splash-container .splash-description {
  color: #FFFFFF;
  font-size: 1.53846rem;
  font-weight: 300;
  display: block;
  margin: 0 auto;
}

.splash-container .form-group {
  margin: 0;
}

.splash-container .input-group {
  border-bottom: 2px solid #3f3f3f;
  padding: 6px 0;
}

.splash-container .input-group .input-group-prepend {
  padding: 0.6rem 0.9rem;
  min-width: 3.847rem;
  font-size: 1.230rem;
  margin-right: 0;
}

.splash-container .input-group .input-group-prepend .icon {
  font-size: 20px;
  color: #2cc185;
  padding-top: 1px;
}

.splash-container .input-group .form-control {
  border: 0;
  background-color: transparent;
  box-shadow: none;
  color: #989898;
  padding: 0;
  font-size: 14px;
  font-weight: 500;
  height: 36px;
}

.mai-top-header {
  border: 0;
  margin-bottom: 0;
  background-color: #323232;
  padding: 0;
}

@media (max-width: 767.98px) {
  .mai-top-header {
    background-color: #2cc185;
  }
}

.mai-top-header > .container {
  padding: 0;
  display: flex;
  align-items: center;
}

@media (max-width: 575.98px) {
  .mai-top-header > .container {
    justify-content: space-between;
  }
}

.mai-top-header .dropdown-toggle:after {
  display: none;
}

.mai-top-header .dropdown-menu {
  margin-top: 0;
}

.mai-top-header .navbar-brand {
  flex: 0 0 auto;
  color: #FFFFFF;
  padding-top: 0;
  padding-bottom: 0;
  font-size: 1.5rem;
  font-weight: 300;
  line-height: 62px;
  height: 62px;
  width: 133px;
  background-image: url("../img/logo.png");
  background-repeat: no-repeat;
  background-position: 0 center;
  margin-right: 45px;
}

.mai-top-header .navbar-brand-text {
  background-image: none;
}

@media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
  .mai-top-header .navbar-brand {
    background-image: url("../img/logo-2x.png");
    background-size: 133px 20px;
  }
}

@media (max-width: 991.98px) {
  .mai-top-header .navbar-brand {
    flex: 1 1 100%;
  }
}

@media (max-width: 767.98px) {
  .mai-top-header .navbar-brand {
    display: none;
  }
}

@media (max-width: 575.98px) {
  .mai-top-header .navbar-brand {
    display: none;
  }
}

@media (max-width: 767.98px) {
  .mai-top-header.mai-top-header-show-logo .navbar-brand {
    display: inline-block;
    flex: 1 1;
    margin-right: 0;
    margin-left: 20px;
  }
}

.mai-top-header .navbar-nav {
  flex-direction: row;
}

.mai-top-header .mai-top-nav {
  flex: 1 1;
}

@media (max-width: 767.98px) {
  .mai-top-header .mai-top-nav {
    display: none;
  }
}

@media (max-width: 575.98px) {
  .mai-top-header .mai-top-nav {
    display: none;
  }
}

@media (max-width: 991.98px) {
  .mai-top-header .mai-top-nav {
    display: none;
  }
}

.mai-top-header .mai-top-nav .nav-link {
  padding: 0 18px;
}

.mai-top-header .navbar-collapse {
  padding-right: 45px;
}

.mai-top-header .mai-icons-nav {
  margin-right: 10px;
  flex: 0 0 auto;
}

@media (max-width: 767.98px) {
  .mai-top-header .mai-icons-nav {
    order: 2;
  }
}

@media (max-width: 767.98px) {
  .mai-top-header .mai-icons-nav > .nav-item.dropdown {
    position: static;
  }
}

@media (max-width: 575.98px) {
  .mai-top-header .mai-icons-nav > .nav-item.dropdown {
    position: static;
  }
}

.mai-top-header .mai-icons-nav > .nav-item.dropdown > .nav-link {
  padding: 0 9px;
  position: relative;
}

.mai-top-header .mai-icons-nav > .nav-item.dropdown > .nav-link .icon {
  font-size: 25px;
  vertical-align: middle;
  color: #707070;
}

@media (max-width: 767.98px) {
  .mai-top-header .mai-icons-nav > .nav-item.dropdown > .nav-link .icon {
    color: #FFFFFF;
  }
}

@media (max-width: 575.98px) {
  .mai-top-header .mai-icons-nav > .nav-item.dropdown > .nav-link .icon {
    color: #FFFFFF;
  }
}

.mai-top-header .mai-icons-nav > .nav-item.dropdown > .nav-link .indicator {
  background: #2cc185;
  border-radius: 50%;
  display: block;
  height: 6px;
  width: 6px;
  position: absolute;
  top: 19px;
  right: 7px;
}

@media (max-width: 767.98px) {
  .mai-top-header .mai-icons-nav > .nav-item.dropdown > .nav-link .indicator {
    background-color: #FFFFFF;
  }
}

@media (max-width: 575.98px) {
  .mai-top-header .mai-icons-nav > .nav-item.dropdown > .nav-link .indicator {
    background-color: #FFFFFF;
  }
}

.mai-top-header .mai-icons-nav > .nav-item.dropdown > .nav-link:hover .icon {
  color: #7f7f7f;
}

@media (max-width: 767.98px) {
  .mai-top-header .mai-icons-nav > .nav-item.dropdown > .nav-link:hover .icon {
    color: #FFFFFF;
  }
}

@media (max-width: 575.98px) {
  .mai-top-header .mai-icons-nav > .nav-item.dropdown > .nav-link:hover .icon {
    color: #FFFFFF;
  }
}

.mai-top-header .mai-icons-nav > .nav-item.dropdown.show > a, .mai-top-header .mai-icons-nav > .nav-item.dropdown.show:hover > a {
  background-color: transparent;
}

.mai-top-header .mai-icons-nav > .nav-item.dropdown.show > a .icon, .mai-top-header .mai-icons-nav > .nav-item.dropdown.show:hover > a .icon {
  color: #29b57c;
}

@media (max-width: 767.98px) {
  .mai-top-header .mai-icons-nav > .nav-item.dropdown.show > a .icon, .mai-top-header .mai-icons-nav > .nav-item.dropdown.show:hover > a .icon {
    color: #FFFFFF;
  }
}

@media (max-width: 575.98px) {
  .mai-top-header .mai-icons-nav > .nav-item.dropdown.show > a .icon, .mai-top-header .mai-icons-nav > .nav-item.dropdown.show:hover > a .icon {
    color: #FFFFFF;
  }
}

.mai-top-header .mai-icons-nav > .nav-item.dropdown.show > a:after, .mai-top-header .mai-icons-nav > .nav-item.dropdown.show:hover > a:after {
  position: absolute;
  content: "";
  display: inline-block;
  border-bottom: 10px solid #3b3b3b;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-top: 0;
  right: 50%;
  bottom: -1px;
  margin-right: -10px;
}

.mai-top-header .mai-icons-nav .mai-messages > .dropdown-menu, .mai-top-header .mai-icons-nav .mai-notifications > .dropdown-menu, .mai-top-header .mai-icons-nav .mai-settings > .dropdown-menu {
  width: 330px;
  border-radius: 5px;
  left: auto;
  margin-right: -165px;
  right: 50%;
  padding: 0;
}

@media (max-width: 767.98px) {
  .mai-top-header .mai-icons-nav .mai-messages > .dropdown-menu, .mai-top-header .mai-icons-nav .mai-notifications > .dropdown-menu, .mai-top-header .mai-icons-nav .mai-settings > .dropdown-menu {
    right: 0;
    margin-right: 0;
  }
}

@media (max-width: 575.98px) {
  .mai-top-header .mai-icons-nav .mai-messages > .dropdown-menu, .mai-top-header .mai-icons-nav .mai-notifications > .dropdown-menu, .mai-top-header .mai-icons-nav .mai-settings > .dropdown-menu {
    width: 100%;
    left: 0;
  }
}

.mai-top-header .mai-icons-nav .mai-messages > .dropdown-menu .title, .mai-top-header .mai-icons-nav .mai-notifications > .dropdown-menu .title, .mai-top-header .mai-icons-nav .mai-settings > .dropdown-menu .title {
  font-family: "Raleway", sans-serif;
  font-size: 1.154rem;
  color: #949494;
  background-color: #3b3b3b;
  text-align: center;
  padding: 14px 0 14px;
}

.mai-top-header .mai-icons-nav .mai-messages > .dropdown-menu .footer a, .mai-top-header .mai-icons-nav .mai-notifications > .dropdown-menu .footer a, .mai-top-header .mai-icons-nav .mai-settings > .dropdown-menu .footer a {
  font-family: "Raleway", sans-serif;
  font-size: 1rem;
  color: #949494;
  background-color: #3b3b3b;
  text-align: center;
  padding: 10px 0 10px;
  display: block;
}

.mai-top-header .mai-icons-nav .mai-messages > .dropdown-menu .footer a:hover, .mai-top-header .mai-icons-nav .mai-notifications > .dropdown-menu .footer a:hover, .mai-top-header .mai-icons-nav .mai-settings > .dropdown-menu .footer a:hover {
  background-color: #383838;
  color: #b2b2b2;
}

.mai-top-header .mai-icons-nav .mai-notifications .mai-scroller-notifications, .mai-top-header .mai-icons-nav .mai-notifications .mai-scroller-messages, .mai-top-header .mai-icons-nav .mai-messages .mai-scroller-notifications, .mai-top-header .mai-icons-nav .mai-messages .mai-scroller-messages {
  position: relative;
  width: 100%;
  height: 252px;
}

.mai-top-header .mai-icons-nav .mai-notifications .mai-scroller-notifications > .content > ul, .mai-top-header .mai-icons-nav .mai-notifications .mai-scroller-messages > .content > ul, .mai-top-header .mai-icons-nav .mai-messages .mai-scroller-notifications > .content > ul, .mai-top-header .mai-icons-nav .mai-messages .mai-scroller-messages > .content > ul {
  list-style: none;
  padding-left: 0;
}

.mai-top-header .mai-icons-nav .mai-notifications .mai-scroller-notifications > .content > ul > li, .mai-top-header .mai-icons-nav .mai-notifications .mai-scroller-messages > .content > ul > li, .mai-top-header .mai-icons-nav .mai-messages .mai-scroller-notifications > .content > ul > li, .mai-top-header .mai-icons-nav .mai-messages .mai-scroller-messages > .content > ul > li {
  overflow: hidden;
  border-bottom: 1px solid #414141;
  background-color: #3d3d3d;
}

.mai-top-header .mai-icons-nav .mai-notifications .mai-scroller-notifications > .content > ul > li > a, .mai-top-header .mai-icons-nav .mai-notifications .mai-scroller-messages > .content > ul > li > a, .mai-top-header .mai-icons-nav .mai-messages .mai-scroller-notifications > .content > ul > li > a, .mai-top-header .mai-icons-nav .mai-messages .mai-scroller-messages > .content > ul > li > a {
  padding: 15px;
  display: block;
  overflow: hidden;
}

.mai-top-header .mai-icons-nav .mai-notifications .mai-scroller-notifications > .content > ul > li > a .icon, .mai-top-header .mai-icons-nav .mai-notifications .mai-scroller-notifications > .content > ul > li > a .img, .mai-top-header .mai-icons-nav .mai-notifications .mai-scroller-messages > .content > ul > li > a .icon, .mai-top-header .mai-icons-nav .mai-notifications .mai-scroller-messages > .content > ul > li > a .img, .mai-top-header .mai-icons-nav .mai-messages .mai-scroller-notifications > .content > ul > li > a .icon, .mai-top-header .mai-icons-nav .mai-messages .mai-scroller-notifications > .content > ul > li > a .img, .mai-top-header .mai-icons-nav .mai-messages .mai-scroller-messages > .content > ul > li > a .icon, .mai-top-header .mai-icons-nav .mai-messages .mai-scroller-messages > .content > ul > li > a .img {
  margin-top: 3px;
  height: 36px;
  width: 36px;
  border-radius: 50%;
  background-color: #2e2e2e;
  float: left;
  text-align: center;
}

.mai-top-header .mai-icons-nav .mai-notifications .mai-scroller-notifications > .content > ul > li > a .icon > span, .mai-top-header .mai-icons-nav .mai-notifications .mai-scroller-notifications > .content > ul > li > a .img > span, .mai-top-header .mai-icons-nav .mai-notifications .mai-scroller-messages > .content > ul > li > a .icon > span, .mai-top-header .mai-icons-nav .mai-notifications .mai-scroller-messages > .content > ul > li > a .img > span, .mai-top-header .mai-icons-nav .mai-messages .mai-scroller-notifications > .content > ul > li > a .icon > span, .mai-top-header .mai-icons-nav .mai-messages .mai-scroller-notifications > .content > ul > li > a .img > span, .mai-top-header .mai-icons-nav .mai-messages .mai-scroller-messages > .content > ul > li > a .icon > span, .mai-top-header .mai-icons-nav .mai-messages .mai-scroller-messages > .content > ul > li > a .img > span {
  font-size: 1.846rem;
  color: #2cc185;
  line-height: 34px;
}

.mai-top-header .mai-icons-nav .mai-notifications .mai-scroller-notifications > .content > ul > li > a .content, .mai-top-header .mai-icons-nav .mai-notifications .mai-scroller-messages > .content > ul > li > a .content, .mai-top-header .mai-icons-nav .mai-messages .mai-scroller-notifications > .content > ul > li > a .content, .mai-top-header .mai-icons-nav .mai-messages .mai-scroller-messages > .content > ul > li > a .content {
  padding-left: 50px;
  line-height: 14px;
}

.mai-top-header .mai-icons-nav .mai-notifications .mai-scroller-notifications > .content > ul > li > a .content .desc, .mai-top-header .mai-icons-nav .mai-notifications .mai-scroller-messages > .content > ul > li > a .content .desc, .mai-top-header .mai-icons-nav .mai-messages .mai-scroller-notifications > .content > ul > li > a .content .desc, .mai-top-header .mai-icons-nav .mai-messages .mai-scroller-messages > .content > ul > li > a .content .desc {
  color: #cbcbcb;
  font-size: 1rem;
  line-height: 17px;
}

.mai-top-header .mai-icons-nav .mai-notifications .mai-scroller-notifications > .content > ul > li > a .content .desc strong, .mai-top-header .mai-icons-nav .mai-notifications .mai-scroller-messages > .content > ul > li > a .content .desc strong, .mai-top-header .mai-icons-nav .mai-messages .mai-scroller-notifications > .content > ul > li > a .content .desc strong, .mai-top-header .mai-icons-nav .mai-messages .mai-scroller-messages > .content > ul > li > a .content .desc strong {
  font-weight: 600;
}

.mai-top-header .mai-icons-nav .mai-notifications .mai-scroller-notifications > .content > ul > li > a .content .date, .mai-top-header .mai-icons-nav .mai-notifications .mai-scroller-messages > .content > ul > li > a .content .date, .mai-top-header .mai-icons-nav .mai-messages .mai-scroller-notifications > .content > ul > li > a .content .date, .mai-top-header .mai-icons-nav .mai-messages .mai-scroller-messages > .content > ul > li > a .content .date {
  color: #8a8a8a;
  display: block;
  font-size: 0.9231rem;
  font-style: italic;
  font-weight: 300;
  margin-top: 5px;
}

.mai-top-header .mai-icons-nav .mai-notifications .mai-scroller-notifications > .content > ul > li:hover, .mai-top-header .mai-icons-nav .mai-notifications .mai-scroller-messages > .content > ul > li:hover, .mai-top-header .mai-icons-nav .mai-messages .mai-scroller-notifications > .content > ul > li:hover, .mai-top-header .mai-icons-nav .mai-messages .mai-scroller-messages > .content > ul > li:hover {
  background-color: #2ab97f;
}

.mai-top-header .mai-icons-nav .mai-notifications .mai-scroller-notifications > .content > ul > li:hover > a, .mai-top-header .mai-icons-nav .mai-notifications .mai-scroller-messages > .content > ul > li:hover > a, .mai-top-header .mai-icons-nav .mai-messages .mai-scroller-notifications > .content > ul > li:hover > a, .mai-top-header .mai-icons-nav .mai-messages .mai-scroller-messages > .content > ul > li:hover > a {
  color: #FFFFFF;
}

.mai-top-header .mai-icons-nav .mai-notifications .mai-scroller-notifications > .content > ul > li:hover > a .icon, .mai-top-header .mai-icons-nav .mai-notifications .mai-scroller-messages > .content > ul > li:hover > a .icon, .mai-top-header .mai-icons-nav .mai-messages .mai-scroller-notifications > .content > ul > li:hover > a .icon, .mai-top-header .mai-icons-nav .mai-messages .mai-scroller-messages > .content > ul > li:hover > a .icon {
  background-color: #26a874;
}

.mai-top-header .mai-icons-nav .mai-notifications .mai-scroller-notifications > .content > ul > li:hover > a .icon > span, .mai-top-header .mai-icons-nav .mai-notifications .mai-scroller-messages > .content > ul > li:hover > a .icon > span, .mai-top-header .mai-icons-nav .mai-messages .mai-scroller-notifications > .content > ul > li:hover > a .icon > span, .mai-top-header .mai-icons-nav .mai-messages .mai-scroller-messages > .content > ul > li:hover > a .icon > span {
  color: #FFFFFF;
}

.mai-top-header .mai-icons-nav .mai-notifications .mai-scroller-notifications > .content > ul > li:hover > a .content .desc, .mai-top-header .mai-icons-nav .mai-notifications .mai-scroller-messages > .content > ul > li:hover > a .content .desc, .mai-top-header .mai-icons-nav .mai-messages .mai-scroller-notifications > .content > ul > li:hover > a .content .desc, .mai-top-header .mai-icons-nav .mai-messages .mai-scroller-messages > .content > ul > li:hover > a .content .desc {
  color: #FFFFFF;
}

.mai-top-header .mai-icons-nav .mai-notifications .mai-scroller-notifications > .content > ul > li:hover > a .content .date, .mai-top-header .mai-icons-nav .mai-notifications .mai-scroller-messages > .content > ul > li:hover > a .content .date, .mai-top-header .mai-icons-nav .mai-messages .mai-scroller-notifications > .content > ul > li:hover > a .content .date, .mai-top-header .mai-icons-nav .mai-messages .mai-scroller-messages > .content > ul > li:hover > a .content .date {
  color: #1c7a54;
}

.mai-top-header .mai-icons-nav .mai-notifications .mai-scroller-notifications > .content > ul > li:last-child, .mai-top-header .mai-icons-nav .mai-notifications .mai-scroller-messages > .content > ul > li:last-child, .mai-top-header .mai-icons-nav .mai-messages .mai-scroller-notifications > .content > ul > li:last-child, .mai-top-header .mai-icons-nav .mai-messages .mai-scroller-messages > .content > ul > li:last-child {
  border-bottom: 0;
}

.mai-top-header .mai-icons-nav .mai-messages .mai-scroller-messages > .content > ul > li > a .img {
  width: 36px;
  height: 36px;
  background-color: transparent;
  border-radius: 2px;
  overflow: hidden;
  margin-top: 1px;
}

.mai-top-header .mai-icons-nav .mai-messages .mai-scroller-messages > .content > ul > li > a .img > img {
  max-width: 100%;
}

.mai-top-header .mai-icons-nav .mai-messages .mai-scroller-messages > .content > ul > li > a > .content .date {
  float: right;
  margin-top: 1px;
}

.mai-top-header .mai-icons-nav .mai-messages .mai-scroller-messages > .content > ul > li > a > .content .name {
  font-family: "Raleway", sans-serif;
  font-size: 1.077rem;
  font-weight: 500;
  color: #cbcbcb;
  display: block;
  margin-bottom: 5px;
}

.mai-top-header .mai-icons-nav .mai-messages .mai-scroller-messages > .content > ul > li > a > .content .desc {
  color: #9e9e9e;
}

.mai-top-header .mai-icons-nav .mai-messages .mai-scroller-messages > .content > ul > li:hover > a .content .name {
  color: #FFFFFF;
}

.mai-top-header .mai-icons-nav .mai-settings .dropdown-menu .content ul {
  padding: 0;
}

.mai-top-header .mai-icons-nav .mai-settings .dropdown-menu .content ul li {
  display: block;
  overflow: hidden;
  list-style: none;
  padding: 15px 20px;
  line-height: 34px;
  border-bottom: 1px solid #424242;
  vertical-align: middle;
  background-color: #3d3d3d;
}

.mai-top-header .mai-icons-nav .mai-settings .dropdown-menu .content ul li > span {
  font-family: "Raleway", sans-serif;
  color: #cbcbcb;
  font-weight: 500;
  font-size: 1.154rem;
}

.mai-top-header .mai-icons-nav .mai-settings .dropdown-menu .content ul li .switch-button {
  vertical-align: middle;
  background-color: #323232;
}

.mai-top-header .mai-icons-nav .mai-settings .dropdown-menu .content ul li .switch-button label:before {
  color: #cbcbcb;
}

.mai-top-header .mai-icons-nav .mai-settings .dropdown-menu .content ul li .switch-button input[type="checkbox"]:checked + span label:before {
  color: #FFFFFF;
}

.mai-top-header .mai-icons-nav .mai-settings .dropdown-menu .content ul li:hover {
  background-color: #404040;
}

.mai-top-header .mai-icons-nav .mai-settings .dropdown-menu .content ul li:last-child {
  border-bottom: 0;
}

@media (max-width: 767.98px) {
  .mai-top-header-show-logo .mai-icons-nav {
    display: none;
  }
}

.mai-top-header .navbar-nav > .nav-item + .nav-item {
  margin-left: 0;
}

.mai-top-header .navbar-nav > .nav-item > .nav-link {
  line-height: 62px;
  font-family: "Raleway", sans-serif;
  font-size: 1.077rem;
  padding: 0 18px;
  outline: none;
}

@media (max-width: 767.98px) {
  .mai-top-header .navbar-nav > .nav-item > .nav-link {
    line-height: 74px;
  }
}

.mai-top-header .navbar-nav > .nav-item.dropdown > a .angle-down {
  position: relative;
  font-size: 2.077rem;
  vertical-align: middle;
  width: 27px;
  display: inline-block;
  line-height: inherit;
}

.mai-top-header .navbar-nav > .nav-item.dropdown .dropdown-menu {
  border: 0;
  border-radius: 0 0 3px 3px;
  box-shadow: none;
  background-color: #323232;
  min-width: 184px;
}

.mai-top-header .navbar-nav > .nav-item.dropdown .dropdown-menu > .dropdown-item {
  font-size: 1rem;
  color: #adadad;
  padding: 9px 20px;
  min-width: 175px;
  font-family: "Raleway", sans-serif;
}

.mai-top-header .navbar-nav > .nav-item.dropdown .dropdown-menu > .dropdown-item:hover {
  background-color: #29b57c;
  color: #FFFFFF;
}

.mai-top-header .navbar-nav > .nav-item.dropdown .dropdown-menu > .dropdown-item:focus {
  background-color: transparent;
}

.mai-user-nav {
  flex: 0 0 auto;
}

@media (max-width: 767.98px) {
  .mai-user-nav {
    flex: 1 1 100%;
    order: 1;
  }
}

@media (max-width: 575.98px) {
  .mai-user-nav {
    flex: 0 1 auto;
  }
}

@media (max-width: 767.98px) {
  .mai-top-header-show-logo .mai-user-nav {
    flex: 0 1 auto;
    margin-right: 20px;
  }
}

@media (max-width: 575.98px) {
  .mai-top-header-show-logo .mai-user-nav {
    flex: 0 1 auto;
    margin-right: 20px;
  }
}

@media (max-width: 575.98px) {
  .mai-user-nav > .nav-item.dropdown {
    width: 100%;
  }
}

.mai-user-nav > .nav-item.dropdown.show > a .angle-down:after, .mai-user-nav > .nav-item.dropdown.show:hover > a .angle-down:after {
  position: absolute;
  content: "";
  display: inline-block;
  border-bottom: 10px solid #323232;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-top: 0;
  right: 50%;
  bottom: 0;
  margin-right: -10px;
}

.mai-user-nav > .nav-item.dropdown > .nav-link {
  color: #FFFFFF;
  padding-right: 0;
  font-size: 1.231rem;
  white-space: nowrap;
}

@media (max-width: 767.98px) {
  .mai-user-nav > .nav-item.dropdown > .nav-link {
    display: flex;
    align-items: center;
  }
}

@media (max-width: 575.98px) {
  .mai-user-nav > .nav-item.dropdown > .nav-link {
    display: flex;
    align-items: center;
  }
}

.mai-user-nav > .nav-item.dropdown > .nav-link img {
  max-width: 30px;
  max-height: 30px;
  border-radius: 3px;
}

@media (max-width: 767.98px) {
  .mai-user-nav > .nav-item.dropdown > .nav-link img {
    flex: 0 0 auto;
  }
}

@media (max-width: 575.98px) {
  .mai-user-nav > .nav-item.dropdown > .nav-link img {
    flex: 0 0 auto;
  }
}

.mai-user-nav > .nav-item.dropdown > .nav-link .user-name {
  margin-left: 10px;
}

@media (max-width: 767.98px) {
  .mai-top-header-show-logo .mai-user-nav > .nav-item.dropdown > .nav-link .user-name {
    display: none;
  }
}

@media (max-width: 575.98px) {
  .mai-user-nav > .nav-item.dropdown > .nav-link .user-name {
    flex: 1 1 100%;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

@media (max-width: 767.98px) {
  .mai-user-nav > .nav-item.dropdown > .nav-link .user-name {
    flex: 1 1 100%;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

@media (max-width: 767.98px) {
  .mai-top-header-show-logo .mai-user-nav > .nav-item.dropdown .dropdown-menu {
    left: auto;
    right: -10px;
  }
}

@media (max-width: 575.98px) {
  .mai-top-header-show-logo .mai-user-nav > .nav-item.dropdown .dropdown-menu {
    right: 10px;
  }
}

.mai-user-nav > .nav-item.dropdown .dropdown-menu .dropdown-item .icon {
  font-size: 1.538rem;
  vertical-align: middle;
  margin-right: 10px;
}

.mai-user-nav > .nav-item.dropdown .dropdown-menu:after {
  left: auto;
  right: 20px;
}

.switch-button {
  display: inline-block;
  line-height: 16px;
  border-radius: 50px;
  background-color: #cccccc;
  width: 57px;
  height: 27px;
  padding: 2px;
  position: relative;
  overflow: hidden;
  vertical-align: middle;
}

.switch-button input[type="checkbox"] {
  display: none;
}

.switch-button label {
  border-radius: 50%;
  border: 1px solid transparent;
  background-color: #FFFFFF;
  margin: 0;
  height: 22px;
  width: 22px;
  display: inline-block;
  cursor: pointer;
  background-clip: padding-box;
}

.switch-button label:before {
  position: absolute;
  font-size: 11px;
  z-index: 0;
  content: "OFF";
  right: 0;
  display: block;
  width: 100%;
  height: 100%;
  line-height: 27px;
  top: 0;
  text-align: right;
  padding-right: 8px;
  color: #FFFFFF;
}

.switch-button input[type="checkbox"]:checked + span {
  background-color: #2cc185;
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  padding: 2px;
}

.switch-button input[type="checkbox"]:checked + span label {
  float: right;
}

.switch-button input[type="checkbox"]:checked + span label:before {
  position: absolute;
  z-index: 0;
  content: "ON";
  color: #FFFFFF;
  left: 0;
  text-align: left;
  padding-left: 8px;
}

.switch-button.switch-button-yesno label:before {
  content: "NO";
}

.switch-button.switch-button-yesno input[type="checkbox"]:checked + span label:before {
  content: "YES";
}

.switch-button.switch-button-xs {
  height: 20px;
  width: 53px;
  line-height: 16px;
  width: 50px;
}

.switch-button.switch-button-xs label {
  height: 16px;
  width: 16px;
}

.switch-button.switch-button-xs label:before {
  line-height: 20px;
}

.switch-button.switch-button-sm {
  height: 24px;
  width: 57px;
  line-height: 20px;
}

.switch-button.switch-button-sm label {
  height: 20px;
  width: 20px;
}

.switch-button.switch-button-sm label:before {
  line-height: 24px;
}

.switch-button.switch-button-lg {
  height: 30px;
  width: 63px;
  line-height: 26px;
  width: 65px;
}

.switch-button.switch-button-lg label {
  height: 26px;
  width: 26px;
}

.switch-button.switch-button-lg label:before {
  line-height: 30px;
}

.switch-button.switch-button-info {
  background-color: #cccccc;
}

.switch-button.switch-button-info input[type="checkbox"]:checked + span {
  background-color: #58C7EF;
}

.switch-button.switch-button-warning {
  background-color: #cccccc;
}

.switch-button.switch-button-warning input[type="checkbox"]:checked + span {
  background-color: #FFDC42;
}

.switch-button.switch-button-danger {
  background-color: #cccccc;
}

.switch-button.switch-button-danger input[type="checkbox"]:checked + span {
  background-color: #F45846;
}

/*------------------------------------------------------------------
  [Code]
*/
/* Pretty printing styles. Used with prettify.js. */
.prettyprint {
  font-size: 12px;
  line-height: 1.5;
}

/* Light Theme*/
pre {
  color: #555555;
}

pre .pln {
  color: #777777;
}

pre .str {
  color: #DD1144;
}

pre .kwd {
  color: #445588;
}

pre .com {
  color: #b8b6b1;
}

pre .typ {
  color: #008080;
}

pre .lit {
  color: #DD1144;
}

pre .pun,
pre .opn,
pre .clo {
  color: #949494;
}

pre .tag {
  color: #728EB2;
}

pre .atn {
  color: #D95050;
}

pre .atv {
  color: #929292;
}

pre .dec,
pre .var {
  color: #DD1144;
}

pre .fun {
  color: #445588;
}

/*Dark Theme*/
pre.ma-code-dark {
  background-color: #4d4342;
  color: #FFFFFF;
}

pre.ma-code-dark .pln {
  color: #ffffff;
}

pre.ma-code-dark .str {
  color: #b5db99;
}

pre.ma-code-dark .kwd {
  color: #e9c865;
}

pre.ma-code-dark .com {
  color: #c3b8b7;
}

pre.ma-code-dark .typ {
  color: #FF9787;
}

pre.ma-code-dark .lit {
  color: #b5db99;
}

pre.ma-code-dark .pun,
pre.ma-code-dark .opn,
pre.ma-code-dark .clo {
  color: #ffffff;
}

pre.ma-code-dark .tag {
  color: #e9c865;
}

pre.ma-code-dark .atn {
  color: #FF9787;
}

pre.ma-code-dark .atv {
  color: #b5db99;
}

pre.ma-code-dark .dec,
pre.ma-code-dark .var {
  color: #b5db99;
}

pre.ma-code-dark .fun {
  color: #e9c865;
}

/* Specify class=linenums on a pre to get line numbering */
ol.linenums {
  margin-top: 0;
  margin-bottom: 0;
}

/* IE indents via margin-left */
li.L0,
li.L1,
li.L2,
li.L3,
li.L5,
li.L6,
li.L7,
li.L8 {
  list-style-type: none;
}

/* Alternate shading for lines */
li.L1,
li.L3,
li.L5,
li.L7,
li.L9 {
  background: #eee;
}

/*------------------------------------------------------------------
  [Color swatch]
*/
.color-swatch {
  margin-top: 20px;
  display: inline-block;
  min-width: 230px;
  overflow: hidden;
}

.color-swatch .state-names {
  margin: 0;
  border: 1px solid #eceeef;
}

.color-swatch .state-names > div {
  text-align: center;
  background-color: #FFFFFF;
  line-height: 27px;
  color: #676767;
}

.color-swatch .colors {
  margin: 0;
}

.color-swatch .colors .state {
  min-height: 35px;
}

.color-swatch .colors .normal-state {
  background-color: #878787;
}

.color-swatch .colors .hover-state {
  background-color: #919191;
}

.color-swatch .color-description {
  padding: 0 .6154rem;
  border: 1px solid #eceeef;
  line-height: 27px;
  color: #676767;
}

.color-swatch-primary .state-names {
  border-color: #2cc185;
}

.color-swatch-primary .colors .normal-state {
  background-color: #2cc185;
}

.color-swatch-primary .colors .hover-state {
  background-color: #31d090;
}

.color-swatch-info .state-names {
  border-color: #58C7EF;
}

.color-swatch-info .colors .normal-state {
  background-color: #58C7EF;
}

.color-swatch-info .colors .hover-state {
  background-color: #6bcdf1;
}

.color-swatch-warning .state-names {
  border-color: #FFDC42;
}

.color-swatch-warning .colors .normal-state {
  background-color: #FFDC42;
}

.color-swatch-warning .colors .hover-state {
  background-color: #ffe056;
}

.color-swatch-danger .state-names {
  border-color: #F45846;
}

.color-swatch-danger .colors .normal-state {
  background-color: #F45846;
}

.color-swatch-danger .colors .hover-state {
  background-color: #f56959;
}

.color-swatch-dark .state-names {
  border-color: #323232;
}

.color-swatch-dark .colors .normal-state {
  background-color: #323232;
}

.color-swatch-dark .colors .hover-state {
  background-color: #3c3c3c;
}

/*------------------------------------------------------------------
  [Colors]
*/
.clr-primary {
  background-color: #2cc185;
}

.clr-success {
  background-color: #2cc185;
}

.clr-info {
  background-color: #58C7EF;
}

.clr-warning {
  background-color: #FFDC42;
}

.clr-danger {
  background-color: #F45846;
}

.clr-grey {
  background-color: #e5e5e5;
}

.clr-dark {
  background-color: #545454;
}

.clr-light {
  background-color: #FFFFFF;
}

.clr-black {
  background-color: #000000;
}

/*------------------------------------------------------------------
  [Grid]
*/
.bs-grid-block {
  border: 2px dashed #cbcbcb;
  font-weight: 300;
  font-size: 1.5rem;
  color: #747474;
  display: table;
  table-layout: fixed;
  width: 100%;
  margin-bottom: 3rem;
}

.bs-grid-block .content {
  display: table-cell;
  height: 177px;
  text-align: center;
  vertical-align: middle;
}

.bs-grid-block .content .size {
  display: block;
}

.bs-grid-block .content .device:before {
  font-size: 1.154rem;
  content: 'Large Desktop';
  color: #2cc185;
}

@media (max-width: 1199.98px) {
  .bs-grid-block .content .device:before {
    content: 'Desktop';
    color: #2cc185;
  }
}

@media (max-width: 991.98px) {
  .bs-grid-block .content .device:before {
    content: 'Laptop';
    color: #2cc185;
  }
}

@media (max-width: 767.98px) {
  .bs-grid-block .content .device:before {
    content: 'Tablet';
    color: #2cc185;
  }
}

@media (max-width: 575.98px) {
  .bs-grid-block .content .device:before {
    content: 'Phone';
    color: #2cc185;
  }
}

/*------------------------------------------------------------------
  [Icons]
*/
.icon-container {
  background-color: #FFFFFF;
  padding: 20px;
  cursor: default;
  text-align: center;
}

.icon-container:hover .icon span {
  color: #2cc185;
}

.icon-container:hover .icon-class {
  color: #2cc185;
}

.icon-container .icon {
  display: block;
  height: 60px;
  width: 60px;
  margin: 0 auto;
  padding: 12px;
  text-align: center;
  vertical-align: middle;
}

.icon-container .icon span {
  transition: color 300ms ease-in-out;
  font-size: 2.769rem;
}

.icon-container .icon-class {
  transition: color 300ms ease-in-out;
  font-size: 1.385rem;
  font-weight: 300;
  vertical-align: middle;
  color: #5d5d5d;
}

/*------------------------------------------------------------------
  [cards]
*/
.card {
  background-color: #FFFFFF;
  margin-bottom: 29px;
  box-shadow: 0 1px 0 rgba(0, 0, 0, 0.05);
  border-radius: 1px;
}

.card-body {
  padding: 14px 20px 14px;
}

.card-header {
  font-size: 1.385rem;
  font-weight: 400;
  color: #3d3d3d;
  padding: 24px 0 14px;
  margin: 0 20px;
}

.card-header .tools {
  float: right;
  font-size: 0;
}

.card-header .tools > a {
  display: inline-block;
}

.card-header .tools .icon {
  display: inline-block;
  font-size: 1.615rem;
  line-height: 1.2;
  vertical-align: middle;
  cursor: pointer;
  color: #3d3d3d;
  min-width: 1.615rem;
  text-align: center;
}

.card-header .tools .icon + .icon {
  margin-left: 6px;
}

.card-header .tools .icon:hover {
  color: #0a0a0a;
}

.card-title {
  font-size: 1.385rem;
  font-weight: 400;
  display: block;
  margin: 0;
}

.card-subtitle {
  display: block;
  font-size: 1rem;
  margin-top: 0;
  line-height: 1;
  margin-bottom: 4px;
  color: #878787;
}

.card-header-divider {
  border-bottom: 1px solid #e6e6e6;
  padding-left: 0;
  padding-right: 0;
}

.card-border {
  box-shadow: none;
  border: 1px solid #e6e6e6;
}

.card-transparent {
  background-color: transparent;
  box-shadow: none;
}

.card-border-color {
  border-top: 4px solid #c9c9c9;
  border-radius: 0 0 1px 1px;
}

.card-border-color-primary {
  border-top-color: #2cc185;
}

.card-border-color-danger {
  border-top-color: #F45846;
}

.card-border-color-warning {
  border-top-color: #FFDC42;
}

.card-border-color-info {
  border-top-color: #58C7EF;
}

.card-border-color-success {
  border-top-color: #2cc185;
}

.card-border-color-dark {
  border-top-color: #545454;
}

.card-header-full-width {
  margin: 0;
  padding-left: 20px;
  padding-right: 20px;
}

.card-body-contrast {
  background-color: #e6e6e6;
  border-radius: 0 0 1px 1px;
}

.card-header-color {
  margin: 0;
  padding: 22px 20px 21px;
  border-radius: 1px 1px 0 0;
}

.card-header-color .card-subtitle {
  margin-bottom: 0;
}

.card-header-color-contrast {
  background-color: #e5e5e5;
}

.card-header-color-primary {
  background-color: #2cc185;
  color: #FFFFFF;
}

.card-header-color-primary .card-subtitle {
  color: #FFFFFF;
}

.card-header-color-primary .tools .icon {
  color: #FFFFFF;
}

.card-header-color-primary .tools .icon:hover {
  color: #26a874;
}

.card-header-color-success {
  background-color: #2cc185;
  color: #FFFFFF;
}

.card-header-color-success .card-subtitle {
  color: #FFFFFF;
}

.card-header-color-success .tools .icon {
  color: #FFFFFF;
}

.card-header-color-success .tools .icon:hover {
  color: #26a874;
}

.card-header-color-info {
  background-color: #58C7EF;
  color: #FFFFFF;
}

.card-header-color-info .card-subtitle {
  color: #FFFFFF;
}

.card-header-color-info .tools .icon {
  color: #FFFFFF;
}

.card-header-color-info .tools .icon:hover {
  color: #3cbeec;
}

.card-header-color-danger {
  background-color: #F45846;
  color: #FFFFFF;
}

.card-header-color-danger .card-subtitle {
  color: #FFFFFF;
}

.card-header-color-danger .tools .icon {
  color: #FFFFFF;
}

.card-header-color-danger .tools .icon:hover {
  color: #f23e29;
}

.card-header-color-warning {
  background-color: #FFDC42;
  color: #FFFFFF;
}

.card-header-color-warning .card-subtitle {
  color: #FFFFFF;
}

.card-header-color-warning .tools .icon {
  color: #FFFFFF;
}

.card-header-color-warning .tools .icon:hover {
  color: #ffd623;
}

.card-header-color-dark {
  background-color: #545454;
  color: #FFFFFF;
}

.card-header-color-dark .card-subtitle {
  color: #FFFFFF;
}

.card-header-color-dark .tools .icon {
  color: #FFFFFF;
}

.card-header-color-dark .tools .icon:hover {
  color: #454545;
}

.card-full-color .card-header {
  border-radius: 1px 1px 0 0;
}

.card-full-color .card-header-divider {
  border-color: rgba(0, 0, 0, 0.07);
}

.card-full-color .card-body {
  border-radius: 0 0 1px 1px;
}

.card-full-color-primary {
  background-color: #2cc185;
  color: #FFFFFF;
}

.card-full-color-primary .card-header {
  color: #FFFFFF;
}

.card-full-color-primary .card-subtitle {
  color: #FFFFFF;
}

.card-full-color-primary .tools .icon {
  color: #FFFFFF;
}

.card-full-color-primary .tools .icon:hover {
  color: #26a874;
}

.card-full-color-success {
  background-color: #2cc185;
  color: #FFFFFF;
}

.card-full-color-success .card-header {
  color: #FFFFFF;
}

.card-full-color-success .card-subtitle {
  color: #FFFFFF;
}

.card-full-color-success .tools .icon {
  color: #FFFFFF;
}

.card-full-color-success .tools .icon:hover {
  color: #26a874;
}

.card-full-color-warning {
  background-color: #FFDC42;
  color: #FFFFFF;
}

.card-full-color-warning .card-header {
  color: #FFFFFF;
}

.card-full-color-warning .card-subtitle {
  color: #FFFFFF;
}

.card-full-color-warning .tools .icon {
  color: #FFFFFF;
}

.card-full-color-warning .tools .icon:hover {
  color: #ffd623;
}

.card-full-color-danger {
  background-color: #F45846;
  color: #FFFFFF;
}

.card-full-color-danger .card-header {
  color: #FFFFFF;
}

.card-full-color-danger .card-subtitle {
  color: #FFFFFF;
}

.card-full-color-danger .tools .icon {
  color: #FFFFFF;
}

.card-full-color-danger .tools .icon:hover {
  color: #f23e29;
}

.card-full-color-dark {
  background-color: #545454;
  color: #FFFFFF;
}

.card-full-color-dark .card-header {
  color: #FFFFFF;
}

.card-full-color-dark .card-subtitle {
  color: #FFFFFF;
}

.card-full-color-dark .tools .icon {
  color: #FFFFFF;
}

.card-full-color-dark .tools .icon:hover {
  color: #454545;
}

.card-full-color-grey {
  background-color: #e5e5e5;
}

.card-table .card-body {
  padding: 0;
}

.card-striped-rows .row:nth-of-type(odd) {
  background-color: whitesmoke;
}

.card-divider {
  margin: 2rem 0;
  border-top: 1px solid #f2f2f2;
}

/*------------------------------------------------------------------
  [Pricing tables]
*/
.pricing-tables {
  margin: 30px 0 20px;
}

.pricing-tables > div {
  padding-left: 10px;
  padding-right: 10px;
}

.pricing-table {
  box-shadow: 0 1px 0 rgba(0, 0, 0, 0.05);
  padding: 35px 20px;
  background-color: #FFFFFF;
  text-align: center;
  margin: 0 0 20px;
}

.pricing-table-title {
  font-size: 1.69231rem;
  font-weight: 600;
  text-transform: capitalize;
  margin-bottom: 2.30769rem;
  color: #2cc185;
}

.pricing-table-price {
  color: #525252;
  padding-bottom: 0.615385rem;
}

.pricing-table-price .currency {
  font-size: 33px;
  font-weight: 300;
  display: inline-block;
  margin-top: 4px;
  vertical-align: top;
}

.pricing-table-price .value {
  font-size: 60px;
  line-height: 1;
  letter-spacing: -3px;
}

.pricing-table-frecuency {
  color: #858585;
  padding-bottom: 5px;
  font-size: 1.38462rem;
}

.pricing-table-features {
  margin: 0 0 30px;
  padding: 0;
  list-style: none;
}

.pricing-table-features > li {
  font-size: 14px;
  line-height: 25px;
  font-weight: 300;
  line-height: 33px;
}

.pricing-table-features > li b {
  font-weight: 600;
}

.pricing-table .btn {
  min-width: 120px;
  margin-bottom: 10px;
}

.pricing-table .card-divider {
  border-color: #d9d9d9;
}

.pricing-table-dark {
  background-color: #323232;
}

.pricing-table-dark .pricing-table-price {
  color: #FFFFFF;
}

.pricing-table-dark .card-divider {
  border-color: #424242;
}

.pricing-table-dark .pricing-table-features {
  color: #FFFFFF;
}

.pricing-table-dark .pricing-table-features b {
  color: #2cc185;
}

.pricing-table-primary {
  background-color: #2cc185;
}

.pricing-table-primary .pricing-table-title {
  color: #2e7357;
}

.pricing-table-primary .pricing-table-price {
  color: #FFFFFF;
}

.pricing-table-primary .pricing-table-frecuency {
  color: #2e7357;
}

.pricing-table-primary .card-divider {
  border-color: rgba(0, 0, 0, 0.08);
}

.pricing-table-primary .pricing-table-features {
  color: #FFFFFF;
}

.table-filters-container {
  margin: 0;
  border-bottom: 1px solid #e6e6e6;
}

.table-filters {
  padding: 25px;
  display: flex;
  flex-direction: column;
}

.table-filter-title {
  flex: 0 0 auto;
  margin-bottom: 15px;
  font-size: 1rem;
  text-transform: uppercase;
  font-weight: 600;
  color: #787878;
}

#slider-value {
  font-size: 1.5rem;
  font-weight: 400;
}

.filter-container {
  padding: 5px 0 15px;
}

/*------------------------------------------------------------------
  [Tile widgets]
*/
.widget.widget-tile {
  padding: 24px 20px;
  margin-bottom: 29px;
  display: table;
  table-layout: fixed;
  width: 100%;
}

.widget.widget-tile .data-info {
  display: table-cell;
  text-align: right;
}

.widget.widget-tile .data-info .value {
  font-size: 1.69231rem;
  font-weight: 300;
}

.widget.widget-tile .data-info .indicator {
  font-size: 1.92308rem;
  margin-right: 7px;
  line-height: 24px;
  vertical-align: middle;
}

.widget.widget-tile .data-info .indicator-equal {
  color: #58C7EF;
}

.widget.widget-tile .data-info .indicator-positive {
  color: #2cc185;
}

.widget.widget-tile .data-info .indicator-negative {
  color: #F45846;
}

.widget.widget-tile .data-info .desc {
  font-size: 1.077rem;
  line-height: 1;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.widget.widget-tile .chart {
  width: 85px;
  min-height: 45px;
  padding: 5px 0;
  display: table-cell;
  vertical-align: middle;
}

.widget-tile-wide {
  display: table;
  padding: 24px 30px;
  width: 100%;
}

.widget-tile-wide > div {
  display: table-cell;
  width: 50%;
  vertical-align: middle;
}

.widget-tile-wide .icon {
  display: table-cell;
  font-size: 3.46154rem;
  color: #2cc185;
  line-height: 1;
  vertical-align: middle;
  margin-right: 5px;
}

.widget-tile-wide .data-info {
  display: table-cell;
  vertical-align: middle;
  padding-left: 1rem;
}

.widget-tile-wide .data-info .title {
  font-size: 1.84615rem;
}

.widget-tile-wide .data-info .desc {
  color: gray;
  font-size: 0.923077rem;
}

.widget-tile-wide .tile-value {
  text-align: right;
}

.widget-tile-wide .tile-value > span {
  display: block;
  padding-right: 10px;
  font-size: 2.76923rem;
  font-weight: 300;
}

/*------------------------------------------------------------------
  [Timeline]
*/
.timeline {
  margin: 2.5rem auto 40px;
  padding: 0;
  padding-bottom: 20px;
  max-width: 840px;
  position: relative;
}

@media (max-width: 575.98px) {
  .timeline {
    padding: 0;
  }
}

.timeline:before {
  content: '';
  position: absolute;
  top: 40px;
  bottom: 0;
  width: 2px;
  background: #dbdbdb;
  left: 110px;
  margin-left: -1px;
}

@media (max-width: 575.98px) {
  .timeline:before {
    left: 50%;
    margin-left: -1px;
    top: -35px;
  }
}

.timeline-item {
  position: relative;
  padding-left: 150px;
  list-style: none;
  margin-bottom: 30px;
}

@media (max-width: 575.98px) {
  .timeline-item {
    padding-left: 0;
    margin-top: 60px;
    margin-bottom: 0;
  }
}

.timeline-item:before {
  content: '';
  position: absolute;
  top: 34.5px;
  left: 102px;
  border: 2px solid #2cc185;
  width: 16px;
  height: 16px;
  background-color: #ededed;
  border-radius: 50px;
}

@media (max-width: 575.98px) {
  .timeline-item:before {
    display: none;
  }
}

.timeline-variant .timeline-item {
  padding-left: 0;
  margin: 30px 0 0;
}

@media (max-width: 575.98px) {
  .timeline-variant .timeline-item {
    margin-top: 60px;
  }
}

.timeline-variant .timeline-item.right {
  right: 0;
  padding-left: calc(50% + 45px);
}

@media (max-width: 575.98px) {
  .timeline-variant .timeline-item.right {
    padding: 0;
  }
}

.timeline-variant .timeline-item.left {
  left: 0;
  padding-right: calc(50% + 45px);
}

@media (max-width: 575.98px) {
  .timeline-variant .timeline-item.left {
    padding: 0;
  }
}

.timeline-variant .timeline-item.left .timeline-content:after {
  right: -5px;
  left: inherit;
}

.timeline-variant .timeline-item.left .timeline-content:before {
  right: -5px;
  left: inherit;
}

.timeline-variant .timeline-item.left .timeline-icon {
  left: initial;
  right: -67px;
}

@media (max-width: 575.98px) {
  .timeline-variant .timeline-item.left .timeline-icon {
    left: 50%;
    margin-left: -18px;
    top: -48px;
  }
}

.timeline-variant .timeline-item:before {
  display: none;
}

.timeline-date {
  position: absolute;
  display: block;
  left: 0;
  top: 27px;
  width: 85px;
  text-align: left;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

@media (max-width: 575.98px) {
  .timeline-date {
    width: 100%;
    left: 0;
    top: -40px;
    text-align: center;
  }
  .timeline-date span {
    background-color: #e0e0e0;
    padding: 5px 8px;
    border-radius: 4px;
    line-height: 25px;
  }
}

.timeline-content {
  position: relative;
  background-color: #FFFFFF;
  padding: 25px 30px 25px 28px;
  border-radius: 3px;
  min-height: 85px;
}

.timeline-item-gallery .timeline-content {
  padding-bottom: 15px;
}

@media (max-width: 575.98px) {
  .timeline-content {
    padding: 15px 18px;
  }
}

.timeline-content:before {
  content: "";
  position: absolute;
  width: 10px;
  height: 10px;
  background-color: #fff;
  transform: rotate(45deg);
  top: 37.5px;
  left: -5px;
}

@media (max-width: 575.98px) {
  .timeline-content:before {
    display: none;
  }
}

.timeline-avatar {
  width: 35px;
  height: 35px;
  display: block;
  border-radius: 50px;
  overflow: hidden;
  vertical-align: middle;
  float: left;
  margin-top: .230769rem;
}

.timeline-avatar img {
  width: 100%;
  height: 100%;
}

.timeline-header {
  display: block;
  padding: 0 0 0 46px;
}

@media (max-width: 575.98px) {
  .timeline-header {
    padding-top: 0;
  }
}

.timeline-variant .timeline-header {
  padding-top: 0;
  line-height: 16px;
}

.timeline-autor {
  display: inline-block;
  font-weight: 700;
}

.timeline-activity {
  display: inline-block;
  margin: 0;
}

.timeline-variant .timeline-activity {
  display: inline;
  line-height: 16px;
}

.timeline-time {
  float: right;
  color: #ababab;
}

.timeline-variant .timeline-time {
  float: none;
  display: block;
  line-height: 21px;
}

.timeline-summary {
  color: #8a8a8a;
  margin-top: 4px;
}

.timeline-summary p {
  margin-bottom: 0;
}

.timeline-variant .timeline-summary {
  margin-top: 20px;
}

.timeline-variant .timeline-summary p {
  line-height: 16px;
}

.timeline-gallery {
  margin-top: 12px;
}

.timeline-variant .timeline-gallery {
  margin-top: 20px;
}

.timeline-gallery .gallery-thumbnail {
  display: inline-block;
  max-width: 100px;
  margin-right: 10px;
  margin-bottom: 10px;
}

.timeline-blockquote {
  margin: 10px 0 0 5px;
  padding: 4px 10px;
  border-left: 4px solid #e0e0e0;
  color: #8a8a8a;
}

.timeline-variant .timeline-blockquote {
  margin-top: 20px;
}

.timeline-blockquote p {
  font-size: 13px;
}

.timeline-blockquote footer {
  color: #8a8a8a;
}

/*Load more button*/
@media (max-width: 575.98px) {
  .timeline-loadmore {
    text-align: center;
    padding: 0;
    margin-bottom: 40px;
    margin-top: 40px;
  }
}

.timeline-loadmore .load-more-btn {
  position: absolute;
  left: 65px;
}

@media (max-width: 575.98px) {
  .timeline-loadmore .load-more-btn {
    position: relative;
    left: 0;
  }
}

.timeline-loadmore:before {
  display: none;
}

.timeline-variant .timeline-loadmore {
  text-align: center;
  margin-bottom: 40px;
}

/*------------------------------------------------------------------
  [Detailed items content like gallery & blockquote]
*/
.timeline-item-detailed .timeline-time {
  line-height: 21px;
}

.timeline-item-detailed .timeline-header {
  line-height: 21px;
}

.timeline-variant .timeline-item-detailed .timeline-header {
  border-bottom: 1px solid #f4f4f4;
  padding-bottom: 15px;
}

.timeline-item-detailed .timeline-avatar {
  vertical-align: top;
}

.timeline-variant {
  max-width: none;
  padding-bottom: 0;
}

.timeline-variant:before {
  left: 50%;
  z-index: -1;
  top: 70px;
}

.timeline-month {
  width: 100%;
  text-align: center;
  list-style: none;
  margin: 30px 0 15px;
  padding: 11px 0 12px;
  font-size: 18px;
  background-color: #f4f4f4;
  font-weight: 300;
}

.timeline-month:first-child {
  margin-top: 0;
  padding-bottom: 5px;
}

.timeline-icon {
  position: absolute;
  color: #FFFFFF;
  top: 20px;
  left: -67px;
  width: 44px;
  height: 44px;
  font-size: 23px;
  border: 2px solid #FFFFFF;
  text-align: center;
  background-color: #ededed;
  border-radius: 50%;
}

@media (max-width: 575.98px) {
  .timeline-icon {
    left: 50%;
    margin-left: -18px;
    top: -48px;
    width: 38px;
    height: 38px;
    font-size: 18px;
  }
}

.timeline-icon > .icon {
  line-height: 40px;
  vertical-align: middle;
}

@media (max-width: 575.98px) {
  .timeline-icon > .icon {
    line-height: 34px;
  }
}

.file .timeline-icon {
  background-color: #2cc185;
}

.comment .timeline-icon {
  background-color: #545454;
}

.gallery .timeline-icon {
  background-color: #2cc185;
}

.quote .timeline-icon {
  background-color: #545454;
}

/*------------------------------------------------------------------
  [Elements]
*/
/*------------------------------------------------------------------
  [Widgets]
*/
.widget {
  background-color: #FFFFFF;
  padding: 20px;
  margin-bottom: 29px;
}

.widget.widget-fullwidth {
  padding: 0;
}

.widget.widget-fullwidth .widget-head {
  padding: 20px 20px 10px;
  margin-bottom: 0;
}

@media (max-width: 575.98px) {
  .widget.widget-fullwidth .widget-head {
    padding-left: 15px;
    padding-left: 15px;
  }
}

.widget.widget-fullwidth .widget-chart-container .counter {
  left: 20px;
}

.widget.widget-fullwidth .widget-info {
  margin: 0;
  padding: 45px 10px;
}

.widget.widget-fullwidth .widget-info .counter-block {
  padding: 7px 0;
  text-align: center;
  border-left: 1px solid #e6e6e6;
}

.widget.widget-fullwidth .widget-info .counter-block:first-child {
  border-left-width: 0;
}

.widget.widget-fullwidth .widget-info .counter-block .counter {
  font-size: 1.84615rem;
  display: block;
}

.widget.widget-fullwidth .widget-info .counter-block .title {
  color: #2cc185;
  font-size: 1.077rem;
  font-weight: 300;
}

.widget.widget-fullwidth .chart-table table {
  margin-bottom: 0;
}

.widget.widget-small {
  margin-bottom: 20px;
}

.widget.widget-small.widget-fullwidth > .widget-head {
  padding: 20px 15px 10px;
}

.widget-head {
  margin-bottom: 20px;
  display: block;
}

.widget-head::after {
  display: block;
  clear: both;
  content: "";
}

.widget-head .title {
  display: block;
  font-size: 1.38462rem;
  font-weight: 300;
}

.widget-head .description {
  display: block;
  color: #9e9e9e;
  line-height: 22px;
  font-weight: 300;
}

.widget-head .tools {
  float: right;
  margin-left: 10px;
}

.widget-head .tools .icon {
  display: inline-block;
  font-size: 1.615rem;
  line-height: 1.2;
  vertical-align: middle;
  cursor: pointer;
  color: #3d3d3d;
  min-width: 1.615rem;
  text-align: center;
}

.widget-head .tools .icon + .icon {
  margin-left: 6px;
}

.widget-head .tools .icon:hover {
  color: #0a0a0a;
}

.widget-head .tools .value {
  font-size: 1.84615rem;
  line-height: 22px;
}

.widget-head .tools .dropdown {
  display: inline-block;
}

.widget-head .tools .dropdown-menu {
  right: 0;
  left: auto;
}

.widget-head .button-toolbar {
  float: right;
}

@media (max-width: 575.98px) {
  .widget-head .button-toolbar .btn-group {
    margin-bottom: 5px;
  }
}

.widget-head .button-toolbar .btn-group + * {
  margin-left: 7px;
}

.widget-head .button-toolbar .dropdown-menu {
  right: 0;
  left: auto;
}

.widget-chart-container {
  position: relative;
}

.widget-chart-container .counter {
  min-height: auto;
  top: 0;
  left: 0;
  position: absolute;
  z-index: 2;
}

.widget-chart-container .counter-inline {
  position: relative;
  display: inline-block;
}

.widget-chart-container .counter .value {
  font-size: 1.84615rem;
}

.widget-chart-container .counter .desc {
  text-transform: uppercase;
  font-size: 0.846154rem;
  color: #b1b1b1;
}

.widget-chart-container .counter-big .value {
  display: block;
  font-size: 2.07692rem;
  line-height: 1;
  font-weight: 300;
  margin-bottom: 5px;
}

.widget-chart-container .indicator-equal .icon {
  color: #2cc185;
}

.widget-chart-container .indicator-positive .icon {
  color: #2cc185;
}

.widget-chart-container .indicator-negative .icon {
  color: #F45846;
}

.widget-chart-container .indicator .icon {
  font-size: 1.92308rem;
  margin-right: 7px;
  line-height: 24px;
  vertical-align: middle;
}

.widget-chart-container .indicator .number {
  font-size: 1.53846rem;
  vertical-align: middle;
}

.widget-chart-info::after {
  display: block;
  clear: both;
  content: "";
}

@media (max-width: 575.98px) {
  .widget-chart-info + .widget-counter-group {
    margin-top: 15px;
  }
}

.widget-fullwidth .widget-chart-info {
  padding: 9px 20px 0;
}

.widget-counter-group {
  padding: 0 20px 10px;
}

.widget-counter-group-right {
  text-align: right;
}

.widget-counter-group .counter {
  position: static;
  display: inline-block;
}

.widget-counter-group .counter + .counter {
  margin-left: 20px;
}

/*------------------------------------------------------------------
  [Alerts]
*/
.alert {
  padding: 0;
  position: relative;
  line-height: 25px;
  border-width: 0;
  margin-bottom: 20px;
}

.alert .icon {
  width: 60px;
  font-size: 24px;
  vertical-align: middle;
  text-align: center;
  line-height: 22px;
  margin-right: 20px;
  display: table-cell;
  cursor: default;
  padding-top: 1px;
}

.alert .close {
  position: absolute;
  font-size: 24px;
  top: 0;
  right: 0.9226rem;
  text-shadow: none;
  padding: 1.308rem 0;
  outline: none;
}

.alert .message {
  display: table-cell;
  padding: 1.308rem 2.3072rem 1.308rem 0.384rem;
  border-left-width: 0;
}

.alert .message > p,
.alert .message > ul {
  margin-bottom: 0;
}

.alert .message > p + p {
  margin-top: 5px;
}

.alert-warning {
  background-color: #fcf2c5;
  color: #d6ae00;
}

.alert-warning > .icon {
  color: #d6ae00;
}

.alert-danger {
  background-color: #f8c7c2;
  color: #c31f0c;
}

.alert-danger > .icon {
  color: #c31f0c;
}

.alert-success {
  background-color: #b7e9d5;
}

.alert-primary {
  background-color: #b7e9d5;
}

.alert-theme {
  padding: 0;
  color: #FFFFFF;
}

.alert-theme > .icon {
  color: #FFFFFF;
}

.alert-theme > .message {
  padding: 1.308rem 2.692rem 1.308rem 0.384rem;
}

.alert-theme.alert-success {
  background-color: #2cc185;
}

.alert-theme.alert-info {
  background-color: #58C7EF;
}

.alert-theme.alert-warning {
  background-color: #FFDC42;
}

.alert-theme.alert-danger {
  background-color: #F45846;
}

.alert-theme.alert-primary {
  background-color: #2cc185;
}

.alert-contrast {
  padding: 0;
  position: relative;
  color: #FFFFFF;
}

.alert-contrast > .icon {
  color: #FFFFFF;
}

.alert-contrast > .message {
  padding: 1.308rem 1.538rem;
}

@media (max-width: 575.98px) {
  .alert-contrast > .message {
    padding: 1.308rem 2.692rem 1.308rem 1.538rem;
  }
}

.alert-contrast.alert-success {
  background-color: #2ab97f;
}

.alert-contrast.alert-success > .icon {
  background-color: #2cc185;
}

.alert-contrast.alert-info {
  background-color: #4fc4ee;
}

.alert-contrast.alert-info > .icon {
  background-color: #58C7EF;
}

.alert-contrast.alert-warning {
  background-color: #ffda38;
}

.alert-contrast.alert-warning > .icon {
  background-color: #FFDC42;
}

.alert-contrast.alert-danger {
  background-color: #f34f3c;
}

.alert-contrast.alert-danger > .icon {
  background-color: #F45846;
}

.alert-contrast.alert-primary {
  background-color: #2ab97f;
}

.alert-contrast.alert-primary > .icon {
  background-color: #2cc185;
}

.alert-dismissible .close, .alert-dismissible .close:not(:disabled):not(.disabled):hover, .alert-dismissible .close:not(:disabled):not(.disabled):focus {
  opacity: 1;
}

.alert-dismissible.alert-success .close {
  color: #186a49;
}

.alert-dismissible.alert-success .close:hover {
  color: #10442f;
}

.alert-dismissible.alert-info .close {
  color: #1399c9;
}

.alert-dismissible.alert-info .close:hover {
  color: #0f799f;
}

.alert-dismissible.alert-warning .close {
  color: #d6ae00;
}

.alert-dismissible.alert-warning .close:hover {
  color: #a88900;
}

.alert-dismissible.alert-danger .close {
  color: #c31f0c;
}

.alert-dismissible.alert-danger .close:hover {
  color: #981809;
}

.alert-dismissible.alert-primary .close {
  color: #186a49;
}

.alert-dismissible.alert-primary .close:hover {
  color: #10442f;
}

.alert-dark {
  background-color: #545454;
  color: #FFFFFF;
}

.alert-dark.alert-dismissible > .close {
  color: #7a7a7a;
  opacity: 0.7;
}

.alert-dark.alert-dismissible > .close:hover {
  color: #7a7a7a;
  opacity: 1;
}

.alert-dark.alert-success > .icon {
  color: #2cc185;
}

.alert-dark.alert-info > .icon {
  color: #58C7EF;
}

.alert-dark.alert-warning > .icon {
  color: #FFDC42;
}

.alert-dark.alert-danger > .icon {
  color: #F45846;
}

.alert-dark.alert-primary > .icon {
  color: #2cc185;
}

.alert-icon {
  background-color: #FFFFFF;
  border: 0;
  padding: 0;
  position: relative;
  width: 100%;
  display: table;
  table-layout: fixed;
}

.alert-icon > .icon {
  display: table-cell;
  width: 60px;
  text-align: center;
  vertical-align: middle;
  cursor: default;
}

.alert-icon > .message {
  display: table-cell;
  padding: 1.308rem 1.538rem;
  color: #545454;
  border: 1px solid #d4d4d4;
  border-left-width: 0;
}

@media (max-width: 575.98px) {
  .alert-icon > .message {
    padding: 1.308rem 2.692rem 1.308rem 1.538rem;
  }
}

.alert-icon.alert-dismissible .close {
  color: #545454;
  opacity: 0.5;
}

.alert-icon.alert-dismissible .close:not(:disabled):not(.disabled):hover, .alert-icon.alert-dismissible .close:hover {
  opacity: 0.6;
}

.alert-icon.alert-icon-colored {
  border: 1px solid #d4d4d4;
}

.alert-icon.alert-icon-colored > .icon {
  position: relative;
  background-color: #f8f8f8;
  border-width: 0;
}

.alert-icon.alert-icon-colored > .icon:after {
  position: absolute;
  display: block;
  top: 50%;
  right: -11px;
  margin-top: -11px;
  width: 0;
  height: 0;
  content: '';
  z-index: 2;
  border-left: 11px solid #f8f8f8;
  -moz-transform: scale(0.9999);
  border-bottom: 11px solid transparent;
  border-top: 11px solid transparent;
}

.alert-icon.alert-icon-colored > .message {
  border-width: 0;
}

.alert-icon.alert-icon-colored.alert-success > .icon {
  color: #2cc185;
}

.alert-icon.alert-icon-colored.alert-info > .icon {
  color: #58C7EF;
}

.alert-icon.alert-icon-colored.alert-warning > .icon {
  color: #FFDC42;
}

.alert-icon.alert-icon-colored.alert-danger > .icon {
  color: #F45846;
}

.alert-icon.alert-icon-colored.alert-primary > .icon {
  color: #2cc185;
}

.alert-simple {
  border: 1px solid #d4d4d4;
  background-color: #FFFFFF;
  color: #545454;
}

.alert-simple.alert-dismissible .close {
  color: #545454;
  opacity: 0.5;
}

.alert-simple.alert-dismissible .close:not(:disabled):not(.disabled):hover, .alert-simple.alert-dismissible .close:hover {
  opacity: 0.6;
}

.alert-simple.alert-success > .icon {
  color: #2cc185;
}

.alert-simple.alert-info > .icon {
  color: #58C7EF;
}

.alert-simple.alert-warning > .icon {
  color: #FFDC42;
}

.alert-simple.alert-danger > .icon {
  color: #F45846;
}

.alert-simple.alert-primary > .icon {
  color: #2cc185;
}

.badge {
  display: inline-block;
  border: 1px solid #cccccc;
  vertical-align: middle;
}

.badge:empty {
  display: none;
}

.badge-pill {
  padding: 0.1935rem 0.5rem;
}

.badge-primary {
  background-color: #2cc185;
  color: #FFFFFF;
  border-color: #2cc185;
}

.badge-primary[href]:hover, .badge-primary[href]:focus {
  background-color: #239768;
}

.badge-success {
  background-color: #2cc185;
  color: #FFFFFF;
  border-color: #2cc185;
}

.badge-success[href]:hover, .badge-success[href]:focus {
  background-color: #239768;
}

.badge-info {
  background-color: #58C7EF;
  color: #FFFFFF;
  border-color: #58C7EF;
}

.badge-info[href]:hover, .badge-info[href]:focus {
  background-color: #29b7eb;
}

.badge-warning {
  background-color: #FFDC42;
  color: #FFFFFF;
  border-color: #FFDC42;
}

.badge-warning[href]:hover, .badge-warning[href]:focus {
  background-color: #ffd30f;
}

.badge-danger {
  background-color: #F45846;
  color: #FFFFFF;
  border-color: #F45846;
}

.badge-danger[href]:hover, .badge-danger[href]:focus {
  background-color: #f12d16;
}

.badge-dark {
  background-color: #323232;
  color: #FFFFFF;
  border-color: #323232;
}

.badge-dark[href]:hover, .badge-dark[href]:focus {
  background-color: #191919;
}

.btn-group > .btn.btn-primary, .input-group-btn > .btn.btn-primary, .input-group-prepend > .btn.btn-primary, .input-group-append > .btn.btn-primary {
  border-left-color: #28b07a;
  border-right-color: #28b07a;
}

.btn-group > .btn.btn-primary:first-child, .input-group-btn > .btn.btn-primary:first-child, .input-group-prepend > .btn.btn-primary:first-child, .input-group-append > .btn.btn-primary:first-child {
  border-left-color: transparent;
}

.btn-group > .btn.btn-primary:last-child, .input-group-btn > .btn.btn-primary:last-child, .input-group-prepend > .btn.btn-primary:last-child, .input-group-append > .btn.btn-primary:last-child {
  border-right-color: transparent;
}

.btn-group > .btn.btn-primary.dropdown-toggle-split, .input-group-btn > .btn.btn-primary.dropdown-toggle-split, .input-group-prepend > .btn.btn-primary.dropdown-toggle-split, .input-group-append > .btn.btn-primary.dropdown-toggle-split {
  border-right-width: 0;
}

.btn-group > .btn.btn-success, .input-group-btn > .btn.btn-success, .input-group-prepend > .btn.btn-success, .input-group-append > .btn.btn-success {
  border-left-color: #28b07a;
  border-right-color: #28b07a;
}

.btn-group > .btn.btn-success:first-child, .input-group-btn > .btn.btn-success:first-child, .input-group-prepend > .btn.btn-success:first-child, .input-group-append > .btn.btn-success:first-child {
  border-left-color: transparent;
}

.btn-group > .btn.btn-success:last-child, .input-group-btn > .btn.btn-success:last-child, .input-group-prepend > .btn.btn-success:last-child, .input-group-append > .btn.btn-success:last-child {
  border-right-color: transparent;
}

.btn-group > .btn.btn-success.dropdown-toggle-split, .input-group-btn > .btn.btn-success.dropdown-toggle-split, .input-group-prepend > .btn.btn-success.dropdown-toggle-split, .input-group-append > .btn.btn-success.dropdown-toggle-split {
  border-right-width: 0;
}

.btn-group > .btn.btn-info, .input-group-btn > .btn.btn-info, .input-group-prepend > .btn.btn-info, .input-group-append > .btn.btn-info {
  border-left-color: #45c1ed;
  border-right-color: #45c1ed;
}

.btn-group > .btn.btn-info:first-child, .input-group-btn > .btn.btn-info:first-child, .input-group-prepend > .btn.btn-info:first-child, .input-group-append > .btn.btn-info:first-child {
  border-left-color: transparent;
}

.btn-group > .btn.btn-info:last-child, .input-group-btn > .btn.btn-info:last-child, .input-group-prepend > .btn.btn-info:last-child, .input-group-append > .btn.btn-info:last-child {
  border-right-color: transparent;
}

.btn-group > .btn.btn-info.dropdown-toggle-split, .input-group-btn > .btn.btn-info.dropdown-toggle-split, .input-group-prepend > .btn.btn-info.dropdown-toggle-split, .input-group-append > .btn.btn-info.dropdown-toggle-split {
  border-right-width: 0;
}

.btn-group > .btn.btn-warning, .input-group-btn > .btn.btn-warning, .input-group-prepend > .btn.btn-warning, .input-group-append > .btn.btn-warning {
  border-left-color: #ffd82e;
  border-right-color: #ffd82e;
}

.btn-group > .btn.btn-warning:first-child, .input-group-btn > .btn.btn-warning:first-child, .input-group-prepend > .btn.btn-warning:first-child, .input-group-append > .btn.btn-warning:first-child {
  border-left-color: transparent;
}

.btn-group > .btn.btn-warning:last-child, .input-group-btn > .btn.btn-warning:last-child, .input-group-prepend > .btn.btn-warning:last-child, .input-group-append > .btn.btn-warning:last-child {
  border-right-color: transparent;
}

.btn-group > .btn.btn-warning.dropdown-toggle-split, .input-group-btn > .btn.btn-warning.dropdown-toggle-split, .input-group-prepend > .btn.btn-warning.dropdown-toggle-split, .input-group-append > .btn.btn-warning.dropdown-toggle-split {
  border-right-width: 0;
}

.btn-group > .btn.btn-danger, .input-group-btn > .btn.btn-danger, .input-group-prepend > .btn.btn-danger, .input-group-append > .btn.btn-danger {
  border-left-color: #f34733;
  border-right-color: #f34733;
}

.btn-group > .btn.btn-danger:first-child, .input-group-btn > .btn.btn-danger:first-child, .input-group-prepend > .btn.btn-danger:first-child, .input-group-append > .btn.btn-danger:first-child {
  border-left-color: transparent;
}

.btn-group > .btn.btn-danger:last-child, .input-group-btn > .btn.btn-danger:last-child, .input-group-prepend > .btn.btn-danger:last-child, .input-group-append > .btn.btn-danger:last-child {
  border-right-color: transparent;
}

.btn-group > .btn.btn-danger.dropdown-toggle-split, .input-group-btn > .btn.btn-danger.dropdown-toggle-split, .input-group-prepend > .btn.btn-danger.dropdown-toggle-split, .input-group-append > .btn.btn-danger.dropdown-toggle-split {
  border-right-width: 0;
}

.btn-group > .btn.btn-dark, .input-group-btn > .btn.btn-dark, .input-group-prepend > .btn.btn-dark, .input-group-append > .btn.btn-dark {
  border-left-color: #4a4a4a;
  border-right-color: #4a4a4a;
}

.btn-group > .btn.btn-dark:first-child, .input-group-btn > .btn.btn-dark:first-child, .input-group-prepend > .btn.btn-dark:first-child, .input-group-append > .btn.btn-dark:first-child {
  border-left-color: transparent;
}

.btn-group > .btn.btn-dark:last-child, .input-group-btn > .btn.btn-dark:last-child, .input-group-prepend > .btn.btn-dark:last-child, .input-group-append > .btn.btn-dark:last-child {
  border-right-color: transparent;
}

.btn-group > .btn.btn-dark.dropdown-toggle-split, .input-group-btn > .btn.btn-dark.dropdown-toggle-split, .input-group-prepend > .btn.btn-dark.dropdown-toggle-split, .input-group-append > .btn.btn-dark.dropdown-toggle-split {
  border-right-width: 0;
}

.btn-group:first-child, .input-group-btn:first-child, .input-group-prepend:first-child, .input-group-append:first-child {
  border-right-color: transparent;
}

.btn-group:last-child, .input-group-btn:last-child, .input-group-prepend:last-child, .input-group-append:last-child {
  border-right-color: transparent;
}

.btn-group.open > .btn + .btn-primary,
.btn-group.open > .btn + .btn-success,
.btn-group.open > .btn + .btn-warning,
.btn-group.open > .btn + .btn-danger, .input-group-btn.open > .btn + .btn-primary,
.input-group-btn.open > .btn + .btn-success,
.input-group-btn.open > .btn + .btn-warning,
.input-group-btn.open > .btn + .btn-danger, .input-group-prepend.open > .btn + .btn-primary,
.input-group-prepend.open > .btn + .btn-success,
.input-group-prepend.open > .btn + .btn-warning,
.input-group-prepend.open > .btn + .btn-danger, .input-group-append.open > .btn + .btn-primary,
.input-group-append.open > .btn + .btn-success,
.input-group-append.open > .btn + .btn-warning,
.input-group-append.open > .btn + .btn-danger {
  border-left-color: rgba(0, 0, 0, 0.15);
}

.btn-group-vertical > .btn.btn-primary {
  border-top-color: #28b07a;
  border-bottom-color: #28b07a;
}

.btn-group-vertical > .btn.btn-primary:first-child {
  border-top-color: transparent;
}

.btn-group-vertical > .btn.btn-primary:last-child {
  border-bottom-color: transparent;
}

.btn-group-vertical > .btn.btn-success {
  border-top-color: #28b07a;
  border-bottom-color: #28b07a;
}

.btn-group-vertical > .btn.btn-success:first-child {
  border-top-color: transparent;
}

.btn-group-vertical > .btn.btn-success:last-child {
  border-bottom-color: transparent;
}

.btn-group-vertical > .btn.btn-info {
  border-top-color: #45c1ed;
  border-bottom-color: #45c1ed;
}

.btn-group-vertical > .btn.btn-info:first-child {
  border-top-color: transparent;
}

.btn-group-vertical > .btn.btn-info:last-child {
  border-bottom-color: transparent;
}

.btn-group-vertical > .btn.btn-warning {
  border-top-color: #ffd82e;
  border-bottom-color: #ffd82e;
}

.btn-group-vertical > .btn.btn-warning:first-child {
  border-top-color: transparent;
}

.btn-group-vertical > .btn.btn-warning:last-child {
  border-bottom-color: transparent;
}

.btn-group-vertical > .btn.btn-danger {
  border-top-color: #f34733;
  border-bottom-color: #f34733;
}

.btn-group-vertical > .btn.btn-danger:first-child {
  border-top-color: transparent;
}

.btn-group-vertical > .btn.btn-danger:last-child {
  border-bottom-color: transparent;
}

.btn-group-vertical > .btn.btn-dark {
  border-top-color: #4a4a4a;
  border-bottom-color: #4a4a4a;
}

.btn-group-vertical > .btn.btn-dark:first-child {
  border-top-color: transparent;
}

.btn-group-vertical > .btn.btn-dark:last-child {
  border-bottom-color: transparent;
}

.btn + .dropdown-toggle-split {
  z-index: 3;
}

.btn + .dropdown-toggle-split > span {
  line-height: 0.2;
  font-size: 1.538rem;
  display: inline-block;
  vertical-align: middle;
  width: 1rem;
  margin-left: -7px;
}

.btn-group-justified {
  display: table;
  width: 100%;
  table-layout: fixed;
  border-collapse: separate;
}

.btn-group-justified .btn,
.btn-group-justified .btn-group {
  float: none;
  display: table-cell;
  width: 1%;
}

.btn-group-justified .btn .btn,
.btn-group-justified .btn-group .btn {
  width: 100%;
}

.btn-group-justified .btn .dropdown-menu,
.btn-group-justified .btn-group .dropdown-menu {
  left: auto;
}

.btn-group-justified > .btn {
  border-right-width: 0;
}

.btn-group-justified > .btn:last-child {
  border-right-width: 1px;
}

.btn:active:focus, .btn.active:focus, .btn:focus {
  outline: none;
}

.btn.dropdown-toggle:after {
  display: none;
}

.btn.btn-outline-primary {
  background-image: none;
  background-color: transparent;
  border-color: #2cc185;
}

.btn.btn-outline-primary:hover {
  color: #FFFFFF;
  background-color: #2cc185;
  border-color: #2cc185;
}

.btn.btn-outline-primary:focus, .btn.btn-outline-primary.focus {
  box-shadow: none;
}

.btn.btn-outline-primary.disabled, .btn.btn-outline-primary:disabled {
  color: #2cc185;
  background-color: transparent;
}

.btn.btn-outline-primary:active, .btn.btn-outline-primary.active,
.show > .btn.btn-outline-primary.dropdown-toggle {
  color: #FFFFFF;
  background-color: #2cc185;
  border-color: #2cc185;
}

.btn.btn-outline-light {
  background-image: none;
  background-color: transparent;
  border-color: #FFFFFF;
}

.btn.btn-outline-light:hover {
  color: #545454;
  background-color: #FFFFFF;
  border-color: #FFFFFF;
}

.btn.btn-outline-light:focus, .btn.btn-outline-light.focus {
  box-shadow: none;
}

.btn.btn-outline-light.disabled, .btn.btn-outline-light:disabled {
  color: #FFFFFF;
  background-color: transparent;
}

.btn.btn-outline-light:active, .btn.btn-outline-light.active,
.show > .btn.btn-outline-light.dropdown-toggle {
  color: #545454;
  background-color: #FFFFFF;
  border-color: #FFFFFF;
}

.btn.btn-outline-info {
  background-image: none;
  background-color: transparent;
  border-color: #58C7EF;
}

.btn.btn-outline-info:hover {
  color: #FFFFFF;
  background-color: #58C7EF;
  border-color: #58C7EF;
}

.btn.btn-outline-info:focus, .btn.btn-outline-info.focus {
  box-shadow: none;
}

.btn.btn-outline-info.disabled, .btn.btn-outline-info:disabled {
  color: #58C7EF;
  background-color: transparent;
}

.btn.btn-outline-info:active, .btn.btn-outline-info.active,
.show > .btn.btn-outline-info.dropdown-toggle {
  color: #FFFFFF;
  background-color: #58C7EF;
  border-color: #58C7EF;
}

.btn.btn-outline-success {
  background-image: none;
  background-color: transparent;
  border-color: #2cc185;
}

.btn.btn-outline-success:hover {
  color: #FFFFFF;
  background-color: #2cc185;
  border-color: #2cc185;
}

.btn.btn-outline-success:focus, .btn.btn-outline-success.focus {
  box-shadow: none;
}

.btn.btn-outline-success.disabled, .btn.btn-outline-success:disabled {
  color: #2cc185;
  background-color: transparent;
}

.btn.btn-outline-success:active, .btn.btn-outline-success.active,
.show > .btn.btn-outline-success.dropdown-toggle {
  color: #FFFFFF;
  background-color: #2cc185;
  border-color: #2cc185;
}

.btn.btn-outline-warning {
  background-image: none;
  background-color: transparent;
  border-color: #FFDC42;
}

.btn.btn-outline-warning:hover {
  color: #FFFFFF;
  background-color: #FFDC42;
  border-color: #FFDC42;
}

.btn.btn-outline-warning:focus, .btn.btn-outline-warning.focus {
  box-shadow: none;
}

.btn.btn-outline-warning.disabled, .btn.btn-outline-warning:disabled {
  color: #FFDC42;
  background-color: transparent;
}

.btn.btn-outline-warning:active, .btn.btn-outline-warning.active,
.show > .btn.btn-outline-warning.dropdown-toggle {
  color: #FFFFFF;
  background-color: #FFDC42;
  border-color: #FFDC42;
}

.btn.btn-outline-danger {
  background-image: none;
  background-color: transparent;
  border-color: #F45846;
}

.btn.btn-outline-danger:hover {
  color: #FFFFFF;
  background-color: #F45846;
  border-color: #F45846;
}

.btn.btn-outline-danger:focus, .btn.btn-outline-danger.focus {
  box-shadow: none;
}

.btn.btn-outline-danger.disabled, .btn.btn-outline-danger:disabled {
  color: #F45846;
  background-color: transparent;
}

.btn.btn-outline-danger:active, .btn.btn-outline-danger.active,
.show > .btn.btn-outline-danger.dropdown-toggle {
  color: #FFFFFF;
  background-color: #F45846;
  border-color: #F45846;
}

.btn .icon {
  position: relative;
  top: -1px;
  font-size: 1.154rem;
  line-height: 0.6;
  vertical-align: middle;
}

.btn .icon.icon-left {
  margin-right: 1px;
}

.btn .icon.icon-right {
  margin-left: 1px;
}

.btn.btn-lg .icon, .btn-group-lg > .btn .icon {
  font-size: 1.231rem;
}

.btn.btn-sm .icon, .btn-group-sm > .btn .icon {
  font-size: 1.077rem;
}

.btn.btn-xs .icon {
  font-size: 1rem;
}

.btn .icon-dropdown {
  font-size: 1.538rem;
  line-height: 0.3;
  vertical-align: middle;
  display: inline-block;
  width: 1rem;
}

.btn-dark {
  color: #FFFFFF;
  background-color: #545454;
  border-color: #545454;
}

.btn-dark:hover {
  color: #FFFFFF;
  background-color: #414141;
  border-color: #3b3b3b;
}

.btn-dark:focus, .btn-dark.focus {
  box-shadow: 0 0 0 0 rgba(110, 110, 110, 0.5);
}

.btn-dark.disabled, .btn-dark:disabled {
  color: #FFFFFF;
  background-color: #545454;
  border-color: #545454;
}

.btn-dark:not(:disabled):not(.disabled):active, .btn-dark:not(:disabled):not(.disabled).active,
.show > .btn-dark.dropdown-toggle {
  color: #FFFFFF;
  background-color: #3b3b3b;
  border-color: #343434;
}

.btn-dark:not(:disabled):not(.disabled):active:focus, .btn-dark:not(:disabled):not(.disabled).active:focus,
.show > .btn-dark.dropdown-toggle:focus {
  box-shadow: 0 0 0 0 rgba(110, 110, 110, 0.5);
}

.btn-primary {
  color: #FFFFFF;
  background-color: #2cc185;
  border-color: #2cc185;
}

.btn-primary:hover, .btn-primary.hover {
  color: #FFFFFF;
  border-color: transparent;
  background-color: #31d090;
}

.show > .btn-primary.dropdown-toggle:focus, .show > .btn-primary.dropdown-toggle.focus {
  color: #FFFFFF;
  background-color: #2cc185;
  box-shadow: none;
  border-color: transparent;
}

.show > .btn-primary.dropdown-toggle:focus:hover, .show > .btn-primary.dropdown-toggle:focus.hover, .show > .btn-primary.dropdown-toggle.focus:hover, .show > .btn-primary.dropdown-toggle.focus.hover {
  border-color: transparent;
  background-color: #31d090;
}

.btn-primary.disabled, .btn-primary:disabled {
  background-color: #2cc185;
  border-color: #2cc185;
  color: #FFFFFF;
}

.btn-primary:not([disabled]):not(.disabled):active, .btn-primary:not([disabled]):not(.disabled).active,
.show > .btn-primary.dropdown-toggle {
  color: #FFFFFF;
  border-color: transparent;
  background-color: #27ac77;
}

.btn-primary:not([disabled]):not(.disabled):active:hover, .btn-primary:not([disabled]):not(.disabled):active:focus, .btn-primary:not([disabled]):not(.disabled):active.focus, .btn-primary:not([disabled]):not(.disabled).active:hover, .btn-primary:not([disabled]):not(.disabled).active:focus, .btn-primary:not([disabled]):not(.disabled).active.focus,
.show > .btn-primary.dropdown-toggle:hover,
.show > .btn-primary.dropdown-toggle:focus,
.show > .btn-primary.dropdown-toggle.focus {
  color: #FFFFFF;
  border-color: transparent;
  background-color: #27ac77;
}

.btn-secondary, .note-btn-group .btn-default {
  color: #545454;
  background-color: #FFFFFF;
  border-color: #cccccc;
}

.btn-secondary:hover, .note-btn-group .btn-default:hover, .btn-secondary.hover, .note-btn-group .hover.btn-default {
  color: #545454;
  border-color: #cccccc;
  background-color: whitesmoke;
}


.show > .btn-secondary.dropdown-toggle:focus,
.note-btn-group .show > .dropdown-toggle.btn-default:focus, .show > .btn-secondary.dropdown-toggle.focus, .note-btn-group .show > .dropdown-toggle.focus.btn-default {
  color: #545454;
  background-color: #FFFFFF;
  box-shadow: none;
  border-color: #cccccc;
}

.show > .btn-secondary.dropdown-toggle:focus:hover, .note-btn-group .show > .dropdown-toggle.btn-default:focus:hover, .show > .btn-secondary.dropdown-toggle:focus.hover, .note-btn-group .show > .dropdown-toggle.btn-default:focus.hover, .show > .btn-secondary.dropdown-toggle.focus:hover, .note-btn-group .show > .dropdown-toggle.focus.btn-default:hover, .show > .btn-secondary.dropdown-toggle.focus.hover, .note-btn-group .show > .dropdown-toggle.focus.hover.btn-default {
  border-color: #cccccc;
  background-color: whitesmoke;
}

.btn-secondary.disabled, .note-btn-group .disabled.btn-default, .btn-secondary:disabled, .note-btn-group .btn-default:disabled {
  background-color: #FFFFFF;
  border-color: #cccccc;
  color: #545454;
}

.btn-secondary:not([disabled]):not(.disabled):active, .note-btn-group .btn-default:not([disabled]):not(.disabled):active, .btn-secondary:not([disabled]):not(.disabled).active, .note-btn-group .btn-default:not([disabled]):not(.disabled).active,
.show > .btn-secondary.dropdown-toggle,
.note-btn-group .show > .dropdown-toggle.btn-default {
  color: #545454;
  border-color: #cccccc;
  background-color: #e6e6e6;
}

.btn-secondary:not([disabled]):not(.disabled):active:hover, .note-btn-group .btn-default:not([disabled]):not(.disabled):active:hover, .btn-secondary:not([disabled]):not(.disabled):active:focus, .note-btn-group .btn-default:not([disabled]):not(.disabled):active:focus, .btn-secondary:not([disabled]):not(.disabled):active.focus, .note-btn-group .btn-default:not([disabled]):not(.disabled):active.focus, .btn-secondary:not([disabled]):not(.disabled).active:hover, .note-btn-group .btn-default:not([disabled]):not(.disabled).active:hover, .btn-secondary:not([disabled]):not(.disabled).active:focus, .note-btn-group .btn-default:not([disabled]):not(.disabled).active:focus, .btn-secondary:not([disabled]):not(.disabled).active.focus, .note-btn-group .btn-default:not([disabled]):not(.disabled).active.focus,
.show > .btn-secondary.dropdown-toggle:hover,
.note-btn-group .show > .dropdown-toggle.btn-default:hover,
.show > .btn-secondary.dropdown-toggle:focus,
.note-btn-group .show > .dropdown-toggle.btn-default:focus, .show > .btn-secondary.dropdown-toggle.focus, .note-btn-group .show > .dropdown-toggle.focus.btn-default {
  color: #545454;
  border-color: #cccccc;
  background-color: #e6e6e6;
}

.btn-info {
  color: #FFFFFF;
  background-color: #58C7EF;
  border-color: #58C7EF;
}

.btn-info:hover, .btn-info.hover {
  color: #FFFFFF;
  border-color: transparent;
  background-color: #6bcdf1;
}

.show > .btn-info.dropdown-toggle:focus, .show > .btn-info.dropdown-toggle.focus {
  color: #FFFFFF;
  background-color: #58C7EF;
  box-shadow: none;
  border-color: transparent;
}

.show > .btn-info.dropdown-toggle:focus:hover, .show > .btn-info.dropdown-toggle:focus.hover, .show > .btn-info.dropdown-toggle.focus:hover, .show > .btn-info.dropdown-toggle.focus.hover {
  border-color: transparent;
  background-color: #6bcdf1;
}

.btn-info.disabled, .btn-info:disabled {
  background-color: #58C7EF;
  border-color: #58C7EF;
  color: #FFFFFF;
}

.btn-info:not([disabled]):not(.disabled):active, .btn-info:not([disabled]):not(.disabled).active,
.show > .btn-info.dropdown-toggle {
  color: #FFFFFF;
  border-color: transparent;
  background-color: #41bfed;
}

.btn-info:not([disabled]):not(.disabled):active:hover, .btn-info:not([disabled]):not(.disabled):active:focus, .btn-info:not([disabled]):not(.disabled):active.focus, .btn-info:not([disabled]):not(.disabled).active:hover, .btn-info:not([disabled]):not(.disabled).active:focus, .btn-info:not([disabled]):not(.disabled).active.focus,
.show > .btn-info.dropdown-toggle:hover,
.show > .btn-info.dropdown-toggle:focus,
.show > .btn-info.dropdown-toggle.focus {
  color: #FFFFFF;
  border-color: transparent;
  background-color: #41bfed;
}

.btn-success {
  color: #FFFFFF;
  background-color: #2cc185;
  border-color: #2cc185;
}

.btn-success:hover, .btn-success.hover {
  color: #FFFFFF;
  border-color: transparent;
  background-color: #31d090;
}

.show > .btn-success.dropdown-toggle:focus, .show > .btn-success.dropdown-toggle.focus {
  color: #FFFFFF;
  background-color: #2cc185;
  box-shadow: none;
  border-color: transparent;
}

.show > .btn-success.dropdown-toggle:focus:hover, .show > .btn-success.dropdown-toggle:focus.hover, .show > .btn-success.dropdown-toggle.focus:hover, .show > .btn-success.dropdown-toggle.focus.hover {
  border-color: transparent;
  background-color: #31d090;
}

.btn-success.disabled, .btn-success:disabled {
  background-color: #2cc185;
  border-color: #2cc185;
  color: #FFFFFF;
}

.btn-success:not([disabled]):not(.disabled):active, .btn-success:not([disabled]):not(.disabled).active,
.show > .btn-success.dropdown-toggle {
  color: #FFFFFF;
  border-color: transparent;
  background-color: #27ac77;
}

.btn-success:not([disabled]):not(.disabled):active:hover, .btn-success:not([disabled]):not(.disabled):active:focus, .btn-success:not([disabled]):not(.disabled):active.focus, .btn-success:not([disabled]):not(.disabled).active:hover, .btn-success:not([disabled]):not(.disabled).active:focus, .btn-success:not([disabled]):not(.disabled).active.focus,
.show > .btn-success.dropdown-toggle:hover,
.show > .btn-success.dropdown-toggle:focus,
.show > .btn-success.dropdown-toggle.focus {
  color: #FFFFFF;
  border-color: transparent;
  background-color: #27ac77;
}

.btn-warning {
  color: #FFFFFF;
  background-color: #FFDC42;
  border-color: #FFDC42;
}

.btn-warning:hover, .btn-warning.hover {
  color: #FFFFFF;
  border-color: transparent;
  background-color: #ffe056;
}

.show > .btn-warning.dropdown-toggle:focus, .show > .btn-warning.dropdown-toggle.focus {
  color: #FFFFFF;
  background-color: #FFDC42;
  box-shadow: none;
  border-color: transparent;
}

.show > .btn-warning.dropdown-toggle:focus:hover, .show > .btn-warning.dropdown-toggle:focus.hover, .show > .btn-warning.dropdown-toggle.focus:hover, .show > .btn-warning.dropdown-toggle.focus.hover {
  border-color: transparent;
  background-color: #ffe056;
}

.btn-warning.disabled, .btn-warning:disabled {
  background-color: #FFDC42;
  border-color: #FFDC42;
  color: #FFFFFF;
}

.btn-warning:not([disabled]):not(.disabled):active, .btn-warning:not([disabled]):not(.disabled).active,
.show > .btn-warning.dropdown-toggle {
  color: #FFFFFF;
  border-color: transparent;
  background-color: #ffd729;
}

.btn-warning:not([disabled]):not(.disabled):active:hover, .btn-warning:not([disabled]):not(.disabled):active:focus, .btn-warning:not([disabled]):not(.disabled):active.focus, .btn-warning:not([disabled]):not(.disabled).active:hover, .btn-warning:not([disabled]):not(.disabled).active:focus, .btn-warning:not([disabled]):not(.disabled).active.focus,
.show > .btn-warning.dropdown-toggle:hover,
.show > .btn-warning.dropdown-toggle:focus,
.show > .btn-warning.dropdown-toggle.focus {
  color: #FFFFFF;
  border-color: transparent;
  background-color: #ffd729;
}

.btn-danger {
  color: #FFFFFF;
  background-color: #F45846;
  border-color: #F45846;
}

.btn-danger:hover, .btn-danger.hover {
  color: #FFFFFF;
  border-color: transparent;
  background-color: #f56959;
}

.show > .btn-danger.dropdown-toggle:focus, .show > .btn-danger.dropdown-toggle.focus {
  color: #FFFFFF;
  background-color: #F45846;
  box-shadow: none;
  border-color: transparent;
}

.show > .btn-danger.dropdown-toggle:focus:hover, .show > .btn-danger.dropdown-toggle:focus.hover, .show > .btn-danger.dropdown-toggle.focus:hover, .show > .btn-danger.dropdown-toggle.focus.hover {
  border-color: transparent;
  background-color: #f56959;
}

.btn-danger.disabled, .btn-danger:disabled {
  background-color: #F45846;
  border-color: #F45846;
  color: #FFFFFF;
}

.btn-danger:not([disabled]):not(.disabled):active, .btn-danger:not([disabled]):not(.disabled).active,
.show > .btn-danger.dropdown-toggle {
  color: #FFFFFF;
  border-color: transparent;
  background-color: #f3422e;
}

.btn-danger:not([disabled]):not(.disabled):active:hover, .btn-danger:not([disabled]):not(.disabled):active:focus, .btn-danger:not([disabled]):not(.disabled):active.focus, .btn-danger:not([disabled]):not(.disabled).active:hover, .btn-danger:not([disabled]):not(.disabled).active:focus, .btn-danger:not([disabled]):not(.disabled).active.focus,
.show > .btn-danger.dropdown-toggle:hover,
.show > .btn-danger.dropdown-toggle:focus,
.show > .btn-danger.dropdown-toggle.focus {
  color: #FFFFFF;
  border-color: transparent;
  background-color: #f3422e;
}

.btn-dark {
  color: #FFFFFF;
  background-color: #545454;
  border-color: #545454;
}

.btn-dark:hover, .btn-dark.hover {
  color: #FFFFFF;
  border-color: transparent;
  background-color: #5e5e5e;
}

.show > .btn-dark.dropdown-toggle:focus, .show > .btn-dark.dropdown-toggle.focus {
  color: #FFFFFF;
  background-color: #545454;
  box-shadow: none;
  border-color: transparent;
}

.show > .btn-dark.dropdown-toggle:focus:hover, .show > .btn-dark.dropdown-toggle:focus.hover, .show > .btn-dark.dropdown-toggle.focus:hover, .show > .btn-dark.dropdown-toggle.focus.hover {
  border-color: transparent;
  background-color: #5e5e5e;
}

.btn-dark.disabled, .btn-dark:disabled {
  background-color: #545454;
  border-color: #545454;
  color: #FFFFFF;
}

.btn-dark:not([disabled]):not(.disabled):active, .btn-dark:not([disabled]):not(.disabled).active,
.show > .btn-dark.dropdown-toggle {
  color: #FFFFFF;
  border-color: transparent;
  background-color: #474747;
}

.btn-dark:not([disabled]):not(.disabled):active:hover, .btn-dark:not([disabled]):not(.disabled):active:focus, .btn-dark:not([disabled]):not(.disabled):active.focus, .btn-dark:not([disabled]):not(.disabled).active:hover, .btn-dark:not([disabled]):not(.disabled).active:focus, .btn-dark:not([disabled]):not(.disabled).active.focus,
.show > .btn-dark.dropdown-toggle:hover,
.show > .btn-dark.dropdown-toggle:focus,
.show > .btn-dark.dropdown-toggle.focus {
  color: #FFFFFF;
  border-color: transparent;
  background-color: #474747;
}

.btn-dark:not([disabled]):not(.disabled).active,
.open > .btn-dark.dropdown-toggle {
  color: #2cc185;
}

.btn-dark:not([disabled]):not(.disabled).active:hover, .btn-dark:not([disabled]):not(.disabled).active:focus, .btn-dark:not([disabled]):not(.disabled).active.focus,
.open > .btn-dark.dropdown-toggle:hover,
.open > .btn-dark.dropdown-toggle:focus,
.open > .btn-dark.dropdown-toggle.focus {
  color: #2cc185;
}

.btn-xs {
  padding: 0.6154rem 0.6923rem;
  font-size: 0.9231rem;
  line-height: 1;
  border-radius: 0;
}

.btn-sm, .btn-group-sm > .btn {
  font-size: 1rem;
}

.btn-lg, .btn-group-lg > .btn {
  font-size: 1.077rem;
}

.btn-rounded {
  border-radius: 1.81rem;
}

.btn-rounded.btn-xs {
  border-radius: 1.5385rem;
}

.btn-rounded.btn-sm, .btn-group-sm > .btn-rounded.btn {
  border-radius: 1.6923rem;
}

.btn-rounded.btn-lg, .btn-group-lg > .btn-rounded.btn {
  border-radius: 2.0001rem;
}

.btn-big {
  padding: .7rem 1rem;
  text-align: center;
  min-width: 70px;
}

.btn-big > .icon {
  display: block;
  font-size: 2rem;
  line-height: 1;
  margin-bottom: .3rem;
}

.btn-social {
  min-width: 2.9rem;
}

.btn-social .icon {
  font-size: 1.1rem;
}

.btn-social.btn-xs {
  min-width: 2.3rem;
}

.btn-facebook .icon {
  color: #5d82d1;
}

.btn-facebook:active .icon, .btn-facebook.active .icon,
.open > .btn-facebook.dropdown-toggle .icon {
  color: #5d82d1;
}

.btn-facebook:active:hover .icon, .btn-facebook:active:focus .icon, .btn-facebook:active.focus .icon, .btn-facebook.active:hover .icon, .btn-facebook.active:focus .icon, .btn-facebook.active.focus .icon,
.open > .btn-facebook.dropdown-toggle:hover .icon,
.open > .btn-facebook.dropdown-toggle:focus .icon,
.open > .btn-facebook.dropdown-toggle.focus .icon {
  color: #5d82d1;
}

.btn-twitter .icon {
  color: #50bff5;
}

.btn-twitter:active .icon, .btn-twitter.active .icon,
.open > .btn-twitter.dropdown-toggle .icon {
  color: #50bff5;
}

.btn-twitter:active:hover .icon, .btn-twitter:active:focus .icon, .btn-twitter:active.focus .icon, .btn-twitter.active:hover .icon, .btn-twitter.active:focus .icon, .btn-twitter.active.focus .icon,
.open > .btn-twitter.dropdown-toggle:hover .icon,
.open > .btn-twitter.dropdown-toggle:focus .icon,
.open > .btn-twitter.dropdown-toggle.focus .icon {
  color: #50bff5;
}

.btn-google-plus .icon {
  color: #eb5e4c;
}

.btn-google-plus:active .icon, .btn-google-plus.active .icon,
.open > .btn-google-plus.dropdown-toggle .icon {
  color: #eb5e4c;
}

.btn-google-plus:active:hover .icon, .btn-google-plus:active:focus .icon, .btn-google-plus:active.focus .icon, .btn-google-plus.active:hover .icon, .btn-google-plus.active:focus .icon, .btn-google-plus.active.focus .icon,
.open > .btn-google-plus.dropdown-toggle:hover .icon,
.open > .btn-google-plus.dropdown-toggle:focus .icon,
.open > .btn-google-plus.dropdown-toggle.focus .icon {
  color: #eb5e4c;
}

.btn-dribbble .icon {
  color: #f7659c;
}

.btn-dribbble:active .icon, .btn-dribbble.active .icon,
.open > .btn-dribbble.dropdown-toggle .icon {
  color: #f7659c;
}

.btn-dribbble:active:hover .icon, .btn-dribbble:active:focus .icon, .btn-dribbble:active.focus .icon, .btn-dribbble.active:hover .icon, .btn-dribbble.active:focus .icon, .btn-dribbble.active.focus .icon,
.open > .btn-dribbble.dropdown-toggle:hover .icon,
.open > .btn-dribbble.dropdown-toggle:focus .icon,
.open > .btn-dribbble.dropdown-toggle.focus .icon {
  color: #f7659c;
}

.btn-flickr .icon {
  color: #0063dc;
}

.btn-flickr:active .icon, .btn-flickr.active .icon,
.open > .btn-flickr.dropdown-toggle .icon {
  color: #0063dc;
}

.btn-flickr:active:hover .icon, .btn-flickr:active:focus .icon, .btn-flickr:active.focus .icon, .btn-flickr.active:hover .icon, .btn-flickr.active:focus .icon, .btn-flickr.active.focus .icon,
.open > .btn-flickr.dropdown-toggle:hover .icon,
.open > .btn-flickr.dropdown-toggle:focus .icon,
.open > .btn-flickr.dropdown-toggle.focus .icon {
  color: #0063dc;
}

.btn-linkedin .icon {
  color: #238cc8;
}

.btn-linkedin:active .icon, .btn-linkedin.active .icon,
.open > .btn-linkedin.dropdown-toggle .icon {
  color: #238cc8;
}

.btn-linkedin:active:hover .icon, .btn-linkedin:active:focus .icon, .btn-linkedin:active.focus .icon, .btn-linkedin.active:hover .icon, .btn-linkedin.active:focus .icon, .btn-linkedin.active.focus .icon,
.open > .btn-linkedin.dropdown-toggle:hover .icon,
.open > .btn-linkedin.dropdown-toggle:focus .icon,
.open > .btn-linkedin.dropdown-toggle.focus .icon {
  color: #238cc8;
}

.btn-youtube .icon {
  color: #ef4e41;
}

.btn-youtube:active .icon, .btn-youtube.active .icon,
.open > .btn-youtube.dropdown-toggle .icon {
  color: #ef4e41;
}

.btn-youtube:active:hover .icon, .btn-youtube:active:focus .icon, .btn-youtube:active.focus .icon, .btn-youtube.active:hover .icon, .btn-youtube.active:focus .icon, .btn-youtube.active.focus .icon,
.open > .btn-youtube.dropdown-toggle:hover .icon,
.open > .btn-youtube.dropdown-toggle:focus .icon,
.open > .btn-youtube.dropdown-toggle.focus .icon {
  color: #ef4e41;
}

.btn-pinterest .icon {
  color: #e13138;
}

.btn-pinterest:active .icon, .btn-pinterest.active .icon,
.open > .btn-pinterest.dropdown-toggle .icon {
  color: #e13138;
}

.btn-pinterest:active:hover .icon, .btn-pinterest:active:focus .icon, .btn-pinterest:active.focus .icon, .btn-pinterest.active:hover .icon, .btn-pinterest.active:focus .icon, .btn-pinterest.active.focus .icon,
.open > .btn-pinterest.dropdown-toggle:hover .icon,
.open > .btn-pinterest.dropdown-toggle:focus .icon,
.open > .btn-pinterest.dropdown-toggle.focus .icon {
  color: #e13138;
}

.btn-github .icon {
  color: #333;
}

.btn-github:active .icon, .btn-github.active .icon,
.open > .btn-github.dropdown-toggle .icon {
  color: #333;
}

.btn-github:active:hover .icon, .btn-github:active:focus .icon, .btn-github:active.focus .icon, .btn-github.active:hover .icon, .btn-github.active:focus .icon, .btn-github.active.focus .icon,
.open > .btn-github.dropdown-toggle:hover .icon,
.open > .btn-github.dropdown-toggle:focus .icon,
.open > .btn-github.dropdown-toggle.focus .icon {
  color: #333;
}

.btn-tumblr .icon {
  color: #426d9b;
}

.btn-tumblr:active .icon, .btn-tumblr.active .icon,
.open > .btn-tumblr.dropdown-toggle .icon {
  color: #426d9b;
}

.btn-tumblr:active:hover .icon, .btn-tumblr:active:focus .icon, .btn-tumblr:active.focus .icon, .btn-tumblr.active:hover .icon, .btn-tumblr.active:focus .icon, .btn-tumblr.active.focus .icon,
.open > .btn-tumblr.dropdown-toggle:hover .icon,
.open > .btn-tumblr.dropdown-toggle:focus .icon,
.open > .btn-tumblr.dropdown-toggle.focus .icon {
  color: #426d9b;
}

.btn-dropbox .icon {
  color: #007EE5;
}

.btn-dropbox:active .icon, .btn-dropbox.active .icon,
.open > .btn-dropbox.dropdown-toggle .icon {
  color: #007EE5;
}

.btn-dropbox:active:hover .icon, .btn-dropbox:active:focus .icon, .btn-dropbox:active.focus .icon, .btn-dropbox.active:hover .icon, .btn-dropbox.active:focus .icon, .btn-dropbox.active.focus .icon,
.open > .btn-dropbox.dropdown-toggle:hover .icon,
.open > .btn-dropbox.dropdown-toggle:focus .icon,
.open > .btn-dropbox.dropdown-toggle.focus .icon {
  color: #007EE5;
}

.btn-behance .icon {
  color: #235cff;
}

.btn-behance:active .icon, .btn-behance.active .icon,
.open > .btn-behance.dropdown-toggle .icon {
  color: #235cff;
}

.btn-behance:active:hover .icon, .btn-behance:active:focus .icon, .btn-behance:active.focus .icon, .btn-behance.active:hover .icon, .btn-behance.active:focus .icon, .btn-behance.active.focus .icon,
.open > .btn-behance.dropdown-toggle:hover .icon,
.open > .btn-behance.dropdown-toggle:focus .icon,
.open > .btn-behance.dropdown-toggle.focus .icon {
  color: #235cff;
}

.btn-color.btn-facebook {
  color: #FFFFFF;
  background-color: #5d82d1;
  border-color: #5d82d1;
}

.btn-color.btn-facebook .icon {
  color: #FFFFFF;
}

.btn-color.btn-facebook:hover {
  color: #FFFFFF;
  background-color: #7191d7;
  border-color: transparent;
}

.btn-color.btn-facebook:focus, .btn-color.btn-facebook.focus {
  color: #FFFFFF;
  background-color: #5d82d1;
  border-color: #5d82d1;
}

.btn-color.btn-facebook:focus:hover, .btn-color.btn-facebook.focus:hover {
  color: #FFFFFF;
  background-color: #7191d7;
  border-color: transparent;
}

.btn-color.btn-facebook:active, .btn-color.btn-facebook.active,
.open > .btn-color.btn-facebook.dropdown-toggle {
  color: #FFFFFF;
  background-color: #4973cb;
  border-color: transparent;
  background-image: none;
}

.btn-color.btn-facebook:active:hover, .btn-color.btn-facebook:active:focus, .btn-color.btn-facebook:active.focus, .btn-color.btn-facebook.active:hover, .btn-color.btn-facebook.active:focus, .btn-color.btn-facebook.active.focus,
.open > .btn-color.btn-facebook.dropdown-toggle:hover,
.open > .btn-color.btn-facebook.dropdown-toggle:focus,
.open > .btn-color.btn-facebook.dropdown-toggle.focus {
  color: #FFFFFF;
  background-color: #4973cb;
  border-color: transparent;
}

.btn-color.btn-facebook:active:hover > .icon, .btn-color.btn-facebook:active:focus > .icon, .btn-color.btn-facebook:active.focus > .icon, .btn-color.btn-facebook.active:hover > .icon, .btn-color.btn-facebook.active:focus > .icon, .btn-color.btn-facebook.active.focus > .icon,
.open > .btn-color.btn-facebook.dropdown-toggle:hover > .icon,
.open > .btn-color.btn-facebook.dropdown-toggle:focus > .icon,
.open > .btn-color.btn-facebook.dropdown-toggle.focus > .icon {
  color: #FFFFFF;
}

.btn-color.btn-twitter {
  color: #FFFFFF;
  background-color: #50bff5;
  border-color: #50bff5;
}

.btn-color.btn-twitter .icon {
  color: #FFFFFF;
}

.btn-color.btn-twitter:hover {
  color: #FFFFFF;
  background-color: #68c8f6;
  border-color: transparent;
}

.btn-color.btn-twitter:focus, .btn-color.btn-twitter.focus {
  color: #FFFFFF;
  background-color: #50bff5;
  border-color: #50bff5;
}

.btn-color.btn-twitter:focus:hover, .btn-color.btn-twitter.focus:hover {
  color: #FFFFFF;
  background-color: #68c8f6;
  border-color: transparent;
}

.btn-color.btn-twitter:active, .btn-color.btn-twitter.active,
.open > .btn-color.btn-twitter.dropdown-toggle {
  color: #FFFFFF;
  background-color: #38b6f4;
  border-color: transparent;
  background-image: none;
}

.btn-color.btn-twitter:active:hover, .btn-color.btn-twitter:active:focus, .btn-color.btn-twitter:active.focus, .btn-color.btn-twitter.active:hover, .btn-color.btn-twitter.active:focus, .btn-color.btn-twitter.active.focus,
.open > .btn-color.btn-twitter.dropdown-toggle:hover,
.open > .btn-color.btn-twitter.dropdown-toggle:focus,
.open > .btn-color.btn-twitter.dropdown-toggle.focus {
  color: #FFFFFF;
  background-color: #38b6f4;
  border-color: transparent;
}

.btn-color.btn-twitter:active:hover > .icon, .btn-color.btn-twitter:active:focus > .icon, .btn-color.btn-twitter:active.focus > .icon, .btn-color.btn-twitter.active:hover > .icon, .btn-color.btn-twitter.active:focus > .icon, .btn-color.btn-twitter.active.focus > .icon,
.open > .btn-color.btn-twitter.dropdown-toggle:hover > .icon,
.open > .btn-color.btn-twitter.dropdown-toggle:focus > .icon,
.open > .btn-color.btn-twitter.dropdown-toggle.focus > .icon {
  color: #FFFFFF;
}

.btn-color.btn-google-plus {
  color: #FFFFFF;
  background-color: #eb5e4c;
  border-color: #eb5e4c;
}

.btn-color.btn-google-plus .icon {
  color: #FFFFFF;
}

.btn-color.btn-google-plus:hover {
  color: #FFFFFF;
  background-color: #ee7363;
  border-color: transparent;
}

.btn-color.btn-google-plus:focus, .btn-color.btn-google-plus.focus {
  color: #FFFFFF;
  background-color: #eb5e4c;
  border-color: #eb5e4c;
}

.btn-color.btn-google-plus:focus:hover, .btn-color.btn-google-plus.focus:hover {
  color: #FFFFFF;
  background-color: #ee7363;
  border-color: transparent;
}

.btn-color.btn-google-plus:active, .btn-color.btn-google-plus.active,
.open > .btn-color.btn-google-plus.dropdown-toggle {
  color: #FFFFFF;
  background-color: #e84935;
  border-color: transparent;
  background-image: none;
}

.btn-color.btn-google-plus:active:hover, .btn-color.btn-google-plus:active:focus, .btn-color.btn-google-plus:active.focus, .btn-color.btn-google-plus.active:hover, .btn-color.btn-google-plus.active:focus, .btn-color.btn-google-plus.active.focus,
.open > .btn-color.btn-google-plus.dropdown-toggle:hover,
.open > .btn-color.btn-google-plus.dropdown-toggle:focus,
.open > .btn-color.btn-google-plus.dropdown-toggle.focus {
  color: #FFFFFF;
  background-color: #e84935;
  border-color: transparent;
}

.btn-color.btn-google-plus:active:hover > .icon, .btn-color.btn-google-plus:active:focus > .icon, .btn-color.btn-google-plus:active.focus > .icon, .btn-color.btn-google-plus.active:hover > .icon, .btn-color.btn-google-plus.active:focus > .icon, .btn-color.btn-google-plus.active.focus > .icon,
.open > .btn-color.btn-google-plus.dropdown-toggle:hover > .icon,
.open > .btn-color.btn-google-plus.dropdown-toggle:focus > .icon,
.open > .btn-color.btn-google-plus.dropdown-toggle.focus > .icon {
  color: #FFFFFF;
}

.btn-color.btn-dribbble {
  color: #FFFFFF;
  background-color: #f7659c;
  border-color: #f7659c;
}

.btn-color.btn-dribbble .icon {
  color: #FFFFFF;
}

.btn-color.btn-dribbble:hover {
  color: #FFFFFF;
  background-color: #f87dac;
  border-color: transparent;
}

.btn-color.btn-dribbble:focus, .btn-color.btn-dribbble.focus {
  color: #FFFFFF;
  background-color: #f7659c;
  border-color: #f7659c;
}

.btn-color.btn-dribbble:focus:hover, .btn-color.btn-dribbble.focus:hover {
  color: #FFFFFF;
  background-color: #f87dac;
  border-color: transparent;
}

.btn-color.btn-dribbble:active, .btn-color.btn-dribbble.active,
.open > .btn-color.btn-dribbble.dropdown-toggle {
  color: #FFFFFF;
  background-color: #f64d8c;
  border-color: transparent;
  background-image: none;
}

.btn-color.btn-dribbble:active:hover, .btn-color.btn-dribbble:active:focus, .btn-color.btn-dribbble:active.focus, .btn-color.btn-dribbble.active:hover, .btn-color.btn-dribbble.active:focus, .btn-color.btn-dribbble.active.focus,
.open > .btn-color.btn-dribbble.dropdown-toggle:hover,
.open > .btn-color.btn-dribbble.dropdown-toggle:focus,
.open > .btn-color.btn-dribbble.dropdown-toggle.focus {
  color: #FFFFFF;
  background-color: #f64d8c;
  border-color: transparent;
}

.btn-color.btn-dribbble:active:hover > .icon, .btn-color.btn-dribbble:active:focus > .icon, .btn-color.btn-dribbble:active.focus > .icon, .btn-color.btn-dribbble.active:hover > .icon, .btn-color.btn-dribbble.active:focus > .icon, .btn-color.btn-dribbble.active.focus > .icon,
.open > .btn-color.btn-dribbble.dropdown-toggle:hover > .icon,
.open > .btn-color.btn-dribbble.dropdown-toggle:focus > .icon,
.open > .btn-color.btn-dribbble.dropdown-toggle.focus > .icon {
  color: #FFFFFF;
}

.btn-color.btn-flickr {
  color: #FFFFFF;
  background-color: #0063dc;
  border-color: #0063dc;
}

.btn-color.btn-flickr .icon {
  color: #FFFFFF;
}

.btn-color.btn-flickr:hover {
  color: #FFFFFF;
  background-color: #006ef6;
  border-color: transparent;
}

.btn-color.btn-flickr:focus, .btn-color.btn-flickr.focus {
  color: #FFFFFF;
  background-color: #0063dc;
  border-color: #0063dc;
}

.btn-color.btn-flickr:focus:hover, .btn-color.btn-flickr.focus:hover {
  color: #FFFFFF;
  background-color: #006ef6;
  border-color: transparent;
}

.btn-color.btn-flickr:active, .btn-color.btn-flickr.active,
.open > .btn-color.btn-flickr.dropdown-toggle {
  color: #FFFFFF;
  background-color: #0058c3;
  border-color: transparent;
  background-image: none;
}

.btn-color.btn-flickr:active:hover, .btn-color.btn-flickr:active:focus, .btn-color.btn-flickr:active.focus, .btn-color.btn-flickr.active:hover, .btn-color.btn-flickr.active:focus, .btn-color.btn-flickr.active.focus,
.open > .btn-color.btn-flickr.dropdown-toggle:hover,
.open > .btn-color.btn-flickr.dropdown-toggle:focus,
.open > .btn-color.btn-flickr.dropdown-toggle.focus {
  color: #FFFFFF;
  background-color: #0058c3;
  border-color: transparent;
}

.btn-color.btn-flickr:active:hover > .icon, .btn-color.btn-flickr:active:focus > .icon, .btn-color.btn-flickr:active.focus > .icon, .btn-color.btn-flickr.active:hover > .icon, .btn-color.btn-flickr.active:focus > .icon, .btn-color.btn-flickr.active.focus > .icon,
.open > .btn-color.btn-flickr.dropdown-toggle:hover > .icon,
.open > .btn-color.btn-flickr.dropdown-toggle:focus > .icon,
.open > .btn-color.btn-flickr.dropdown-toggle.focus > .icon {
  color: #FFFFFF;
}

.btn-color.btn-linkedin {
  color: #FFFFFF;
  background-color: #238cc8;
  border-color: #238cc8;
}

.btn-color.btn-linkedin .icon {
  color: #FFFFFF;
}

.btn-color.btn-linkedin:hover {
  color: #FFFFFF;
  background-color: #2b9ada;
  border-color: transparent;
}

.btn-color.btn-linkedin:focus, .btn-color.btn-linkedin.focus {
  color: #FFFFFF;
  background-color: #238cc8;
  border-color: #238cc8;
}

.btn-color.btn-linkedin:focus:hover, .btn-color.btn-linkedin.focus:hover {
  color: #FFFFFF;
  background-color: #2b9ada;
  border-color: transparent;
}

.btn-color.btn-linkedin:active, .btn-color.btn-linkedin.active,
.open > .btn-color.btn-linkedin.dropdown-toggle {
  color: #FFFFFF;
  background-color: #1f7db2;
  border-color: transparent;
  background-image: none;
}

.btn-color.btn-linkedin:active:hover, .btn-color.btn-linkedin:active:focus, .btn-color.btn-linkedin:active.focus, .btn-color.btn-linkedin.active:hover, .btn-color.btn-linkedin.active:focus, .btn-color.btn-linkedin.active.focus,
.open > .btn-color.btn-linkedin.dropdown-toggle:hover,
.open > .btn-color.btn-linkedin.dropdown-toggle:focus,
.open > .btn-color.btn-linkedin.dropdown-toggle.focus {
  color: #FFFFFF;
  background-color: #1f7db2;
  border-color: transparent;
}

.btn-color.btn-linkedin:active:hover > .icon, .btn-color.btn-linkedin:active:focus > .icon, .btn-color.btn-linkedin:active.focus > .icon, .btn-color.btn-linkedin.active:hover > .icon, .btn-color.btn-linkedin.active:focus > .icon, .btn-color.btn-linkedin.active.focus > .icon,
.open > .btn-color.btn-linkedin.dropdown-toggle:hover > .icon,
.open > .btn-color.btn-linkedin.dropdown-toggle:focus > .icon,
.open > .btn-color.btn-linkedin.dropdown-toggle.focus > .icon {
  color: #FFFFFF;
}

.btn-color.btn-youtube {
  color: #FFFFFF;
  background-color: #ef4e41;
  border-color: #ef4e41;
}

.btn-color.btn-youtube .icon {
  color: #FFFFFF;
}

.btn-color.btn-youtube:hover {
  color: #FFFFFF;
  background-color: #f16459;
  border-color: transparent;
}

.btn-color.btn-youtube:focus, .btn-color.btn-youtube.focus {
  color: #FFFFFF;
  background-color: #ef4e41;
  border-color: #ef4e41;
}

.btn-color.btn-youtube:focus:hover, .btn-color.btn-youtube.focus:hover {
  color: #FFFFFF;
  background-color: #f16459;
  border-color: transparent;
}

.btn-color.btn-youtube:active, .btn-color.btn-youtube.active,
.open > .btn-color.btn-youtube.dropdown-toggle {
  color: #FFFFFF;
  background-color: #ed3829;
  border-color: transparent;
  background-image: none;
}

.btn-color.btn-youtube:active:hover, .btn-color.btn-youtube:active:focus, .btn-color.btn-youtube:active.focus, .btn-color.btn-youtube.active:hover, .btn-color.btn-youtube.active:focus, .btn-color.btn-youtube.active.focus,
.open > .btn-color.btn-youtube.dropdown-toggle:hover,
.open > .btn-color.btn-youtube.dropdown-toggle:focus,
.open > .btn-color.btn-youtube.dropdown-toggle.focus {
  color: #FFFFFF;
  background-color: #ed3829;
  border-color: transparent;
}

.btn-color.btn-youtube:active:hover > .icon, .btn-color.btn-youtube:active:focus > .icon, .btn-color.btn-youtube:active.focus > .icon, .btn-color.btn-youtube.active:hover > .icon, .btn-color.btn-youtube.active:focus > .icon, .btn-color.btn-youtube.active.focus > .icon,
.open > .btn-color.btn-youtube.dropdown-toggle:hover > .icon,
.open > .btn-color.btn-youtube.dropdown-toggle:focus > .icon,
.open > .btn-color.btn-youtube.dropdown-toggle.focus > .icon {
  color: #FFFFFF;
}

.btn-color.btn-pinterest {
  color: #FFFFFF;
  background-color: #e13138;
  border-color: #e13138;
}

.btn-color.btn-pinterest .icon {
  color: #FFFFFF;
}

.btn-color.btn-pinterest:hover {
  color: #FFFFFF;
  background-color: #e4474e;
  border-color: transparent;
}

.btn-color.btn-pinterest:focus, .btn-color.btn-pinterest.focus {
  color: #FFFFFF;
  background-color: #e13138;
  border-color: #e13138;
}

.btn-color.btn-pinterest:focus:hover, .btn-color.btn-pinterest.focus:hover {
  color: #FFFFFF;
  background-color: #e4474e;
  border-color: transparent;
}

.btn-color.btn-pinterest:active, .btn-color.btn-pinterest.active,
.open > .btn-color.btn-pinterest.dropdown-toggle {
  color: #FFFFFF;
  background-color: #d92027;
  border-color: transparent;
  background-image: none;
}

.btn-color.btn-pinterest:active:hover, .btn-color.btn-pinterest:active:focus, .btn-color.btn-pinterest:active.focus, .btn-color.btn-pinterest.active:hover, .btn-color.btn-pinterest.active:focus, .btn-color.btn-pinterest.active.focus,
.open > .btn-color.btn-pinterest.dropdown-toggle:hover,
.open > .btn-color.btn-pinterest.dropdown-toggle:focus,
.open > .btn-color.btn-pinterest.dropdown-toggle.focus {
  color: #FFFFFF;
  background-color: #d92027;
  border-color: transparent;
}

.btn-color.btn-pinterest:active:hover > .icon, .btn-color.btn-pinterest:active:focus > .icon, .btn-color.btn-pinterest:active.focus > .icon, .btn-color.btn-pinterest.active:hover > .icon, .btn-color.btn-pinterest.active:focus > .icon, .btn-color.btn-pinterest.active.focus > .icon,
.open > .btn-color.btn-pinterest.dropdown-toggle:hover > .icon,
.open > .btn-color.btn-pinterest.dropdown-toggle:focus > .icon,
.open > .btn-color.btn-pinterest.dropdown-toggle.focus > .icon {
  color: #FFFFFF;
}

.btn-color.btn-github {
  color: #FFFFFF;
  background-color: #333;
  border-color: #333;
}

.btn-color.btn-github .icon {
  color: #FFFFFF;
}

.btn-color.btn-github:hover {
  color: #FFFFFF;
  background-color: #404040;
  border-color: transparent;
}

.btn-color.btn-github:focus, .btn-color.btn-github.focus {
  color: #FFFFFF;
  background-color: #333;
  border-color: #333;
}

.btn-color.btn-github:focus:hover, .btn-color.btn-github.focus:hover {
  color: #FFFFFF;
  background-color: #404040;
  border-color: transparent;
}

.btn-color.btn-github:active, .btn-color.btn-github.active,
.open > .btn-color.btn-github.dropdown-toggle {
  color: #FFFFFF;
  background-color: #262626;
  border-color: transparent;
  background-image: none;
}

.btn-color.btn-github:active:hover, .btn-color.btn-github:active:focus, .btn-color.btn-github:active.focus, .btn-color.btn-github.active:hover, .btn-color.btn-github.active:focus, .btn-color.btn-github.active.focus,
.open > .btn-color.btn-github.dropdown-toggle:hover,
.open > .btn-color.btn-github.dropdown-toggle:focus,
.open > .btn-color.btn-github.dropdown-toggle.focus {
  color: #FFFFFF;
  background-color: #262626;
  border-color: transparent;
}

.btn-color.btn-github:active:hover > .icon, .btn-color.btn-github:active:focus > .icon, .btn-color.btn-github:active.focus > .icon, .btn-color.btn-github.active:hover > .icon, .btn-color.btn-github.active:focus > .icon, .btn-color.btn-github.active.focus > .icon,
.open > .btn-color.btn-github.dropdown-toggle:hover > .icon,
.open > .btn-color.btn-github.dropdown-toggle:focus > .icon,
.open > .btn-color.btn-github.dropdown-toggle.focus > .icon {
  color: #FFFFFF;
}

.btn-color.btn-tumblr {
  color: #FFFFFF;
  background-color: #426d9b;
  border-color: #426d9b;
}

.btn-color.btn-tumblr .icon {
  color: #FFFFFF;
}

.btn-color.btn-tumblr:hover {
  color: #FFFFFF;
  background-color: #4a7aad;
  border-color: transparent;
}

.btn-color.btn-tumblr:focus, .btn-color.btn-tumblr.focus {
  color: #FFFFFF;
  background-color: #426d9b;
  border-color: #426d9b;
}

.btn-color.btn-tumblr:focus:hover, .btn-color.btn-tumblr.focus:hover {
  color: #FFFFFF;
  background-color: #4a7aad;
  border-color: transparent;
}

.btn-color.btn-tumblr:active, .btn-color.btn-tumblr.active,
.open > .btn-color.btn-tumblr.dropdown-toggle {
  color: #FFFFFF;
  background-color: #3a6089;
  border-color: transparent;
  background-image: none;
}

.btn-color.btn-tumblr:active:hover, .btn-color.btn-tumblr:active:focus, .btn-color.btn-tumblr:active.focus, .btn-color.btn-tumblr.active:hover, .btn-color.btn-tumblr.active:focus, .btn-color.btn-tumblr.active.focus,
.open > .btn-color.btn-tumblr.dropdown-toggle:hover,
.open > .btn-color.btn-tumblr.dropdown-toggle:focus,
.open > .btn-color.btn-tumblr.dropdown-toggle.focus {
  color: #FFFFFF;
  background-color: #3a6089;
  border-color: transparent;
}

.btn-color.btn-tumblr:active:hover > .icon, .btn-color.btn-tumblr:active:focus > .icon, .btn-color.btn-tumblr:active.focus > .icon, .btn-color.btn-tumblr.active:hover > .icon, .btn-color.btn-tumblr.active:focus > .icon, .btn-color.btn-tumblr.active.focus > .icon,
.open > .btn-color.btn-tumblr.dropdown-toggle:hover > .icon,
.open > .btn-color.btn-tumblr.dropdown-toggle:focus > .icon,
.open > .btn-color.btn-tumblr.dropdown-toggle.focus > .icon {
  color: #FFFFFF;
}

.btn-color.btn-dropbox {
  color: #FFFFFF;
  background-color: #007EE5;
  border-color: #007EE5;
}

.btn-color.btn-dropbox .icon {
  color: #FFFFFF;
}

.btn-color.btn-dropbox:hover {
  color: #FFFFFF;
  background-color: #008cff;
  border-color: transparent;
}

.btn-color.btn-dropbox:focus, .btn-color.btn-dropbox.focus {
  color: #FFFFFF;
  background-color: #007EE5;
  border-color: #007EE5;
}

.btn-color.btn-dropbox:focus:hover, .btn-color.btn-dropbox.focus:hover {
  color: #FFFFFF;
  background-color: #008cff;
  border-color: transparent;
}

.btn-color.btn-dropbox:active, .btn-color.btn-dropbox.active,
.open > .btn-color.btn-dropbox.dropdown-toggle {
  color: #FFFFFF;
  background-color: #0070cc;
  border-color: transparent;
  background-image: none;
}

.btn-color.btn-dropbox:active:hover, .btn-color.btn-dropbox:active:focus, .btn-color.btn-dropbox:active.focus, .btn-color.btn-dropbox.active:hover, .btn-color.btn-dropbox.active:focus, .btn-color.btn-dropbox.active.focus,
.open > .btn-color.btn-dropbox.dropdown-toggle:hover,
.open > .btn-color.btn-dropbox.dropdown-toggle:focus,
.open > .btn-color.btn-dropbox.dropdown-toggle.focus {
  color: #FFFFFF;
  background-color: #0070cc;
  border-color: transparent;
}

.btn-color.btn-dropbox:active:hover > .icon, .btn-color.btn-dropbox:active:focus > .icon, .btn-color.btn-dropbox:active.focus > .icon, .btn-color.btn-dropbox.active:hover > .icon, .btn-color.btn-dropbox.active:focus > .icon, .btn-color.btn-dropbox.active.focus > .icon,
.open > .btn-color.btn-dropbox.dropdown-toggle:hover > .icon,
.open > .btn-color.btn-dropbox.dropdown-toggle:focus > .icon,
.open > .btn-color.btn-dropbox.dropdown-toggle.focus > .icon {
  color: #FFFFFF;
}

.btn-color.btn-behance {
  color: #FFFFFF;
  background-color: #235cff;
  border-color: #235cff;
}

.btn-color.btn-behance .icon {
  color: #FFFFFF;
}

.btn-color.btn-behance:hover {
  color: #FFFFFF;
  background-color: #3d6fff;
  border-color: transparent;
}

.btn-color.btn-behance:focus, .btn-color.btn-behance.focus {
  color: #FFFFFF;
  background-color: #235cff;
  border-color: #235cff;
}

.btn-color.btn-behance:focus:hover, .btn-color.btn-behance.focus:hover {
  color: #FFFFFF;
  background-color: #3d6fff;
  border-color: transparent;
}

.btn-color.btn-behance:active, .btn-color.btn-behance.active,
.open > .btn-color.btn-behance.dropdown-toggle {
  color: #FFFFFF;
  background-color: #0a49ff;
  border-color: transparent;
  background-image: none;
}

.btn-color.btn-behance:active:hover, .btn-color.btn-behance:active:focus, .btn-color.btn-behance:active.focus, .btn-color.btn-behance.active:hover, .btn-color.btn-behance.active:focus, .btn-color.btn-behance.active.focus,
.open > .btn-color.btn-behance.dropdown-toggle:hover,
.open > .btn-color.btn-behance.dropdown-toggle:focus,
.open > .btn-color.btn-behance.dropdown-toggle.focus {
  color: #FFFFFF;
  background-color: #0a49ff;
  border-color: transparent;
}

.btn-color.btn-behance:active:hover > .icon, .btn-color.btn-behance:active:focus > .icon, .btn-color.btn-behance:active.focus > .icon, .btn-color.btn-behance.active:hover > .icon, .btn-color.btn-behance.active:focus > .icon, .btn-color.btn-behance.active.focus > .icon,
.open > .btn-color.btn-behance.dropdown-toggle:hover > .icon,
.open > .btn-color.btn-behance.dropdown-toggle:focus > .icon,
.open > .btn-color.btn-behance.dropdown-toggle.focus > .icon {
  color: #FFFFFF;
}

.btn-hspace {
  margin-right: 4px;
}

.btn-vspace {
  margin-bottom: 5px;
}

.btn-space {
  margin-right: 4px;
  margin-bottom: 5px;
}

.btn-link {
  font-weight: 700;
  color: #545454;
}

.btn-link:hover, .btn-link:focus {
  color: #7a7a7a;
  text-decoration: none;
}

.btn-link:active, .btn-link.active,
.show > .btn-link.dropdown-toggle {
  background-color: rgba(0, 0, 0, 0.07);
}

.btn-link-secondary {
  color: #545454;
}

.btn-link-secondary:hover {
  color: #7a7a7a;
}

.btn-link-secondary:focus, .btn-link-secondary.focus {
  color: #545454;
}

.btn-link-secondary:focus:hover, .btn-link-secondary.focus:hover {
  color: #7a7a7a;
}

.btn-link-secondary.disabled, .btn-link-secondary:disabled {
  color: #7a7a7a;
  cursor: not-allowed;
}

.btn-link-primary {
  color: #2cc185;
}

.btn-link-primary:hover {
  color: #239768;
}

.btn-link-primary:focus, .btn-link-primary.focus {
  color: #2cc185;
}

.btn-link-primary:focus:hover, .btn-link-primary.focus:hover {
  color: #239768;
}

.btn-link-primary.disabled, .btn-link-primary:disabled {
  color: #5fdba9;
  cursor: not-allowed;
}

.btn-link-success {
  color: #2cc185;
}

.btn-link-success:hover {
  color: #239768;
}

.btn-link-success:focus, .btn-link-success.focus {
  color: #2cc185;
}

.btn-link-success:focus:hover, .btn-link-success.focus:hover {
  color: #239768;
}

.btn-link-success.disabled, .btn-link-success:disabled {
  color: #5fdba9;
  cursor: not-allowed;
}

.btn-link-info {
  color: #58C7EF;
}

.btn-link-info:hover {
  color: #29b7eb;
}

.btn-link-info:focus, .btn-link-info.focus {
  color: #58C7EF;
}

.btn-link-info:focus:hover, .btn-link-info.focus:hover {
  color: #29b7eb;
}

.btn-link-info.disabled, .btn-link-info:disabled {
  color: #9edef6;
  cursor: not-allowed;
}

.btn-link-warning {
  color: #FFDC42;
}

.btn-link-warning:hover {
  color: #ffd30f;
}

.btn-link-warning:focus, .btn-link-warning.focus {
  color: #FFDC42;
}

.btn-link-warning:focus:hover, .btn-link-warning.focus:hover {
  color: #ffd30f;
}

.btn-link-warning.disabled, .btn-link-warning:disabled {
  color: #ffea8f;
  cursor: not-allowed;
}

.btn-link-danger {
  color: #F45846;
}

.btn-link-danger:hover {
  color: #f12d16;
}

.btn-link-danger:focus, .btn-link-danger.focus {
  color: #F45846;
}

.btn-link-danger:focus:hover, .btn-link-danger.focus:hover {
  color: #f12d16;
}

.btn-link-danger.disabled, .btn-link-danger:disabled {
  color: #f8998e;
  cursor: not-allowed;
}

.breadcrumb-item .icon {
  font-size: 1.4rem;
}

.breadcrumb-item + .breadcrumb-item {
  padding-left: 0;
}

.breadcrumb-item + .breadcrumb-item:before {
  position: relative;
  top: -.07692rem;
  font-family: "Stroke 7";
  font-size: 1.8rem;
  line-height: .4;
  vertical-align: middle;
  padding: 0;
}

/*------------------------------------------------------------------
  [Code]
*/
code {
  padding: .2rem .4rem;
}

pre {
  padding: 1.538rem;
  border: 0;
  border-radius: 0;
  background-color: #fafafa;
}

pre code {
  white-space: pre-wrap;
  padding: 0;
}

.accordion .card {
  border: 0 solid transparent;
  box-shadow: 0 0 0.307rem rgba(0, 0, 0, 0.04);
  background-color: trn;
}

.accordion .card + .card {
  margin-top: .384rem;
}

.accordion .card-header {
  padding: 0;
  border-bottom: 0 solid transparent;
  background-color: #FFFFFF;
}

.accordion .card-header button {
  display: block;
  width: 100%;
  font-size: 1.384rem;
  text-align: left;
  line-height: 1;
  padding: 1.599rem 1.538rem;
  color: #545454;
  background-color: transparent;
}

.accordion .card-header button .icon {
  transition: transform 200ms ease-in-out;
  font-size: 2.307rem;
  line-height: .7;
  vertical-align: middle;
  position: relative;
  top: -1px;
  display: inline-block;
  margin-right: .384rem;
}

.accordion .card-header button[aria-expanded='true'] {
  color: #2cc185;
}

.accordion .card-header button[aria-expanded='true'] .icon {
  transform: rotate(90deg);
}

.accordion .card-block {
  padding: 1.692rem 1.538rem;
}

.accordion-dark .card {
  background-color: #545454;
  color: #FFFFFF;
}

.accordion-dark .card-header {
  background-color: #545454;
}

.accordion-dark .card-header button {
  color: #FFFFFF;
}

.accordion-dark .card-header button[aria-expanded='true'] {
  color: #2cc185;
  background-color: #4c4c4c;
}

.custom-control {
  min-height: 1.69231rem;
  min-width: 1.69231rem;
  margin-bottom: .923rem;
}

.custom-control .custom-control-label {
  position: unset;
}

.custom-control .custom-control-label:after, .custom-control .custom-control-label:before {
  left: 0;
}

.custom-control-input .custom-control-label {
  line-height: 1.6;
}

.custom-control-input:checked ~ .custom-control-label::after {
  color: #2cc185;
}

.custom-control-input:checked ~ .custom-control-label::before {
  border-color: #2cc185;
}

.custom-control-input:checked ~ .custom-control-color::after {
  background-color: #2cc185;
  color: #FFFFFF;
}

.custom-radio .custom-control-input:checked ~ .custom-control-color::after {
  border-radius: 50%;
}

.custom-checkbox .custom-control-label::before {
  border: 2px solid #ebebeb;
  content: "";
}

.custom-checkbox .custom-control-input:checked ~ .custom-control-label::after {
  content: "\e6ca";
  font-family: "Stroke 7";
  font-size: 20px;
  font-weight: normal;
  text-align: center;
  line-height: 1.1;
}

.custom-radio .custom-control-label::before {
  border: 2px solid #ebebeb;
  content: "";
}

.custom-radio .custom-control-input:checked ~ .custom-control-label::after {
  content: "\e6ce";
  font-family: "Stroke 7";
  font-size: 14px;
  font-weight: normal;
  text-align: center;
  line-height: 1.6;
}

.custom-radio-icon {
  margin: 0;
  padding: 0;
  width: 52px;
  height: 52px;
}

.custom-radio-icon .custom-control-label::after, .custom-radio-icon .custom-control-label::before {
  display: none;
}

.custom-radio-icon .custom-control-label {
  cursor: pointer;
  width: 52px;
  height: 52px;
  line-height: 1;
  border: 2px solid #d5d8de;
  border-radius: 50%;
  font-size: 2.847rem;
  color: #bdbdbd;
  background-color: #FFFFFF;
  text-align: center;
}

.custom-radio-icon .custom-control-label:hover {
  border-color: #b8bdc8;
}

.custom-radio-icon .custom-control-label i {
  vertical-align: middle;
}

.custom-radio-icon input[type="radio"]:checked + .custom-control-label {
  border-color: #2cc185;
  color: #2cc185;
  background-color: #FFFFFF;
}

.custom-radio-icon input[type="radio"]:checked + .custom-control-label i {
  color: #2cc185;
}

.custom-radio-icon input[type="radio"]:focus + .custom-control-label {
  box-shadow: none;
}

.custom-radio-icon.custom-control-inline + .custom-radio-icon.custom-control-inline {
  margin-left: 18px;
}

.form-control-success {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3E%3Cpath fill='%232cc185' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3E%3C/svg%3E");
}

.form-control-danger {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%23F45846' viewBox='-2 -2 7 7'%3E%3Cpath stroke='%23d9534f' d='M0 0l3 3m0-3L0 3'/%3E%3Ccircle r='.5'/%3E%3Ccircle cx='3' r='.5'/%3E%3Ccircle cy='3' r='.5'/%3E%3Ccircle cx='3' cy='3' r='.5'/%3E%3C/svg%3E");
}

.was-validated .form-control-feedback:valid, .form-control-feedback.is-valid, .was-validated
.col-form-label:valid,
.col-form-label.is-valid, .was-validated
.form-check-label:valid,
.form-check-label.is-valid, .was-validated
.custom-control:valid,
.custom-control.is-valid {
  color: #545454;
}

.was-validated .form-control:valid, .form-control.is-valid, .was-validated
.custom-select:valid,
.custom-select.is-valid {
  border-color: #2cc185;
}

.was-validated .form-control:valid:focus, .form-control.is-valid:focus, .was-validated
.custom-select:valid:focus,
.custom-select.is-valid:focus {
  box-shadow: none;
}

.was-validated .custom-file-input:valid ~ .custom-file-control, .custom-file-input.is-valid ~ .custom-file-control {
  border-color: #2cc185;
}

.was-validated .custom-file-input:valid ~ .custom-file-control::before, .custom-file-input.is-valid ~ .custom-file-control::before {
  border-color: inherit;
}

.was-validated .custom-file-input:valid:focus, .custom-file-input.is-valid:focus {
  box-shadow: none;
}

.was-validated .custom-control-input:valid ~ .custom-control-label, .custom-control-input.is-valid ~ .custom-control-label {
  color: #545454;
}

.was-validated .custom-control-input:valid ~ .custom-control-label::after, .custom-control-input.is-valid ~ .custom-control-label::after {
  color: #2cc185;
}

.was-validated .custom-control-input:valid ~ .custom-control-label::before, .custom-control-input.is-valid ~ .custom-control-label::before {
  background-color: #FFFFFF;
  border-color: #2cc185;
  color: #2cc185;
}

.was-validated .custom-control-input:valid:checked ~ .custom-control-label::before, .custom-control-input.is-valid:checked ~ .custom-control-label::before {
  background-color: #FFFFFF;
}

.was-validated .custom-control-input:valid:active ~ .custom-control-label::before, .custom-control-input.is-valid:active ~ .custom-control-label::before {
  background-color: #b2edd6;
  border-color: #b2edd6;
}

.was-validated .custom-control-input:checked:valid ~ .custom-control-color::after, .custom-control-input:checked.is-valid ~ .custom-control-color::after {
  background-color: #2cc185;
  color: #FFFFFF;
}

.was-validated .form-control-feedback:invalid, .form-control-feedback.is-invalid, .was-validated
.col-form-label:invalid,
.col-form-label.is-invalid, .was-validated
.form-check-label:invalid,
.form-check-label.is-invalid, .was-validated
.custom-control:invalid,
.custom-control.is-invalid {
  color: #545454;
}

.was-validated .form-control:invalid, .form-control.is-invalid, .was-validated
.custom-select:invalid,
.custom-select.is-invalid {
  border-color: #F45846;
}

.was-validated .form-control:invalid:focus, .form-control.is-invalid:focus, .was-validated
.custom-select:invalid:focus,
.custom-select.is-invalid:focus {
  box-shadow: none;
}

.was-validated .custom-file-input:invalid ~ .custom-file-control, .custom-file-input.is-invalid ~ .custom-file-control {
  border-color: #F45846;
}

.was-validated .custom-file-input:invalid ~ .custom-file-control::before, .custom-file-input.is-invalid ~ .custom-file-control::before {
  border-color: inherit;
}

.was-validated .custom-file-input:invalid:focus, .custom-file-input.is-invalid:focus {
  box-shadow: none;
}

.was-validated .custom-control-input:invalid ~ .custom-control-label, .custom-control-input.is-invalid ~ .custom-control-label {
  color: #545454;
}

.was-validated .custom-control-input:invalid ~ .custom-control-label::after, .custom-control-input.is-invalid ~ .custom-control-label::after {
  color: #F45846;
}

.was-validated .custom-control-input:invalid ~ .custom-control-label::before, .custom-control-input.is-invalid ~ .custom-control-label::before {
  background-color: #FFFFFF;
  border-color: #F45846;
  color: #F45846;
}

.was-validated .custom-control-input:invalid:checked ~ .custom-control-label::before, .custom-control-input.is-invalid:checked ~ .custom-control-label::before {
  background-color: #FFFFFF;
}

.was-validated .custom-control-input:invalid:active ~ .custom-control-label::before, .custom-control-input.is-invalid:active ~ .custom-control-label::before {
  background-color: #fef0ee;
  border-color: #fef0ee;
}

.was-validated .custom-control-input:checked:invalid ~ .custom-control-color::after, .custom-control-input:checked.is-invalid ~ .custom-control-color::after {
  background-color: #F45846;
  color: #FFFFFF;
}

.custom-control-sm {
  min-width: 1.38462rem;
  min-height: 1.38462rem;
}

.custom-control-sm.custom-control {
  top: 2px;
}

.custom-control-sm .custom-control-input ~ .custom-control-label::before {
  width: 1.38462rem;
  height: 1.38462rem;
}

.custom-control-sm .custom-control-input:checked ~ .custom-control-label::after {
  width: 1.38462rem;
  height: 1.38462rem;
  font-size: 15px;
  right: 0;
  line-height: 1.3;
  vertical-align: top;
}

.custom-select {
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.custom-select:focus {
  box-shadow: 0 0 0 transparent;
}

.input-group .custom-file-label {
  display: flex;
  align-items: center;
}

.input-group .custom-file-label:after {
  display: flex;
  align-items: center;
  height: 100%;
}

.dropdown-showcase {
  margin: 0;
}

.dropdown-menu {
  color: #545454;
  border-radius: 0;
  box-shadow: 0 0 0.3077rem rgba(0, 0, 0, 0.25);
}

.dropdown-menu.dropdown-menu-dark {
  background-color: #323232;
  box-shadow: none;
}

.dropdown-menu-dark .dropdown-divider {
  height: 0;
  margin: 0.5rem 0;
  overflow: hidden;
  border-top: 1px solid #3f3f3f;
}

.dropdown-item {
  padding: 0.7rem 1.5rem;
}

.dropdown-item:focus {
  background-color: transparent;
}

.dropdown-item:active {
  color: #212529;
  background-color: #f8f9fa;
}

.dropdown-item.active:hover, .dropdown-item.active:active {
  background-color: #f8f9fa;
  color: #2cc185;
}

.dropdown-menu-dark .dropdown-item {
  color: #adadad;
}

.dropdown-menu-dark .dropdown-item.active {
  color: #2cc185;
}

.dropdown-menu-dark .dropdown-item:active {
  background-color: transparent;
}

.dropdown-menu-dark .dropdown-item:hover:hover, .dropdown-menu-dark .dropdown-item:hover:focus {
  color: #FFFFFF;
  background-color: #2cc185;
}

.dropdown-item .icon {
  font-size: 1.385rem;
  vertical-align: middle;
}

.form-control {
  font-size: 1.077rem;
  padding: 0.923077rem 1.307rem;
}

.form-control[multiple] {
  padding: 0;
}

.form-control[multiple] option {
  padding: 14px 13px;
}

textarea.form-control {
  background-color: #fafafa;
}

textarea.form-control:focus {
  background-color: #fafafa;
}

.inputfile {
  width: 0.1px;
  height: 0.1px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: -1;
}

.inputfile + label {
  padding: 0.81rem 0.7692rem;
  display: inline-block;
  cursor: pointer;
}

.inputfile + label i {
  margin-right: 10px;
}

.form-control-xs {
  height: 2.077rem;
  padding: 0.385rem 1.307rem;
  font-size: 1rem;
}

select.form-control-xs:not([size]):not([multiple]) {
  height: 3.12rem;
}

.form-control-sm {
  font-size: 1rem;
  padding: 0.615385rem 1.307rem;
}

.form-control-lg {
  font-size: 1.230rem;
  padding: 1.15385rem 1.307rem;
}

.form-horizontal .form-group.row .col-form-label {
  text-align: right;
}

.custom-select {
  line-height: 1.2;
  padding: 0.923077rem 2.307rem 0.923077rem 1.307rem;
  -moz-appearance: none;
  -webkit-appearance: none;
}

.form-control-success,
.form-control-danger {
  padding-right: 2.3076rem;
  background-repeat: no-repeat;
  background-position: center right 0.807693rem;
  background-size: 1.615385rem 1.615385rem;
}

@media (max-width: 767.98px) {
  .container {
    width: 100%;
    max-width: 100%;
  }
}

.input-group .form-control:hover, .input-group .form-control:focus, .input-group .form-control:active {
  z-index: 0;
}

.input-group-prepend .input-group-text, .input-group-append .input-group-text {
  padding: 0.6rem 0.9rem;
  min-width: 3.847rem;
  font-size: 1rem;
  color: #c2c2c2;
}

.input-group-lg > .input-group-prepend, .input-group-lg > .input-group-append {
  font-size: 1.38462rem;
}

.input-group-prepend .custom-control, .input-group-append .custom-control {
  margin: 0;
  padding: 0;
}

.input-group-prepend input[type="radio"], .input-group-append input[type="radio"], .input-group-prepend
input[type="checkbox"], .input-group-append
input[type="checkbox"] {
  vertical-align: middle;
  margin-right: 0;
}

.input-group-btn {
  flex-direction: row;
}

.input-search input {
  border: 1px solid #ebebeb;
  border-radius: 0;
  display: block;
  font-size: 1rem;
}

.input-search input:focus {
  background-color: transparent;
}

.input-search .input-group-btn {
  position: absolute;
  right: 0;
  display: block;
  width: auto;
  top: 1px;
  right: 1px;
  z-index: 3;
}

.input-search .input-group-btn button {
  box-shadow: none;
  border: none;
  height: 3.07077rem;
  cursor: pointer;
}

.input-search .input-group-btn button:active {
  background-color: transparent;
  box-shadow: none;
}

.input-search .input-group-btn button .icon {
  font-size: 20px;
  color: #878787;
}

.input-search .input-group-btn button:hover {
  background-color: transparent;
}

.input-search.input-group-lg .input-group-btn button {
  height: 3.68615rem;
}

.input-search.input-group-sm .input-group-btn button {
  height: 2.37846rem;
}

.input-search.input-group-sm .input-group-btn button .icon {
  font-size: 22px;
}

.input-group-lg > .form-control:not(textarea),
.input-group-lg > .input-group-prepend > .input-group-text,
.input-group-lg > .input-group-append > .input-group-text,
.input-group-lg > .input-group-prepend > .btn,
.input-group-lg > .input-group-append > .btn {
  font-size: 1.230rem;
}

.input-group-sm > .form-control:not(textarea),
.input-group-sm > .input-group-prepend > .input-group-text,
.input-group-sm > .input-group-append > .input-group-text,
.input-group-sm > .input-group-prepend > .btn,
.input-group-sm > .input-group-append > .btn {
  font-size: 1rem;
}

/*------------------------------------------------------------------
  [List group]
*/
.list-group-item.active .badge {
  background: #FFFFFF;
  color: #2cc185;
}

.list-group-item .icon {
  flex: 0 0 auto;
  margin-right: .7rem;
  font-size: 1.385rem;
  vertical-align: middle;
}

.list-group-item.active .icon {
  color: #FFFFFF;
}

.list-group-item .text {
  flex: 1 1 auto;
}

.list-group-item .badge {
  flex: 0 0 auto;
}

.active .list-group-item .badge {
  background: #FFFFFF;
  color: #2cc185;
}

.modal-content {
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.3);
  overflow: hidden;
  background-color: #FFFFFF;
  color: #545454;
  border: none;
  border-radius: 0;
  max-width: 545px;
}

.modal-content .btn-link {
  font-weight: 400;
}

.modal-header {
  border: 0;
  padding: 19px;
  justify-content: flex-end;
}

.modal-header h1, .modal-header h2, .modal-header h3, .modal-header h4, .modal-header h5, .modal-header h6 {
  display: block;
  margin: 0;
  color: #545454;
}

.modal-header .close {
  font-size: 20px;
  opacity: 0.4;
  text-shadow: none;
}

.modal-header .close:hover {
  opacity: 0.8;
}

.modal-body {
  padding: 20px 30px 30px;
}

.modal-body .row:last-child .form-group {
  margin-bottom: 0;
}

.modal-main-icon {
  display: inline-block;
  height: 77px;
  width: 77px;
  line-height: normal;
  font-size: 77px;
  margin-bottom: 37px;
}

.modal-footer {
  padding: 20px 30px;
  border: none;
}

.modal-dark .modal-content {
  background-color: #323232;
  color: #d1d1d1;
}

.modal-dark .modal-header .close, .modal-dark .modal-header .close:not(:disabled):not(.disabled):focus {
  color: #FFFFFF;
  opacity: 0.6;
}

.modal-dark .modal-header .close:hover, .modal-dark .modal-header .close .close:not(:disabled):not(.disabled):hover, .modal-dark .modal-header .close:not(:disabled):not(.disabled):focus:hover, .modal-dark .modal-header .close:not(:disabled):not(.disabled):focus .close:not(:disabled):not(.disabled):hover {
  color: #FFFFFF;
  opacity: 1;
}

.modal-dark .btn {
  border-width: 0;
}

.modal-dark .modal-footer .btn-link-secondary {
  color: #e0e0e0;
}

.modal-dark .modal-footer .btn-link-secondary:hover {
  color: #cccccc;
}

.modal-header-colored {
  justify-content: space-between;
  background-color: whitesmoke;
}

.modal-header-colored + .modal-body {
  padding-top: 30px;
}

.modal-dark .modal-header-colored {
  background-color: #4a4a4a;
}

.modal-dark .modal-header-colored h1, .modal-dark .modal-header-colored h2, .modal-dark .modal-header-colored h3, .modal-dark .modal-header-colored h4, .modal-dark .modal-header-colored h5, .modal-dark .modal-header-colored h6 {
  color: #d1d1d1;
}

.modal-header-colored-primary, .modal-dark .modal-header-colored-primary {
  background-color: #2cc185;
}

.modal-header-colored-primary h1, .modal-header-colored-primary h2, .modal-header-colored-primary h3, .modal-header-colored-primary h4, .modal-header-colored-primary h5, .modal-header-colored-primary h6, .modal-dark .modal-header-colored-primary h1, .modal-dark .modal-header-colored-primary h2, .modal-dark .modal-header-colored-primary h3, .modal-dark .modal-header-colored-primary h4, .modal-dark .modal-header-colored-primary h5, .modal-dark .modal-header-colored-primary h6 {
  color: #FFFFFF;
}

.modal-header-colored-primary .close, .modal-dark .modal-header-colored-primary .close {
  color: #FFFFFF !important;
  opacity: 0.6 !important;
}

.modal-header-colored-primary .close:hover, .modal-dark .modal-header-colored-primary .close:hover {
  opacity: 1 !important;
}

.modal-header-colored-success, .modal-dark .modal-header-colored-success {
  background-color: #2cc185;
}

.modal-header-colored-success h1, .modal-header-colored-success h2, .modal-header-colored-success h3, .modal-header-colored-success h4, .modal-header-colored-success h5, .modal-header-colored-success h6, .modal-dark .modal-header-colored-success h1, .modal-dark .modal-header-colored-success h2, .modal-dark .modal-header-colored-success h3, .modal-dark .modal-header-colored-success h4, .modal-dark .modal-header-colored-success h5, .modal-dark .modal-header-colored-success h6 {
  color: #FFFFFF;
}

.modal-header-colored-success .close, .modal-dark .modal-header-colored-success .close {
  color: #FFFFFF !important;
  opacity: 0.6 !important;
}

.modal-header-colored-success .close:hover, .modal-dark .modal-header-colored-success .close:hover {
  opacity: 1 !important;
}

.modal-header-colored-info, .modal-dark .modal-header-colored-info {
  background-color: #58C7EF;
}

.modal-header-colored-info h1, .modal-header-colored-info h2, .modal-header-colored-info h3, .modal-header-colored-info h4, .modal-header-colored-info h5, .modal-header-colored-info h6, .modal-dark .modal-header-colored-info h1, .modal-dark .modal-header-colored-info h2, .modal-dark .modal-header-colored-info h3, .modal-dark .modal-header-colored-info h4, .modal-dark .modal-header-colored-info h5, .modal-dark .modal-header-colored-info h6 {
  color: #FFFFFF;
}

.modal-header-colored-info .close, .modal-dark .modal-header-colored-info .close {
  color: #FFFFFF !important;
  opacity: 0.6 !important;
}

.modal-header-colored-info .close:hover, .modal-dark .modal-header-colored-info .close:hover {
  opacity: 1 !important;
}

.modal-header-colored-warning, .modal-dark .modal-header-colored-warning {
  background-color: #FFDC42;
}

.modal-header-colored-warning h1, .modal-header-colored-warning h2, .modal-header-colored-warning h3, .modal-header-colored-warning h4, .modal-header-colored-warning h5, .modal-header-colored-warning h6, .modal-dark .modal-header-colored-warning h1, .modal-dark .modal-header-colored-warning h2, .modal-dark .modal-header-colored-warning h3, .modal-dark .modal-header-colored-warning h4, .modal-dark .modal-header-colored-warning h5, .modal-dark .modal-header-colored-warning h6 {
  color: #FFFFFF;
}

.modal-header-colored-warning .close, .modal-dark .modal-header-colored-warning .close {
  color: #FFFFFF !important;
  opacity: 0.6 !important;
}

.modal-header-colored-warning .close:hover, .modal-dark .modal-header-colored-warning .close:hover {
  opacity: 1 !important;
}

.modal-header-colored-danger, .modal-dark .modal-header-colored-danger {
  background-color: #F45846;
}

.modal-header-colored-danger h1, .modal-header-colored-danger h2, .modal-header-colored-danger h3, .modal-header-colored-danger h4, .modal-header-colored-danger h5, .modal-header-colored-danger h6, .modal-dark .modal-header-colored-danger h1, .modal-dark .modal-header-colored-danger h2, .modal-dark .modal-header-colored-danger h3, .modal-dark .modal-header-colored-danger h4, .modal-dark .modal-header-colored-danger h5, .modal-dark .modal-header-colored-danger h6 {
  color: #FFFFFF;
}

.modal-header-colored-danger .close, .modal-dark .modal-header-colored-danger .close {
  color: #FFFFFF !important;
  opacity: 0.6 !important;
}

.modal-header-colored-danger .close:hover, .modal-dark .modal-header-colored-danger .close:hover {
  opacity: 1 !important;
}

.modal-header-colored-dark, .modal-dark .modal-header-colored-dark {
  background-color: #323232;
}

.modal-header-colored-dark h1, .modal-header-colored-dark h2, .modal-header-colored-dark h3, .modal-header-colored-dark h4, .modal-header-colored-dark h5, .modal-header-colored-dark h6, .modal-dark .modal-header-colored-dark h1, .modal-dark .modal-header-colored-dark h2, .modal-dark .modal-header-colored-dark h3, .modal-dark .modal-header-colored-dark h4, .modal-dark .modal-header-colored-dark h5, .modal-dark .modal-header-colored-dark h6 {
  color: #d1d1d1;
}

.modal-header-colored-dark .close, .modal-dark .modal-header-colored-dark .close {
  color: #FFFFFF !important;
  opacity: 0.6 !important;
}

.modal-header-colored-dark .close:hover, .modal-dark .modal-header-colored-dark .close:hover {
  opacity: 1 !important;
}

.modal-full-color .modal-content {
  color: #FFFFFF;
}

.modal-full-color .modal-header .close, .modal-full-color .modal-header .close:not(:disabled):not(.disabled):focus {
  color: #FFFFFF;
  opacity: 0.6;
}

.modal-full-color .modal-header .close:hover, .modal-full-color .modal-header .close .close:not(:disabled):not(.disabled):hover, .modal-full-color .modal-header .close:not(:disabled):not(.disabled):focus:hover, .modal-full-color .modal-header .close:not(:disabled):not(.disabled):focus .close:not(:disabled):not(.disabled):hover {
  color: #FFFFFF;
  opacity: 1;
}

.modal-full-color .modal-footer .btn-link-secondary {
  color: #FFFFFF;
}

.modal-full-color .modal-footer .btn-link-secondary:hover {
  color: #ebebeb;
}

.modal-full-color .btn {
  border-width: 0;
}

.modal-full-color .btn-primary,
.modal-full-color .btn-success,
.modal-full-color .btn-info,
.modal-full-color .btn-warning,
.modal-full-color .btn-danger,
.modal-full-color .btn-dark {
  background-color: rgba(0, 0, 0, 0);
}

.modal-full-color .btn-primary:focus,
.modal-full-color .btn-success:focus,
.modal-full-color .btn-info:focus,
.modal-full-color .btn-warning:focus,
.modal-full-color .btn-danger:focus,
.modal-full-color .btn-dark:focus {
  background-color: rgba(0, 0, 0, 0);
}

.modal-full-color .btn-primary:hover, .modal-full-color .btn-primary .hover,
.modal-full-color .btn-success:hover,
.modal-full-color .btn-success .hover,
.modal-full-color .btn-info:hover,
.modal-full-color .btn-info .hover,
.modal-full-color .btn-warning:hover,
.modal-full-color .btn-warning .hover,
.modal-full-color .btn-danger:hover,
.modal-full-color .btn-danger .hover,
.modal-full-color .btn-dark:hover,
.modal-full-color .btn-dark .hover {
  background-color: rgba(0, 0, 0, 0.1);
}

.modal-full-color .btn-primary:active,
.modal-full-color .btn-success:active,
.modal-full-color .btn-info:active,
.modal-full-color .btn-warning:active,
.modal-full-color .btn-danger:active,
.modal-full-color .btn-dark:active {
  background-color: rgba(0, 0, 0, 0.15);
}

.modal-full-color-primary .modal-header {
  background-color: #2cc185;
}

.modal-full-color-primary .modal-body, .modal-full-color-primary .modal-footer {
  background-color: #2cc185;
}

.modal-full-color-primary .btn-default {
  color: #2cc185;
}

.modal-full-color-primary .btn-default:active {
  color: #2cc185;
}

.modal-full-color-success .modal-header {
  background-color: #2cc185;
}

.modal-full-color-success .modal-body, .modal-full-color-success .modal-footer {
  background-color: #2cc185;
}

.modal-full-color-success .btn-default {
  color: #2cc185;
}

.modal-full-color-success .btn-default:active {
  color: #2cc185;
}

.modal-full-color-info .modal-header {
  background-color: #58C7EF;
}

.modal-full-color-info .modal-body, .modal-full-color-info .modal-footer {
  background-color: #58C7EF;
}

.modal-full-color-info .btn-default {
  color: #58C7EF;
}

.modal-full-color-info .btn-default:active {
  color: #58C7EF;
}

.modal-full-color-warning .modal-header {
  background-color: #FFDC42;
}

.modal-full-color-warning .modal-body, .modal-full-color-warning .modal-footer {
  background-color: #FFDC42;
}

.modal-full-color-warning .btn-default {
  color: #FFDC42;
}

.modal-full-color-warning .btn-default:active {
  color: #FFDC42;
}

.modal-full-color-danger .modal-header {
  background-color: #F45846;
}

.modal-full-color-danger .modal-body, .modal-full-color-danger .modal-footer {
  background-color: #F45846;
}

.modal-full-color-danger .btn-default {
  color: #F45846;
}

.modal-full-color-danger .btn-default:active {
  color: #F45846;
}

.modal-full-color-dark .modal-header {
  background-color: #323232;
}

.modal-full-color-dark .modal-body, .modal-full-color-dark .modal-footer {
  background-color: #323232;
}

.modal-full-color-dark .btn-default {
  color: #323232;
}

.modal-full-color-dark .btn-default:active {
  color: #323232;
}

.full-width {
  width: 90%;
  max-width: none;
}

@media (max-width: 767.98px) {
  .full-width {
    width: 100%;
  }
}

.full-width.modal-container {
  width: 100%;
}

@media (max-width: 767.98px) {
  .full-width.modal-container {
    width: 100%;
  }
}

.full-width.modal-container .modal-content {
  width: 81%;
}

@media (max-width: 767.98px) {
  .full-width.modal-container .modal-content {
    width: 100%;
  }
}

.full-width .modal-content {
  width: 100%;
  max-width: none;
}

.custom-width .modal-dialog {
  width: 700px;
  max-width: none;
}

.custom-width .modal-content {
  width: 700px;
  max-width: none;
}

@media (max-width: 767.98px) {
  .custom-width .modal-content, .custom-width .modal-dialog, .custom-width .modal-header {
    width: 100% !important;
  }
}

@media (max-width: 575.98px) {
  .modal-dialog {
    margin: 0 auto;
  }
}

.mai-modal-open {
  overflow-x: visible;
}

@media (max-width: 767.98px) {
  .nav {
    flex-wrap: nowrap;
  }
}

.nav-link {
  font-size: 1.077rem;
  color: #545454;
}

.nav-link.hover, .nav-link:hover {
  color: #2cc185;
}

.nav-link .icon {
  font-size: 1.231rem;
  vertical-align: middle;
}

.tab-content {
  padding: 1.538rem;
  background-color: #FFFFFF;
}

.nav-tabs-dark .nav-link.active {
  background-color: #545454;
  color: #2cc185;
}

.nav-tabs-dark + .tab-content {
  background-color: #545454;
  color: #FFFFFF;
}

.nav-tabs-primary .nav-link.active {
  background-color: #2cc185;
  color: #FFFFFF;
}

.nav-tabs-primary + .tab-content {
  background-color: #2cc185;
  color: #FFFFFF;
}

.tab-left .nav-tabs, .tab-right .nav-tabs {
  float: left;
  flex-direction: column;
}

.tab-left .nav-tabs > li, .tab-right .nav-tabs > li {
  float: none;
  margin-right: 0;
  margin-bottom: 0;
}

.tab-left .nav-tabs > li > a, .tab-right .nav-tabs > li > a {
  text-align: left;
  min-width: 38px;
  padding: 1.154rem 1.308rem;
}

.tab-left .tab-content, .tab-right .tab-content {
  overflow: auto;
}

.tab-right .nav-tabs {
  float: right;
}

.tab-icons .nav-link .icon {
  font-size: 1.6rem;
}

/*------------------------------------------------------------------
  [Pagination]
*/
.pagination {
  padding-left: 0;
  border-radius: 0;
}

.page-item:first-child .page-link {
  border-radius: 0;
  padding: 0.4rem 0.35rem 0.6rem;
}

.page-item:last-child .page-link {
  border-radius: 0;
  padding: 0.4rem 0.35rem 0.6rem;
}

.pagination-rounded .page-item:first-child .page-link {
  border-radius: 50%;
  padding: 0.35rem 0 0.55rem;
}

.pagination-rounded .page-item:last-child .page-link {
  border-radius: 50%;
  padding: 0.35rem 0 0.55rem;
}

.page-link {
  color: #4ad69e;
  background-color: #FFFFFF;
  border: 1px solid #dee2e6;
  margin-left: .4923rem;
}

.page-link > span {
  font-size: 1.846rem;
  line-height: 0.5;
  vertical-align: middle;
}

.pagination-rounded .page-link {
  border-radius: 50%;
  width: 2.492rem;
  height: 2.492rem;
  padding: 0.5rem 0;
  text-align: center;
  line-height: 1.4;
  vertical-align: middle;
}

.popover-title {
  margin-top: 0;
}

/*------------------------------------------------------------------
  [Tables]
*/
.table > thead > tr > th {
  border-bottom-width: 2px;
  border-top-width: 0;
  font-weight: 400;
}

.table > thead.primary > tr > th {
  color: #2cc185;
}

.table > thead.success > tr > th {
  color: #2cc185;
}

.table > tbody > tr > td {
  font-weight: 400;
  vertical-align: middle;
}

.ma-table-responsive {
  scroller-x: hidden;
}

@media (max-width: 767.98px) {
  .ma-table-responsive {
    display: block;
    width: 100%;
    overflow-x: auto;
  }
}

.table-bordered > thead > tr > th {
  padding: 13px 10px;
  border-bottom-width: 1px;
}

.table-bordered tbody > tr td:first-child {
  padding-left: 10px;
}

.table-bordered tbody > tr td:last-child {
  padding-right: 10px;
}

.table-primary,
.table-primary > th,
.table-primary > td {
  background-color: #2cc185;
  color: #FFFFFF;
}

.table-hover .table-primary:hover {
  background-color: #27ac77;
  color: #FFFFFF;
}

.table-hover .table-primary:hover > td,
.table-hover .table-primary:hover > th {
  background-color: #27ac77;
  color: #FFFFFF;
}

.table-success,
.table-success > th,
.table-success > td {
  background-color: #2cc185;
  color: #FFFFFF;
}

.table-hover .table-success:hover {
  background-color: #27ac77;
  color: #FFFFFF;
}

.table-hover .table-success:hover > td,
.table-hover .table-success:hover > th {
  background-color: #27ac77;
  color: #FFFFFF;
}

.table-info,
.table-info > th,
.table-info > td {
  background-color: #58C7EF;
  color: #FFFFFF;
}

.table-hover .table-info:hover {
  background-color: #41bfed;
  color: #FFFFFF;
}

.table-hover .table-info:hover > td,
.table-hover .table-info:hover > th {
  background-color: #41bfed;
  color: #FFFFFF;
}

.table-warning,
.table-warning > th,
.table-warning > td {
  background-color: #FFDC42;
  color: #FFFFFF;
}

.table-hover .table-warning:hover {
  background-color: #ffd729;
  color: #FFFFFF;
}

.table-hover .table-warning:hover > td,
.table-hover .table-warning:hover > th {
  background-color: #ffd729;
  color: #FFFFFF;
}

.table-danger,
.table-danger > th,
.table-danger > td {
  background-color: #F45846;
  color: #FFFFFF;
}

.table-hover .table-danger:hover {
  background-color: #f3422e;
  color: #FFFFFF;
}

.table-hover .table-danger:hover > td,
.table-hover .table-danger:hover > th {
  background-color: #f3422e;
  color: #FFFFFF;
}

.table-dark,
.table-dark > th,
.table-dark > td {
  background-color: #545454;
  color: #FFFFFF;
}

.table-hover .table-dark:hover {
  background-color: #474747;
  color: #FFFFFF;
}

.table-hover .table-dark:hover > td,
.table-hover .table-dark:hover > th {
  background-color: #474747;
  color: #FFFFFF;
}

.table-responsive {
  border: 0;
  margin-bottom: 0;
}

.table-responsive tbody > tr > td {
  padding-top: 0.807692rem;
  padding-bottom: 0.807692rem;
}

.table tr th .custom-control, .table tr td .custom-control {
  padding: 0;
  margin: 0;
  vertical-align: middle;
}

.table tr th.actions, .table tr td.actions {
  text-align: center;
}

.table tr th.actions .icon, .table tr td.actions .icon {
  color: #545454;
  font-size: 1.38462rem;
  line-height: 0.8;
  vertical-align: middle;
}

.table tr th.actions .icon:hover, .table tr td.actions .icon:hover {
  color: gray;
}

.table tbody tr td.user-avatar img {
  height: 30px;
  width: 30px;
  border-radius: 50%;
  margin-right: 10px;
}

@media (max-width: 575.98px) {
  .table tbody tr td.user-avatar img {
    display: none;
  }
}

.table tbody tr td.cell-detail span {
  display: block;
}

.table tbody tr td.cell-detail .cell-detail-description {
  display: block;
  font-size: .846154rem;
  color: #999999;
}

.table tbody tr td.user-info img {
  float: left;
}

.table tbody tr td.user-info span {
  padding-left: 40px;
}

.table tbody tr td.milestone {
  min-width: 100px;
}

.table tbody tr td.milestone span {
  display: block;
  font-size: 0.846154rem;
  color: #999999;
  margin-bottom: 0.461538rem;
}

.table tbody tr td.milestone span.completed {
  float: right;
}

.table tbody tr td.milestone .progress {
  height: 6px;
  background-color: #e6e6e6;
  margin-bottom: 0;
}

.table tbody tr td.milestone .progress .progress-bar {
  height: 6px;
  border-radius: 0.25rem;
}

.table tbody tr.online td:first-child {
  border-left: 3px solid #2cc185;
  padding-left: 17px;
}

.table tbody tr.primary td:first-child {
  border-left: 3px solid #2cc185;
  padding-left: 17px;
}

.table tbody tr.info td:first-child {
  border-left: 3px solid #58C7EF;
  padding-left: 17px;
}

.table tbody tr.success td:first-child {
  border-left: 3px solid #2cc185;
  padding-left: 17px;
}

.table tbody tr.warning td:first-child {
  border-left: 3px solid #FFDC42;
  padding-left: 17px;
}

.table tbody tr.danger td:first-child {
  border-left: 3px solid #F45846;
  padding-left: 17px;
}

.table .number {
  text-align: right;
}

.table.table-borderless thead tr th {
  border-bottom: 2px solid #eceeef;
}

.table.table-borderless tbody tr td {
  border-top-width: 0;
}

.card-table table {
  margin-bottom: 0;
}

.card-table tr th:first-child, .card-table tr td:first-child {
  padding-left: 20px;
}

.card-table tr th:last-child, .card-table tr td:last-child {
  padding-right: 20px;
}

.card-table thead tr th {
  padding-top: 15px;
  padding-bottom: 10px;
}

.lead {
  line-height: 1.4;
}

.blockquote p {
  font-size: 1.308rem;
}

.docs {
  margin: 0;
  /* Pretty printing styles. Used with prettify.js. */
  /* Specify class=linenums on a pre to get line numbering */
  /* IE indents via margin-left */
  /* Alternate shading for lines */
}

.docs p {
  font-size: inherit;
}

.docs .aside-container {
  background: #f0f0f0;
  padding-right: 0;
  padding-left: 0;
  width: 284px;
  flex: 0 0 284px;
}

.docs .page-aside {
  background: #f0f0f0;
  padding: 0;
  border-right: 1px solid #e8e8e8;
}

@media (max-width: 767.98px) {
  .docs .page-aside {
    position: relative;
    width: 100%;
    flex: 0 0 100%;
    display: block;
    height: auto;
    margin-top: 0;
    padding-bottom: 0;
    left: 0;
    border-right: 0;
    border-bottom: 2px solid #f2f2f2;
  }
}

.docs .page-aside .navbar-toggler {
  padding: 0;
  padding-right: 11px;
  text-align: right;
  margin: 0;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  position: absolute;
  outline: none;
}

.docs .page-aside .navbar-toggler .icon {
  font-size: 2.07692rem;
  color: #545454;
}

.docs .page-aside .mai-scroller-aside {
  position: relative;
  height: 100%;
  width: 100%;
}

.docs .page-aside .aside-header .title {
  word-wrap: break-word;
}

.docs .page-aside .aside-content {
  position: relative;
  height: 100%;
}

@media (max-width: 767.98px) {
  .docs .page-aside .aside-content {
    width: 100%;
  }
}

.docs .page-aside .aside-content.affix-top {
  position: fixed;
  top: 0;
}

@media (max-width: 767.98px) {
  .docs .page-aside .aside-content.affix-top {
    position: relative;
  }
}

.docs .page-aside .aside-content .mai-scroller {
  position: relative;
  width: 100%;
  height: 100%;
}

.docs .page-aside .aside-content .aside-nav {
  padding: 0;
}

.docs .page-aside .aside-content .aside-nav .navbar-nav {
  padding: 0;
  font-size: 1rem;
  line-height: 20px;
}

.docs .page-aside .aside-content .aside-nav .navbar-nav > .nav-item > .nav-link:hover {
  background-color: #f7f7f7;
}

.docs .page-aside .aside-content .aside-nav .navbar-nav > .nav-item > .nav-link.active {
  color: #2cc185;
}

.docs .page-aside .aside-content .aside-nav .navbar-nav > .nav-item > .nav-link.active + ul {
  display: block;
}

.docs .page-aside .aside-content .aside-nav .navbar-nav > .nav-item > ul {
  padding: 10px 0;
  display: none;
  background-color: #e6e6e6;
  border-top: 1px solid #dedede;
  border-bottom: 1px solid #dedede;
  font-size: 1rem;
  line-height: 20px;
}

.docs .page-aside .aside-content .aside-nav .navbar-nav > .nav-item > ul .nav-link {
  padding: 8px 22px;
}

.docs .page-aside .aside-content .aside-nav .navbar-nav > .nav-item > ul .nav-link:hover {
  background-color: #f0f0f0;
}

.docs .page-aside .aside-content .aside-nav .navbar-nav > .nav-item > ul .nav-link.active {
  color: #2cc185;
}

.docs .shareable-anchor:before {
  display: block;
  height: 3.077rem;
  margin-top: -3.077rem;
  visibility: hidden;
  content: "";
}

.docs .shareable-anchor div a {
  display: none;
}

.docs .shareable-anchor div:hover a {
  display: inline-block;
}

.docs .page-content {
  background-color: #f2f2f2;
  padding-left: 35px;
  padding-right: 35px;
  padding-top: 35px;
}

@media (max-width: 767.98px) {
  .docs .page-content {
    padding-left: 10px;
    padding-right: 10px;
    padding-top: 0;
  }
}

.docs section > h1, .docs section > h2 {
  margin-top: 60px;
  margin-bottom: 25px;
  padding-bottom: 15px;
  border-bottom: 1px solid #ededed;
}

.docs section > h1, .docs section > .h1 {
  font-size: 36px;
}

.docs section > h2, .docs section > .h2 {
  font-size: 30px;
}

.docs section > h3, .docs section > .h3 {
  margin-top: 45px;
  margin-bottom: 10px;
  font-size: 27px;
}

.docs section > h4, .docs section > .h4 {
  margin-top: 20px;
  margin-bottom: 10px;
  font-size: 24px;
}

.docs section > p {
  font-size: 17px;
  line-height: 33px;
  font-weight: 300;
  margin-bottom: 25px;
}

.docs section > p b, .docs section > p strong {
  font-weight: 400;
}

.docs section > ul, .docs section > ol {
  font-size: 17px;
  font-weight: 300;
  line-height: 33px;
}

.docs section > img {
  max-width: 100%;
}

.docs .gallery-container .item {
  width: 50%;
}

.docs .prettyprint {
  font-size: 13px;
  line-height: 1.5;
}

.docs pre {
  border: 0;
  padding: 20px;
  margin-bottom: 0;
}

.docs .code-panel {
  margin-bottom: 30px;
}

.docs .code-panel__heading {
  background: #5A5775;
  color: #cecece;
  font-size: 15px;
  padding: 11px 16px;
}

.docs pre {
  background-color: #4F4A68;
  color: #EDEBE6;
}

.docs pre .pln {
  color: #eeeeee;
}

.docs pre .str {
  color: #92CF78;
}

.docs pre .kwd {
  color: #f386ac;
}

.docs pre .com {
  color: #8275E7;
}

.docs pre .typ {
  color: #97f6fb;
}

.docs pre .lit {
  color: #dcc175;
}

.docs pre .pun,
.docs pre .opn,
.docs pre .clo {
  color: #e3e2e0;
}

.docs pre .tag {
  color: #f386ac;
}

.docs pre .atn {
  color: #1DFDC0;
}

.docs pre .atv {
  color: #F2C770;
}

.docs pre .dec,
.docs pre .var {
  color: #E86493;
}

.docs pre .fun {
  color: #445588;
}

.docs ol.linenums {
  margin-top: 0;
  margin-bottom: 0;
}

.docs li.L0,
.docs li.L1,
.docs li.L2,
.docs li.L3,
.docs li.L5,
.docs li.L6,
.docs li.L7,
.docs li.L8 {
  list-style-type: none;
}

.docs li.L1,
.docs li.L3,
.docs li.L5,
.docs li.L7,
.docs li.L9 {
  background: #eee;
}

@media (max-width: 767.98px) {
  .mai-email .main-content {
    padding: 0;
  }
}

.email {
  margin: 0;
}

.email-aside {
  background: #f0f0f0;
  width: 269px;
  flex: 0 0 269px;
  padding: 0;
  border-right: 1px solid #e8e8e8;
}

@media (max-width: 767.98px) {
  .email-aside {
    position: relative;
    width: 100%;
    flex: 0 0 100%;
    display: block;
    height: auto;
    margin-top: 0;
    padding-bottom: 0;
    left: 0;
    border-right: 0;
    border-bottom: 2px solid #f2f2f2;
  }
}

.email-aside .content {
  position: relative;
}

.aside-header {
  position: relative;
  padding: 20px 23px;
}

.aside-header .title {
  display: block;
  margin: 16px 0 8px;
  font-size: 25px;
  line-height: 25px;
}

.aside-header .navbar-toggler {
  padding: 0;
  padding-right: 11px;
  text-align: right;
  margin: 0;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  position: absolute;
}

.aside-header .navbar-toggler .icon {
  font-size: 2.07692rem;
  color: #545454;
}

.aside-header .description {
  color: #6e6e6e;
  font-size: 1rem;
  line-height: 1;
  margin: 0;
}

.aside-nav {
  visibility: visible;
}

.aside-nav.collapse {
  display: block;
}

@media (max-width: 767.98px) {
  .aside-nav {
    border-bottom: 1px solid #e6e6e6;
  }
  .aside-nav.collapse {
    display: none;
  }
  .aside-nav.show {
    display: block;
  }
}

.aside-nav .navbar-nav li a {
  color: #545454;
  padding: 13px 23px;
}

.aside-nav .navbar-nav li a:hover {
  background-color: #e8e8e8;
}

.aside-nav .navbar-nav li.active a {
  color: #2cc185;
}

.aside-nav .navbar-nav li.active a .icon {
  color: #2cc185;
}

.aside-nav .navbar-nav li.active a:hover {
  background-color: #e8e8e8;
}

.aside-nav .navbar-nav li .badge {
  float: right;
  font-size: .846154rem;
  padding: 4px 7px;
}

.aside-nav .navbar-nav li .icon {
  font-size: 1.46154rem;
  vertical-align: middle;
  text-align: center;
  min-width: 19px;
  margin-right: 3px;
  color: #757575;
}

.aside-nav .title {
  display: block;
  color: #bfbfbf;
  font-size: .846154rem;
  text-transform: uppercase;
  margin-top: 32px;
  padding: 8px 23px 4px;
}

.aside-nav .aside-compose {
  padding: 23px;
}

.email-content {
  padding: 0;
  background-color: #FFFFFF;
}

.email-inbox-header {
  background-color: #FFFFFF;
  padding: 30px 25px;
}

@media (max-width: 767.98px) {
  .email-inbox-header {
    padding: 20px 25px;
  }
}

.email-title {
  display: block;
  margin: 3px 0 0;
  font-size: 2.15385rem;
  line-height: 1;
}

@media (max-width: 767.98px) {
  .email-title {
    margin-bottom: 10px;
  }
}

.email-title .icon {
  font-size: 1.76923rem;
  color: #878787;
}

.email-title .new-messages {
  font-size: 1rem;
  color: #a1a1a1;
  margin-left: 3px;
}

.email-filters {
  padding: 0 20px 30px;
  border-bottom: 1px solid #e6e6e6;
  background-color: #FFFFFF;
  display: table;
  width: 100%;
}

.email-filters > div {
  display: table-cell;
  vertical-align: middle;
}

@media (max-width: 767.98px) {
  .email-filters > div {
    vertical-align: top;
  }
}

.email-filters .btn-group .btn {
  min-width: 38px;
}

.email-filters .btn-group + .btn-group {
  margin-left: 10px;
}

@media (max-width: 575.98px) {
  .email-filters .btn-group + .btn-group {
    margin-left: 0;
    margin-top: 10px;
  }
}

.email-filters .mai-select-all.custom-checkbox {
  display: inline-block;
  vertical-align: middle;
  padding: 0;
  margin-bottom: 0;
  margin-right: 1rem;
}

.email-filters .mai-select-all.custom-checkbox label:before {
  margin-right: 18px;
}

.email-filters .email-filters-right {
  text-align: right;
}

.email-filters input {
  margin-right: 8px;
}

.email-pagination-indicator {
  display: inline-block;
  vertical-align: middle;
  margin-right: 13px;
}

@media (max-width: 767.98px) {
  .email-pagination-indicator {
    position: absolute;
    margin-right: 0;
    top: 36px;
    right: 25px;
  }
}

.email-pagination-nav .btn {
  min-width: 30px;
  padding-right: 3px;
  padding-left: 3px;
}

.email-pagination-nav .btn i {
  font-size: 2.30769rem;
  line-height: 0.2;
  vertical-align: middle;
}

.email-list {
  background: #FFFFFF;
}

.email-list-item {
  border-bottom: 1px solid #e6e6e6;
  padding: 14px 20px;
  display: flex;
  width: 100%;
  cursor: pointer;
  position: relative;
  background-color: whitesmoke;
}

.email-list-item-unread {
  background-color: #FFFFFF;
}

.email-list-item-unread:after {
  content: '';
  display: block;
  position: absolute;
  width: 3px;
  top: -1px;
  bottom: -1px;
  left: 0;
  background-color: #2cc185;
}

.email-list-item-unread .from, .email-list-item-unread .date {
  font-weight: 500;
}

.email-list-item .icon {
  margin-right: 7px;
  font-size: 1.15385rem;
  vertical-align: middle;
  color: #7a7a7a;
}

.email-list-item .from {
  font-size: 1.15385rem;
  line-height: 1;
  color: #3d3d3d;
  flex: 0 0 25%;
}

.email-list-item .msg {
  margin: 0;
  font-size: 1rem;
  line-height: 1;
  color: #878787;
  flex: 0 0 65%;
}

.email-list-item .date {
  flex: 0 0 10%;
  font-size: 1rem;
  line-height: 1;
  text-align: right;
}

.email-list-item:hover {
  background-color: #f7f7f7;
}

.email-list-detail, .email-list-actions {
  display: flex;
  align-items: center;
}

.email-list-detail {
  flex: 1;
  justify-content: space-between;
  margin-left: .692308rem;
}

.email-list-actions .custom-checkbox {
  padding: 0;
  margin: 0;
  flex: 0 0 0%;
}

.email-list-actions .favorite {
  flex: 0 0 0%;
  margin-left: 1rem;
}

.email-list-actions .favorite span {
  font-size: 1.23077rem;
  line-height: 1;
  color: #d4d4d4;
}

.email-list-actions .favorite:hover span {
  color: #a1a1a1;
}

.email-list-actions .favorite.active span {
  color: #FFC600;
}

.email-detail-head {
  background-color: #FFFFFF;
  padding: 45px 45px 30px;
}

@media (max-width: 575.98px) {
  .email-detail-head {
    padding: 45px 30px 30px;
  }
}

.email-head-title {
  padding: 25px 25px;
  color: #6e6e6e;
  font-size: 2.15385rem;
}

.email-head-title .icon {
  color: dimgray;
  margin-right: 12px;
  vertical-align: middle;
  position: relative;
  top: -1px;
  float: left;
  font-size: 1.53846rem;
}

.email-head-sender {
  display: flex;
  align-items: center;
}

@media (max-width: 767.98px) {
  .email-head-sender {
    flex-flow: column;
  }
}

.email-head-sender .avatar {
  margin-right: 10px;
}

.email-head-sender .avatar img {
  max-width: 40px;
  max-height: 40px;
  border-radius: 50%;
}

@media (max-width: 767.98px) {
  .email-head-sender .avatar img {
    max-width: 60px;
    max-height: 60px;
  }
}

.email-head-sender .sender {
  font-size: 1.15385rem;
  flex: 1;
}

.email-head-sender .sender .actions {
  display: inline-block;
  position: relative;
}

.email-head-sender .sender .icon {
  font-size: 1.53846rem;
  color: #6e6e6e;
}

.email-head-sender .sender .icon i {
  vertical-align: middle;
}

.email-head-sender .options {
  display: flex;
  align-items: center;
}

@media (max-width: 767.98px) {
  .email-head-sender .options {
    justify-content: space-between;
    width: 100%;
  }
}

.email-head-sender .options .date {
  display: inline-flex;
  font-size: 1.15385rem;
}

.email-head-sender .options .date .time {
  color: #ababab;
  margin-left: .384615rem;
}

.email-head-sender .options .favorite {
  margin: 0 .384615rem;
}

.email-head-sender .options .favorite span {
  font-size: 1.38462rem;
  line-height: 1;
  color: #d4d4d4;
}

.email-head-sender .options .favorite:hover span {
  color: #a1a1a1;
}

.email-head-sender .options .favorite.active span {
  color: #FFC600;
}

.email-head-subject {
  margin-top: 30px;
  flex: 0 0 50%;
  max-width: 55%;
}

@media (max-width: 767.98px) {
  .email-head-subject {
    text-align: center;
    max-width: none;
  }
}

.email-head-subject .title {
  font-size: 2.76923rem;
  line-height: 1.3;
  font-weight: 300;
}

.email-body {
  background-color: #FFFFFF;
  padding: 30px 45px 45px;
}

@media (max-width: 575.98px) {
  .email-body {
    padding: 30px;
  }
}

.email-body p {
  margin-bottom: 20px;
}

.email-body p:last-child {
  margin-bottom: 0;
}

.email-attachments {
  background-color: #FFFFFF;
  padding: 35px 45px;
  border-top: 1px solid #e6e6e6;
}

@media (max-width: 575.98px) {
  .email-attachments {
    padding: 30px;
  }
}

.email-attachments .attachements-resume {
  display: flex;
  align-items: center;
  margin-bottom: 8px;
}

.email-attachments .attachements-resume .title {
  font-size: 1rem;
  flex: 1 1;
}

@media (max-width: 991.98px) {
  .email-attachments .attachements-resume .title {
    flex: 1 1 50%;
  }
}

.email-attachments .attachements-resume .options {
  flex: 0 1 22%;
  display: flex;
}

@media (max-width: 991.98px) {
  .email-attachments .attachements-resume .options {
    flex: 1 1 22%;
  }
}

.email-attachments .attachements-resume .options a {
  flex: 1 1 50%;
}

.email-attachments .attachements-files .file {
  display: flex;
  align-items: center;
  margin-top: 3px;
}

.email-attachments .attachements-files .file-name {
  flex: 1 1;
  display: flex;
  align-items: center;
}

@media (max-width: 991.98px) {
  .email-attachments .attachements-files .file-name {
    flex: 1 1 50%;
  }
}

.email-attachments .attachements-files .file-name .icon {
  margin-right: .230769rem;
}

.email-attachments .attachements-files .file-name .file-size {
  margin-left: 3px;
}

.email-attachments .attachements-files .file-options {
  flex: 0 1 22%;
  display: flex;
}

@media (max-width: 991.98px) {
  .email-attachments .attachements-files .file-options {
    flex: 1 1 22%;
  }
}

.email-attachments .attachements-files .file-options a {
  flex: 1 1 50%;
}

.email-compose-fields {
  background-color: #FFFFFF;
  padding: 30px 30px 10px;
}

@media (max-width: 767.98px) {
  .email-compose-fields {
    padding-top: 0;
  }
}

.email-compose-fields label {
  padding-top: 15px;
}

.email-editor {
  padding: 30px 30px 0;
}

.email-editor .note-toolbar {
  border: 0;
}

.email-editor .form-group {
  text-align: right;
  margin-top: 15px;
}

.email-editor .form-group .icon {
  font-size: 1.76923rem;
}

/*------------------------------------------------------------------
  [Error pages]
*/
.mai-error .error-container {
  text-align: center;
  margin-top: 50px;
}

.mai-error .error-image svg {
  position: relative;
  max-width: 255px;
  right: -20px;
}

.mai-error .error-image svg .cls-1 {
  fill: #2cc185;
}

.mai-error .error-image svg .cls-2 {
  fill: #25a471;
}

@media (max-width: 575.98px) {
  .mai-error .error-image svg {
    max-width: 123px;
    right: -12px;
  }
}

.mai-error .error-number {
  display: block;
  font-size: 14.0769rem;
  font-weight: 700;
  line-height: 1;
  margin-bottom: 30px;
  max-height: 240px;
}

.mai-error .error-number:after {
  padding-top: 0.25%;
  display: block;
  content: '';
}

@media (max-width: 575.98px) {
  .mai-error .error-number {
    padding: 20px 0;
    font-size: 90px;
    margin-bottom: 5px;
    line-height: 100px;
  }
}

.mai-error .error-description {
  font-size: 2.30769rem;
  line-height: 1;
  margin-bottom: 30px;
}

@media (max-width: 575.98px) {
  .mai-error .error-description {
    font-size: 1.46154rem;
  }
}

.mai-error .error-goback-text {
  font-size: 1.69231rem;
  font-weight: 300;
  margin-bottom: 30px;
  margin-top: 15px;
}

@media (max-width: 575.98px) {
  .mai-error .error-goback-text {
    font-size: 1.15385rem;
  }
}

.mai-error .error-goback-button {
  margin-bottom: 30px;
}

.mai-error .footer {
  margin-bottom: 30px;
}

/*------------------------------------------------------------------
  [Page 404]
*/
@media (max-width: 575.98px) {
  .mai-error-404 .error-container .error-number {
    padding: 0;
    font-size: 70px;
  }
}

@media (max-width: 340px) {
  .mai-error-404 .error-container .error-number {
    font-size: 50px;
    line-height: 70px;
  }
}

@media (max-width: 295px) {
  .mai-error-404 .error-container .error-number {
    font-size: 40px;
  }
}

@media (max-width: 575.98px) {
  .mai-forgot-password {
    height: 100%;
  }
}

.mai-forgot-password .main-content {
  padding: 0;
}

.form-forgot-password .contact {
  text-align: center;
  font-size: 1rem;
}

/*------------------------------------------------------------------
  [Gallery]
*/
.gallery-container .item {
  width: 25%;
  margin-bottom: 20px;
  padding: 0 10px;
}

.gallery-container .item .photo {
  background: #FFFFFF;
}

.gallery-container .item .photo .img {
  position: relative;
}

.gallery-container .item .photo .img img {
  width: 100%;
}

.gallery-container .item .photo .img .over {
  top: 0;
  opacity: 0;
  position: absolute;
  height: 100%;
  width: 100%;
  background: rgba(44, 193, 133, 0.8);
  transition: opacity 300ms ease;
  -webkit-transition: opacity 300ms ease;
  overflow: hidden;
}

.gallery-container .item .photo .img .over .info-wrapper {
  display: table;
  height: 100%;
  width: 100%;
  margin-top: -80px;
  transition: margin-top 200ms ease-in-out;
}

.gallery-container .item .photo .img .over .info {
  display: table-cell;
  vertical-align: middle;
  position: relative;
  top: 0;
  text-align: center;
  color: #FFFFFF;
  padding: 0 10px;
}

.gallery-container .item .photo .img .over .title {
  font-size: 1.38462rem;
  font-weight: 300;
}

.gallery-container .item .photo .img .over .description {
  margin: 10px 0 18px;
  line-height: 15px;
}

.gallery-container .item .photo .img .over .func a {
  display: inline-block;
  height: 30px;
  width: 30px;
  margin-right: 10px;
  background: rgba(255, 255, 255, 0.2);
  border-radius: 50%;
}

.gallery-container .item .photo .img .over .func a:hover {
  background: rgba(255, 255, 255, 0.3);
}

.gallery-container .item .photo .img .over .func a i {
  font-size: 18px;
  color: #FFFFFF;
  line-height: 30px;
}

.gallery-container .item .photo .img:hover .over {
  opacity: 1;
}

.gallery-container .item .photo .img:hover .over .info-wrapper {
  margin-top: 0;
}

.gallery-container .item .photo .description {
  line-height: 20px;
  display: table;
  width: 100%;
}

.gallery-container .item .photo .description .icon {
  display: table-cell;
  background-color: #e6e6e6;
  padding: 20px;
  vertical-align: middle;
  width: 20px;
}

.gallery-container .item .photo .description .icon a {
  color: #616161;
}

.gallery-container .item .photo .description .icon a i {
  font-size: 30px;
}

@media (max-width: 575.98px) {
  .gallery-container .item .photo .description .icon a i {
    font-size: 20px;
  }
}

.gallery-container .item .photo .description .icon a:hover {
  color: #2cc185;
}

@media (max-width: 575.98px) {
  .gallery-container .item .photo .description .icon {
    padding: 15px;
  }
}

.gallery-container .item .photo .description .desc {
  display: table-cell;
  padding: 10px 20px;
  vertical-align: middle;
}

.gallery-container .item .photo .description .desc h4 {
  margin: 0;
  font-size: 18px;
}

@media (max-width: 575.98px) {
  .gallery-container .item .photo .description .desc h4 {
    font-size: 15px;
  }
}

.gallery-container .item .photo .description .desc span {
  color: #7f7f7f;
  font-size: 12px;
  font-weight: 300;
}

@media (max-width: 575.98px) {
  .gallery-container .item .photo .description .desc {
    padding: 10px;
  }
}

.gallery-container .item.w2 {
  width: 50%;
}

@media (max-width: 1199.98px) {
  .gallery-container .item {
    width: 33.333%;
  }
}

@media (max-width: 991.98px) {
  .gallery-container .item {
    width: 50%;
  }
}

@media (max-width: 575.98px) {
  .gallery-container {
    max-width: 767px;
  }
  .gallery-container .item {
    width: 100%;
    padding: 0;
  }
  .gallery-container .item.w2 {
    width: 100%;
  }
}

.invoice-aside {
  background-color: #ededed;
  display: flex;
  flex-direction: column;
  padding: 50px 33px;
  min-width: 300px;
}

@media (max-width: 575.98px) {
  .invoice-aside {
    padding: 25px;
  }
}

.invoice-logo {
  height: 39px;
  min-width: 233px;
  background-image: url("../img/logo-inv-2x.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  background-position: 0;
  margin: 0 auto 115px;
}

@media (max-width: 575.98px) {
  .invoice-logo {
    height: 20px;
    min-width: 133px;
    width: 133px;
    margin: 0 auto 57.5px;
    float: none;
    min-width: auto;
  }
}

.invoice-person {
  text-align: center;
}

.invoice-person .name {
  font-size: 1.53846rem;
  font-weight: 600;
}

@media (max-width: 575.98px) {
  .invoice-person .name {
    font-size: 1.07692rem;
  }
}

.invoice-person .position {
  font-size: 1.23077rem;
}

@media (max-width: 575.98px) {
  .invoice-person .position {
    font-size: 1rem;
  }
}

.invoice-person span {
  font-size: 1.23077rem;
  line-height: 1.78;
  display: block;
  font-weight: 300;
}

@media (max-width: 575.98px) {
  .invoice-person span {
    font-size: 1rem;
  }
}

.invoice-payment-direction {
  text-align: center;
  margin: 89px 0;
}

@media (max-width: 575.98px) {
  .invoice-payment-direction {
    margin: 44.5px 0;
  }
}

.invoice-payment-direction .icon {
  font-size: 4.23077rem;
  line-height: 1;
}

@media (max-width: 767.98px) {
  .invoice-payment-direction .icon {
    font-size: 1.92308rem;
  }
}

.invoice-company-info {
  margin-top: 100px;
}

@media (max-width: 575.98px) {
  .invoice-company-info {
    margin-top: 50px;
    text-align: center;
  }
}

.invoice-company-info .summary {
  font-size: 1.077rem;
  line-height: 1.3;
}

@media (max-width: 575.98px) {
  .invoice-company-info .summary {
    font-size: 1rem;
  }
}

.invoice-company-info .summary .title {
  display: block;
  font-size: 1.38462rem;
  line-height: 1;
  font-weight: 600;
  margin-bottom: 1.38462rem;
}

@media (max-width: 575.98px) {
  .invoice-company-info .summary .title {
    font-size: 1.07692rem;
  }
}

.invoice-company-info .phone li, .invoice-company-info .email li {
  font-size: 1.077rem;
  line-height: 1.7;
}

.invoice-content {
  background-color: #FFFFFF;
  padding: 50px 33px;
  flex: 1 1 0%;
}

@media (max-width: 575.98px) {
  .invoice-content {
    padding: 25px;
  }
}

.invoice-header {
  margin-bottom: 100px;
}

@media (max-width: 575.98px) {
  .invoice-header {
    margin-bottom: 50px;
  }
}

.invoice-title {
  font-size: 2.69231rem;
  font-weight: 300;
}

@media (max-width: 575.98px) {
  .invoice-title {
    font-size: 1.92308rem;
  }
}

.invoice-order {
  text-align: right;
}

.invoice-order .invoice-number {
  display: block;
  margin-top: 1rem;
  font-size: 1.38462rem;
}

@media (max-width: 575.98px) {
  .invoice-order .invoice-number {
    font-size: 1.07692rem;
  }
}

.invoice-order .invoice-date {
  font-size: 1.077rem;
  color: #858585;
}

@media (max-width: 575.98px) {
  .invoice-order .invoice-date {
    font-size: 1rem;
  }
}

.invoice-details {
  width: 100%;
  font-size: 1.07692rem;
  margin-bottom: 110px;
}

@media (max-width: 575.98px) {
  .invoice-details {
    font-size: 1rem;
    margin-bottom: 55px;
  }
}

.invoice-details thead th {
  text-align: right;
  padding-bottom: 1.69231rem;
  font-size: 1.38462rem;
}

@media (max-width: 575.98px) {
  .invoice-details thead th {
    font-size: 1rem;
    padding-bottom: 1.15385rem;
  }
}

.invoice-details thead th:first-child {
  text-align: left;
}

.invoice-details tr > td {
  padding: 23px 0;
}

@media (max-width: 767.98px) {
  .invoice-details tr > td {
    padding: 10px 0;
  }
}

.invoice-details tbody .hours {
  text-align: right;
}

.invoice-details tbody .amount {
  text-align: right;
}

.invoice-summary {
  width: 100%;
  font-size: 1.38462rem;
  border-top: 1px solid #d9d9d9;
  margin-bottom: 110px;
}

@media (max-width: 575.98px) {
  .invoice-summary {
    margin-bottom: 55px;
    font-size: 1.07692rem;
  }
}

.invoice-summary th {
  padding-top: 26px;
  font-weight: 600;
  width: 20%;
}

@media (max-width: 575.98px) {
  .invoice-summary th {
    width: 37%;
  }
}

.invoice-summary th.total {
  width: 60%;
  font-size: 1.84615rem;
  text-align: right;
}

@media (max-width: 575.98px) {
  .invoice-summary th.total {
    font-size: 1.07692rem;
    width: 26%;
  }
}

.invoice-summary .total-value {
  text-align: right;
  font-size: 3.07692rem;
}

@media (max-width: 575.98px) {
  .invoice-summary .total-value {
    font-size: 1.07692rem;
    font-weight: 600;
  }
}

.invoice-payment-details {
  border: 1px solid #d9d9d9;
  border-left-width: 0;
  border-right-width: 0;
  padding: 23px 0;
}

.invoice-payment-details p {
  margin-bottom: .538462rem;
}

.invoice-message .title {
  display: block;
  font-weight: 600;
  text-transform: uppercase;
  color: #545454;
  font-size: 1.23077rem;
}

@media (max-width: 575.98px) {
  .invoice-message .title {
    font-size: 1.077rem;
  }
}

.invoice-message p {
  font-size: 1.23077rem;
  color: #999999;
  line-height: 1.6;
}

@media (max-width: 575.98px) {
  .invoice-message p {
    font-size: 1rem;
  }
}

.invoice-footer {
  text-align: right;
}

@media (max-width: 575.98px) {
  .invoice-footer {
    text-align: center;
  }
}

@media (max-width: 575.98px) {
  .mai-login {
    height: 100%;
  }
}

.mai-login .main-content {
  padding: 0;
}

.mai-login ::-webkit-input-placeholder {
  /* WebKit browsers */
  color: rgba(255, 255, 255, 0.4);
}

.mai-login :-moz-placeholder {
  /* Mozilla Firefox 4 to 18 */
  color: rgba(255, 255, 255, 0.4);
  opacity: 1;
}

.mai-login :-ms-input-placeholder {
  /* Internet Explorer 10+ */
  color: rgba(255, 255, 255, 0.4);
}

.login-submit {
  padding: 2.4rem 0 2.5rem;
}

.login-tools > div {
  padding: 0;
}

.login-remember .custom-control-label::before {
  background-color: transparent;
  border-width: 2px;
  border-color: #4c4c4c;
}

.login-remember .custom-control-input:checked ~ .custom-control-label::before,
.login-remember .custom-control-input:active ~ .custom-control-label::before {
  background-color: transparent;
}

.login-forgot-password a {
  color: #FFFFFF;
  opacity: .8;
}

.login-forgot-password a:hover {
  opacity: 1;
}

@media (max-width: 575.98px) {
  .mai-sign-up {
    height: 100%;
  }
}

.mai-sign-up .main-content {
  padding: 0;
}

.sign-up-form .form-group.inline [class*="col-"] {
  padding: 0;
}

.sign-up-form .sign-up-submit {
  padding: 1.53846rem 0 1.15385rem;
}

.sign-up-form .title {
  font-size: 15px;
  color: #d9d9d9;
  text-align: center;
}

.sign-up-form .social-signup [class*="col-"] {
  padding: 0 7px;
}

.sign-up-form .social-signup [class*="col-"]:first-child {
  padding-left: 0;
}

.sign-up-form .social-signup [class*="col-"]:last-child {
  padding-right: 0;
}

.sign-up-form .conditions {
  text-align: center;
  font-size: 1rem;
}

/*------------------------------------------------------------------
  [dataTables]
*/
table.dataTable {
  margin: 0 !important;
  border-collapse: collapse !important;
}

table.dataTable thead .sorting:before,
table.dataTable thead .sorting:after,
table.dataTable thead .sorting_asc:before,
table.dataTable thead .sorting_asc:after,
table.dataTable thead .sorting_desc:before,
table.dataTable thead .sorting_desc:after {
  font-size: 20px;
  bottom: .5rem;
}

div.dataTables_wrapper {
  flex-flow: column;
  align-items: stretch;
  padding-left: 0;
  padding-right: 0;
}

div.dataTables_wrapper div.dataTables_info {
  color: #757575;
  padding-top: 5px;
}

div.dataTables_wrapper div.dataTables_paginate ul.pagination {
  margin: 0;
  justify-content: flex-end;
}

div.dataTables_wrapper div.dataTables_length label, div.dataTables_wrapper div.dataTables_filter label {
  margin-bottom: 0;
  font-size: 13px;
  color: #757575;
}

div.dataTables_wrapper div.dataTables_length label {
  justify-content: flex-start;
}

div.dataTables_wrapper div.dataTables_length label select {
  padding: 2px;
  margin: 0 5px;
}

div.dataTables_wrapper div.dataTables_filter label {
  justify-content: flex-end;
}

div.dataTables_wrapper div.dataTables_filter label select {
  padding: 2px;
}

.mai-datatable-header {
  border-bottom: 1px solid #eceeef;
  margin: 2px 0 0;
  padding: 15px 10px 22px;
}

.mai-datatable-header .dt-buttons .btn embed {
  outline: none;
}

.mai-datatable-footer {
  margin: 0;
  padding: 15px 3px 13px;
  background-color: #f7f7f7;
  border-top: 1px solid #eceeef;
}

.datepicker {
  direction: ltr;
}

.datepicker table tr td,
.datepicker table tr th {
  border-radius: 50%;
}

.datepicker-inline {
  width: 220px;
}

.datepicker-rtl {
  direction: rtl;
}

.datepicker-rtl table tr td span {
  float: right;
}

.datepicker-dropdown {
  top: 0;
  left: 0;
  min-width: 160px;
  padding: 10px;
  z-index: 1000 !important;
}

.datepicker-dropdown:before {
  content: '';
  display: inline-block;
  border-left: 7px solid transparent;
  border-right: 7px solid transparent;
  border-bottom: 7px solid #636c72;
  border-top: 0;
  border-bottom-color: rgba(0, 0, 0, 0.1);
  position: absolute;
}

.datepicker-dropdown:after {
  content: '';
  display: inline-block;
  border-left: 6px solid transparent;
  border-right: 6px solid transparent;
  border-bottom: 6px solid #FFFFFF;
  border-top: 0;
  position: absolute;
}

.datepicker-dropdown.datepicker-orient-left:before {
  left: 6px;
}

.datepicker-dropdown.datepicker-orient-left:after {
  left: 7px;
}

.datepicker-dropdown.datepicker-orient-right:before {
  right: 6px;
}

.datepicker-dropdown.datepicker-orient-right:after {
  right: 7px;
}

.datepicker-dropdown.datepicker-orient-bottom:before {
  top: -7px;
}

.datepicker-dropdown.datepicker-orient-bottom:after {
  top: -6px;
}

.datepicker-dropdown.datepicker-orient-top:before {
  bottom: -7px;
  border-bottom: 0;
  border-top: 7px solid rgba(0, 0, 0, 0.1);
}

.datepicker-dropdown.datepicker-orient-top:after {
  bottom: -6px;
  border-bottom: 0;
  border-top: 6px solid #FFFFFF;
}

.datepicker table {
  margin: 0;
  user-select: none;
  border-collapse: separate;
}

.datepicker table thead tr th {
  padding: 10px 4px 8px;
  font-weight: 400;
  color: #707070;
}

.datepicker td, .datepicker th {
  text-align: center;
  width: 2.5rem;
  height: 2.5rem;
  color: #878787;
  border: none;
}

.table-striped .datepicker table tr td, .table-striped .datepicker table tr th {
  background-color: transparent;
}

.datepicker table tr td {
  padding: 0 8px;
  line-height: 1.3;
  height: 33px;
  width: 33px;
}

.datepicker table tr td.day:hover, .datepicker table tr td.day.focused {
  background: #eceeef;
  cursor: pointer;
}

.datepicker table tr td.old, .datepicker table tr td.new {
  color: #c7c7c7;
}

.datepicker table tr td.disabled, .datepicker table tr td.disabled:hover {
  background: none;
  color: #c7c7c7;
  cursor: default;
}

.datepicker table tr td.highlighted {
  background: #58C7EF;
  border-radius: 0;
}

.datepicker table tr td.today, .datepicker table tr td.today:hover, .datepicker table tr td.today.disabled, .datepicker table tr td.today.disabled:hover {
  background-color: whitesmoke;
  color: #878787;
}

.datepicker table tr td.today:hover:hover {
  color: #878787;
}

.datepicker table tr td.today.active:hover {
  color: #fff;
}

.datepicker table tr td.range, .datepicker table tr td.range:hover, .datepicker table tr td.range.disabled, .datepicker table tr td.range.disabled:hover {
  background: #eceeef;
}

.datepicker table tr td.range.today, .datepicker table tr td.range.today:hover, .datepicker table tr td.range.today.disabled, .datepicker table tr td.range.today.disabled:hover {
  color: #212529;
  background-color: #FFFFFF;
  border-color: #f6c478;
}

.datepicker table tr td.range.today:hover, .datepicker table tr td.range.today:hover:hover, .datepicker table tr td.range.today.disabled:hover, .datepicker table tr td.range.today.disabled:hover:hover {
  color: #212529;
  background-color: #FFDC42;
  border-color: #f3af48;
}

.datepicker table tr td.range.today:focus, .datepicker table tr td.range.today.focus, .datepicker table tr td.range.today:hover:focus, .datepicker table tr td.range.today:hover.focus, .datepicker table tr td.range.today.disabled:focus, .datepicker table tr td.range.today.disabled.focus, .datepicker table tr td.range.today.disabled:hover:focus, .datepicker table tr td.range.today.disabled:hover.focus {
  box-shadow: 0 0 0 0 rgba(214, 172, 108, 0.5);
}

.datepicker table tr td.range.today.disabled, .datepicker table tr td.range.today:disabled, .datepicker table tr td.range.today:hover.disabled, .datepicker table tr td.range.today:hover:disabled, .datepicker table tr td.range.today.disabled.disabled, .datepicker table tr td.range.today.disabled:disabled, .datepicker table tr td.range.today.disabled:hover.disabled, .datepicker table tr td.range.today.disabled:hover:disabled {
  color: #212529;
  background-color: #FFFFFF;
  border-color: #f6c478;
}

.datepicker table tr td.range.today:not(:disabled):not(.disabled):active, .datepicker table tr td.range.today:not(:disabled):not(.disabled).active,
.show > .datepicker table tr td.range.today.dropdown-toggle, .datepicker table tr td.range.today:hover:not(:disabled):not(.disabled):active, .datepicker table tr td.range.today:hover:not(:disabled):not(.disabled).active,
.show > .datepicker table tr td.range.today:hover.dropdown-toggle, .datepicker table tr td.range.today.disabled:not(:disabled):not(.disabled):active, .datepicker table tr td.range.today.disabled:not(:disabled):not(.disabled).active,
.show > .datepicker table tr td.range.today.disabled.dropdown-toggle, .datepicker table tr td.range.today.disabled:hover:not(:disabled):not(.disabled):active, .datepicker table tr td.range.today.disabled:hover:not(:disabled):not(.disabled).active,
.show > .datepicker table tr td.range.today.disabled:hover.dropdown-toggle {
  color: #212529;
  background-color: #e6e6e6;
  border-color: #f2aa3c;
}

.datepicker table tr td.range.today:not(:disabled):not(.disabled):active:focus, .datepicker table tr td.range.today:not(:disabled):not(.disabled).active:focus,
.show > .datepicker table tr td.range.today.dropdown-toggle:focus, .datepicker table tr td.range.today:hover:not(:disabled):not(.disabled):active:focus, .datepicker table tr td.range.today:hover:not(:disabled):not(.disabled).active:focus,
.show > .datepicker table tr td.range.today:hover.dropdown-toggle:focus, .datepicker table tr td.range.today.disabled:not(:disabled):not(.disabled):active:focus, .datepicker table tr td.range.today.disabled:not(:disabled):not(.disabled).active:focus,
.show > .datepicker table tr td.range.today.disabled.dropdown-toggle:focus, .datepicker table tr td.range.today.disabled:hover:not(:disabled):not(.disabled):active:focus, .datepicker table tr td.range.today.disabled:hover:not(:disabled):not(.disabled).active:focus,
.show > .datepicker table tr td.range.today.disabled:hover.dropdown-toggle:focus {
  box-shadow: 0 0 0 0 rgba(214, 172, 108, 0.5);
}

.datepicker table tr td.selected, .datepicker table tr td.selected:hover, .datepicker table tr td.selected.disabled, .datepicker table tr td.selected.disabled:hover {
  color: #FFFFFF;
  background-color: #7b868d;
  border-color: #4b5257;
  color: #fff;
  text-shadow: none;
}

.datepicker table tr td.selected:hover, .datepicker table tr td.selected:hover:hover, .datepicker table tr td.selected.disabled:hover, .datepicker table tr td.selected.disabled:hover:hover {
  color: #FFFFFF;
  background-color: #34383b;
  border-color: #34383b;
}

.datepicker table tr td.selected:focus, .datepicker table tr td.selected.focus, .datepicker table tr td.selected:hover:focus, .datepicker table tr td.selected:hover.focus, .datepicker table tr td.selected.disabled:focus, .datepicker table tr td.selected.disabled.focus, .datepicker table tr td.selected.disabled:hover:focus, .datepicker table tr td.selected.disabled:hover.focus {
  box-shadow: 0 0 0 0 rgba(102, 108, 112, 0.5);
}

.datepicker table tr td.selected.disabled, .datepicker table tr td.selected:disabled, .datepicker table tr td.selected:hover.disabled, .datepicker table tr td.selected:hover:disabled, .datepicker table tr td.selected.disabled.disabled, .datepicker table tr td.selected.disabled:disabled, .datepicker table tr td.selected.disabled:hover.disabled, .datepicker table tr td.selected.disabled:hover:disabled {
  color: #FFFFFF;
  background-color: #7b868d;
  border-color: #4b5257;
}

.datepicker table tr td.selected:not(:disabled):not(.disabled):active, .datepicker table tr td.selected:not(:disabled):not(.disabled).active,
.show > .datepicker table tr td.selected.dropdown-toggle, .datepicker table tr td.selected:hover:not(:disabled):not(.disabled):active, .datepicker table tr td.selected:hover:not(:disabled):not(.disabled).active,
.show > .datepicker table tr td.selected:hover.dropdown-toggle, .datepicker table tr td.selected.disabled:not(:disabled):not(.disabled):active, .datepicker table tr td.selected.disabled:not(:disabled):not(.disabled).active,
.show > .datepicker table tr td.selected.disabled.dropdown-toggle, .datepicker table tr td.selected.disabled:hover:not(:disabled):not(.disabled):active, .datepicker table tr td.selected.disabled:hover:not(:disabled):not(.disabled).active,
.show > .datepicker table tr td.selected.disabled:hover.dropdown-toggle {
  color: #FFFFFF;
  background-color: #636c72;
  border-color: #2e3235;
}

.datepicker table tr td.selected:not(:disabled):not(.disabled):active:focus, .datepicker table tr td.selected:not(:disabled):not(.disabled).active:focus,
.show > .datepicker table tr td.selected.dropdown-toggle:focus, .datepicker table tr td.selected:hover:not(:disabled):not(.disabled):active:focus, .datepicker table tr td.selected:hover:not(:disabled):not(.disabled).active:focus,
.show > .datepicker table tr td.selected:hover.dropdown-toggle:focus, .datepicker table tr td.selected.disabled:not(:disabled):not(.disabled):active:focus, .datepicker table tr td.selected.disabled:not(:disabled):not(.disabled).active:focus,
.show > .datepicker table tr td.selected.disabled.dropdown-toggle:focus, .datepicker table tr td.selected.disabled:hover:not(:disabled):not(.disabled):active:focus, .datepicker table tr td.selected.disabled:hover:not(:disabled):not(.disabled).active:focus,
.show > .datepicker table tr td.selected.disabled:hover.dropdown-toggle:focus {
  box-shadow: 0 0 0 0 rgba(102, 108, 112, 0.5);
}

.datepicker table tr td.active, .datepicker table tr td.active.active, .datepicker table tr td.active.active:hover, .datepicker table tr td.active.active.disabled, .datepicker table tr td.active.active.disabled:hover {
  background-color: #2cc185;
  color: #FFFFFF;
  border-width: 0;
  color: #fff;
  text-shadow: none;
}

.datepicker table tr td span {
  display: block;
  width: 23%;
  height: 48px;
  line-height: 48px;
  float: left;
  margin: 1%;
  cursor: pointer;
  border-radius: 3px;
}

.datepicker table tr td span:hover, .datepicker table tr td span.focused {
  background: #eceeef;
}

.datepicker table tr td span.disabled, .datepicker table tr td span.disabled:hover {
  background: none;
  color: #636c72;
  cursor: default;
}

.datepicker table tr td span.active, .datepicker table tr td span.active.active, .datepicker table tr td span.active.active:hover, .datepicker table tr td span.active:hover.active:hover, .datepicker table tr td span.active.active.disabled, .datepicker table tr td span.active.active.disabled:hover {
  background-color: #2cc185;
  color: #FFFFFF;
  border-width: 0;
  color: #fff;
  text-shadow: none;
}

.datepicker table tr td span.old, .datepicker table tr td span.new {
  color: #c7c7c7;
}

.datepicker .datepicker-switch {
  width: 145px;
}

.datepicker .datepicker-switch,
.datepicker tfoot tr th {
  cursor: pointer;
}

.datepicker .datepicker-switch:hover,
.datepicker tfoot tr th:hover {
  color: #707070;
  background-color: transparent;
}

.datepicker .prev,
.datepicker .next {
  cursor: pointer;
}

.datepicker .prev:hover,
.datepicker .next:hover {
  color: #2cc185;
  background-color: transparent;
}

.datepicker .cw {
  font-size: 10px;
  width: 12px;
  padding: 0 2px 0 5px;
  vertical-align: middle;
}

.input-append.date .add-on,
.input-prepend.date .add-on {
  cursor: pointer;
}

.input-append.date .add-on i,
.input-prepend.date .add-on i {
  margin-top: 3px;
}

.input-daterange input {
  text-align: center;
}

.input-daterange .add-on {
  display: inline-block;
  width: auto;
  min-width: 16px;
  height: 1.538;
  padding: 4px 5px;
  font-weight: normal;
  line-height: 1.538;
  text-align: center;
  text-shadow: 0 1px 0 #FFFFFF;
  vertical-align: middle;
  background-color: #eceeef;
  border: 1px solid #ccc;
  margin-left: -5px;
  margin-right: -5px;
}

form.dropzone {
  position: relative;
  border: 2px dashed #d4d4d4;
  padding: 70px 60px 80px;
  background: transparent;
}

form.dropzone .icon {
  background-color: #e6e6e6;
  border-radius: 50%;
  display: inline-block;
  height: 100px;
  width: 100px;
  margin-bottom: 15px;
  padding: 20px;
  text-align: center;
}

form.dropzone .icon span {
  font-size: 60px;
  color: #5d5d5d;
}

form.dropzone h2 {
  margin-bottom: 30px;
  font-size: 40px;
  font-weight: 300;
}

form.dropzone .note {
  font-size: 16px;
}

form.dropzone .dz-preview.dz-file-preview .dz-image, form.dropzone .dz-preview.dz-image-preview .dz-image {
  border: 3px solid #2cc185;
  border-radius: 3px;
  background: #ededed;
}

form.dropzone .dz-preview.dz-file-preview.dz-error .dz-image, form.dropzone .dz-preview.dz-image-preview.dz-error .dz-image {
  border-color: #F45846;
}

form.dropzone .dz-preview.dz-file-preview.dz-success .dz-image, form.dropzone .dz-preview.dz-image-preview.dz-success .dz-image {
  border-color: #2cc185;
}

form.dropzone .dz-preview .dz-details .dz-filename span, form.dropzone .dz-preview .dz-details .dz-size span {
  background: transparent;
}

form.dropzone .dz-preview .dz-error-message {
  word-break: break-word;
}

.dropzone-mobile-trigger {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
}

.tooltip-chart {
  display: flex;
}

.tooltip-chart .content-chart {
  background-color: rgba(0, 0, 0, 0.8);
  color: #FFF;
  border-radius: 3px;
  padding: 5px 12px 5px;
  position: relative;
}

.tooltip-chart .content-chart:before {
  content: '';
  width: 0;
  height: 0;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-top: 5px solid rgba(0, 0, 0, 0.8);
  position: absolute;
  bottom: -5px;
  margin-left: 50%;
  left: -5px;
}

.tooltip-chart .content-chart.arrow-none:before {
  display: none;
}

.tooltip-chart .content-chart span {
  display: block;
  font-size: 12px;
  font-weight: 600;
  margin-bottom: -2px;
  text-align: center;
  color: #89e4bf;
}

.tooltip-chart .content-chart .label {
  font-size: 11px;
  text-align: center;
}

.tooltip-chart .content-chart .label .label-x, .tooltip-chart .content-chart .label .label-y {
  display: inline-block;
}

/*------------------------------------------------------------------
  [Full calendar]
*/
.full-calendar .fc-toolbar {
  padding-top: 25px;
  margin-bottom: 30px;
}

.full-calendar .fc-view-container .fc-event-container .fc-event {
  background: #2cc185;
  border-color: #24a06e;
  color: #FFFFFF;
  border-radius: 0;
  font-size: .923077rem;
}

.full-calendar .fc-view-container .fc-border-separate .fc-today {
  background-color: #f7f7f7;
}

.full-calendar .fc-view-container .fc-cell-overlay {
  background-color: fade(#2cc185, 50%);
}

.full-calendar .fc-button {
  color: #545454;
  background: #FFFFFF;
  border-color: #cccccc;
  height: initial;
  padding: 0.81rem 0.7692rem;
  font-size: 1rem;
  line-height: 1;
  border-radius: 0;
  font-size: 1rem;
  box-shadow: none;
  text-shadow: none;
}

.full-calendar .fc-button:focus, .full-calendar .fc-button.focus {
  color: #545454;
  background-color: #FFFFFF;
  border-color: #cccccc;
  box-shadow: none;
  outline: none;
}

.full-calendar .fc-button:focus:hover, .full-calendar .fc-button.focus:hover {
  border-color: #cccccc;
  background-color: whitesmoke;
}

.full-calendar .fc-button:hover, .full-calendar .fc-button.hover {
  border-color: #cccccc;
  background-color: whitesmoke;
}

.full-calendar .fc-button:active, .full-calendar .fc-button.fc-state-active, .full-calendar .fc-button.active,
.open > .full-calendar .fc-button.dropdown-toggle {
  box-shadow: none;
  border-color: #cccccc;
  background-color: #e8e8e8;
}

.full-calendar .fc-button:active:hover, .full-calendar .fc-button:active:focus, .full-calendar .fc-button:active.focus, .full-calendar .fc-button.fc-state-active:hover, .full-calendar .fc-button.fc-state-active:focus, .full-calendar .fc-button.fc-state-active.focus, .full-calendar .fc-button.active:hover, .full-calendar .fc-button.active:focus, .full-calendar .fc-button.active.focus,
.open > .full-calendar .fc-button.dropdown-toggle:hover,
.open > .full-calendar .fc-button.dropdown-toggle:focus,
.open > .full-calendar .fc-button.dropdown-toggle.focus {
  background-color: #e8e8e8;
  border-color: #cccccc;
  box-shadow: none;
}

.full-calendar .fc-button:active, .full-calendar .fc-button.active,
.open > .full-calendar .fc-button.dropdown-toggle {
  background-image: none;
}

.full-calendar .fc-button.disabled, .full-calendar .fc-button.disabled:hover, .full-calendar .fc-button.disabled:focus, .full-calendar .fc-button.disabled.focus, .full-calendar .fc-button.disabled:active, .full-calendar .fc-button.disabled.active, .full-calendar .fc-button[disabled], .full-calendar .fc-button[disabled]:hover, .full-calendar .fc-button[disabled]:focus, .full-calendar .fc-button[disabled].focus, .full-calendar .fc-button[disabled]:active, .full-calendar .fc-button[disabled].active,
fieldset[disabled] .full-calendar .fc-button,
fieldset[disabled] .full-calendar .fc-button:hover,
fieldset[disabled] .full-calendar .fc-button:focus,
fieldset[disabled] .full-calendar .fc-button.focus,
fieldset[disabled] .full-calendar .fc-button:active,
fieldset[disabled] .full-calendar .fc-button.active {
  background-color: #FFFFFF;
  border-color: #cccccc;
}

.full-calendar .fc-button.fc-prev-button, .full-calendar .fc-button.fc-next-button {
  padding-left: 7px;
  padding-right: 7px;
}

.full-calendar .fc-button .fc-icon {
  position: relative;
  top: -2px;
  line-height: 0.4;
  vertical-align: middle;
  overflow: visible;
  height: auto;
}

.full-calendar .fc-button .fc-icon:after {
  position: static;
  font-weight: 400;
  font-size: 1.61538rem;
}

.full-calendar .fc-state-default.fc-corner-left {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.full-calendar .fc-state-default.fc-corner-right {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.full-calendar .fc-row .fc-content-skeleton td,
.full-calendar .fc-row .fc-helper-skeleton td {
  border-color: inherit;
}

.full-calendar .fc-unthemed .fc-today {
  background-color: #f0fcf7;
}

@media phone {
  .full-calendar {
    max-width: 767px;
  }
  .full-calendar .fc-toolbar .fc-left, .full-calendar .fc-toolbar .fc-center, .full-calendar .fc-toolbar .fc-right {
    text-align: center;
    display: block;
    width: 100%;
  }
  .full-calendar .fc-toolbar .fc-left {
    padding-bottom: 20px;
  }
  .full-calendar .fc-toolbar .fc-center {
    padding: 0;
  }
}

.fullcalendar-external-events .fc-event {
  margin: 2px 0;
  padding: 5px;
  background: #2cc185;
  color: #FFFFFF;
  font-size: .923077rem;
  cursor: pointerr;
  display: inline-block;
  border-width: 0;
  border-radius: 0;
}

.fullcalendar-external-events p input {
  margin-top: 15px;
}

.fullcalendar-external-events p label {
  font-weight: 300;
  vertical-align: middle;
}

/*------------------------------------------------------------------
  [Notifications]
*/
#gritter-notice-wrapper {
  width: 29.23rem;
  top: 6rem;
}

.gritter-item-wrapper {
  background: #FFFFFF;
  box-shadow: 0 0px 0.307rem rgba(0, 0, 0, 0.25);
  margin-bottom: 20px;
}

.gritter-item {
  font-family: "Open Sans", sans-serif;
  background: transparent;
  color: #FFFFFF;
  padding: 0;
  display: table;
  table-layout: fixed;
  width: 100%;
}

.gritter-icon-container,
.gritter-img-container {
  display: table-cell;
  width: 26%;
  vertical-align: middle;
  text-align: center;
}

.gritter-image {
  float: none;
  width: 4.61538rem;
  height: 4.61538rem;
}

.gritter-content {
  width: auto;
}

.gritter-with-image, .gritter-without-image {
  padding: 1.153rem 2.692rem 1.51rem 1.7rem;
  color: #545454;
  width: 23rem;
  display: table-cell;
}

.gritter-with-image p, .gritter-without-image p {
  font-size: 1rem;
  line-height: 1.5;
}

.gritter-without-image {
  padding-left: 1.461rem;
}

.gritter-close {
  display: block;
  top: 10px;
  right: 10px;
  left: auto;
  height: 1.525rem;
  width: 1.525rem;
  font-size: 0;
  background: transparent;
  text-indent: inherit;
  color: #8c8c8c;
}

.gritter-close:hover {
  color: gray;
}

.gritter-close:after {
  content: '';
  font-family: "Stroke 7";
  font-size: 1.525rem;
  text-shadow: none;
  line-height: 1;
}

.gritter-title {
  text-shadow: none;
  font-weight: 300;
  font-size: 17px;
}

.gritter-clean {
  background: #FFFFFF;
}

.gritter-clean .gritter-item {
  color: #545454;
}

.gritter-clean .gritter-item .gritter-img-container {
  background-color: #fafafa;
}

.gritter-clean .gritter-item .gritter-content {
  color: #545454;
}

.gritter-clean .gritter-item .gritter-content .gritter-close {
  opacity: 0.5;
}

.gritter-clean .gritter-item .gritter-content .gritter-close:after {
  color: #545454;
}

.gritter-social .gritter-icon {
  font-size: 2.8rem;
}

.gritter-social .gritter-content {
  padding-left: 0;
  width: 21.6rem;
}

.gritter-social.facebook .gritter-icon-container {
  color: #5d82d1;
}

.gritter-social.facebook .gritter-icon:after {
  content: "";
  font-family: "FontAwesome";
}

.gritter-social.twitter .gritter-icon-container {
  color: #50bff5;
}

.gritter-social.twitter .gritter-icon:after {
  content: "";
  font-family: "FontAwesome";
}

.gritter-social.google-plus .gritter-icon-container {
  color: #eb5e4c;
}

.gritter-social.google-plus .gritter-icon:after {
  content: "";
  font-family: "FontAwesome";
}

.gritter-social.dribbble .gritter-icon-container {
  color: #f7659c;
}

.gritter-social.dribbble .gritter-icon:after {
  content: "";
  font-family: "FontAwesome";
}

.gritter-social.flickr .gritter-icon-container {
  color: #0063dc;
}

.gritter-social.flickr .gritter-icon:after {
  content: "";
  font-family: "FontAwesome";
}

.gritter-social.linkedin .gritter-icon-container {
  color: #238cc8;
}

.gritter-social.linkedin .gritter-icon:after {
  content: "";
  font-family: "FontAwesome";
}

.gritter-social.youtube .gritter-icon-container {
  color: #ef4e41;
}

.gritter-social.youtube .gritter-icon:after {
  content: "";
  font-family: "FontAwesome";
}

.gritter-social.pinterest .gritter-icon-container {
  color: #e13138;
}

.gritter-social.pinterest .gritter-icon:after {
  content: "";
  font-family: "FontAwesome";
}

.gritter-social.github .gritter-icon-container {
  color: #333;
}

.gritter-social.github .gritter-icon:after {
  content: "";
  font-family: "FontAwesome";
}

.gritter-social.tumblr .gritter-icon-container {
  color: #426d9b;
}

.gritter-social.tumblr .gritter-icon:after {
  content: "";
  font-family: "FontAwesome";
}

.gritter-theme {
  background-color: #323232;
}

.gritter-theme .gritter-title {
  color: #2cc185;
}

.gritter-theme .gritter-content {
  color: #FFFFFF;
}

.gritter-color .gritter-content {
  color: #FFFFFF;
}

.gritter-color .gritter-close {
  color: #FFFFFF;
  opacity: 0.7;
}

.gritter-color .gritter-close:hover {
  opacity: 1;
}

.gritter-color.primary {
  background: #2cc185;
}

.gritter-color.success {
  background: #2cc185;
}

.gritter-color.info {
  background: #58C7EF;
}

.gritter-color.warning {
  background: #FFDC42;
}

.gritter-color.danger {
  background: #F45846;
}

.gritter-color.dark {
  background: #545454;
}

@media (max-width: 575.98px) {
  .ms-container {
    width: auto;
  }
}

@media (max-width: 767.98px) {
  .ms-container {
    margin: 0 auto;
  }
}

.ms-container .ms-selectable li.ms-elem-selectable,
.ms-container .ms-selection li.ms-elem-selection {
  padding: 6px 10px;
}

.ms-container .ms-selectable, .ms-container .ms-selection {
  background-color: transparent;
}

.ms-container .ms-selectable li.ms-hover,
.ms-container .ms-selection li.ms-hover {
  background-color: #2cc185;
}

.ms-container .ms-list {
  border: 2px solid #ebebeb;
  background-color: #FFFFFF;
  border-radius: 0;
  box-shadow: none;
}

.ms-container .ms-list.ms-focus {
  box-shadow: none;
  border-color: #2cc185;
}

.ms-container .search-input {
  height: 30px;
  margin-bottom: 10px;
}

/*------------------------------------------------------------------
  [Nestable list]
*/
.dd {
  position: relative;
  display: block;
  margin: 0;
  padding: 0;
  max-width: 46.1538rem;
  list-style: none;
  font-size: 1rem;
  line-height: 1.53846rem;
}

.dd-list {
  display: block;
  position: relative;
  margin: 0;
  padding: 0;
  list-style: none;
}

.dd-list .dd-item .dd-handle, .dd-list .dd3-item .dd-handle {
  cursor: default;
  display: block;
  margin: 0.384615rem 0;
  padding: 0.538462rem 0.769231rem;
  text-decoration: none;
  border: 1px solid #dedede;
  background: #FFFFFF;
}

.dd-list .dd-item .dd-handle:hover, .dd-list .dd3-item .dd-handle:hover {
  color: #FFFFFF;
  background: #2cc185;
  border-color: #2cc185;
}

.dd-list .dd-item > button, .dd-list .dd3-item > button {
  display: block;
  position: relative;
  cursor: pointer;
  float: left;
  width: 1.92308rem;
  height: 1.53846rem;
  margin: 0.615385rem 0.153846rem;
  padding: 0;
  text-indent: 100%;
  white-space: nowrap;
  overflow: hidden;
  border: 0;
  background: transparent;
  font-size: 1.5rem;
  color: #878787;
}

.dd-list .dd-item > button:before, .dd-list .dd3-item > button:before {
  content: '+';
  display: block;
  position: absolute;
  width: 100%;
  text-align: center;
  text-indent: 0;
}

.dd-list .dd-item > button[data-action="collapse"]:before, .dd-list .dd3-item > button[data-action="collapse"]:before {
  content: '-';
}

.dd-list .dd-item .dd3-content, .dd-list .dd3-item .dd3-content {
  display: block;
  margin: 0.384615rem 0;
  padding: 0.538462rem 0.769231rem 0.538462rem 3.07692rem;
  text-decoration: none;
  background: none repeat scroll 0 0 #FFFFFF;
  border: 1px solid #dedede;
}

.dd-list .dd-item .dd3-content:hover, .dd-list .dd3-item .dd3-content:hover {
  background: #FFFFFF;
}

.dd-list .dd-item .dd3-handle, .dd-list .dd3-item .dd3-handle {
  position: absolute;
  margin: 0;
  left: 0;
  top: 0;
  cursor: all-scroll;
  width: 2.61538rem;
  text-indent: 100%;
  white-space: nowrap;
  overflow: hidden;
  border: 1px solid #2cc185;
  background: #2cc185;
  height: 2.7rem;
}

.dd-list .dd-item .dd3-handle:before, .dd-list .dd3-item .dd3-handle:before {
  content: '=';
  display: block;
  position: absolute;
  left: 0;
  top: 0.384615rem;
  width: 100%;
  text-align: center;
  text-indent: 0;
  color: #FFFFFF;
  font-size: 1.8rem;
  line-height: 1.84615rem;
  font-weight: normal;
}

.dd-list .dd-item .dd3-handle:hover, .dd-list .dd3-item .dd3-handle:hover {
  background: #35d192;
  border-color: #35d192;
}

.dd-list .dd3-item > button {
  margin-left: 2.69231rem;
}

.dd-list .dd-item, .dd-list .dd-empty, .dd-list .dd-placeholder {
  display: block;
  position: relative;
  margin: 0;
  padding: 0;
  min-height: 1.53846rem;
  font-size: 1rem;
  line-height: 1.53846rem;
}

.dd-list .dd-placeholder, .dd-list .dd-empty {
  margin: 0.384615rem 0;
  padding: 0;
  min-height: 30px;
  background: #FFF;
  border: 1px dashed #b3b3b3;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
}

.dd-list .dd-empty {
  border: 1px dashed #bbb;
  min-height: 7.69231rem;
  background-color: #e5e5e5;
  background-image: -webkit-linear-gradient(45deg, #FFFFFF 25%, transparent 25%, transparent 75%, #FFFFFF 75%, #FFFFFF), -webkit-linear-gradient(45deg, #FFFFFF 25%, transparent 25%, transparent 75%, #FFFFFF 75%, #FFFFFF);
  background-image: -moz-linear-gradient(45deg, #FFFFFF 25%, transparent 25%, transparent 75%, #FFFFFF 75%, #FFFFFF), -moz-linear-gradient(45deg, #FFFFFF 25%, transparent 25%, transparent 75%, #FFFFFF 75%, #FFFFFF);
  background-image: linear-gradient(45deg, #FFFFFF 25%, transparent 25%, transparent 75%, #FFFFFF 75%, #FFFFFF), linear-gradient(45deg, #FFFFFF 25%, transparent 25%, transparent 75%, #FFFFFF 75%, #FFFFFF);
  background-size: 4.61538rem 4.61538rem;
  background-position: 0 0, 2.30769rem 2.30769rem;
}

.dd-list .dd-collapsed .dd-list {
  display: none;
}

.dd-list.dd-dragel {
  position: absolute;
  pointer-events: none;
  z-index: 9999;
}

.dd-list.dd-dragel > .dd-item .dd-handle {
  margin-top: 0;
}

.dd-list.dd-dragel .dd-handle, .dd-list.dd-dragel .dd3-handle {
  -webkit-box-shadow: 0.153846rem 0.307692rem 0.461538rem 0 rgba(0, 0, 0, 0.1);
  box-shadow: 0.153846rem 0.307692rem 0.461538rem 0 rgba(0, 0, 0, 0.1);
}

.dd-list .dd-list {
  padding-left: 2.30769rem;
}

/*------------------------------------------------------------------
  [Parsley]
*/
.parsley-errors-list.filled {
  margin-top: 10px;
  margin-bottom: 0;
  padding: 15px 20px;
  position: relative;
  background-color: #F45846;
  color: #FFFFFF;
}

.parsley-errors-list.filled:after {
  content: "";
  display: inline-block;
  position: absolute;
  top: -6px;
  left: 20px;
  width: 0;
  height: 0;
  content: '';
  z-index: 2;
  border-bottom: 7px solid #F45846;
  -moz-transform: scale(0.9999);
  border-left: 7px solid transparent;
  border-right: 7px solid transparent;
}

.parsley-errors-list.filled > li {
  list-style: none;
}

/*------------------------------------------------------------------
  [Perfect Scrollbar]
*/
/*Perfect Scrollbar*/
.ps:hover > .ps__rail-y, .ps.ps--scrolling-y > .ps__rail-y {
  background-color: transparent;
}

.ps:hover > .ps__rail-y:hover, .ps.ps--scrolling-y > .ps__rail-y:hover {
  width: 6px;
  background-color: rgba(0, 0, 0, 0.15);
}

.ps:hover > .ps__rail-y > .ps__thumb-y, .ps.ps--scrolling-y > .ps__rail-y > .ps__thumb-y {
  right: 0;
  width: 6px;
  border-radius: 3px;
  background-color: rgba(0, 0, 0, 0.5);
}

.ps > .ps__rail-y {
  width: 6px;
  background-color: rgba(0, 0, 0, 0.1);
}

.ps > .ps__rail-y > .ps__thumb-y {
  right: 0;
  width: 6px;
  border-radius: 3px;
  background-color: rgba(0, 0, 0, 0.5);
}

/*------------------------------------------------------------------
  [Select2]
*/
.select2-container--default .select2-selection--single, .select2-container--default .select2-selection--multiple {
  border: 0.153846rem solid #ebebeb;
  border-radius: 0;
}

.select2-container--default .select2-selection--single {
  height: 3.23077rem;
}

.select2-container--default .select2-selection--single .select2-selection__rendered {
  padding: 0 15px;
  height: 2.923078rem;
  line-height: 2.923078rem;
  font-size: 1.077rem;
  color: #878787;
}

.select2-container--default .select2-selection--single .select2-selection__arrow {
  height: 3.076924rem;
  width: 30px;
}

.select2-container--default .select2-selection--single .select2-selection__arrow b {
  border: 0;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  margin: 0;
}

.select2-container--default .select2-selection--single .select2-selection__arrow b:after {
  content: "";
  font-family: "Stroke 7";
  font-size: 25px;
  font-weight: normal;
  line-height: 3.076924rem;
  color: #878787;
}

.select2-container--default .select2-selection--multiple {
  min-height: 42px;
  line-height: 1;
}

.select2-container--default .select2-selection--multiple .select2-selection__rendered {
  padding: 4px 12px;
  min-height: 3.23077rem;
}

.select2-container--default .select2-selection--multiple .select2-selection__choice {
  border-radius: 0;
  background-color: #f2f2f2;
  color: #6e6e6e;
  border-width: 0;
  padding: 4px 6px;
  line-height: 18px;
}

.select2-container--default .select2-selection--multiple .select2-selection__choice__remove {
  color: #7a7a7a;
  margin-right: 3px;
}

.select2-container--default .select2-selection--multiple .select2-selection__choice__remove:hover {
  color: #616161;
}

.select2-container--default .select2-selection--multiple .select2-search--inline .select2-search__field {
  line-height: 2;
}

.select2-container--default.select2-container--default.select2-container--focus .select2-selection--multiple {
  border: 0.153846rem solid #ebebeb;
}

.select2-container--default.select2-container--open .select2-selection--single .select2-selection__arrow b:after {
  content: "";
}

.select2-container--default .select2-results__group {
  font-size: 12px;
  color: #6e6e6e;
}

.select2-container--default .select2-results__option {
  padding: 10px 6px;
}

.select2-container--default .select2-results__option[aria-selected="true"] {
  background-color: #f7f7f7;
}

.select2-container--default .select2-results__option--highlighted[aria-selected] {
  background-color: #2cc185;
}

.select2-container--default .select2-dropdown {
  border-width: 0.153846rem;
  border-color: #ebebeb;
}

.select2-container--default .select2-dropdown--above {
  border-radius: 0;
  box-shadow: none;
}

.select2-container--default .select2-dropdown--below {
  border-radius: 0;
  box-shadow: none;
}

.select2-container--default .select2-search--dropdown {
  background-color: white;
  border-bottom: 0.153846rem solid #ebebeb;
}

.select2-container--default .select2-search--dropdown .select2-search__field {
  background-color: transparent;
  border-width: 0;
  outline: none;
}

/*------------------------------------------------------------------
  [Slider]
*/
/*Slider*/
.slider.slider-vertical {
  margin-right: 13px;
}

@media (max-width: 575.98px) {
  .slider.slider-vertical {
    margin-right: 10px;
  }
}

.slider.slider-horizontal {
  height: 23px;
  top: 11px;
}

.slider.slider-horizontal .slider-track {
  height: 8px;
}

.slider-selection {
  background: #2cc185;
  border: 1px solid #2cc185;
  box-shadow: none;
}

.slider-track {
  background: #f2f2f2;
  box-shadow: none;
}

.slider-handle {
  opacity: 1;
  background: #FFFFFF;
  border: 1px solid #f0f0f0;
  box-shadow: 0 0 3px rgba(0, 0, 0, 0.12);
  height: 22px;
  width: 22px;
  transition: box-shadow 200ms ease-in-out;
  cursor: pointer;
}

.note-editor.note-frame {
  margin-bottom: 0;
  border-width: 0;
  box-shadow: none;
}

.note-editor.note-frame .note-toolbar {
  margin: 0;
  padding-bottom: 5px;
}

.note-editor.note-frame .note-toolbar .btn .fa {
  font-size: 14px;
}

.note-editor.note-frame .note-toolbar > .btn-group {
  margin-left: 0;
  margin-right: 5px;
  margin-top: 5px;
}

.note-editor.note-frame .note-toolbar .note-color .dropdown-toggle {
  padding-left: 5px;
  width: 20px;
}

.note-editor.note-frame .note-statusbar {
  background-color: #f6f6f6;
}

.note-editor.note-frame .note-statusbar .note-resizebar {
  height: auto;
  padding-top: 3px;
  padding-bottom: 4px;
}

.note-editor.note-frame .note-editable {
  border: 1px solid #ebebeb;
  padding: 10px 12px;
  margin-top: 6px;
  border-radius: 0;
}

.note-editor.note-frame .note-editing-area .note-editable {
  color: #545454;
}

.note-btn-group .dropdown-menu > li > a {
  display: block;
  padding: 3px 20px;
  clear: both;
  font-weight: normal;
  line-height: 1.42857143;
  color: #333;
  white-space: nowrap;
}

.note-popover .popover-content .note-color .dropdown-toggle,
.note-editor .card-header.note-toolbar .note-color .dropdown-toggle {
  width: 20px;
  padding-left: 1px;
}

.swal2-shown {
  overflow-x: unset;
}

.swal2-shown .swal2-popup {
  padding: 0;
  border-radius: 0.3rem;
}

.swal2-shown .swal2-popup .swal2-checkbox,
.swal2-shown .swal2-popup .swal2-file,
.swal2-shown .swal2-popup .swal2-input,
.swal2-shown .swal2-popup .swal2-radio,
.swal2-shown .swal2-popup .swal2-select,
.swal2-shown .swal2-popup .swal2-textarea {
  margin: auto;
}

.swal2-shown .swal2-popup .swal2-header {
  align-items: start;
  padding: 20px;
}

.swal2-shown .swal2-popup .swal2-header .swal2-progresssteps {
  margin: 0;
  padding: 10px 0 20px;
}

.swal2-shown .swal2-popup .swal2-header .swal2-icon {
  order: 2;
  margin: 30px auto 0;
}

.swal2-shown .swal2-popup .swal2-header .swal2-image {
  order: 3;
  margin: 30px auto 0;
}

.swal2-shown .swal2-popup .swal2-header .swal2-title {
  font-size: 1.538rem;
  font-weight: 300;
  order: 1;
  margin: 0;
  line-height: 1.538;
}

.swal2-shown .swal2-popup .swal2-close {
  width: auto;
  height: auto;
  right: 5px;
  outline: none;
  opacity: 0.4;
  color: #000000;
  font-size: 2.15rem;
  font-weight: bold;
  line-height: 1;
  padding: 1rem;
}

.swal2-shown .swal2-popup .swal2-close:hover {
  opacity: 0.75;
}

.swal2-shown .swal2-popup .swal2-content {
  padding: 10px 20px 20px;
}

.swal2-shown .swal2-popup .swal2-content #swal2-content {
  text-align: left;
  font-size: 1rem;
  color: #545454;
  font-weight: 400;
  line-height: 21px;
}

.swal2-shown .swal2-popup .swal2-content .swal2-textarea {
  min-height: 100px;
}

.swal2-shown .swal2-popup .swal2-content .swal2-input, .swal2-shown .swal2-popup .swal2-content .swal2-textarea, .swal2-shown .swal2-popup .swal2-content .swal2-select {
  display: block;
  width: 100%;
  height: 3.23077rem;
  padding: 0.923077rem 1.307rem;
  font-size: 1rem;
  line-height: 1;
  color: #878787;
  background-color: #FFFFFF;
  background-clip: padding-box;
  border: 2px solid #ebebeb;
  border-width: 1px;
  box-shadow: none;
  padding: 10px 12px;
  font-size: 1.1538rem;
  transition: none;
  height: 3.23077rem;
  border-radius: 0;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .swal2-shown .swal2-popup .swal2-content .swal2-input, .swal2-shown .swal2-popup .swal2-content .swal2-textarea, .swal2-shown .swal2-popup .swal2-content .swal2-select {
    transition: none;
  }
}

.swal2-shown .swal2-popup .swal2-content .swal2-input::-ms-expand, .swal2-shown .swal2-popup .swal2-content .swal2-textarea::-ms-expand, .swal2-shown .swal2-popup .swal2-content .swal2-select::-ms-expand {
  background-color: transparent;
  border: 0;
}

.swal2-shown .swal2-popup .swal2-content .swal2-input:focus, .swal2-shown .swal2-popup .swal2-content .swal2-textarea:focus, .swal2-shown .swal2-popup .swal2-content .swal2-select:focus {
  color: #878787;
  background-color: #FFFFFF;
  border-color: #89e4bf;
  outline: 0;
  box-shadow: 0 0 0 0 transparent;
}

.swal2-shown .swal2-popup .swal2-content .swal2-input::placeholder, .swal2-shown .swal2-popup .swal2-content .swal2-textarea::placeholder, .swal2-shown .swal2-popup .swal2-content .swal2-select::placeholder {
  color: #bababa;
  opacity: 1;
}

.swal2-shown .swal2-popup .swal2-content .swal2-input:disabled, .swal2-shown .swal2-popup .swal2-content .swal2-input[readonly], .swal2-shown .swal2-popup .swal2-content .swal2-textarea:disabled, .swal2-shown .swal2-popup .swal2-content .swal2-textarea[readonly], .swal2-shown .swal2-popup .swal2-content .swal2-select:disabled, .swal2-shown .swal2-popup .swal2-content .swal2-select[readonly] {
  background-color: #fafafa;
  opacity: 1;
}

.swal2-shown .swal2-popup .swal2-actions {
  padding: 20px;
  margin: 0;
  justify-content: end;
}

.swal2-shown .swal2-popup.swal2-loading .swal2-actions .swal2-styled.swal2-confirm {
  border-left-color: #2cc185 !important;
  border-right-color: #2cc185 !important;
  background-color: transparent;
}

.swal2-shown .swal2-popup .swal2-footer {
  margin: 0;
  padding: 20px;
  justify-content: left;
}

.swal2-shown .content-text-center.swal2-popup .swal2-content #swal2-content {
  text-align: center;
}

.swal2-shown .content-header-left.swal2-popup .swal2-header {
  align-items: start;
}

.swal2-shown .content-header-center.swal2-popup .swal2-header {
  align-items: center;
}

.swal2-shown .content-header-right.swal2-popup .swal2-header {
  align-items: end;
}

.swal2-shown .content-header-title-left.swal2-popup .swal2-header .swal2-title {
  align-self: start;
}

.swal2-shown .content-header-title-center.swal2-popup .swal2-header .swal2-title {
  align-self: center;
}

.swal2-shown .content-header-title-right.swal2-popup .swal2-header .swal2-title {
  align-self: end;
}

.swal2-shown .content-text-left.swal2-popup .swal2-content #swal2-content {
  text-align: left;
}

.swal2-shown .content-text-center.swal2-popup .swal2-content #swal2-content {
  text-align: center;
}

.swal2-shown .content-text-right.swal2-popup .swal2-content #swal2-content {
  text-align: right;
}

.swal2-shown .content-actions-left.swal2-popup .swal2-actions {
  justify-content: start;
}

.swal2-shown .content-actions-center.swal2-popup .swal2-actions {
  justify-content: center;
}

.swal2-shown .content-actions-right.swal2-popup .swal2-actions {
  justify-content: end;
}

.swal2-shown .content-footer-left.swal2-popup .swal2-footer {
  justify-content: start;
}

.swal2-shown .content-footer-center.swal2-popup .swal2-footer {
  justify-content: center;
}

.swal2-shown .content-footer-right.swal2-popup .swal2-footer {
  justify-content: end;
}

.swal2-shown .colored-header.swal2-popup {
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
}

.swal2-shown .colored-header.swal2-popup .swal2-header {
  padding: 30px 20px;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
}

.swal2-shown .colored-header.swal2-popup .swal2-header .swal2-title, .swal2-shown .colored-header.swal2-popup .swal2-header .swal2-icon {
  color: #FFFFFF;
  border-color: #FFFFFF;
}

.swal2-shown .colored-header.swal2-popup .swal2-content {
  padding: 35px 20px 20px;
}

.swal2-shown .colored-header.swal2-popup .swal2-actions .swal2-styled.swal2-confirm {
  border-left-color: transparent !important;
  border-right-color: transparent !important;
}

.swal2-shown .colored-header .swal2-close {
  color: rgba(0, 0, 0, 0.7);
  line-height: 1.95;
}

.swal2-shown .colored-header-primary.swal2-popup .swal2-header {
  background-color: #2cc185;
}

.swal2-shown .colored-header-primary.swal2-popup .swal2-actions .swal2-styled.swal2-confirm {
  color: #212529;
  background-color: #FFFFFF;
  border-color: #2cc185;
}

.swal2-shown .colored-header-primary.swal2-popup .swal2-actions .swal2-styled.swal2-confirm:hover {
  color: #FFFFFF;
  background-color: #2cc185;
  border-color: #239768;
}

.swal2-shown .colored-header-primary.swal2-popup .swal2-actions .swal2-styled.swal2-confirm:focus, .swal2-shown .colored-header-primary.swal2-popup .swal2-actions .swal2-styled.swal2-confirm.focus {
  box-shadow: 0 0 0 0 rgba(42, 170, 119, 0.5);
}

.swal2-shown .colored-header-primary.swal2-popup .swal2-actions .swal2-styled.swal2-confirm.disabled, .swal2-shown .colored-header-primary.swal2-popup .swal2-actions .swal2-styled.swal2-confirm:disabled {
  color: #212529;
  background-color: #FFFFFF;
  border-color: #2cc185;
}

.swal2-shown .colored-header-primary.swal2-popup .swal2-actions .swal2-styled.swal2-confirm:not(:disabled):not(.disabled):active, .swal2-shown .colored-header-primary.swal2-popup .swal2-actions .swal2-styled.swal2-confirm:not(:disabled):not(.disabled).active,
.show > .swal2-shown .colored-header-primary.swal2-popup .swal2-actions .swal2-styled.swal2-confirm.dropdown-toggle {
  color: #212529;
  background-color: #e6e6e6;
  border-color: #208d61;
}

.swal2-shown .colored-header-primary.swal2-popup .swal2-actions .swal2-styled.swal2-confirm:not(:disabled):not(.disabled):active:focus, .swal2-shown .colored-header-primary.swal2-popup .swal2-actions .swal2-styled.swal2-confirm:not(:disabled):not(.disabled).active:focus,
.show > .swal2-shown .colored-header-primary.swal2-popup .swal2-actions .swal2-styled.swal2-confirm.dropdown-toggle:focus {
  box-shadow: 0 0 0 0 rgba(42, 170, 119, 0.5);
}

.swal2-shown .colored-header-info.swal2-popup .swal2-header {
  background-color: #58C7EF;
}

.swal2-shown .colored-header-info.swal2-popup .swal2-actions .swal2-styled.swal2-confirm {
  color: #212529;
  background-color: #FFFFFF;
  border-color: #58C7EF;
}

.swal2-shown .colored-header-info.swal2-popup .swal2-actions .swal2-styled.swal2-confirm:hover {
  color: #212529;
  background-color: #58C7EF;
  border-color: #29b7eb;
}

.swal2-shown .colored-header-info.swal2-popup .swal2-actions .swal2-styled.swal2-confirm:focus, .swal2-shown .colored-header-info.swal2-popup .swal2-actions .swal2-styled.swal2-confirm.focus {
  box-shadow: 0 0 0 0 rgba(80, 175, 209, 0.5);
}

.swal2-shown .colored-header-info.swal2-popup .swal2-actions .swal2-styled.swal2-confirm.disabled, .swal2-shown .colored-header-info.swal2-popup .swal2-actions .swal2-styled.swal2-confirm:disabled {
  color: #212529;
  background-color: #FFFFFF;
  border-color: #58C7EF;
}

.swal2-shown .colored-header-info.swal2-popup .swal2-actions .swal2-styled.swal2-confirm:not(:disabled):not(.disabled):active, .swal2-shown .colored-header-info.swal2-popup .swal2-actions .swal2-styled.swal2-confirm:not(:disabled):not(.disabled).active,
.show > .swal2-shown .colored-header-info.swal2-popup .swal2-actions .swal2-styled.swal2-confirm.dropdown-toggle {
  color: #212529;
  background-color: #e6e6e6;
  border-color: #1eb3e9;
}

.swal2-shown .colored-header-info.swal2-popup .swal2-actions .swal2-styled.swal2-confirm:not(:disabled):not(.disabled):active:focus, .swal2-shown .colored-header-info.swal2-popup .swal2-actions .swal2-styled.swal2-confirm:not(:disabled):not(.disabled).active:focus,
.show > .swal2-shown .colored-header-info.swal2-popup .swal2-actions .swal2-styled.swal2-confirm.dropdown-toggle:focus {
  box-shadow: 0 0 0 0 rgba(80, 175, 209, 0.5);
}

.swal2-shown .colored-header-warning.swal2-popup .swal2-header {
  background-color: #FFDC42;
}

.swal2-shown .colored-header-warning.swal2-popup .swal2-actions .swal2-styled.swal2-confirm {
  color: #212529;
  background-color: #FFFFFF;
  border-color: #FFDC42;
}

.swal2-shown .colored-header-warning.swal2-popup .swal2-actions .swal2-styled.swal2-confirm:hover {
  color: #212529;
  background-color: #FFDC42;
  border-color: #ffd30f;
}

.swal2-shown .colored-header-warning.swal2-popup .swal2-actions .swal2-styled.swal2-confirm:focus, .swal2-shown .colored-header-warning.swal2-popup .swal2-actions .swal2-styled.swal2-confirm.focus {
  box-shadow: 0 0 0 0 rgba(222, 193, 62, 0.5);
}

.swal2-shown .colored-header-warning.swal2-popup .swal2-actions .swal2-styled.swal2-confirm.disabled, .swal2-shown .colored-header-warning.swal2-popup .swal2-actions .swal2-styled.swal2-confirm:disabled {
  color: #212529;
  background-color: #FFFFFF;
  border-color: #FFDC42;
}

.swal2-shown .colored-header-warning.swal2-popup .swal2-actions .swal2-styled.swal2-confirm:not(:disabled):not(.disabled):active, .swal2-shown .colored-header-warning.swal2-popup .swal2-actions .swal2-styled.swal2-confirm:not(:disabled):not(.disabled).active,
.show > .swal2-shown .colored-header-warning.swal2-popup .swal2-actions .swal2-styled.swal2-confirm.dropdown-toggle {
  color: #212529;
  background-color: #e6e6e6;
  border-color: #ffd002;
}

.swal2-shown .colored-header-warning.swal2-popup .swal2-actions .swal2-styled.swal2-confirm:not(:disabled):not(.disabled):active:focus, .swal2-shown .colored-header-warning.swal2-popup .swal2-actions .swal2-styled.swal2-confirm:not(:disabled):not(.disabled).active:focus,
.show > .swal2-shown .colored-header-warning.swal2-popup .swal2-actions .swal2-styled.swal2-confirm.dropdown-toggle:focus {
  box-shadow: 0 0 0 0 rgba(222, 193, 62, 0.5);
}

.swal2-shown .colored-header-danger.swal2-popup .swal2-header {
  background-color: #F45846;
}

.swal2-shown .colored-header-danger.swal2-popup .swal2-actions .swal2-styled.swal2-confirm {
  color: #212529;
  background-color: #FFFFFF;
  border-color: #F45846;
}

.swal2-shown .colored-header-danger.swal2-popup .swal2-actions .swal2-styled.swal2-confirm:hover {
  color: #FFFFFF;
  background-color: #F45846;
  border-color: #f12d16;
}

.swal2-shown .colored-header-danger.swal2-popup .swal2-actions .swal2-styled.swal2-confirm:focus, .swal2-shown .colored-header-danger.swal2-popup .swal2-actions .swal2-styled.swal2-confirm.focus {
  box-shadow: 0 0 0 0 rgba(212, 80, 66, 0.5);
}

.swal2-shown .colored-header-danger.swal2-popup .swal2-actions .swal2-styled.swal2-confirm.disabled, .swal2-shown .colored-header-danger.swal2-popup .swal2-actions .swal2-styled.swal2-confirm:disabled {
  color: #212529;
  background-color: #FFFFFF;
  border-color: #F45846;
}

.swal2-shown .colored-header-danger.swal2-popup .swal2-actions .swal2-styled.swal2-confirm:not(:disabled):not(.disabled):active, .swal2-shown .colored-header-danger.swal2-popup .swal2-actions .swal2-styled.swal2-confirm:not(:disabled):not(.disabled).active,
.show > .swal2-shown .colored-header-danger.swal2-popup .swal2-actions .swal2-styled.swal2-confirm.dropdown-toggle {
  color: #212529;
  background-color: #e6e6e6;
  border-color: #ec250e;
}

.swal2-shown .colored-header-danger.swal2-popup .swal2-actions .swal2-styled.swal2-confirm:not(:disabled):not(.disabled):active:focus, .swal2-shown .colored-header-danger.swal2-popup .swal2-actions .swal2-styled.swal2-confirm:not(:disabled):not(.disabled).active:focus,
.show > .swal2-shown .colored-header-danger.swal2-popup .swal2-actions .swal2-styled.swal2-confirm.dropdown-toggle:focus {
  box-shadow: 0 0 0 0 rgba(212, 80, 66, 0.5);
}

.swal2-shown .colored-header-dark.swal2-popup .swal2-header {
  background-color: #323232;
}

.swal2-shown .colored-header-dark.swal2-popup .swal2-actions .swal2-styled.swal2-confirm {
  color: #212529;
  background-color: #FFFFFF;
  border-color: #545454;
}

.swal2-shown .colored-header-dark.swal2-popup .swal2-actions .swal2-styled.swal2-confirm:hover {
  color: #FFFFFF;
  background-color: #545454;
  border-color: #3b3b3b;
}

.swal2-shown .colored-header-dark.swal2-popup .swal2-actions .swal2-styled.swal2-confirm:focus, .swal2-shown .colored-header-dark.swal2-popup .swal2-actions .swal2-styled.swal2-confirm.focus {
  box-shadow: 0 0 0 0 rgba(76, 77, 78, 0.5);
}

.swal2-shown .colored-header-dark.swal2-popup .swal2-actions .swal2-styled.swal2-confirm.disabled, .swal2-shown .colored-header-dark.swal2-popup .swal2-actions .swal2-styled.swal2-confirm:disabled {
  color: #212529;
  background-color: #FFFFFF;
  border-color: #545454;
}

.swal2-shown .colored-header-dark.swal2-popup .swal2-actions .swal2-styled.swal2-confirm:not(:disabled):not(.disabled):active, .swal2-shown .colored-header-dark.swal2-popup .swal2-actions .swal2-styled.swal2-confirm:not(:disabled):not(.disabled).active,
.show > .swal2-shown .colored-header-dark.swal2-popup .swal2-actions .swal2-styled.swal2-confirm.dropdown-toggle {
  color: #212529;
  background-color: #e6e6e6;
  border-color: #343434;
}

.swal2-shown .colored-header-dark.swal2-popup .swal2-actions .swal2-styled.swal2-confirm:not(:disabled):not(.disabled):active:focus, .swal2-shown .colored-header-dark.swal2-popup .swal2-actions .swal2-styled.swal2-confirm:not(:disabled):not(.disabled).active:focus,
.show > .swal2-shown .colored-header-dark.swal2-popup .swal2-actions .swal2-styled.swal2-confirm.dropdown-toggle:focus {
  box-shadow: 0 0 0 0 rgba(76, 77, 78, 0.5);
}

.swal2-shown .modal-full-color.swal2-popup .swal2-header .swal2-title, .swal2-shown .modal-full-color.swal2-popup .swal2-header .swal2-icon {
  color: #FFFFFF;
  border-color: #FFFFFF;
}

.swal2-shown .modal-full-color.swal2-popup .swal2-content #swal2-content {
  color: #FFFFFF;
}

.swal2-shown .modal-full-color .swal2-close {
  color: #FFFFFF;
  opacity: 0.6;
}

.swal2-shown .modal-full-color .swal2-close:hover {
  opacity: 1;
}

.swal2-shown .modal-full-color-primary {
  background-color: #2cc185;
}

.swal2-shown .modal-full-color-info {
  background-color: #58C7EF;
}

.swal2-shown .modal-full-color-warning {
  background-color: #FFDC42;
}

.swal2-shown .modal-full-color-danger {
  background-color: #F45846;
}

.swal2-shown .modal-full-color-dark {
  background-color: #323232;
}

.swal2-progress-steps .swal2-progress-step {
  background-color: #2cc185;
}

.swal2-progress-steps .swal2-progress-step.swal2-active-progress-step {
  background-color: #2cc185;
}

.swal2-progress-steps .swal2-progress-step.swal2-active-progress-step ~ .swal2-progress-step-line,
.swal2-progress-steps .swal2-progress-step.swal2-active-progress-step ~ .swal2-progress-step {
  background-color: #89e4bf;
}

.swal2-progress-steps .swal2-progress-step-line {
  background-color: #2cc185;
}

.swal2-actions .btn {
  margin-right: 5px;
}

/*------------------------------------------------------------------
  [Wizard]
*/
.block-wizard {
  padding: 0;
}

.fuelux .wizard {
  box-shadow: none;
  border-radius: 0;
  -webkit-border-radius: 0;
  border: 0;
  background: #FFFFFF;
}

.fuelux .wizard label.control-label {
  font-weight: 600;
}

.fuelux .wizard .wizard-title {
  margin: 15px 0 20px;
}

.fuelux .wizard > .actions {
  line-height: 48px;
  background: #f7f7f7;
  padding: 0;
  font-size: 0;
  z-index: 1;
}

.fuelux .wizard > .actions .btn {
  position: relative;
  line-height: 48px;
  border-width: 0;
  border-radius: 0;
  vertical-align: top;
  padding: 0 14px;
  box-shadow: none;
  font-weight: 400;
  color: #949494;
}

.fuelux .wizard > .actions .btn:focus, .fuelux .wizard > .actions .btn:active {
  box-shadow: none;
  border-width: 0;
}

.fuelux .wizard > .actions .btn:hover {
  background-color: #f7f7f7;
}

.fuelux .wizard > .actions .btn:first-child {
  border-right: 1px solid #d4d4d4;
}

.fuelux .wizard > .actions .btn .icon {
  position: absolute;
  top: 12px;
  font-size: 25px;
  line-height: 1;
  color: #949494;
}

.fuelux .wizard > .actions .btn.btn-prev {
  padding-left: 25px;
}

.fuelux .wizard > .actions .btn.btn-prev .icon {
  left: 4px;
}

.fuelux .wizard > .actions .btn.btn-next {
  padding-right: 25px;
}

.fuelux .wizard > .actions .btn.btn-next .icon {
  right: 4px;
}

.fuelux .wizard > .steps-container {
  border-radius: 0;
}

.fuelux .wizard > .steps-container > ul.steps > li {
  height: 48px;
  background-color: #f7f7f7;
}

.fuelux .wizard > .steps-container > ul.steps > li .chevron {
  border-left-color: #e0e0e0;
}

.fuelux .wizard > .steps-container > ul.steps > li .chevron:before {
  border-left: 14px solid #f7f7f7;
}

.fuelux .wizard > .steps-container > ul.steps li {
  font-size: 15px;
}

.fuelux .wizard > .steps-container > ul.steps li:first-child {
  border-radius: 0;
}

.fuelux .wizard > .steps-container > ul.steps li.active {
  background: #FFFFFF;
  color: #2cc185;
}

.fuelux .wizard > .steps-container > ul.steps li.active .chevron:before {
  -moz-transform: scale(0.9999);
  border-left: 14px solid #FFFFFF;
}

.fuelux .wizard > .steps-container > ul.steps li.complete {
  color: #FFFFFF;
  background: #2cc185;
}

.fuelux .wizard > .steps-container > ul.steps li.complete .chevron {
  border-left-color: #24a06e;
}

.fuelux .wizard > .steps-container > ul.steps li.complete .chevron:before {
  border-left: 14px solid #2cc185;
}

.fuelux .wizard > .steps-container > ul.steps li.complete:hover {
  background: #2fcd8e;
}

.fuelux .wizard > .steps-container > ul.steps li.complete:hover .chevron {
  border-left-color: #26a874;
}

.fuelux .wizard > .steps-container > ul.steps li.complete:hover .chevron:before {
  border-left-color: #2fcd8e;
}

.fuelux .wizard > .steps-container > ul.steps li .chevron {
  -moz-transform: scale(0.9999);
}

.fuelux .wizard > .actions button {
  font-size: 12px;
}

.fuelux .wizard > .actions button i {
  font-size: 11px;
  line-height: 17px;
}

.fuelux .wizard > .actions button.btn-prev i {
  margin-right: 3px;
}

.fuelux .wizard > .actions button.btn-next i {
  margin-left: 3px;
}

.fuelux .wizard .step-content {
  padding: 20px 30px;
  clear: both;
  border-top: 1px solid #E2E2E2;
}

@media (max-width: 767px) {
  .wizard-row .fuelux {
    position: static;
  }
}

.editable-checklist label {
  align-items: center;
  justify-content: flex-start;
}

.editable-checklist label input[type="checkbox"] {
  margin-right: 5px;
}

.editableform .form-group {
  flex-flow: column;
  align-items: flex-start;
}

.ads-chart {
  margin-bottom: 29px;
}

.ads-chart.widget.widget-fullwidth .widget-head {
  margin-bottom: 1rem;
}

.ads-resume {
  display: flex;
  justify-content: space-between;
}

.ads-info {
  display: flex;
  flex-direction: column;
  flex: 0 1 45%;
  padding: 30px;
}

.ads-progress .progress {
  border-radius: 0.25rem;
  margin-bottom: 1.76923rem;
  height: auto;
}

.ads-progress .progress-bar {
  display: block;
  border-radius: 0.25rem;
  height: 4px;
}

.ads-progress-counter {
  display: block;
  font-size: 1.84615rem;
  line-height: 1;
}

.ads-progress-title {
  font-size: 1.077rem;
}

.ads-legend .legendColorBox {
  padding: 0 7px 0 0;
}

.ads-legend .legendColorBox > div {
  border: 0 !important;
}

.ads-legend .legendColorBox > div > div {
  width: 8px !important;
  height: 8px !important;
}

.ads-legend .legendLabel {
  font-size: 1rem;
}

.ads-users {
  position: relative;
  padding-right: 30px;
  width: 50%;
}

.users-chart-counter {
  position: absolute;
  display: flex;
  flex-direction: column;
  top: 50%;
  width: 100%;
  text-align: center;
  margin-top: -20px;
}

.users-chart-counter .users-counter {
  font-size: 1.84615rem;
  line-height: 1;
}

.widget-calendar {
  padding: 0;
}

.cal-calendar {
  position: relative;
  padding: 40px;
  background-color: #545454;
}

@media (max-width: 991.98px) {
  .cal-calendar {
    padding: 20px 10px;
  }
}

.cal-calendar:after {
  position: absolute;
  display: block;
  top: 135px;
  left: 0;
  background-color: #2cc185;
  height: 46px;
  width: 100%;
  content: " ";
  z-index: 0;
}

@media (max-width: 991.98px) {
  .cal-calendar:after {
    top: 75px;
  }
}

.cal-calendar .ui-datepicker {
  position: relative;
  z-index: 1;
}

.cal-calendar .ui-datepicker .ui-datepicker-header {
  margin-bottom: 4.61538rem;
}

@media (max-width: 991.98px) {
  .cal-calendar .ui-datepicker .ui-datepicker-header {
    margin-bottom: 2.31538rem;
  }
}

.cal-calendar .ui-datepicker .ui-datepicker-header .ui-datepicker-title {
  color: #FFFFFF;
  cursor: default;
  line-height: 1;
  margin: 0 auto;
  text-align: center;
  font-weight: 300;
  font-size: 2.76923rem;
}

@media (max-width: 991.98px) {
  .cal-calendar .ui-datepicker .ui-datepicker-header .ui-datepicker-title {
    font-size: 1.8rem;
  }
}

.cal-calendar .ui-datepicker .ui-datepicker-header .ui-datepicker-prev, .cal-calendar .ui-datepicker .ui-datepicker-header .ui-datepicker-next {
  height: 1.8em;
  position: absolute;
  top: -5px;
  width: 2.76923rem;
  color: #FFFFFF;
  font-size: 2.76923rem;
}

@media (max-width: 991.98px) {
  .cal-calendar .ui-datepicker .ui-datepicker-header .ui-datepicker-prev, .cal-calendar .ui-datepicker .ui-datepicker-header .ui-datepicker-next {
    font-size: 1.69231rem;
  }
}

@media (max-width: 575.98px) {
  .cal-calendar .ui-datepicker .ui-datepicker-header .ui-datepicker-prev, .cal-calendar .ui-datepicker .ui-datepicker-header .ui-datepicker-next {
    font-size: 1.69231rem;
  }
}

.cal-calendar .ui-datepicker .ui-datepicker-header .ui-datepicker-prev span, .cal-calendar .ui-datepicker .ui-datepicker-header .ui-datepicker-next span {
  overflow: hidden;
  display: block;
  text-indent: -99999px;
}

.cal-calendar .ui-datepicker .ui-datepicker-header .ui-datepicker-prev span:after, .cal-calendar .ui-datepicker .ui-datepicker-header .ui-datepicker-next span:after {
  cursor: pointer;
  content: "\e686";
  font-family: "Stroke 7";
  display: block;
  position: absolute;
  width: 16px;
  height: 16px;
  top: 0;
  text-indent: 0;
}

.cal-calendar .ui-datepicker .ui-datepicker-header .ui-datepicker-prev {
  left: 0;
}

.cal-calendar .ui-datepicker .ui-datepicker-header .ui-datepicker-next {
  right: 0;
}

.cal-calendar .ui-datepicker .ui-datepicker-header .ui-datepicker-next span:after {
  content: "\e684";
}

.cal-calendar .ui-datepicker table.ui-datepicker-calendar {
  width: 100%;
}

.cal-calendar .ui-datepicker table.ui-datepicker-calendar th {
  border: 0;
  padding: .769231rem .230769rem 1.30769rem;
  text-align: center;
  font-weight: 300;
  color: #FFFFFF;
}

.cal-calendar .ui-datepicker table.ui-datepicker-calendar th span {
  font-size: 1.30769rem;
}

.cal-calendar .ui-datepicker table.ui-datepicker-calendar tr td {
  border: 0;
  padding: .230769rem;
  text-align: center;
}

.cal-calendar .ui-datepicker table.ui-datepicker-calendar tr td a {
  display: inline-block;
  padding: .615385rem .538462rem;
  text-align: center;
  text-decoration: none;
  margin-top: .769231rem;
  color: #FFFFFF;
  width: 33px;
  height: 33px;
  line-height: 1;
  font-size: 1.30769rem;
}

.cal-calendar .ui-datepicker table.ui-datepicker-calendar tr td a:hover {
  color: #2cc185;
}

.cal-calendar .ui-datepicker table.ui-datepicker-calendar tr td a.ui-state-active, .cal-calendar .ui-datepicker table.ui-datepicker-calendar tr td a.ui-state-highlight {
  background-color: #2cc185;
  color: #545454;
  border-radius: 50%;
}

.cal-calendar .ui-datepicker table.ui-datepicker-calendar tr td a.ui-state-highlight {
  background-color: #2cc185;
  color: #FFFFFF;
}

.add-note {
  display: block;
  text-align: center;
  color: #FFFFFF;
  font-size: 1.53846rem;
  font-weight: 300;
  line-height: 1;
  margin-top: 2.30769rem;
}

@media (max-width: 991.98px) {
  .add-note {
    font-size: 1.3rem;
  }
}

.add-note:hover {
  color: #FFFFFF;
}

.add-note .icon {
  color: #2cc185;
  font-size: 2rem;
  margin-right: 5px;
  vertical-align: middle;
  line-height: 0.2;
}

.cal-notes {
  background-color: #FFFFFF;
  padding: 30px;
}

.cal-notes span {
  display: block;
}

.cal-notes span.day {
  font-size: 2.76923rem;
  font-weight: 300;
}

.cal-notes span.date {
  font-size: 15px;
  font-weight: 300;
  color: #2cc185;
}

.cal-notes span.title {
  font-size: 1.38462rem;
  border-bottom: 1px solid #d9d9d9;
  margin-top: 1.38462rem;
  padding-bottom: .153846rem;
}

.cal-notes ul {
  padding: 0;
  list-style: none;
  padding-top: 20px;
}

.cal-notes ul li {
  padding-bottom: 10px;
  font-size: 1.077rem;
}

.cal-notes ul li span {
  display: inline-block;
}

.cal-notes ul li span.hour {
  float: right;
  color: #2cc185;
}

.cal-notes ul li span.event-name {
  font-size: 1.077rem;
}

.cal-notes ul li:last-child {
  padding-bottom: 0;
}

@media (max-width: 575.98px) {
  .cal-notes,
  .cal-calendar {
    display: block;
  }
  .cal-notes {
    width: 100%;
  }
}

.earnings {
  margin-bottom: 29px;
}

.earnings.widget.widget-fullwidth .widget-head {
  margin-bottom: 1rem;
}

.earnings-resume {
  padding: 0 20px;
  margin-bottom: -15px;
}

.earnings-value {
  display: flex;
  flex-direction: column;
  margin-bottom: 1rem;
}

.earnings-value.earnings-value-big {
  margin-bottom: 2.30769rem;
}

.earnings-counter {
  font-size: 1.23077rem;
}

.earnings-value-big .earnings-counter {
  font-size: 2.76923rem;
}

.earnings-title {
  color: #999999;
}

.widget-indicators {
  background-color: #FFFFFF;
  margin-bottom: 29px;
}

.indicator-item {
  padding: 12px 30px;
  display: flex;
  width: 100%;
  margin-bottom: 0;
  align-items: center;
  border-bottom: 1px solid #e6e6e6;
}

.indicator-item-icon {
  flex: 1;
}

.indicator-item-icon .icon {
  font-size: 3.07692rem;
  color: #2cc185;
}

.indicator-item-value {
  flex: 0 1 50%;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.indicator-value-counter {
  font-size: 1.84615rem;
}

.indicator-value-title {
  font-weight: 300;
}

.project-list {
  background-color: transparent;
  margin-bottom: 29px;
}

.project-list-title {
  font-size: 1.38462rem;
  margin-bottom: 28px;
}

.project-item {
  position: relative;
  display: flex;
  align-items: center;
  background-color: #FFFFFF;
  padding: 23px;
  margin-bottom: .923077rem;
}

@media (max-width: 767.98px) {
  .project-item {
    flex-direction: column;
    align-items: stretch;
  }
}

.project-item .description {
  font-size: .846154rem;
  color: #999999;
}

@media (max-width: 767.98px) {
  .project-item .description {
    font-size: 1rem;
  }
}

.project-item-column {
  display: flex;
}

.project-item-title {
  display: flex;
  flex: 0 1 20%;
  flex-direction: column;
  padding-right: 5px;
}

@media (max-width: 767.98px) {
  .project-item-title {
    order: 2;
    margin-bottom: 10px;
  }
}

.project-item-user {
  display: flex;
  align-items: center;
  flex: 0 1 25%;
  padding-right: 5px;
}

@media (max-width: 767.98px) {
  .project-item-user {
    order: 1;
    align-items: center;
    margin-bottom: 20px;
  }
}

.project-item-user .user-avatar {
  margin-right: 12px;
}

.project-item-user .user-avatar img {
  border-radius: 50%;
  width: 30px;
  height: 30px;
}

@media (max-width: 767.98px) {
  .project-item-user .user-avatar img {
    height: 45px;
    width: 45px;
  }
}

.project-item-user .user-info {
  display: flex;
  flex-direction: column;
}

@media (max-width: 767.98px) {
  .project-item-user .user-info .name {
    font-size: 1.077rem;
  }
}

.project-item-state {
  display: flex;
  flex-direction: column;
  flex: 0 1 20%;
  padding-right: 5px;
}

@media (max-width: 767.98px) {
  .project-item-state {
    order: 3;
    margin-bottom: 10px;
  }
}

.project-item-date {
  display: flex;
  flex-direction: column;
  flex: 0 1 15%;
  padding-right: 5px;
}

@media (max-width: 767.98px) {
  .project-item-date {
    order: 4;
    margin-bottom: 10px;
  }
}

.project-item-progress {
  flex: 0 1 15%;
  display: flex;
  flex-direction: column;
}

@media (max-width: 767.98px) {
  .project-item-progress {
    order: 5;
    margin-bottom: 10px;
  }
}

.project-item-progress span {
  text-align: right;
}

.project-item-progress .progress {
  border-radius: 0.25rem;
  height: auto;
}

.project-item-progress .progress-bar {
  height: 6px;
  border-radius: 0.25rem;
}

.project-item-actions {
  flex: 0 1 5%;
  display: flex;
  justify-content: flex-end;
}

@media (max-width: 767.98px) {
  .project-item-actions {
    position: absolute;
    top: 23px;
    right: 23px;
  }
}

.project-item-actions .icon {
  font-size: 1.23077rem;
  margin-left: .384615rem;
}

@media (max-width: 767.98px) {
  .project-item-actions .icon {
    font-size: 1.38462rem;
  }
}

.todo-list {
  margin-bottom: 29px;
}

.todo-list-container {
  padding: 12px 23px 0;
}

.todo-tasks {
  padding: 0;
}

.todo-task {
  list-style: none;
  display: flex;
  align-items: center;
  padding: 12px 0;
}

.todo-task .custom-control {
  margin: 0;
  padding-left: 2.30769rem;
  flex: 1 1;
}

.todo-task .custom-control-input:checked ~ .custom-control-label {
  text-decoration: line-through;
}

.todo-new-task .input-group {
  border-top: 1px solid #ebebeb;
  padding: 12px 3px;
}

.todo-new-task input {
  border-width: 0;
}

.todo-new-task .input-group-append {
  background-color: transparent;
  border-width: 0;
  font-size: 30px;
  padding: .5rem .9rem;
  color: #c2c2c2;
}

.usage {
  background-color: #FFFFFF;
  padding: 20px 20px 35px;
  margin-bottom: 29px;
}

.usage-head {
  display: flex;
  align-items: center;
  margin-bottom: 1rem;
}

.usage-head-tools .icon {
  font-size: 16px;
}

.usage-head-title {
  flex: 1;
  font-size: 1.38462rem;
}

.usage-resume {
  display: flex;
  padding-top: .615385rem;
}

.usage-data {
  display: flex;
  flex: 1;
  flex-direction: column;
}

.usage-counter {
  font-size: 2.76923rem;
}

.usage-icon {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}

.usage-icon .icon {
  font-size: 3.69231rem;
}

.usage.usage-primary {
  background-color: #2cc185;
  color: #FFFFFF;
}

.usage.usage-primary .usage-detail {
  color: #358565;
}

.usage.usage-primary .usage-icon .icon {
  color: #358565;
}

.usage.usage-success {
  background-color: #2cc185;
  color: #FFFFFF;
}

.usage.usage-success .usage-detail {
  color: #358565;
}

.usage.usage-success .usage-icon .icon {
  color: #358565;
}

.usage.usage-info {
  background-color: #58C7EF;
  color: #FFFFFF;
}

.usage.usage-info .usage-detail {
  color: #41acd3;
}

.usage.usage-info .usage-icon .icon {
  color: #41acd3;
}

.usage.usage-warning {
  background-color: #FFDC42;
  color: #FFFFFF;
}

.usage.usage-warning .usage-detail {
  color: #e7c327;
}

.usage.usage-warning .usage-icon .icon {
  color: #e7c327;
}

.usage.usage-danger {
  background-color: #F45846;
  color: #FFFFFF;
}

.usage.usage-danger .usage-detail {
  color: #d8402f;
}

.usage.usage-danger .usage-icon .icon {
  color: #d8402f;
}

.usage.usage-dark {
  background-color: #323232;
  color: #FFFFFF;
}

.usage.usage-dark .usage-head-title {
  color: #b5b5b5;
}

.usage.usage-dark .usage-detail {
  color: #dbdbdb;
}

.usage.usage-dark .usage-counter {
  color: #dbdbdb;
}

.usage.usage-dark .usage-icon .icon {
  color: #dbdbdb;
}

.user-countdown {
  border-top-width: 2px;
}

.user-countdown .card-body {
  padding-bottom: 20px;
}

.countdown-title {
  font-size: 1.23077rem;
  font-weight: 300;
  margin-top: 1rem;
}

.time-component {
  display: inline-block;
  font-weight: 300;
  width: 33.3%;
  padding-top: 0.384615rem;
}

.time-component:last-child .time-counter:after {
  content: " ";
}

.time-counter {
  position: relative;
  display: block;
  text-align: center;
  font-size: 5rem;
  line-height: 1;
}

@media (max-width: 1199.98px) {
  .time-counter {
    font-size: 4rem;
  }
}

@media (max-width: 991.98px) {
  .time-counter {
    font-size: 3rem;
  }
}

@media (max-width: 767.98px) {
  .time-counter {
    font-size: 4rem;
  }
}

.time-counter:after {
  position: absolute;
  content: ":";
  display: block;
  right: 0;
  width: 6px;
  top: 0;
}

.time-component-title {
  display: block;
  text-align: center;
  font-size: 1.077rem;
  color: #323232;
  margin-top: 1rem;
}

.user-display {
  margin-bottom: 30px;
  background-color: #FFFFFF;
}

.user-display-cover {
  max-height: 290px;
  overflow: hidden;
}

@media (max-width: 767.98px) {
  .user-display-cover {
    max-height: 145px;
  }
}

.user-display-cover img {
  width: 100%;
}

.user-display-bottom {
  position: relative;
  padding: 20px;
}

@media (max-width: 767.98px) {
  .user-display-bottom {
    padding: 0;
  }
}

.user-display-id {
  position: absolute;
  left: 89px;
  top: -118.666667px;
  border-radius: 50%;
}

@media (max-width: 991.98px) {
  .user-display-id {
    left: 35.6px;
    top: -131.851852px;
  }
}

@media (max-width: 767.98px) {
  .user-display-id {
    left: 59.333333px;
    top: -117px;
  }
}

@media (max-width: 575.98px) {
  .user-display-id {
    text-align: center;
    margin-right: 0;
    left: 50%;
    margin-left: -44.5px;
    top: -127px;
  }
}

.user-display-avatar {
  width: 178px;
  height: 178px;
  border-radius: 50%;
  margin-bottom: 0.769231rem;
}

@media (max-width: 767.98px) {
  .user-display-avatar {
    width: 89px;
    height: 89px;
    margin-right: 10px;
    margin-bottom: 0;
  }
}

@media (max-width: 575.98px) {
  .user-display-avatar {
    margin-bottom: 0.769231rem;
  }
}

.user-display-name {
  font-size: 2.30769rem;
  line-height: 1;
  color: #404040;
}

@media (max-width: 767.98px) {
  .user-display-name {
    display: inline-block;
    color: #FFFFFF;
    text-shadow: 0 0 3px rgba(0, 0, 0, 0.8);
  }
}

@media (max-width: 575.98px) {
  .user-display-name {
    display: block;
    font-size: 1.23077rem;
    font-weight: 700;
  }
}

.user-display-stats {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}

@media (max-width: 575.98px) {
  .user-display-stats {
    display: block;
  }
}

.user-display-stat {
  border-right: 1px solid #d9d9d9;
  min-width: 153px;
  text-align: center;
  padding: 20px 0;
}

@media (max-width: 991.98px) {
  .user-display-stat {
    min-width: 0;
    width: 17%;
    padding: 15px 0;
  }
}

@media (max-width: 767.98px) {
  .user-display-stat {
    width: 25%;
  }
}

@media (max-width: 575.98px) {
  .user-display-stat {
    border-right: 0;
    width: 100%;
    display: flex;
    justify-content: space-between;
    padding: 10px 20px;
  }
}

.user-display-stat:last-child {
  border-right-width: 0;
}

.user-display-stat-counter {
  display: block;
  text-align: center;
  font-size: 2.76923rem;
  color: #2cc185;
  line-height: 1;
  margin-bottom: 0.5rem;
}

@media (max-width: 991.98px) {
  .user-display-stat-counter {
    font-size: 2.077rem;
  }
}

@media (max-width: 767.98px) {
  .user-display-stat-counter {
    font-size: 1.38462rem;
    order: 1;
  }
}

@media (max-width: 575.98px) {
  .user-display-stat-counter {
    margin-top: 0.2rem;
  }
}

.user-display-stat-title {
  display: block;
  text-align: center;
  font-size: 1.3rem;
  text-transform: uppercase;
  line-height: 1;
}

@media (max-width: 991.98px) {
  .user-display-stat-title {
    font-size: 1.154rem;
  }
}

@media (max-width: 767.98px) {
  .user-display-stat-title {
    font-size: 1.077rem;
  }
}

@media (max-width: 575.98px) {
  .user-display-stat-title {
    font-size: 0.923077rem;
    margin-top: 0.6rem;
  }
}

.user-info-list .card-header {
  font-weight: 300;
  text-transform: uppercase;
}

.user-info-list .card-header-divider {
  border-color: #d1d1d1;
}

.user-info-list table {
  width: 100%;
  table-layout: fixed;
}

.user-info-list table tr td {
  padding: 0.615385rem 0;
}

.user-info-list table tr td.icon {
  font-size: 1.53846rem;
  width: 28px;
  line-height: 1;
}

.user-info-list table tr td.icon > span {
  vertical-align: middle;
}

.user-info-list table tr td.item {
  font-size: 1.077rem;
  width: 34%;
}

.user-info-list table.social tr td.icon {
  font-size: 1.077rem;
  width: 10px;
}

.user-develop-chart .legend-container {
  padding: 20px;
}

.user-develop-chart .legendColorBox {
  padding-right: 7px;
}

.user-develop-chart .legendColorBox > div {
  border: 0 !important;
  border-radius: 50%;
}

.user-develop-chart .legendColorBox > div > div {
  width: 10px !important;
  height: 10px !important;
  border-radius: 50%;
}

.user-develop-chart .legendLabel {
  padding-right: 25px;
  font-size: 1rem;
}

@media (max-width: 575.98px) {
  .user-develop-chart .legendLabel {
    padding-right: 15px;
  }
}

.user-stats {
  padding: 0;
  background-color: #FFFFFF;
  margin-bottom: 29px;
}

.user-stats-id {
  padding: 32px;
  display: flex;
  align-items: center;
}

.user-stats-avatar {
  position: relative;
  margin-right: 12px;
}

.user-stats-avatar img {
  border-radius: 50%;
  width: 60px;
  height: 60px;
}

.user-stats-status-indicator {
  position: absolute;
  display: block;
  background-color: #545454;
  border-radius: 50%;
  height: 13px;
  width: 13px;
  top: 0;
  right: 2px;
  border: 2px solid #FFFFFF;
}

.user-stats-status-indicator.online {
  background-color: #2cc185;
}

.user-stats-status-indicator.away {
  background-color: #FFDC42;
}

.user-stats-status-indicator.busy {
  background-color: #F45846;
}

.user-stats-info {
  display: flex;
  flex-direction: column;
}

.user-stats-name {
  color: #3d3d3d;
  font-size: 1.38462rem;
}

.user-stats-position {
  color: #ababab;
}

.user-stats-indicators {
  padding: 32px;
  padding-top: 5px;
}

.user-stats-indicator {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: .615385rem 0;
}

.user-stats-indicator-title {
  font-weight: 300;
}

.user-stats-indicator-counter {
  font-size: 1.23077rem;
  color: #2cc185;
}

.user-stats-milestone {
  padding: 6px 32px 51.2px;
}

.user-stats-milestone span {
  display: block;
  margin-bottom: 10px;
}

.user-stats-milestone .progress {
  border-radius: 0.25rem;
  height: auto;
}

.user-stats-milestone .progress-bar {
  height: 8px;
  border-radius: 0.25rem;
}

.user-stats-more-details {
  display: block;
  padding: 18px 32px;
  font-size: 1.077rem;
  text-align: center;
  color: #545454;
  background-color: #fafafa;
}

.week-chart.widget.widget-fullwidth .widget-head {
  margin-bottom: 3rem;
}
